/* .dataBaseContainer {
  position: relative;
  width: 94%;
  margin: 0 auto;
} */

.dataBaseContainer {
  width: calc(100% - (4.69vw + 30px)) !important;
  margin-left: 4.69vw !important;
  margin-right: 30px !important;
  position: relative;
}

.rowGapTop30 {
  margin-top: 30px;
}

.ant-picker {
  padding: 4px 10px !important;
}

.g2-tooltip {
  width: 200px;
}

.analyzerContainer {
  border: 1px solid #ccc;
  position: absolute;
  top: 80px;
  background-color: #fff;
  border-radius: 8px;
  padding: 25px;
  max-width: 30%;
}

.analyzerHeader {
  font-size: 24px;
  font-family: Montserrat-SemiBold;
}

.analyzerHeader p {
  margin: 0;
}

.searchTxt {
  /* font-size: 13px;
  font-family: Montserrat-Medium; */
  margin: 16px 0 32px;
}

.analyzerContainer
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 6vh !important;
  border-radius: 10px;
}

.dataBaseDropdownStyle .ant-select-arrow {
  display: none;
}

.inputTop {
  position: absolute;
  z-index: 1;
  left: 39px;
  font-family: Montserrat-SemiBold;
  font-size: 10px;
  margin-top: 8px;
}

.dataBaseDropdownStyle .ant-select-selection-item {
  padding-top: 20px !important;
  font-size: 16px;
  font-family: Montserrat-Regular;
}

.dataBaseDropdownStyle .ant-select-selection-placeholder {
  padding-top: 22px !important;
  font-size: 16px;
  font-family: Montserrat-Regular;
}

.analyzerContainer .ant-picker-suffix {
  display: none;
}

.rangePickerStyle {
  margin-top: 22px;
}

.rangePickerStyle .ant-picker-range {
  height: 6vh;
  width: 100%;
  border-radius: 10px;
}

.rangePickerStyle .anticon svg {
  display: none;
}

.rangePickerStyle .ant-picker-separator {
  border-left: 1px solid #d9d9d9;
  height: 36px;
}

.endRight {
  left: 54% !important;
}

.rangePickerStyle .ant-picker-input > input:placeholder-shown {
  margin-top: 6px;
}

.rangePickerStyle .ant-picker-input {
  margin-top: 20px;
}

.searchAnalyticsBtn {
  margin-top: 22px;
  display: flex;
}

.searchAnalyticsBtn Button {
  width: 100%;
  color: #fff;
  font-family: Montserrat-SemiBold;
  letter-spacing: 0.8px;
  height: 42px;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 11px;
}

/* spraying operation */

/* .sprayContainer {
  display: flex;
  padding-left: 42px;
  padding-right: 30px;
  justify-content: space-between;
  height: calc(100vh - 150px);
} */

.sprayContainer {
  width: calc(100% - (4.69vw + 30px)) !important;
  margin-left: 4.69vw !important;
  margin-right: 30px !important;
  position: relative;
  display: flex;
  height: calc(100vh - 150px);
}

.sphVH {
  height: calc(100vh - 20px);
}
.spLeftCol {
  /* height: calc(100vh - 135px); */
  width: 26% !important;
}
.spchvh {
  height: calc(100vh - 100px);
}
.spchvh .piechartBlk1,
.spchvh .lMapCont,
.spchvh .spLeftCol {
  height: calc(100vh - 100px) !important;
}

.spchvh .piechartBlk1 {
  margin-bottom: 0px !important;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  /* width: 25%; */
}
.sprayBlk {
  display: flex;
  position: relative;
}
.pieChartStyle {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  /* min-width: 360px; */
  margin-right: 16px;
}
.piechartBlk {
  min-height: 273px;
  height: calc(100vh - 500px);
  overflow-y: auto;
}
.piechartBlk1 {
  min-height: 273px;
  /* height: calc(100vh - 60px); */
  overflow-y: auto;
}

.pieChartStyle .ant-tabs-tab {
  margin-top: 0;
}

.pieChartStyle .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 45px;
}

.pieChartStyle .ant-tabs-nav-wrap {
  position: fixed;
}

.operationalHours {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sprayTxt {
  font-family: Montserrat-SemiBold;
  text-align: center;
  margin-bottom: 14px;
  font-size: 16px;
}

.totalHours {
  font-family: Montserrat-Regular;
  font-size: 11px;
  letter-spacing: 0.6px;
}

.hrMinTxt {
  font-family: Montserrat-SemiBold;
  font-size: 11px;
}

.pieTxtStyle {
  width: 318px;
}

.pieChartStyle .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #44b3b7;
}
.pieChartStyle .ant-tabs-tab:hover {
  color: #44b3b7;
}
.pieChartStyle .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .tabNor {
  color: #000;
}
.pieChartStyle .ant-tabs-tab:hover .tabNor {
  color: #000;
}

.pieChartStyle .ant-tabs-nav-list {
  font-family: Montserrat-Regular;
}

.pieChartStyle .ant-tabs-ink-bar {
  position: absolute;
  top: 0px;
  height: 5px !important;
  background: #44b3b7 !important;
  border-radius: 0 0 10px 10px;
}

.tabNor {
  text-align: center;
  font-family: Montserrat-SemiBold;
}

.operationContainer {
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 10px 12px;
  margin-bottom: 8px;
  cursor: pointer;
}

.herbSprayStyle {
  font-family: Montserrat-SemiBold;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.6px;
}

.herSprayPercent {
  font-family: Montserrat-Medium;
  font-size: 14px;
}

.herSprayPercent .ant-progress-inner {
  width: 22px !important;
  height: 22px !important;
  margin-top: 111px;
  margin-left: -29px;
}

.herSprayPercent .ant-progress-text {
  display: none;
}

.herSprayPercent .ant-progress-circle-trail {
  stroke: #bfbfbf;
}

.dateStyle {
  font-family: Montserrat-Regular;
  font-size: 13px;
}

.progressBarColors {
  width: 100%;
  height: 10px;
  border: 1px solid #cccccc82;
  margin-top: 15px;
  display: flex;
}

.progressWidth1 {
  background-color: #44b3b7;
  height: 8px;
}

.progressWidth2 {
  background-color: #565bb1;
  height: 8px;
}

.progressWidth3 {
  background-color: #868bc9;
  height: 8px;
}

.progressWidth4 {
  background: repeating-linear-gradient(
    -45deg,
    #7e7d7d,
    #fff 2px,
    #e3dfdf 5px,
    #fff 5px
  );
  height: 8px;
}

.progressWidth5 {
  background-color: #91d9d8;
  height: 8px;
}

.herbiColor {
  background-color: #9bcd56;
}

.fungiColor {
  background-color: #ffb62f;
}

.herbiColor2 {
  background-color: #44b3b7;
}

.fungiFlex {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  margin-top: 8px;
}

.progressRadius {
  margin: 0 32px 0 0;
  border-radius: 6px;
}

.fntSize {
  font-family: Montserrat-Medium;
  font-size: 12px !important;
}

.opa {
  opacity: 0.4;
}

.videoFeedBtn {
  margin-top: 198px;
}

.videoFeedBtn Button {
  width: 100%;
  background-color: rgb(30, 58, 70);
  color: #fff;
  font-family: Montserrat-SemiBold;
  letter-spacing: 0.8px;
  height: 42px;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 11px;
}

.fSize {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-family: Montserrat-SemiBold;
}

.progressWidth5 {
  background-color: #a9bf51;
  height: 8px;
}

.progressWidth6 {
  background-color: #738023;
  height: 8px;
}

.progressWidth7 {
  background-color: #ccc;
  height: 8px;
}

.progressWidth8 {
  background-color: #67afb7b1;
  height: 8px;
}

.progressRportBarColors {
  width: 80%;
  height: 10px;
  border: 1px solid #cccccc82;
  margin-top: 15px;
  display: flex;
}

.hrMinTxtReport {
  font-family: Montserrat-SemiBold;
  font-size: 11px;
  margin-bottom: -12px;
}

.progressRadiusReport {
  border-radius: 6px;
  margin-right: 5px;
}

.sprayTbl {
  top: 50% !important;
  transform: translateY(-50%);
  right: 0;
  left: unset;
  position: absolute !important;
  width: 220px !important;
}
.sprayTbl table td {
  white-space: nowrap;
}
.sprayOper {
  height: 45px !important;
  margin: 18px 0px !important;
  position: relative !important;
}
/* .anticon-arrow-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
} */

.sprayTabMenu ul.sprayUlList {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin: 0;
  min-width: 521px;
  padding: 9px 12px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.29) !important;
  align-items: center;
  display: flex;
  height: 52px;
  z-index: 99;
  background: #fff;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}
/* .sprayTabMenu ul.sprayUlList:hover, */
.sprayTabMenu ul.sprayUlList.sprayActive {
  top: 70%;
  height: 75px;
  padding: 9px 18px;
  min-width: 531px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}
.sprayTabMenu ul.sprayUlList li {
  /* border-right: 1px solid #ccc; */
  /* cursor: pointer; */
  display: inline;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  padding: 7px 17px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
}
.sprayTabMenu ul.sprayUlList li:nth-child(2) {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.sprayTabMenu ul.sprayUlList li:nth-child(3) {
  border-right: 1px solid #ccc;
}
/* .sprayTabMenu ul.sprayUlList:hover li.sparyItemActive,  */
.sprayTabMenu ul.sprayUlList.sprayActive li.sparyItemActive {
  box-shadow: inset 0px 0px 6px rgb(0 0 0 / 29%) !important;
  border: 0;
  border-radius: 10px;
}
.sprayTabMenu ul.sprayUlList.sprayActive li.operationTxt {
  color: #bfbfbf !important;
}
.sprayTabMenu ul.sprayUlList:hover li,
.sprayTabMenu ul.sprayUlList.sprayActive li {
  display: inline-flex;
  flex-direction: column;
}
/* .sprayTabMenu ul.sprayUlList:hover li span.titleTxt, */
.sprayTabMenu ul.sprayUlList.sprayActive li span.titleTxt {
  display: inline-block;
}
.sprayTabMenu ul.sprayUlList li span.titleTxt {
  display: none;
  color: #000;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
}
.sprayTabMenu ul.sprayUlList li:nth-child(4) {
  border-right: 0;
}
.sprayTabMenu ul.sprayUlList li:last-child {
  border-right: 0;
  padding-right: 0;
}
.searchSprayIcon {
  background-color: #366376;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
}
.sprayoperlist {
  padding: 0 !important;
}
.sprayoperlist .ant-tabs-nav {
  padding: 0 20px;
  padding-top: 0;
  border-bottom: 0;
  margin: 0;
}
.sprayoperlist .ant-tabs-content-holder {
  padding: 10px 15px;
  min-height: 250px;
  height: calc(100vh - 650px);
  overflow-y: auto;
}
.herSprayPercent .ant-progress,
.herSprayPercent .ant-progress-outer {
  position: unset !important;
  padding-right: 3px;
}
.herSprayPercent .ant-progress-inner {
  position: unset !important;
  font-size: 16px !important;
  width: 15px !important;
  height: 15px !important;
  margin: 0 !important;
}
.sprayoperhrs .operationalHours .anticon-exclamation {
  background: #ffb62f;
  border-radius: 50%;
  padding: 2px 2px;
  font-size: 10px;
}
.operationTxt {
  position: relative;
}
.operationTxt .operationsDropdown {
  position: absolute;
  top: 68px;
  left: 0;
  background-color: #fff;
  border-radius: 10px;
  padding-left: 0;
}
.operationsDropdown h6 {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  padding-left: 15px;
  padding-top: 7px;
  letter-spacing: 0.35px;
  text-transform: uppercase;
}
.operationsDropdown h5 {
  color: #366376;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  padding-right: 15px;
  letter-spacing: 0.35px;
  text-transform: uppercase;
  text-align: right;
}
.operationTxt .operationsDropdown .operationDroplistBlk {
  padding-left: 0;
  min-width: 300px;
  height: 200px;
  overflow-y: auto;
}
.operationTxt
  .operationsDropdown
  .operationDroplistBlk
  .operationDroplistInrBlk {
  padding: 0 15px !important;
  width: 100%;
}
.operationTxt
  .operationsDropdown
  .operationDroplistBlk
  .operationDroplistInrBlk
  .operationContainer {
  color: #000;
  padding: 10px 18px;
}
.line {
  border-bottom: 1px solid #ccc;
  margin: 10px 0px;
}
.duration-range-block {
  margin-top: 20px;
  display: flex;
}
.duration-range-item {
  flex: 1;
  position: relative;
}

.analytics-pagination {
  position: relative;
  margin: 10px 0px;
  /* right: 1.2vw; */
  /* top: 0; */
}

.analytics-pagination .ant-pagination {
  width: fit-content;
  margin: auto;
}

.analytics-pagination > .ant-pagination > li.ant-pagination-options {
  display: none;
}

.duration-range-item > .inputTop {
  left: 16px;
}

@media (max-width: 1399px) {
  .sprayBlk {
    justify-content: center;
    flex-wrap: wrap;
  }
  .sprayTbl {
    position: unset !important;
    transform: unset !important;
  }
  .sprayPiechart {
    display: block;
    margin: auto !important;
  }
}

@media (max-width: 1500px) {
  .ant-picker-input input {
    font-size: 0.8em;
  }
  .dataBaseDropdownStyle .ant-select-selection-item {
    font-size: 0.8em !important;
  }
  .dataBaseContainer .ant-select-selection-placeholder {
    font-size: 0.8em !important;
  }
  .inputTop {
    font-size: 0.5em;
  }
}

.dropdownStyle.hwb,
.dropdownStyle.hwb .ant-picker-input input {
  border: none !important;
  font-size: 14px;
  font-family: Montserrat-Regular;
}

.analyticsHover {
  display: none;
}

.piechartBlk1:hover .analyticsHover {
  display: block;
}

.jag-loader {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 9;
  justify-content: center;
  align-items: center;
}
.edit-loader-new {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 10;
  justify-content: center;
  align-items: center;
}
