.accordionContainer {
  position: relative;
  margin-bottom: 20px;
  word-break: break-word;
  white-space: break-spaces;
}
.accordionContainer .anticon {
  position: absolute;
  right: 12px;
  top: 18px;
}
.basebtnCSV {
  position: absolute;
  bottom: 3px !important;
  left: 48px;
}
/* .tableContainer table{
    border: 1px solid #ddd;
} */
.tableContainer table thead tr {
  font-family: Montserrat-SemiBold;
}
.buttonTxt {
  /* font-family: Montserrat-SemiBold;
    font-size: 16px;
    min-width: 82px;
    min-height: 40px;
    background-color: #EB921F; */
  color: #fff;
  /* border-radius: 6px;
    letter-spacing: 1.2px; */
  float: right;
  margin-bottom: 14px;
}
.buttonTxt:hover {
  color: #fff;
  background: #e2850b !important;
  border-color: #eb921f !important;
}
table.base {
  position: absolute;
  /* top: 44px;
    left: 330px; */
  /* top: 2%; */
  left: 50px;
  border: 0;
  width: 96%;
  height: 50px;
  text-align: center;
  font-family: Montserrat-Medium;
  font-size: 14px;
}
table.base tr td {
  min-width: auto;
  width: 156px;
  text-align: left;
}
.baseTbl .ant-table-tbody tr td {
  font-family: Montserrat-Medium !important;
  background: #fff !important;
}
.baseModalContainer {
  position: relative;
  top: 150px;
}
.baseModalContainer .ant-modal-content {
  min-width: 850px;
  /* min-height: 619px; */
  overflow: hidden;
  border-radius: 12px;
  margin-left: -150px;
}
/* .baseModalContainer .ant-table-wrapper {
    border: 1px solid #dddddd;
} */
/* .canelBtn,
.okBtn {
  margin: 5px;
}
.canelBtn {
  font-family: Montserrat-SemiBold;
  min-width: 108px;
  min-height: 38px;
  font-size: 16px;
}
.okBtn {
  min-width: 98px;
  min-height: 36px;
  background: #eb921f;
  border: 0;
  font-size: 16px;
  font-family: Montserrat-SemiBold;
  border-radius: 3px;
}
.okBtn:hover {
  background: #ad6a14eb;
} */
/* .baseModalContainer .ant-table-column-sorter {
  position: absolute;
  right: 112px;
} */
/* .baseModalContainer .ant-modal-title{
   font-family: Montserrat-Medium;
   font-size: 20px;
   letter-spacing: 0.8px;
} */
.baseModalContainer thead tr th {
  font-family: Montserrat-SemiBold;
}
.tractorTxt {
  font-family: Montserrat-Medium;
  margin: 16px 0;
  letter-spacing: 0.8px;
  font-size: 15px;
}
.unAssignedTractor .ant-table-thead {
  visibility: collapse;
}
/* .baseModalContainer .ant-table-column-sorters {
  position: relative;
  right: 126px;
} */
.indicatorImg {
  /* margin-right: 10px; */
}
/* .ant-table-column-title {
  flex: 1 1;
  margin-left: 106px;
} */
.indicatorImg {
  /* margin-right: 10px; */
}
.baseTbl .ant-table-thead > tr > th {
  background: #f7f7f7;
  border-bottom: 1px solid #ebecf0;
  color: #242424;
  font-family: 'Montserrat-SemiBold';
  /* padding: 10px 15px 11px 15px !important; */
}
.baseTbl .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #fafbfc !important;
}
.basestationLayout .ant-collapse {
  margin: 0 !important;
  border-radius: 6px;
  background: #fff !important;
}
.basestationLayout .ant-collapse-content-box {
  display: inline-block;
  width: 100%;
  padding: 24px !important;
}
.basestationLayout .ant-collapse-header {
  font-size: 16px;
  border-radius: 6px !important;
}
.basestationLayout .ant-collapse-content {
  border-radius: 0 0 6px 6px !important;
}
.baseTbl .ant-table-tbody > tr > td {
  background: #fff;
  padding: 10px 15px 11px 15px !important;
  font-size: 12px !important;
  font-family: 'Montserrat-Medium';
  word-break: break-word;
  white-space: break-spaces;
  color: #172b4d !important;
  letter-spacing: 0.25px;
}

.accordionContainer .bsIcon {
  background-image: url(../../assets/images/bsIcon.png);
  background-repeat: no-repeat;
  position: absolute;
  z-index: 9999;
  width: 20px;
  height: 20px;
  top: 15px;
  left: 15px;
}

.basestationTbl .flHead {
  width: 65%;
}

.ctTxt {
  margin-right: 10px;
}

.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow: auto;
}

.ant-select-selection-overflow-item .ant-select-selection-item {
  background: #67afb7 0% 0% no-repeat padding-box;
  color: #ffffff !important;
  border-radius: 6px;
  opacity: 1;
}
.ant-select-selection-item-remove {
  display: none;
}

.antd_select .ant-select-selector {
  background-color: #f7f7f7 !important;
}
.th {
  cursor: text;
}

.teamsSelect .ant-select-selection-overflow {
  flex-wrap: inherit !important;
  display:block;
}
.teamsSelect .teamName {
  /* font-size: 12px !important;
  font-family: Montserrat-Medium; */
  padding-left: 10px !important;
  padding-right: 5px !important;
  margin: 0 !important;
}

.teamsSelect .anticon {
  padding-right: 8px !important;
}
.teamsSelect .ant-select-selector {
  overflow-y: auto;
  padding-left: 5px !important;
  /* height: inherit !important; */
  /* max-height: 90px; */
  overflow-x: hidden;
  background-color: transparent !important;
}

.teamsSelect  .ant-select-selection-overflow .ant-select-selection-item {
  display: inline-flex !important;
}

.basename{
  font-size: 24px !important ;
  text-align: center !important;
  font-family: 'Montserrat-SemiBold' !important;
  letter-spacing: 0.18px !important;
  line-height: 29px !important;
}

.basePin {
  /* padding-left: 40px !important; */
  padding-left: 22px !important;
  color: #727272 !important;
}

.basebtns {
  margin-top: 100px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb64 {
  margin-bottom: 5.92vh !important;
}
.mb73 {
  margin-bottom: 6.75vh !important;
}
.baseStationTitle {
  color: #606060;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.4px;
  line-height: 15px;
}
.editBasestationWidget{
  margin-top: 100px;
}
.basestationWidget .fleetTbl .flHead {
  width: 60% !important;
}
.editBasestationWidget .basestationTbl .flHead {
  width: 60% !important;
}
.basestationWidget .fleetTbl tr td {
  color: #727272;
  font-size: 16px;
  font-family: 'Montserrat-SemiBold' !important;
  letter-spacing: 1.71px;
  line-height: 19px;
}
.basestationWidget .proeditBtn button {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
}
.basestationWidget .proeditBtn button:hover {
  color: #67AFB7 !important;
}
.editBasestationWidget .proInput {
  width: 100%;
}
.editBasestationWidget .ant-input {
  background-color: #fff !important;
}
.editBasestationWidget .newFleetBtns {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 40px;
}
.editBasestationWidget .newFleetBtns .fleetCancel {
  box-shadow: 0 0 6px #00000029;
  width: 136px;
  height: 34px;
  font-size: 14px !important;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
  margin-right: 20px !important;
}
.editBasestationWidget .newFleetBtns .fleetCancel:hover {
  border-color: transparent !important;
}
.editBasestationWidget .newFleetBtns .fleetSave {
  box-shadow: 0 0 6px #00000029;
  width: 136px;
  height: 34px;
  font-size: 14px !important;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
}
@media(max-width: 1440px) {
  .basestationWidget .ant-avatar {
    width: 65px !important;
    height: 100px !important;
  }
  .editBasestationWidget .ant-avatar {
    width: 65px !important;
    height: 100px !important;
  }
}