.employeesList {
  width: 100%;
  font-family: Montserrat-SemiBold;
  border: 1px solid #707070;
  /* margin: 25px; */
}

/* .employeesList .ant-card-head{
  border-bottom: 1px solid #cccddc;
} */

.employeesList .ant-input-prefix {
  font-size: 18px;
  color: #bbb;
}

.indicatorimges {
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* border: 1px solid; */
  max-width: 72px;
  border-radius: 50px;
}

.t-pic-block img {
  /* width: 70%; */
  margin: auto;
}

.tractorPics.t_error,
.indicatorimges.t_error {
  border: 1px solid #d43d38;
}

.indicatoricons {
  display: flex;
  justify-content: space-between;
}

.imagesindicator {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.indicatorImg {
  display: block;
}
.indicatorImg1 {
  margin-left: 10px;
}

.search-box {
  width: 300px;
  height: 32px;
  /* color: #f0f0f0; */
  border-radius: 4px;
  /* margin: 20px 4px 20px 0px; */
}
table.tablecls {
  table-layout: auto !important;
}
/* .rowClassName1 {
  background-color: #f8f7f4;
  font-family: 'Montserrat-Medium';
} */
.table-border {
  border: 1px solid #cccddc;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #fff;
}

th {
  background-color: #f5f5f5;
}

.textCenter {
  text-align: center;
}

/* .tractorPic img {
  width: 13.02vw;
  height: 27.65vh;
} */
.fleetTbl th,
.fleetTbl td {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 13px;
  padding-bottom: 13px;
}
.flHead {
  font-size: 16px;
  font-family: Montserrat-SemiBold !important;
}
.fleetTbl {
  table-layout: fixed;
}
.fleetTbl td {
  /* font-size: 16px;
  font-family: Montserrat-Regular; */
  word-break: break-word;
  vertical-align: top;
}
.mtb20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.hStas {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.oStatusHead {
  font-size: 16px;
  font-family: Montserrat-SemiBold;
}
.oStatusTxt {
  font-size: 16px;
  font-family: Montserrat-Regular;
}
.tractorPic img {
  /* width: 13.02vw;
  height: 27.65vh; */
  max-width: 100%;
  max-height: 100%;
}
.tractorPic .ant-avatar {
  border-radius: 0 !important;
  border: none !important;
}
.ant-table-thead > tr > th {
  background: transparent !important;
  border-right: none !important;
  font-size: 16px;
  font-family: Montserrat-SemiBold;
}
.ant-table-thead > tr > th:before {
  border-right: none !important;
  width: 0px !important;
}
.tblDft .ant-table-wrapper {
  border-radius: 15px;
}
.farmTabsTbl {
  width: 100%;
}
.farmTabsTbl .infinite-scroll-component {
  height: auto !important;
  /* max-height: calc(100vh - 326px); */
  border-radius: 15px;
}

@media screen and (max-width: 1440px) {
  /* .tractorPic img, */
  .avatarMT {
    width: 200px !important;
    height: 200px !important;
  }
  /* .proRoleHead {
    font-size: 30px;
  } */
  .proRole {
    font-size: 16px;
  }
}

.mr15 {
  margin-right: 15px;
}
.mr10 {
  margin-right: 10px;
}
.ant-popover-inner-content {
  padding: 0 !important;
}
.indicatoricons:hover .charging-container {
  border-color: '#67afb7';
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.remoteRestartStyle {
  display: flex;
  cursor: pointer;
}

.toggleSwitchCon .ant-switch-checked {
  background-color: #eb921f;
  height: 14px;
}

.toggleSwitchCon .ant-switch-checked .ant-switch-handle::before {
  background-color: #eb921f;
}

.toggleSwitchCon .ant-switch-handle {
  position: absolute;
  top: -3px;
}

.toggleSwitchCon .ant-switch-handle::before {
  position: absolute;
  right: 1px;
  left: -2px;
}

.toggleSwitchCon .ant-switch {
  min-width: 36px;
  height: 12px;
}

.restartTxt {
  height: 32px;
  border: none;
  border-radius: 4px;
  background-color: #e16d01;
  color: #fff;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  padding: 4px 15px;
}

.restartManger .ant-modal-body {
  max-height: 520px;
  overflow: auto;
  padding-top: 0px;
}

.eyeIconStyle {
  margin-left: 38px;
}
.eyeIconDbl {
  cursor: not-allowed;
}

.upTname {
  min-width: 62px;
}
.jfStart {
  justify-content: flex-start !important;
}

.fleetsTbl table {
  table-layout: inherit !important;
}

.mkvStyle {
  font-size: 1vw;
  font-family: Montserrat-SemiBold;
  margin-top: -4px;
}

.tractorDetailsContainer {
  border-bottom: 1px solid #d5d5d5;
}

.trDetailsTxt {
  /* font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.3px;
  line-height: 19px; */
  margin-bottom: 10px;
  color: #727272;
}

/* .tractorList {
  display: flex;
  justify-content: space-between;
  height: 126px;
  padding: 0 14px;
} */

.trIconsTxt {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.tractorPics {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  text-align: center;
  width: 72px;
  border-radius: 20px;
  height: 27px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.tractorPics1 {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  text-align: center;
  width: 75px;
  border-radius: 20px;
  height: 27px;
}

.tractorPics:hover {
  transform: scale(1.2);
}

.fleetSwVer {
  /* font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.29px; */
  text-transform: capitalize;
  color: #707070;
}

.fleetSwVerNo {
  /* font-size: 15px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.25px; 
    line-height: 22px;*/
  color: #000;
  flex-wrap: nowrap;
}

.mlb {
  margin-left: 8px;
  margin-bottom: 6px;
}

.editTractorDetCon {
  /* height: 526px; */
  opacity: 0.4;
}

.height100 {
  height: 100%;
}

.newFleetBtns {
  text-align: center;
  font-family: Montserrat-SemiBold;
}

.newFleetBtns .fleetCancel {
  box-shadow: 0px 0px 6px #00000029;
  text-transform: uppercase;
  /* letter-spacing: 1.25px;
  font-size: 14px; */
  color: #366376;
  border-radius: 10px;
  margin-right: 20px;
  /* font-family: 'Montserrat-SemiBold';
  line-height: 18px; */
  padding: 7px 35px;
  height: 34px;
}

.newFleetBtns .fleetSave {
  box-shadow: 0px 0px 6px #00000029;
  text-transform: uppercase;
  /* letter-spacing: 1.25px;
  font-size: 14px; */
  background-color: #366376;
  color: #fff;
  border-radius: 10px;
  padding: 7px 47px;
  /* line-height: 18px; */
  height: 34px;
}

.fleetTop {
  justify-content: unset !important;
}

.editFleetButtons {
  padding-top: 10px;
  background: #f7f7f7;
  /* height: 60px; */
  position: absolute;
  bottom: 40px;
  right: 0px;
  width: 100%;
}

.mb60 {
  margin-bottom: 60px;
}

.mb25 {
  margin-bottom: 25px;
}

.tractorLable {
  color: #727272 !important;
  line-height: 15px;
  letter-spacing: 0.4px !important;
  font-size: 12px;
  font-family: 'Montserrat-Medium' !important;
  margin-bottom: 8px !important;
}

.proInputMain {
  margin-bottom: 88px;
}

.proInputMain .proInput {
  margin-bottom: 0px !important;
}

.proInputMain input {
  background: #fff !important;
}

.tractorType .fleetMsgTxt:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.tractorLeft {
  width: calc(50% - 10px);
  margin-right: 10px;
}

.tractorRight {
  width: calc(50% - 10px);
  margin-left: 10px;
}

.vs {
  width: 130px !important;
}

.fleetpin {
  white-space: nowrap;
}

.cPointer{
  cursor: pointer;
}