.analyticsRightside.newAnalytics {
  background: #fff;
}

.analyticsRightside.newAnalytics .datalayerContent2 {
  padding: 0px;
}

.analyticsRightside.newAnalytics .anaJngTabs .videoSnaps .snapText {
  background: none;
  border: none;
  text-shadow: 0 1px 1px rgb(0 0 0 / 90%);
  color: #f0f0f0;
}

.analyticsRightside.newAnalytics .anaJngTabs .thumbWraper .ant-card {
  background-color: #fff !important;
  /* margin-bottom: 10px; */
}

.analyticsRightside .anaJngTabs .videoSnaps {
  height: 150px;
  display: inline-block;
  margin-bottom: 8px !important;
}

.analyticsRightside .dbWidget {
  border: none !important;
  padding: 5px 10px;
}

.analyticsRightside .thumbWraper {
  margin-right: 0px !important;
}

.analyticsRightside .ant-card-head {
  margin-bottom: 10px;
}

.analyticsRightside .barDisplay {
  padding: 0 !important;
}

.analyticsRightside .anaJngTabs .barDisplay:last-child div {
  width: 45px !important;
}

.analyticsRightside .dataLayerRowHead2 {
  position: absolute;
  z-index: 999;
  color: white;
  background: #1f3a46;
  left: 10px !important;
  width: 30px;
  height: 30px;
  padding: 0px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px !important;
}

.piechartBlk1 .pieTxtStyle {
  width: 318px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.piechartBlk1 .sprayBlk {
  display: block;
}

.piechartBlk1 .pieTxtStyle {
  width: inherit;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.piechartBlk1 .graphTbl {
  width: 100% !important;
  position: relative;
  left: 0px;
  top: 0px !important;
  border-bottom: 1px solid #ccc;
  position: inherit !important;
  transform: inherit !important;
  padding-bottom: 10px;
}

.piechartBlk1 .graphTbl td {
  padding: 2px 0px !important;
  font-family: Montserrat-Regular;
  font-size: 11px;
}

.piechartBlk1 {
  min-height: 273px;
  overflow-y: auto;
  height: calc(100vh - 19vh);
  position: relative;
}

.piechartBlk1 .sprayoperhrs {
  margin-bottom: 15px;
}

.piechartBlk1 .sprayoperlist {
  margin-right: 0px !important;
}

.piechartBlk1 .dateStyle {
  font-size: 11px;
}

.piechartBlk1 .sprayoperlist .ant-tabs-content-holder {
  padding: 10px !important;
}

.tglexpand2 {
  right: 230px;
}

.dataLayerBtn.tglexpand2:hover {
  right: 345px;
}

.dataLayerBtn.tglexpand2 {
  transition: 1s;
  animation-duration: 6s;
}

.attContainer .pieChartStyle .ant-tabs-ink-bar {
  border-bottom: 0 !important;
}

.sprayPiechart .g2-tooltip-value::after {
  content: ' %';
}
