.autoDListCont {
  background-color: #dfe4f4;
}

.editTabs .autoDriveTab.ant-tabs-tab-active {
  background-color: #dfe4f4 !important;
  border-bottom: none;
}
.autoDriveListWrapper {
  height: auto;
  width: 100%;
  padding: 5px 8px 12px 8px;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 8px;
}
.autoDConfigWrapper {
  height: auto;
  width: 100%;
  padding: 20px;
  background-color: transparent;
  border: 1px solid #b6b6b7;
  border-radius: 8px;
}
.livecmdTxt {
  font-size: 12px;
  font-family: Montserrat-Medium;
  margin-bottom: 20px;
}
.livecmdList {
  font-size: 12px;
  font-family: Montserrat-Medium;
  text-align: center;
  color: #959595;
}

.panelHead {
  padding: 0px !important;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 12px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 0.6px;
  color: #5c5c5c;
}
.autoDriveColapse {
  background: none;
  border: none;
  position: relative;
}
.panelHead .ant-collapse-header {
  padding: 12px 10px 15px 10px !important;
}

.panelHead .anticon-right svg {
  transform: rotate(-90deg);
  font-family: Montserrat-SemiBold;
  color: #959595;
  margin-right: 8px !important;
}
.panelHead .anticon-right {
  margin-right: 8px !important;
  width: 12px;
}
.autoDriveColapse .ant-collapse-content {
  border-top: none;
}
.panelHead {
  border-bottom: none !important;
  position: relative;
}
.autoDriveStatus {
  padding: 14px 4px 0px 4px;
  display: inline-block;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  /* justify-content: space-between; */
}
.opPer {
  background: #546cb2;
  border-radius: 4px;
  height: 24px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-family: Montserrat-Medium;
  color: #fff;
}

.plusIcon {
  background-image: url(../../assets/images/plusNor.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}
.plusIcon:hover {
  background-image: url(../../assets/images/plusSel.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
}
.pauseIcon {
  background-image: url(../../assets/images/pauseNor.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}
.pauseIcon:hover {
  background-image: url(../../assets/images/pauseSel.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
}
.addTractorTxt {
  position: absolute;
  right: 0;
  text-transform: uppercase;
  letter-spacing: 0.79px;
  color: #546cb2;
  font-family: Montserrat-SemiBold;
  font-size: 10px;
  width: 100px;
}
.addTractorTxt {
  right: -105px;
  display: none;
  transition: 10s;
}

.plusIcon:hover > .addTractorTxt,
.pauseIcon:hover > .addTractorTxt {
  display: block;
  right: 0;
  transition: 10s;
  background: #fff;
  margin-right: 25px;
}
.w70 {
  width: 55px !important;
}

.autoDriveColapse .ant-collapse-content-box {
  padding: 0px;
}
.operationTractorInside {
  margin-bottom: 4px;
  padding: 10px 20px 12px 20px;
  cursor: pointer;
  width: 100%;
  border: 2px solid transparent;
  border-radius: 6px;
}
.operationTractorInside:hover {
  background-color: #f7f7f7;
  border: 2px solid transparent;
}
.operationTractorInside:active,
.operationTractorInside.active {
  border: 2px solid #546cb2;
  background-color: #eceff8;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 29%);
}
.operationTractorInside:active .opTname {
  font-family: Montserrat-SemiBold;
}

.operationTractorBox {
  border-bottom: 1px solid #cececf;
  margin-bottom: 4px;
}
.opBoxRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 12px;
}
.tnSec {
  display: flex;
  align-items: center;
}
.opTname {
  color: #000;
  font-size: 16px;
  font-family: Montserrat-Medium;
  line-height: 19px;
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
}
.mr8 {
  margin-right: 8px;
}
.mr12 {
  margin-right: 12px;
}
.pCricle {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.opTsignals {
  display: flex;
}
.opTspeed,
.opTrpm,
.opThitch {
  display: flex;
  align-items: center;
}
.opThitch,
.opTsignals {
  min-width: 70px;
}
.mr5 {
  margin-right: 5px;
}
.mr3 {
  margin-right: 3px;
}
.f10 {
  font-size: 10px;
  font-family: Montserrat-Regular;
}
.f12 {
  font-size: 12px;
  font-family: Montserrat-Regular;
}
.f16 {
  font-size: 16px;
  font-family: Montserrat-Medium;
}
.mb0 {
  margin-bottom: 0px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.pauseIcon {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 10px;
}
.tCompTxt {
  font-size: 8px;
  font-family: Montserrat-Medium;
  margin-left: 5px;
}

/* remotedrive settings & details */
/* .autoDriveScoller {
  overflow-y: auto;
  height: calc(100vh - 265px);
  padding-right: 10px;
  /* height: calc(100vh - 545px); 
} */
.rautoDriveRightSec .rautocloseIcon {
  color: #366376;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rautoDriveTitle h4 {
  font-size: 26px;
  font-family: Montserrat-Regular;
  color: #959595;
  line-height: 32px;
  letter-spacing: 0.19px;
  margin-top: 40px;
  margin-bottom: 0;
  text-align: center;
}
.rautoDriveTitle span {
  display: block;
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  color: #959595;
  line-height: 18px;
  text-align: center;
}
.rautoDriveTitle .accordions {
  margin-top: 20px;
  border-radius: 10px !important;
  border: 0 !important;
}
.rautoDriveTitle .ant-collapse-item {
  border: 2px solid #546cb2;
  border-radius: 10px !important;
}
.rautoDriveTitle .ant-collapse-header {
  background: #fff;
  border-radius: 10px 10px 0 0 !important;
  border: 0;
}
.rautoDriveTitle .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  border-radius: 10px !important;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.13px;
  line-height: 18px;
  padding: 10px 5px 10px 20px !important;
}
.rautoDriveTitle .ant-collapse-content {
  border-radius: 0 0 10px 10px !important;
  border-top: 0 !important;
}
.rautoDriveTitle .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}
.rautoDriveTitle
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  float: right !important;
}
.live_command_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 20px 20px !important;
  border-bottom: 1px solid #e7e7e7;
}
.dFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.live_command_flex h6 {
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  color: #272727;
  letter-spacing: 0.13px;
  line-height: 18px;
  margin: 0;
}
.live_command_flex span {
  color: #b6b6b7;
  cursor: pointer;
  font-size: 8px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.08px;
  line-height: 10px;
  text-align: left;
}
.waypointBlk {
  /* height: 265px; */
  overflow-y: auto;
  padding: 16px 5px !important;
}
.waypointInrBlk {
  padding: 0 16px 16px 16px !important;
}
.waypointBlk h5.wayPointTitle {
  font-size: 11px;
  font-family: 'Montserrat-Medium';
  color: #272727;
  letter-spacing: 0.13px;
  line-height: 18px;
  text-align: left;
}
.waypointBlk h5 img {
  margin-right: 5px;
}
.commandStartBtn {
  color: #366376;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
  position: fixed;
  right: 25px;
  bottom: 50px;
}

.rautoDriveDetailsTitle h2 {
  color: #000000;
  font-size: 30px;
  font-family: Montserrat-Semibold;
  letter-spacing: 0.25px;
  line-height: 42px;
  text-align: center;
  margin: 0;
  margin-top: 10px;
}
.rautoDriveDetailsTitle p {
  color: #000000;
  font-size: 14px;
  font-family: Montserrat-Regular;
  line-height: 18px;
  text-align: center;
  margin: 0;
}
.adTabsContentSec .rautoDriveStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.rautoDriveStatus .t_battery {
  gap: 6px;
}
.rautoDriveStatusName span {
  background-color: #546cb2;
  color: #fff;
  font-size: 14px;
  font-family: Montserrat-Regular;
  letter-spacing: 0.13px;
  line-height: 18px;
  text-align: center;
  padding: 3px 15px;
  border-radius: 15px;
}
/* ul.autodriveStatusIcons {
  list-style: none;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 14px;
  padding: 2px 0 2px 0;
  margin: 0;
}
.rautoDriveStatus .autodriveStatusIcons {
  padding: 0px 5px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 60px;
}
.rautoDriveStatus .autodriveStatusIcons .t_signals > img:first-child {
  margin-right: 10px !important;
}
.rautoDriveStatus .autodriveStatusIcons .t_signals {
  padding-right: 0;
}
ul.autodriveStatusIcons li {
  display: inline;
  padding: 0 8px;
}
ul.autodriveStatusIcons li img {
  width: 12px;
} */

.autoDriveBlk1 {
  /* border: 1px solid #959595;
  border-radius: 10px;
  margin-top: 30px; */
  margin-top: 10px;
}
.implementsMainBlk {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-right: 10px;
}
.implementsMainBlk .ant-select-selection-item {
  font-size: 11px;
  font-family: Montserrat-Regular;
}

.implementsMainBlk .anticon-setting {
  font-size: 18px;
  cursor: pointer;
}
.autoDriveBlk1 .implementsBlk {
  display: flex;
  align-items: center;
  gap: 7px;
  position: relative;
  width: 100%;
}
.autoDriveBlk1 .implementsBlk img {
  width: 20px;
  position: absolute;
  z-index: 9;
  left: 8px;
}
.autoDriveBlk1 .implementsBlk .ant-select {
  border: 1px solid #959595 !important;
  border-radius: 10px !important;
  width: 100% !important;
}
.autoDriveBlk1
  .implementsBlk
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 0 !important;
  padding-left: 40px !important;
}
.autoDriveInrBlk {
  border-bottom: 1px solid #e7e7e7;
  padding: 15px;
}
.autoDriveInrBlk2 {
  padding: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.autoDriveInrBlk2 input,
.autoDriveInrBlk2 input:hover,
.autoDriveInrBlk2 input:focus {
  border: 2px solid #546cb2;
  border-color: #546cb2 !important;
  border-radius: 10px;
  color: #1e1e1e;
  font-size: 12px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.38px;
  line-height: 15px;
  padding: 8px;
}
.autoDriveCode {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.autoDriveCode h6 {
  color: #272727;
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.13px;
  line-height: 18px;
}
.autoDriveCode .ant-progress,
.autoDriveCode .ant-progress-outer {
  position: relative !important;
  height: auto !important;
}
.autoDriveCode .ant-progress .ant-progress-inner {
  width: 50px !important;
  height: 50px !important;
  position: relative !important;
}
.autoDriveCode .ant-progress-circle .ant-progress-text {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
}
.commandSteps {
  padding: 5px 0px;
  /* padding-left: 0px !important; */
}
.commandSteps .ant-timeline {
  margin-top: 12px;
}
.commandSteps .ant-timeline-item {
  padding-bottom: 15px !important;
}
.commandSteps .ant-timeline-item-tail {
  border-left: 6px dotted #546cb2;
  left: 5px !important;
  top: 20px !important;
  height: calc(100% - 25px) !important;
}
.commandSteps .ant-timeline-item-head {
  border-color: #546cb2 !important;
  color: #546cb2 !important;
  width: 15px !important;
  height: 15px !important;
}
.commandSteps .ant-timeline li:last-child {
  padding-bottom: 0 !important;
}
.commandSteps .ant-timeline-item-last > .ant-timeline-item-content {
  min-height: auto !important;
}
.commandSteps .ant-timeline input,
.commandSteps .ant-timeline input:hover,
.commandSteps .ant-timeline input:focus {
  border-radius: 10px;
  border: 1px solid #b6b6b7;
  border-color: #b6b6b7 !important;
  color: #1e1e1e;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.38px;
  height: 35px !important;
}

.commandSteps .searchMapBlk .anticon-search {
  top: 33% !important;
}

.commandSteps .addStep {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 0px;
}
.commandSteps .addStep h5 {
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.38px;
  line-height: 15px;
  color: #000000;
  margin: 0;
}
/* .ant-dropdown {
    top: 405px !important;
} */
.commandSteps .addStep .ant-dropdown-trigger {
  box-shadow: none !important;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.38px;
  line-height: 15px;
  color: #000000;
  margin: 0;
  border: 0 !important;
  padding: 0 !important;
}
.commandSteps .addStep .ant-dropdown-trigger span {
  color: #000000 !important;
}
.ant-dropdown-menu {
  border-radius: 5px !important;
  border: 1px solid #707070 !important;
  padding: 7px 5px !important;
}
.ant-dropdown-menu-item {
  font-size: 12px !important;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.38px;
  line-height: 15px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-right: 20px !important;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #e7e7e7 !important;
}
.accordions .saveCommandBtn {
  /* color: #546cb2;
  cursor: pointer;
  display: block;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.89px;
  line-height: 13px;
  text-align: right;
  margin-top: 28px;
  margin-bottom: 7px;
  
   */
  color: #546cb2;
  cursor: pointer;
  display: inline-flex;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.89px;
  line-height: 13px;
  text-align: right;
  margin-top: 28px;
  margin-bottom: 7px;
  height: auto;
  width: auto;
  min-width: 77px;
  position: unset;
  padding: 8px 15px;
  align-items: center;
  justify-content: center;
  float: right;
  border-radius: 15px;
  min-height: 30px;
}
.autoDriveCodeGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  gap: 12px 5px;
  align-items: center;
  /* display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  justify-content: space-between; */
}
.implement-txt {
  font-size: 11px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.13px;
  line-height: 18px;
  color: #272727;
  /* margin-bottom: 15px; */
  display: flex;
  cursor: pointer;
  position: relative;
}
.implement-txt img {
  margin-right: 7px;
}
.autoDriveEdit {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 0px;
  flex-direction: column;
  text-align: right;
  font-size: 12px;
  float: right;
}
.autoDriveEdit .fa {
  cursor: pointer;
}
.autoDriveMph {
  color: #272727;
  font-size: 11px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.11px;
  line-height: 15px;
}
.autoDriveMph img,
.autoDriveRPM img,
.autoDrivePer img,
.autoDrivePer svg {
  margin-right: 5px;
}
.autoDriveRPM {
  display: flex;
  gap: 5px;
  align-items: flex-start;
}
.autoDriveRPM img {
  width: 15px;
}
.autoDriveRPM h6 {
  color: #272727;
  font-size: 14px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.11px;
  line-height: 15px;
  margin: 0;
}
.autoDriveRPM span {
  color: #272727;
  font-size: 11px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.11px;
  line-height: 15px;
}
.autoDrivePer {
  color: #272727;
  font-size: 11px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.11px;
  line-height: 15px;
}
.autoDrivePer img {
  width: 15px !important;
  margin-right: 5px;
}
.autoDrivePer svg {
  width: 20px !important;
}
.autoDriveEdit .fa {
  font-size: 15px;
  width: 15px !important;
  height: 15px !important;
}
.autoDrivePerIcon svg {
  width: 15px !important;
}
/* .autoDriveCamViews {
  /* margin-bottom: 34px; */
  /* width: 310px; 
} */
.autoDriveCamViews .ant-tabs-tab {
  color: #546cb2 !important;
  font-size: 12px !important;
  font-family: 'Montserrat-Medium' !important;
  letter-spacing: 0.6px !important;
  line-height: 13px !important;
  padding: 12px 20px !important;
  margin: 0 !important;
}
.autoDriveCamViews .ant-tabs-tab.ant-tabs-tab-active {
  padding: 2px 10px !important;
  background: #546cb2 !important;
  color: #fff !important;
  font-size: 12px !important;
  font-family: 'Montserrat-Medium' !important;
  letter-spacing: 0.6px !important;
  line-height: 13px !important;
  border-radius: 8px 8px 0 0 !important;
}
.autoDriveCamViews .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}
.autoDriveCamViews .ant-tabs-ink-bar {
  background: transparent !important;
}
.autoDriveCamViews .ant-tabs-tabpane img {
  width: 100%;
}
.editOperationBlk {
  border: 3px solid #546cb2;
  border-radius: 10px;
  margin-top: 30px;
  padding: 15px;
  padding-bottom: 18px;
  position: relative;
}
.editOperationBlk .operationCloseIcon {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
}
.editOperationBlk h6 {
  color: #959595;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.11px;
  line-height: 18px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
}
.editOperationGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px 10px;
}
.editOperationGrid .operationField {
  display: flex;
  align-items: center;
  gap: 10px;
}
.editOperationGrid .operationField input,
.editOperationGrid .operationField input:hover,
.editOperationGrid .operationField input:focus,
.editOperInrBlk input,
.editOperInrBlk input:hover,
.editOperInrBlk input:focus {
  border: 1px solid #707070 !important;
  border-color: #707070 !important;
  border-radius: 9px;
  width: 55px;
  text-align: center;
}
.editOperationGrid .editOperInrBlk {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 10px;
}
.editOperInrBlk span {
  color: #5c5c5c;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.09px;
  line-height: 13px;
}
.editOperInrBlk img {
  width: 18px;
}
.editOperInrBlk.operationBtns {
  margin-top: 15px;
}
.editOperInrBlk .floatBtn {
  cursor: pointer;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.11px;
  line-height: 15px;
  background: #546cb2;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  display: block;
  margin-bottom: 5px;
  text-align: center;
}
.editOperInrBlk .posBtn {
  cursor: pointer;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.11px;
  line-height: 15px;
  background: #e7e7e7;
  color: #272727;
  padding: 5px 10px;
  border-radius: 3px;
  display: block;
  margin-bottom: 5px;
  text-align: center;
}
.ptosettings h3 {
  color: #5c5c5c;
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.09px;
  line-height: 13px;
  margin-bottom: 10px;
}
.ptosettings p {
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.11px;
  line-height: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.applyChangesBtn {
  color: #366376;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.11px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin-top: 25px;
}
.autoDriveCamControls {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px; */
  margin-top: 30px;
  /* margin-left: 30px; */
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 30px;
}
.autoDriveCamControls img {
  width: 45px;
}
.autoDriveCamPlayControls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.autoDriveCamPlayControls img {
  cursor: pointer;
  width: 50px;
}
.autoDriveCamPlayControls span {
  font-size: 8px;
  font-family: 'Montserrat-Medium';
  color: #959595;
  letter-spacing: 0.48px;
  line-height: 10px;
}

.operationsDuration h2 {
  color: #070707;
  font-size: 26px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 1.04px;
  line-height: 32px;
  margin: 0;
  width: 100px;
  display: block;
}
.operationsDuration p {
  cursor: pointer;
  background-color: #e0963f;
  border-radius: 7px;
  color: #fff;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  line-height: 13px;
  padding: 5px 10px;
  text-align: center;
  min-width: 107px;
  min-height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-collapse {
  border: 0 !important;
}

.rowSelection {
  padding: 20px;
}
.rowSelection .rowSelectionStart {
  display: flex;
  align-items: center;
  gap: 20px;
}
.rowSelection .rowSelectionStart span {
  color: #000;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
}
.rowSelection .rowSelectionStart input,
.rowSelection .rowSelectionStart input:hover,
.rowSelection .rowSelectionStart input:focus {
  width: 65px;
  height: 35px;
  background: #fff !important;
  background-color: #fff !important;
  padding-left: 11px !important;
  text-align: center;
  border: 1px solid #d9d9d9 !important;
}
.rowSelection .rowSelectionEnd {
  display: flex;
  align-items: center;
  gap: 20px;
}
.rowSelection .rowSelectionEnd span {
  color: #000;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
}
.rowSelection .rowSelectionEnd input,
.rowSelection .rowSelectionEnd input:hover,
.rowSelection .rowSelectionEnd input:focus {
  width: 65px;
  height: 35px;
  background: #fff !important;
  background-color: #fff !important;
  padding-left: 11px !important;
  text-align: center;
  border: 1px solid #d9d9d9 !important;
}
.rowSelectionDropdown {
  margin: 10px 0;
}
.rowSelectionDropdown .ant-select {
  width: 200px !important;
}
.rowSelectionDropdown .ant-select .ant-select-selection-item {
  color: #000 !important;
  font-family: 'Montserrat-Medium';
  text-align: left !important;
}
.rowSelectionDropdown .ant-select input {
  background: #fff !important;
  background-color: #fff !important;
}
.operNameBlk h3.operNameTitle {
  text-align: center;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  margin: 0;
}
.operNameBlk .operType {
  font-size: 12px;
  text-align: center;
  font-family: 'Montserrat-SemiBold';
}
.operNameBlk h5 {
  font-size: 14px;
  color: #5c5c5c;
  font-family: 'Montserrat-Regular';
  text-transform: uppercase;
}
.speedSelection {
  padding: 10px 20px;
}
.speedSelectionBLk {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.speedSelectionBLk .speedSelectionleft {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 5px;
}
.speedSelectionBLk .speedSelectionleft img {
  margin-bottom: 12px;
}
.speedSelectionBLk input {
  background: #fff !important;
  background-color: #fff !important;
  height: 35px;
  width: 65px;
  padding-left: 11px !important;
  text-align: center;
}
.speedSelectionBLk .speedSelectionInrBlk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.speedSelectionBLk .speedSelectionInrBlk .speedSelectionmph {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
}
.speedSelectionInrBlk input {
  background: #fff !important;
  background-color: #fff !important;
  height: 35px !important;
  width: 65px !important;
  padding-left: 11px !important;
  text-align: center;
}
.speedSelectionBLk h6 {
  color: #5c5c5c;
  font-size: 11px;
  font-family: 'Montserrat-SemiBold';
}
.operPtoSettings {
  padding: 10px 20px;
}
.operPtoSettings p {
  color: #000 !important;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.11px;
  line-height: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.operPtoSettings p span {
  color: #000 !important;
  font-size: 12px !important;
}
.operPtoSettings p label {
  padding-left: 17px;
}
.operPtoSettings input {
  background: #fff !important;
  background-color: #fff !important;
  height: 35px !important;
  width: 65px !important;
  padding-left: 11px !important;
  text-align: center;
}
.operPtoSettingsBlk {
  display: flex;
  justify-content: space-between;
}
/* .operPtoSettingsBlk .operptoimgBlk {
    display: flex;
    align-items: center;
    gap: 5px;
} */
.operPtoSettingsBlk .operptoimgBlk img {
  padding-left: 24px;
  margin-bottom: 5px;
}
.operPtoSettingsBlk .operPtoRpm {
  display: flex;
  align-items: center;
  gap: 5px;
}
.operPtoRpm .ptorpm {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
}
.pointHitch {
  padding: 10px 20px;
}
.pointHitch .pointHitchBlk {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pointHitch .pointHitchBlk .editOperInrBlk {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 0 !important;
}
.pointHitch input {
  background: #fff !important;
  background-color: #fff !important;
  height: 35px !important;
  width: 65px !important;
  padding-left: 11px !important;
  text-align: center;
}
.operPtoSettingsBlk .ant-radio-checked .ant-radio-inner {
  border-color: #546cb2 !important;
}
.operPtoSettingsBlk .ant-radio-checked::after {
  border: 1px solid #546cb2 !important;
}
.operPtoSettingsBlk .ant-radio-inner::after {
  background-color: #546cb2 !important;
}
.operSaveBtn {
  cursor: pointer;
  display: block;
  color: #366376;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
.childInrPanel .ant-collapse-item {
  box-shadow: 0 3px 6px #00000029 !important;
  border: 1px solid #e7e7e7 !important;
}

@media (max-width: 1330px) {
  .editOperationBlk h6 {
    font-size: 12px;
  }
}
@media (max-width: 1449px) {
  .rautoDriveRightSec {
    padding: 20px;
  }
}
.mrt5 {
  margin-top: 5px;
}
.saveBtnAlign {
  width: 77px;
  color: white;
  align-items: inherit;
  position: absolute;
  right: 31px;
}

.liveCommandStartBtn {
  text-align: center;
  height: auto;
  width: auto;
  min-width: 77px;
  padding: 8px 15px;
  align-items: center;
  justify-content: center;
  float: right;
  border-radius: 15px;
  min-height: 30px;
  margin-top: 10px;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  bottom: 20px;
  right: 20px;
  background-color: #8a9a48 !important;
  color: white !important;
}
.liveCommandStartBtn span {
  color: white !important;
}

.ml-10 {
  margin-left: 10px;
}
.mt-30 {
  margin-top: 30px !important;
}
.wtimg30 {
  width: 40px !important;
}
.disconnectBtnSec1 {
  max-width: 140px;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  background: #ec9325;
  border: 7px solid #f1ce90;
  border-radius: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.connectTxt1 {
  font-size: 9px;
  font-family: Montserrat-Bold;
  letter-spacing: 0.36px;
  text-align: center;
  color: #959595;
  text-transform: uppercase;
  line-height: 17px;
}
.connectTxt1 img {
  margin-right: 6px;
  margin-top: 16px;
  padding: 9px;
}
.tractormeterBlk {
  border: 1px solid #959595 !important;
  /* border: 1px solid #e7e7e7 !important; */
  border-radius: 10px !important;
  /* box-shadow: 0 3px 6px #00000029; */
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px 13px !important;
  min-height: 160px;
}
.fleetConnectBtn {
  background: #366376;
  border-radius: 15px;
  box-shadow: 0 3px 6px #00000029;
  color: #fff !important;
  cursor: pointer;
  font-size: 12px !important;
  font-family: 'Montserrat-SemiBold';
  margin: 5px 0 10px 0 !important;
  padding: 3px 15px;
  text-transform: uppercase;
}
.waypointTimeline {
  display: block !important;
  border: 1px solid #959595;
  border-radius: 10px;
  padding: 15px !important;
}
.waypointTimeline .waypointBlk {
  padding: 0 !important;
}
.waypointTimeline .waypointBlk .waypointInrBlk {
  padding: 0 !important;
}
.addStepIcon {
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;
}
.addStepIcon .anticon-plus-circle {
  color: #546cb2 !important;
  font-size: 16px;
}
.addStepIcon span.addStepTxt {
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.38px;
  line-height: 15px;
  color: #000 !important;
}
.commandSteps .commandStepsFlex {
  display: flex;
  align-items: center;
  gap: 6px;
}
.commandSteps .commandStepsFlex .searchMapBlk {
  margin-bottom: 0 !important;
}
.commandSteps .commandStepsFlex .searchMapBlk .ant-input {
  background-color: #fff !important;
}
.commandSteps .commandStepsFlex .anticon-check {
  background: #fff !important;
  border: 1px solid #959595;
  border-radius: 50%;
  color: #959595 !important;
  font-size: 11px;
  padding: 4px;
  cursor: pointer;
}
.commandSteps .commandStepsFlex .anticon-check.checked {
  /* background: #546cb2 !important;
  border: 1px solid #546cb2; */
  background: rgb(169, 191, 81) !important;
  border: 1px solid rgb(169, 191, 81);
  border-radius: 50%;
  color: #fff !important;
  font-size: 11px;
  padding: 4px;
}
/* .autodriveDisconnectBlk {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
} */
.autodriveDisconnectBlk .disconnectBtnSec {
  background: #ec9325;
  border: 3px solid #f1ce90 !important;
  color: #fff !important;
  font-size: 9px !important;
  font-family: 'Montserrat-SemiBold';
  margin-bottom: 0 !important;
  max-width: 105px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: 28px !important;
}
.rautocloseIcon .disconnectBtnSec {
  background: #ec9325;
  border: 3px solid #f1ce90 !important;
  color: #fff !important;
  font-size: 9px !important;
  font-family: 'Montserrat-SemiBold';
  margin-bottom: 0 !important;
  max-width: 105px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: 28px !important;
}
.rautocloseIcon .disconnectBtnSec img {
  margin-right: 5px;
}
/* .autodriveDisconnectBlk .connectTxt {
} */
.autodriveDisconnectBlk img {
  width: 15px !important;
  margin-right: 3px !important;
}
.startClearBtns {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 20px;
}
.startClearBtns .driveStartBtn {
  background-color: #8a9a48 !important;
  border-color: #8a9a48 !important;
  /* background-color: #546cb2 !important; */
  /* border-color: #546cb2 !important; */
  border-radius: 15px;
  color: white !important;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  height: 28px !important;
  line-height: 15px !important;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.startClearBtns .driveStartBt:hover {
  background-color: #fff !important;
}
.endresumepauseBtns {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.endresumepauseBtns .autoDriveCamPlayControls img {
  width: 35px !important;
}
.endresumepauseBtns .autoDriveCamPlayControls img.resumeTask {
  width: 28px !important;
}
.savedCommand .ant-input {
  padding-left: 5px !important;
}
span.hitch-icon-min svg {
  width: 20px;
  height: 20px;
}
.bg-purple {
  background-color: #e9ebf4;
}
/* .autoDriveScoller .mbcams {
  margin-bottom: 15vh !important;
} */
.dflexspace {
  display: flex;
  align-items: center;
  gap: 3px;
}
.mr2 {
  margin-right: 2px;
}
.pathPopup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 95%;
  background-color: #fff;
  border: 2px solid #ec9326;
  border-radius: 12px;
  box-shadow: 3px 3px 6px #00000029;
  padding: 15px 20px 15px 20px;
  /* display: flex; */
  /* align-items: flex-start; */
  /* justify-content: space-between; */
  gap: 10px;
  z-index: 9;
  cursor: pointer;
  height: 176px;
  transition: all 400ms;
}
.pathPopup.minimise {
  left: 40%;
  width: 75%;
  height: 65px;
  padding: 8px 16px;
}
.pathPopup .path-inr-blk h4 {
  color: #242424;
  font-size: 17px;
  font-family: Montserrat-SemiBold;
  line-height: 20px;
  padding-top: 6px;
}
.pathPopup .path-inr-blk p {
  color: #767676;
  font-size: 14px;
  font-family: Montserrat-Medium;
  line-height: 18px;
  margin-bottom: 20px;
}
.pathPopup-img {
  margin-right: 12px;
}
.pathPopup.minimise .path-inr-blk p,
.pathPopup.minimise .continuebtn {
  display: none;
}
.pathPopup.minimise .path-inr-blk h4 {
  padding-top: 10px;
}
.pathPopup-minus {
  position: absolute;
  right: 16px;
  top: 15px;
  font-size: 16px;
}
.pathPopup-plus {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 16px;
}
.pathPopup .continuebtn {
  background-color: #8a9a48;
  border-radius: 7px;
  color: #fff;
  cursor: pointer;
  display: block;
  width: 195px;
  margin: auto;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  line-height: 18px;
  padding: 8px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: all 1s;
}

.pausedMsg {
  background-color: #f1ce90;
  color: #000000;
  font-size: 10px;
  font-family: Montserrat-Medium;
  line-height: 13px;
  margin: 0 0 5px 0;
  padding: 3px 7px;
  border-radius: 5px;
  letter-spacing: 0.35px;
}
.pausedMsg img {
  padding-right: 3px;
}
.settings-o {
  position: absolute;
  right: 20px;
  top: 16px;
}

.error_code_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 110px;
}

.notAvail {
  font-family: Montserrat-Medium;
  font-size: 17px;
  color: #242424;
  margin-bottom: 20px;
}
.showDtl {
  font-family: Montserrat-SemiBold;
  font-size: 14px;
  color: #4e74e5;
  text-decoration: underline;
  cursor: pointer;
}

.commonPopup {
  padding-bottom: 0;
  top: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.commonPopup .ant-modal-content,
.commonPopup .ant-modal-header {
  border-radius: 10px;
}

.errorCodePopup .ant-modal-content {
  width: 650px;
}
.mb70 {
  margin-bottom: 70px;
}
.mb40 {
  margin-bottom: 40px;
}

.errorCodeList {
  min-height: 50px;
  max-height: 200px;
  overflow-y: auto;
}
.errorListItem {
  margin-bottom: 15px;
}
.errorCodeNo {
  font-family: Montserrat-Bold;
  font-size: 14px;
  color: #000;
  margin-right: 10px;
}
.errorCodeName {
  font-family: Montserrat-Medium;
  font-size: 14px;
  color: #000;
}
.errorCodeNameAlpha {
  font-family: Montserrat-Medium;
  font-size: 14px;
  color: #d33c39;
}

.errorCodePopup .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorPopupBtn,
.errorPopupBtn:hover,
.errorPopupBtn:focus {
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #366376 !important;
  color: #fff;
  font-size: 14px;
  padding: 4px 15px;
  min-width: 100px;
}

.closeIconGrid {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
}
.closeIconGrid .anticon-close {
  text-align: left;
}
.closeIconGrid .swithBtn {
  text-align: center;
}

.camera_box:hover {
  background-color: #e7e7e7;
  color: #7a86c4;
}
.camera_box.active {
  background-color: #5562b4;
}
/* .autoDrive_color .camera_box.active {
  background-color: #ec9328;
}
.chargin_color .camera_box.active {
  background-color: #aabe38;
} */

.ts_off_color .camera_box.active {
  background-color: #cfcfcf;
}
.ts_shadow_color .camera_box.active {
  background-color: #67afb7;
}
.ts_rowfollow_color .camera_box.active {
  background-color: #67afb7;
}
.ts_autodrive_color .camera_box.active {
  background-color: #ec9322;
}
.ts_manual_color .camera_box.active {
  background-color: #67afb7;
}
.ts_routine_color .camera_box.active {
  background-color: #ec9322;
}
.ts_charging_color .camera_box.active {
  background-color: #a9bf51;
}
.ts_available_color .camera_box.active {
  background-color: #343b5e;
}
.ts_faulty_color .camera_box.active {
  background-color: #d43d38;
}

.camera_box.active .t_camera_lbl {
  color: #fff !important;
}
.camera_box_cam_icon {
  margin-top: 6px;
  padding-left: 4px;
  cursor: pointer;
}
.border-none {
  border: none !important;
}
.snapshot-cam {
  position: absolute;
  left: 70px;
  padding: 10px 16px;
  cursor: pointer;
}

.logoutPop .ant-modal-body {
  padding: 15px 0px 0px 0px !important;
}
.redBdr {
  border: 1px solid #f00 !important;
}
.pusTxt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  color: #D33C39;
}
.whiteBdr,
.t_off .redBdr {
  border: 1px solid #fff;
}
.t_signals .mr4 {
  margin-right: 0px;
}

.t_off ~ .redBdr {
  border: 1px solid #fff;
}
.rautoDriveStatus .whiteBdr .t_signals img:first-child,
.rautoDriveStatus .redBdr .t_signals img:first-child,
.rautoDriveStatus .whiteBdr .t_signals img:first-child {
  margin-right: 5px !important;
}

/* right side autodrive css */

@media (max-width: 1440px) {
  .livePic .ant-avatar.defaulticon {
    height: 175px !important;
  }
  .autoDriveCancel.mb40 {
    margin-bottom: 20px !important;
  }
}

.adrImpDtl {
  height: calc(100vh - 100px) !important;
}

@media (min-width: 1441px) and (max-width: 4320px) {
  .proRow.livePic .ant-avatar.impImgW {
    width: calc(100% - 0px) !important;
  }
  .chooseTxt {
    padding-right: 0 !important;
  }
}

/* .t_signals.t_error {
  border: 1px solid #d43d38;
} */

/* @media (min-width: 768px) and (max-width: 1800px) {
  .remoteOperationsPopup {
    padding: 5px 20px 5px 30px;
    min-height: 98px;
    left: 25px;
  }
} */
.maint_autodrive_btns_full {
  grid-template-columns: 100% !important;
  width: 100% !important;
}
.maint_autodrive_btns_full .comm_btn {
  min-height: 44px !important;
  flex-direction: row !important;
}
.dFlexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchFieldBlk {
  display: flex;
  align-items: center;
  padding: 0 15px;
  gap: 6px;
}
.searchFieldBlk .searchMapBlk {
  display: flex;
  margin-bottom: 0 !important;
  min-width: 225px;
  width: 100%;
  position: relative;
}
.searchFieldBlk .anticon-close-circle {
  font-size: 16px;
}
.searchFieldBlk .searchMapBlk .anticon-search {
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.saveCommandBlk {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px;
  gap: 6px;
}
.saveCommandBlk .searchMapBlk {
  margin-bottom: 0 !important;
}
.saveCommandBlk .anticon-close-circle {
  font-size: 16px;
}
.commandStepsFlexRoute .ant-input {
  padding-right: 20px !important;
}
.commandBackBtn {
  position: relative;
}
.commandBackBtn .backBtn {
  cursor: pointer;
  position: absolute;
  left: 15px;
  top: -30px;
  font-size: 11px;
  font-family: 'Montserrat-Medium';
  color: #959595;
  letter-spacing: 1.25px;
}
.inputWidth {
  width: 100%;
}
.commandBackBtn .searchMapBlk .ant-input,
.commandBackBtn .searchMapBlk .ant-input:hover,
.commandBackBtn .saveCommandBlk .ant-select-selector {
  background-color: #f7f7f7!important;
  border: 1px solid #d9d9d9 !important;
  border-color: #d9d9d9 !important;
  border-radius: 10px!important;
  height: 36px !important;
  font-size: 14px !important;
  font-family: "Montserrat-SemiBold" !important;
  letter-spacing: .1px !important;
  line-height: 18px !important;
}
.gotoPointBlk .searchMapBlk {
  width: 100%;
}