.fuvWrapper {
    display: block;

}

.fuvRow {
    display: flex;
}

.fuvHead {
    background-color: #fff;
    border-color: #D0D0D0;
    height: 60px;
    width: 100%; 
    padding: 0 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.fuvButtons {
    display: flex;
    flex-direction: row;

}
.fuvBtn, .fuvBtn:hover, .fuvBtn:focus {
    background-color: #B6B6B7;
    border-radius: 6px;
    height: 38px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 14px;
    color: #000000c9 ;
    font-family: Montserrat-SemiBold;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    cursor: pointer;
}

.resumeAllTxt, .resumeAllTxt:hover, .resumeAllTxt:focus {
    background-color: #8A9A48;
    border-radius: 6px;
    height: 38px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 14px;
    color: #fff ;
    font-family: Montserrat-SemiBold;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    cursor: pointer;
}

.fuvBtn img {margin-right: 5px;}


.fuvRoot {
    width: 100%;
    min-width: 350px;
}
.fuvFilesList {
    width: 60%;   
    display: block;
    align-items: center;
}
.fuvbdr {
    display: flex;
    border-top: 1px solid #D0D0D0;
    /* border-right: 1px solid #D0D0D0; */
    border-bottom: 1px solid #D0D0D0;
    align-items: center;
    justify-content: flex-start;
}
.rootTxt {
    font-size: 14px;
    color: #000000 ;
    font-family: Montserrat-SemiBold;
    letter-spacing: .80px;
    text-transform: uppercase;
    margin-left: 10px;
}
.fuvFileHead {
    display: inline-flex;
    width: 100%;
    height: 60px;
    padding: 0 15px;
    border-bottom: 1px solid #D0D0D0;    
}

.fuvFileBtn img {margin-right: 5px;}

.fuvFilesList .fuvFileBtn {
    margin-right: 40px;
}
.fuvFileBtn {
    background: transparent;
    border: none;
    outline: none;
    font-family: Montserrat-SemiBold;
    letter-spacing: .80px;
    cursor: pointer;
}


.fuvTable .ant-table table {
    padding: 0;
    border-left: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
}

.fuvTable .ant-table-pagination.ant-pagination {
    display: none;
}
.mb10 {
    margin-bottom: 10px;
}
.fullBdr {
    /* border: 1px solid #D0D0D0; */
    background: transparent;
}
.fuvConsole {
    padding: 10px 15px;
}
.fuvConsole p {
    font-size: 14px;
    font-family: Montserrat-Medium;
    color: #000;
    margin-bottom: 0;
}
.fuvError {
    color: #EF2C2C !important;
}

.rootFolders {
    padding: 10px 15px;
    max-height: 520px;
    overflow: auto;
}
.rootFolders .ant-collapse {
    background: none;
    border: none;
}
.rootFolders .ant-collapse > .ant-collapse-item {
    border: none;
}
.rootFolders .ant-collapse-header {
    font-size: 14px;
    font-family: Montserrat-SemiBold;
    letter-spacing: .80px;
}

.fileUploadBack{
    margin: 20px 44px;
}


.pauseTxt, .pauseTxt:hover, .pauseTxt:focus {
    background-color: #B6B6B7;
    border-radius: 6px;
    height: 38px;
    outline: none;
    border: none;
    font-size: 14px;
    color: #000000c9 ;
    letter-spacing: 0.8px;
    cursor: pointer;
    width: 118px;
}

.resumeTxt, .resumeTxt:hover, .resumeTxt:focus {
    background-color: #8A9A48;
    border-radius: 6px;
    height: 38px;
    outline: none;
    border: none;
    font-size: 14px;
    color: #fff ;
    letter-spacing: 0.8px;
    cursor: pointer;
    width: 118px;
}

.fuvRoot .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content{
    display: none !important;
}

.rootItemsTxt {
    font-size: 16px;
    font-family: Montserrat-Regular;
    text-transform: capitalize;
}
.rootTxt {
    font-size: 16px;
}

.fuvWrapper {
    border-radius: 10px;
    border: 1px solid #D0D0D0;
    background: #fff;
}
.fuvRow > .fuvHead {
    border-radius: 10px 10px 0 0 ;
}