@import url('../common/navigation.css');
.orgDetailsStyles {
  /* width: 100%;
  height: 250px;
  margin: 5px;
  border: 0.01px solid #cccddc;
  border-radius: 5px 5px 5px 5px; */
  font-family: Montserrat-Medium;
  margin: 0 auto;
}
.container {
  display: flex;
}
.col1 {
  width: 250px;
}
.col2 {
  width: 750px;
}
.col2-styles {
  display: flex;
  margin-bottom: 8px;
}
.orgDetails {
  width: 150px;
  display: flex;
  /* font-size: 12px;
  font-family: Montserrat-ExtraLight; */
}
.orgData {
  width: 650px;
  font-size: 12px;
  font-family: Montserrat-Medium;
}
.editBtn {
  /* width: 70px; */
  /* height: 35px; */
  border: none;
  border-radius: 4px;
  background-color: #eb921f;
  color: #fff;
  /* padding: 6px 20px; */
}
.snapshotContent .employeeCreate,
.snapshotContent .employeeCreate:hover,
.snapshotContent .employeeCreate:focus {
  height: 40px;
  border: none;
  border-radius: 10px !important;
  background-color: #1e3a46 !important;
  background: #1e3a46 !important;
  color: #fff;
  font-size: 14px;
  padding: 4px 15px;
}
.imgStyles {
  width: 150px;
  height: 150px;
  margin-bottom: 18px;
  border: 150px !important;
}

.employeesList {
  /* width: 100%; */
  /* height: 250px; */
  /* margin: 5px; */
  font-family: Montserrat-SemiBold;
  /* border: 0.01px solid #cccddc; */
  /* border-radius: 5px 5px 5px 5px; */
}
.table-border {
  border: 1px solid #ebecf0;
}
.marginLeftIcon {
  margin-left: 5px;
  height: 18px;
  width: 18px;
}
.emailAlign {
  /* line-height: 36px; */
  min-width: 240px;
}
.iconAlign {
  /* margin-right: 100px; */
}

.hoverWrapper {
  display: flex;
}

.hoverWrapper:hover .hoverShow1 {
  display: block;
  cursor: pointer;
}
.hoverWrapper .hoverShow1 {
  display: none;
}

/* .hoverWrapper img {
  float: right;
} */

/* Edit modal */

.modalContainer .ant-modal-footer {
  padding: 18px 16px;
}
.modalContainer .ant-modal-body {
  text-align: center;
}
.modalContainer .ant-modal-content {
  min-width: 700px;
  border: 0;
  font-family: Montserrat-SemiBold;
}
.btnSave {
  height: 32px;
  /* background-color: #eb921f; */
  font-size: 14px;
  color: #fff;
  border-radius: 3px;
  text-transform: uppercase;
}
.btnSaveFarm {
  height: 32px;
  /* background-color: #eb921f; */
  font-size: 14px;
  color: #fff;
  border-radius: 3px;
  text-transform: uppercase;
}
.btnSaveFarm:hover {
  background-color: #f7f7f7;
}
/* .btnCancel {
  width: 90px;
  width: 100px;
  height: 32px;
  font-size: 14px;
  color: #545454;
  border-radius: 5px;
  border-radius: 2px;
} */
.modalBox {
  display: flex;
}
.uploadImg {
  margin-right: 40px;
  position: relative;
}
.uploadTxt {
  margin-right: 115px;
  margin-bottom: 15px;
  font-family: Montserrat-Medium;
}
.btnUpload {
  width: 200px;
  height: 36px;
  background-color: #f7f7f7;
  margin-top: -23px;
  margin-right: -195px;
  border-radius: 2px;
  border: 1px solid #d6d6d6;
}
.ant-upload {
  width: 200px !important;
  height: 36px !important;
  margin-top: 10px !important;
  margin-left: -32px !important;
}
.btnRemove {
  margin-right: 40px;
  font-family: Montserrat-Medium;
  color: #ff5252;
}

.modalContainer .ant-tooltip-inner {
  display: none;
}
.modalContainer .ant-upload.ant-upload-select {
  display: flex;
}
.modalContainer .ant-upload.ant-upload-select-picture-card {
  border: none;
  background-color: transparent;
}
.modalContainer .ant-upload-list-picture-card .ant-upload-list-item {
  right: 15px;
}
.modal-col1,
.modal-col2,
.modaladdEmp-col2 {
  width: 50%;
  margin-right: 20px;
}
.Txt1 {
  text-align: left;
  /* font-family: Montserrat-Medium; */
}
/* .input {
  width: 305px;
  height: 36px;
  background-color: #edeef2;
  margin-bottom: 10px;
  border-radius: 4px;
  font-family: Montserrat-Medium;
} */
.on-hover-hand-change {
  cursor: pointer;
}
.atomCheckBox {
  margin: 18px;
  transform: translateX(-73px);
}
.EmployeeModalContainer .ant-modal-body {
  min-width: 700px;
  text-align: center;
}
.EmployeeModalContainer .ant-modal-content {
  min-width: 700px;
  border: 0;
  font-family: Montserrat-SemiBold;
}
/* .rowClassName1 {
  background-color: #f8f7f4;
  font-family: 'Montserrat-Medium';
} */
.search-box {
  width: 300px;
  height: 32px;
  /* color: #f0f0f0; */
  border-radius: 4px;
  margin: 0px 4px 0px 0px;
}
.searchIcon {
  opacity: 0.4;
}
.ant-form-item-label > label::after {
  content: '';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.addMScroll {
  height: 350px;
  overflow-y: auto;
}
.formColR {
  width: 100%;
  display: inline-block;
}
.btnDeltete {
  margin-left: 3px;
}
.tooltip .tooltiptext {
  width: 120px;
  background-color: green;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 6px 5px;
  margin-left: 13px;
}
.load {
  padding-top: 152px;
  padding-left: 303px;
  position: fixed;
}
.custom-loader {
  padding-top: 4px;
  padding-left: 295px;
  position: fixed;
}

.infinite-scroll-component {
  height: auto !important;
  max-height: calc(100vh - 326px);
}
.mbN20 {
  margin-bottom: -20px !important;
}

.empTbl .infinite-scroll-component {
  height: auto !important;
  max-height: calc(100vh - 500px);
}

.impTbl .infinite-scroll-component {
  height: auto !important;
  max-height: calc(100vh - 395px);
}

/* #empTbl table tbody tr,
#rolesTbl table tbody tr,
#tractorTbl table tbody tr,
#basesTbl table tbody tr {
  cursor: pointer;
} */

.hoverable table tbody tr {
}

.nTxt {
  color: #ababab;
  margin-right: 30px;
}

#empTbl .ant-table-thead {
  position: sticky;
  z-index: 20;
  top: 0;
}

.grPopup .ant-modal-body {
  min-height: 450px;
}

.profContainer {
  /* padding: 20px; */
  display: block;
  /* width: 75vw; */
  margin: 0 auto;
}
.profPicSec {
  /* margin-right: 20px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* width: 40%;
  margin-bottom: 55px; */
}

.profHead {
  font-size: 20px;
  font-family: Montserrat-SemiBold;
  line-height: 23px;
  /* margin: 0px; */
  padding: 0px;
}

.profAddress,
.profDescCont {
  font-size: 16px;
  font-family: Montserrat-Regular;
  line-height: 23px;
  margin: 0px;
  padding: 0px;
  max-width: 460px;
}
.profAddress {
  max-width: 240px;
  word-break: break-word;
}
.padt40 {
  padding-top: 40px;
}

.profDescHead {
  font-size: 16px;
  font-family: Montserrat-Medium;
  line-height: 19px;
  margin: 0px;
  padding: 0px;
}
.profCropRow {
  display: flex;
  margin-bottom: 24px;
}
.profCropHead {
  margin: 0px;
  padding: 0px;
  min-width: 150px;
  display: inline-block;
  word-break: break-word;
}
.profCropValue {
  margin: 0px;
  padding: 0px;
  min-width: 15vw;
  display: inline-block;
  padding-left: 20px;
  word-break: break-word;
}
.profCropValue div {
  line-height: 22px;
}

.ovf_hidden {
  overflow-y: hidden !important;
}
.ovfY_auto {
  overflow-y: auto;
}
.ml0 {
  margin-left: 0 auto;
}

.btnSecRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  align-self: baseline;
}

.profileMapMaker {
  display: block;
  min-width: 1050px;
  margin: 0 auto;
}
.mapMakerSec {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid #707070;
  min-height: 250px;
}
.tblTopOutSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.searchSec .ant-input-search {
  border: 1px solid #e7e7e7;
  border-radius: 6px;
  /* min-height: 50px; */
  background: #f7f7f7;
  /* height:50px; */
  display: flex;
  align-items: center;
  /* width: 18.28vw !important; */
}
/* .searchSec .ant-input-search input {
  padding-left: 40px !important;
  min-height: 50px;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.1px;
  line-height: 18px;
  width: 100%;
} */

.mb20 {
  margin-bottom: 20px;
}
.tblTopOutSec .ant-input,
.tblTopOutSec .ant-input:focus,
.tblTopOutSec .ant-input-group-addon,
.tblTopOutSec .ant-input-search-button {
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  border-color: none !important;
  box-shadow: none !important;
}

.tblHeadSec {
  display: flex;
  font-size: 40px;
}

.empTbl .ant-card-head {
  border-bottom: 0px;
}
.empTbl .ant-card .ant-card-body {
  border: 1px solid #707070;
  padding: 0px !important;
  margin-left: 24px;
  margin-right: 24px;
}
.tblDft {
  max-height: calc(100vh - 313px);
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  /* overflow: hidden; */
}
.empTbl .ant-card-head-title {
  font-size: 16px;
  font-family: Montserrat-SemiBold;
}

.empTbl .ant-table-thead > tr > th {
  background: transparent !important;
  font-size: 16px;
  font-family: Montserrat-SemiBold;
  color: #000;
  /* padding-top: 18px;
  padding-bottom: 18px; */
  height: 56px;
  border-bottom: 1px solid #e7e7e7;
}
.ant-table-tbody > tr > td {
  font-size: 16px;
  font-family: Montserrat-Regular;
  height: 62px;
  border: none !important;
  letter-spacing: 0.25px;
  padding: 15px;
  color: #000;
  box-sizing: border-box;
  line-height: 20px;
}
.ant-table-thead > tr > th {
  /* padding: 13px; */
  height: 62px;
}

.topRight {
  position: absolute;
  top: 0;
  right: 0;
}

/* .elTabs .ant-tabs-content {
  margin-top: 27px !important;
} */
.elTabs1 .ant-tabs-content {
  margin-top: -20px !important;
}
.profHeadTxt {
  font-size: 2.5vw;
  font-family: Montserrat-SemiBold;
}
.mb32 {
  margin-bottom: 32px;
}

.profRunTxt {
  font-size: 18px;
  font-family: Montserrat-Regular;
  line-height: 26px;
  margin-bottom: 49px;
}

.rightSideWidegt {
  position: fixed;
  /* right: -420px; */
  top: 0px;
  /* width: 420px; */
  background: #f7f7f7;
  height: 100vh;
  /* padding:38px; */
  z-index: 999;
  box-shadow: -1px 0px 6px rgba(0, 0, 0, 0.29);
  transition: 1s;
}
.hide {
  right: -420px;
  transition: 1s;
}
.mb55 {
  margin-bottom: 6.08vh;
}
.mb24 {
  margin-bottom: 2.65vh !important;
}
.mb48 {
  margin-bottom: 5.31vh;
}
.mb40 {
  margin-bottom: 4.42vh;
}
.mbvh20 {
  margin-bottom: 2.21vh !important;
}
.mbvh48 {
  margin-bottom: 5.32vh;
}
.mbvh55 {
  margin-bottom: 6.1vh;
}
.proRow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.proRoleHead {
  /* font-size: 1.77vw;
  font-family: Montserrat-SemiBold;
  line-height: 124%; */
  text-align: center;
  margin: 0px;
}
.proRole {
  font-size: 1.04vw;
  font-family: Montserrat-SemiBold;
  line-height: 120%;
  text-align: center;
  margin: 0;
}
.proRoleType {
  font-size: 1.04vw;
  font-family: Montserrat-SemiBold;
  line-height: 118%;
  text-align: left;
  margin: 0 0 16px 0;
}
.proRoleTypeHead {
  font-size: 20px;
  font-family: Montserrat-SemiBold;
  line-height: 24px;
  text-align: left;
  /* margin: 0 0 16px 0; */
  font-weight: 600;
}
.proRoleTypeHead_details {
  /* font-size: 16px;
  font-family: Montserrat-SemiBold;
  line-height: 24px; */
  text-align: left;
  margin: 0;
  font-weight: normal !important;
}
.proRoleCards {
  border: 1px solid #cececf;
  border-radius: 15px;
  opacity: 1;
  padding: 12px;
  margin: 0 0 16px 0;
}
.proSideHeading {
  /* font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) var(--unnamed-font-size-12) /
    var(--unnamed-line-spacing-15) var(--unnamed-font-family-montserrat); */
  color: var(---67afb7);
  text-align: left;
  /* font: normal normal medium 12px/15px Montserrat;
  letter-spacing: 0.11px; */
  color: #67afb7;
  opacity: 1;
  text-transform: capitalize;
}
.proRoleType_details {
  font-size: 12px;
  font-family: Montserrat-Regular;
  line-height: 20px;
  letter-spacing: 0.19px;
  color: #333333;
  text-align: left;
  margin: 6px 0 0 22px;
}
.proRoleTypeInput {
  font-size: 20px;
  font-family: Montserrat-SemiBold;
  line-height: 24px;
  text-align: left;
  margin: 0 0 16px 0;
  min-width: 14.8vw;
}
.proRowInput {
  display: flex;
  align-items: left;
  justify-content: flex-start;
  flex-direction: column;
}
.proRoleValue {
  /* font-size: 0.94vw;
  font-family: Montserrat-Regular;
  line-height: 26px; */
  text-align: left;
  margin: 0 0 0 22px;
  /* cursor: pointer; */
}
.proRowType {
  display: flex;
  align-items: left;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
}
.proeditBtn {
  background: transparent;
  height: 70px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
}

.proeditBtn button {
  /* font-size: 1.04vw;
  font-family: Montserrat-SemiBold; */
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  color: #366376;
  right: 38px;
  bottom: 20px;
  position: absolute;
  text-transform: uppercase;
}
.proInput {
  min-width: 14.8vw;
  margin-bottom: 24px;
}
.proInput input,
.proInput input::placeholder {
  font-size: 16px;
  color: #1e3a46;
  font-family: Montserrat-Medium;
  line-height: 19px;
  letter-spacing: 0.093rem;
}
.proPic img {
  border-radius: 75%;
  width: 9.32vw;
  height: 19.8vh;
}

.show {
  display: block;
  transition: 5s;
}

.positionMap-inner {
  position: absolute;
  bottom: 38px;
  right: 8px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 5px;
  color: #fff;
  width: 100px !important;
}

.positionMap-inner::before {
  content: '';
  position: absolute;
  background-image: url(../../assets/images/mapMilesLineW.svg);
  width: 60px;
  height: 5px;
  margin-top: -3px;
}

/* .elTabs .ant-tabs-content {
  height: calc(100vh - 162px);
  overflow-y: auto;
} */
.elTabs1 .ant-tabs-content {
  height: calc(100vh - 115px);
  overflow-y: auto;
}

.profPic img {
  max-width: 100%;
}
/* .profDetails {
  width: 60%;
} */
.profilePic {
  width: 15.25vw;
  height: 15.25vw;
}
.profilePic .profile-style,
.profilePic img {
  max-width: 100%;
  width: 100%;
  border-radius: 150px;
  border: none;
  box-shadow: none;
  border-radius: 75%;
  /* border: 1px solid #ccc; */
  object-fit: cover;
  max-width: 100%;
  height: auto;
}
.profile-style-width {
  width: 100%;
  border-radius: 150px;
  border: none;
  box-shadow: none;
  border-radius: 75%;
  /* border: 1px solid #ccc; */
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}
.profile-style-width > .ant-avatar-string {
  padding: 39% !important;
}

.tblContainer {
  /* padding: 20px; */
  display: block;
  width: 94%;
  margin: 0 auto;
}

/* .searchMapBlk {
  position: relative;
  margin-bottom: 19px;
  width: 100%;
} */
.searchMapBlk .anticon-search {
  color: #939393;
  width: 12px;
  height: 12px;
}
.searchMapBlk .ant-input {
  width: 100%;
  height: 50px;
  background-color: #f7f7f7 !important;
  border-radius: 10px !important;
  padding-left: 30px;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.1px;
  line-height: 18px;
}
.searchMapBlk.ant-input::placeholder {
  color: #959595;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  line-height: 18px;
  letter-spacing: 0.1px;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: 0;
  padding: 0;
  border: 0;
  position: absolute;
}

/* .searchSec .ant-input-group-addon button {
  width: 40px;
  height: 59px;
} */

/* .ant-input-group .ant-input {
  left: 1.51vw;
} */
.site-layout .ant-input {
  background: transparent;
}

.ant-table table {
  padding-left: 20px;
  padding-right: 20px;
}
.elTabs .ant-layout {
  background: #fff;
  border-radius: 20px !important;
}

div#total {
  padding-top: 5px;
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 1.71px;
  line-height: 19px;
  position: relative;
  top: 35px;
  /* background: #fff; */
}
.closeButton {
  padding: 4.2vh 1.98vw 0px 1.98vw;
}
.profileView {
  overflow-y: auto;
  height: calc(100vh - 160px);
  padding: 0px 38px 20px 38px;
}
.tblDft .ant-table-body {
  /* height: calc(100vh -  370px); 
  overflow-x: hidden; */
}
.iconProfile {
  display: flex;
  align-items: center;
}

.noBorder {
  border: none;
}
.smallFont {
  font-size: 16px;
  margin: 0px 10px;
}
.closeButton img {
  cursor: pointer;
}
.wwrapBreakAll {
  word-break: break-word;
}
.empName .wwrapBreakAll {
  width: 200px;
  margin-left: 25px;
}
.ant-table-column-sorter {
  visibility: hidden;
}
.sort-pad {
  padding-left: 10px;
}
/* .tblDft .ant-empty.ant-empty-normal {
  display: none;
} */
.tblDft td.ant-table-cell.ant-table-column-sort {
  background-color: #fff;
}
/* div#empTbl {
  min-height: calc(100vh - 23vh);
} */

@media screen and (min-width: 1600px) and (max-width: 9999px) {
  /* .elTabs .ant-tabs-content {
    height: calc(100vh - 162px);
    overflow-y: auto;
  } */
}

@media screen and (max-width: 1440px) {
  .proPic img {
    border-radius: 75%;
    width: 145px;
    height: 145px;
  }

  .proPic .ant-avatar {
    border-radius: 75%;
    width: 145px;
    height: 145px;
  }
  .proPic .ant-avatar .ant-avatar-string {
    border-radius: 75%;
    width: 145px;
    height: 145px;
  }
  /* .proRoleHead {
    font-size: 30px;
  } */
  .proRole {
    font-size: 16px;
  }
  .proRoleType {
    font-size: 16px;
  }
  .proRoleValue {
    font-size: 16px;
  }
  .proInput {
    min-width: 284px;
  }
  .proRoleDescriptionValue {
    font-size: 12px;
  }
  /* .proRoleType_details {
    font-size: 14px;
  } */
}

@media screen and (min-width: 1441px) and (max-width: 9999px) {
  .proPic img {
    border-radius: 75%;
    width: 179px;
    height: 179px;
  }

  .proPic .ant-avatar {
    border-radius: 75%;
    width: 179px;
    height: 179px;
  }
  .proPic .ant-avatar .ant-avatar-string {
    border-radius: 75%;
    width: 179px;
    height: 179px;
  }
}

.emailAlign .ant-avatar {
  border: 1px solid #67afb7;
}
.proPic .ant-avatar {
  background: #67afb7;
  border: 1px solid #67afb7;
}
.personeeltab .proPic .ant-avatar {
  background: transparent !important;
}
.iconBoxProfile .ant-avatar {
  display: flex;
  align-items: center;
}

#empTbl .ant-table-body {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.personnelSec {
  margin-top: 5px;
}
.personnelSec .anticon-arrow-left {
  color: #366376;
  border-radius: 9px;
  box-shadow: 0 0 6px #00000029;
  padding: 8px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  /* margin-right: 3.125vw; */
  position: absolute;
  left: 4.69vw;
}
.personnelBlk .personnelInnerBlk {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 6px #00000029;
  width: 23.3vw;
  margin-right: 4.1vw;
  padding: 20px 0 20px 0;
  cursor: pointer;
}
.personnelBlk .personnelInnerBlk:last-child {
  margin-right: 0;
  cursor: pointer;
}
.personnelInnerBlk h4 {
  font-size: 22px;
  font-family: 'Montserrat-Medium';
  color: #000;
  line-height: 29px;
  text-align: center;
  margin: 0;
}
.personnelInnerBlk h6 {
  font-size: 13px;
  font-family: 'Montserrat-SemiBold';
  color: #000;
  line-height: 18px;
  letter-spacing: 0.1px;
  text-align: center;
  margin: 0;
  line-height: 24px;
}
.personnelInnerBlk p {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  color: #366376;
  line-height: 18px;
  letter-spacing: 1.25px;
  text-align: center;
  margin: 0;
  cursor: pointer;
  text-transform: uppercase;
}
.personnelInnerBlk p:hover {
  color: #67afb7;
}
.personnelList {
  padding-top: 50px;
  padding-bottom: 40px;
}
.personnelInnerBlk h3:first-child {
  margin-top: 0;
}
.personnelInnerBlk h3 {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  color: #000;
  line-height: 26px;
  letter-spacing: 0.56px;
  margin: 0;
  padding-bottom: 15px;
  margin-top: 40px;
}
.personnelInnerBlk h3 span {
  float: right;
}
.personnelInnerBlk ul {
  padding-left: 2.34vw;
  list-style: none;
}
.personnelInnerBlk ul li {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  color: #000;
  line-height: 22px;
  letter-spacing: 0.25px;
  /* margin-bottom: 24px; */
  margin-bottom: 2.66vh;
}
.personnelList {
  /* height: calc(100vh - 650px); */
  overflow-y: auto;
  padding: 0 24px 0 32px;
  margin-top: 30px;
  margin-bottom: 28px;
}
/* Scrollbar width */
.personnelList::-webkit-scrollbar {
  width: 3px;
}
.personnelList::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.personnelList::-webkit-scrollbar-thumb {
  background: #959595;
  border-radius: 6px;
}
.personnelList::-webkit-scrollbar-thumb {
  background: #959595 !important;
}

.ml34 {
  margin-left: 34px;
}

.width284 {
  width: 284px;
}

.uploadImg .ant-upload-list-picture-card-container {
  margin: -128px 8px 8px 114px !important;
}
.uploadImg .ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #fff;
}

.uploadImg .ant-upload.ant-upload-select-picture-card {
  display: contents;
}
.uploadImg .ant-upload {
  margin-top: -55px !important;
  margin-left: 106px !important;
  z-index: 10;
  position: absolute;
}
.veditroles {
  position: absolute;
  right: 0px;
  top: -25px;
}
.vHtxt {
  /* font-size: 14px;
  font-family: Montserrat-SemiBold; */
  color: #366376;
  text-transform: uppercase;
  /* letter-spacing: 1.25px;
  line-height: 18px; */
  cursor: pointer;
}

.vHtxt:hover {
  color: #67afb7;
  pointer-events: auto;
}
.farmTabsTbl {
  position: relative;
}

/* new styles 22-12-2021 */
.permissionsRow {
  margin-top: 5px;
}
.permissionsBlk {
  width: 100%;
}
.leftArrowIcon {
  color: #366376;
  border-radius: 9px;
  box-shadow: 0 0 6px #00000029;
  padding: 8px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
.permissionsBlk h4 {
  font-size: 24px;
  font-family: 'Montserrat-Medium';
  line-height: 29px;
  margin: 0;
}
.permissionsBlk h6 {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.1px;
  line-height: 18px;
  margin: 0;
}
.permissionsTbl {
  width: 100%;
  padding: 20px;
  padding-top: 0;
  height: calc(100vh - 285px);
  overflow: auto;
}
.permissionsTbl table {
  table-layout: fixed;
}
.permissionsTbl table thead {
  border-bottom: 1px solid #ebecf0 !important;
}
.permissionsTbl table thead th {
  background-color: #fff;
  font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.71px;
  line-height: 19px;
  color: #000;
  position: sticky;
  top: 0;
  z-index: 3;
  height: 62px;
}
.permissionsTbl table tr th:first-child,
.permissionsTbl table tr td:first-child {
  text-align: left;
  width: 400px;
}
.permissionsTbl table tr th:last-child,
.permissionsTbl table tr td:last-child {
  width: auto;
}
.permissionsTbl table tr th,
.permissionsTbl table tr td {
  text-align: center;
  width: 180px;
}
.permissionsTbl table tr td:first-child {
  padding-left: 45px;
}
.permissionsTbl table tr td {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.25px;
  line-height: 22px;
  color: #000;
  padding-top: 15px;
  padding-bottom: 15px;
}
.permissionsTbl table tr.parentTblRow td:first-child {
  padding-left: 0;
}
.permissionsTbl .ant-checkbox-checked .ant-checkbox-inner,
.permissionsTbl .ant-checkbox-inner {
  background-color: transparent;
  border-color: #366376 !important;
  border-radius: 5px;
  width: 19px;
  height: 19px;
}
.permissionsTbl .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #366376;
  border-top: 0;
  border-left: 0;
  left: 27%;
}
.permissionsTbl .ant-checkbox-checked::after {
  border: 0 !important;
}
.addPermissionButton {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  height: 60px;
  width: 60px;
  float: right;
  cursor: pointer;
  position: absolute;
  right: 70px;
  bottom: 0px;
}
.editRoles {
  cursor: pointer;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  color: #366376;
  text-transform: uppercase;
  float: right;
  /* margin-top: 260px; */
  right: 38px;
  position: absolute;
  bottom: 20px;
}

/* .empView{
  height: calc(100vh - 105px);
} */

.mt106 {
  margin-top: 106px;
}
.permissionsTbl .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 12px;
  height: 2px;
  background-color: #366376;
}
.permissonPopup .anticon-close {
  display: none;
}
.permissonPopup {
  width: 512px !important;
  height: 321px !important;
}
.permissonPopup .ant-modal-content {
  border-radius: 15px !important;
}
.permissonPopup .ant-modal-body {
  padding: 56px 64px;
}
.permissonPopup p {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.25px;
  line-height: 22px;
  padding-left: 0;
  margin-top: 45px;
  margin-bottom: 0;
}
.permissonVerSec {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
@media screen and (max-width: 1440px) {
  .permissonPopup {
    width: 512px !important;
    height: 321px !important;
  }
  /* .searchSec .ant-input-group-addon button {
    height: 55px;
  } */
}

.permissionsTbl .parentTblRow .ant-checkbox-checked .ant-checkbox-inner,
.permissionsTbl .parentTblRow .ant-checkbox-inner {
  width: 22px;
  height: 22px;
}
.permissionsTbl .parentTblRow .ant-checkbox-checked .ant-checkbox-inner::after {
  left: 25%;
  top: 45%;
  width: 6.714286px;
  height: 11.142857px;
}
.editImg {
  position: absolute;
  right: 20px;
  bottom: 4px;
  cursor: pointer;
}
.editImgs {
  position: absolute;
  right: 20px;
  bottom: 30px;
  cursor: pointer;
}

.mt3 {
  margin-top: 3px;
}

.personnelSec .personnelBlk {
  height: calc(100vh - 270px);
}
.ovf_scroll {
  overflow-y: auto;
  height: calc(100vh - 400px);
}
.pos-abs {
  position: absolute;
  bottom: 20px;
  left: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.pos-rel {
  position: relative;
}

.mb1 {
  margin-bottom: 10px !important;
}
.empName .ant-avatar-string {
  text-transform: uppercase;
}
.addedbtn {
  right: 390px !important;
  transition: 1s !important;
}
.proPic .defaulticon img {
  border-radius: 50%;
  /* width: 65px!important;
  height: 65px!important; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.proPic .ant-avatar.defaulticon {
  border-radius: 75%;
  width: 145px;
  height: 145px;
  background: #fff;
  border: 1px solid #fff;
}
.permissionTxt {
  font-family: Montserrat-Regular;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: #3e3e3e;
}
.customCheck {
  display: flex;
}
.customCheck .ant-checkbox {
  width: 30px !important;
}
.customCheck .checkBoxItem .ant-checkbox-checked .ant-checkbox-inner::after,
.ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border: 1px solid #959595 !important;
  border-radius: 5px;
}

/* viewport styles */
/* .elTabs > .ant-tabs-nav {
  margin-bottom: 2.21vh !important;
  height: 12.3vh !important;
} */
/* .elTabs .ant-tabs-content {
  margin-top: 2.99vh !important;
  /* height: calc(100vh - 175px)!important; */
/* height: calc(100vh - 18.2vh) !important; */
*/ .viewportContainer .tblTopOutSec {
  margin-bottom: 4vh !important;
}
.mbv40 {
  margin-bottom: 4vh !important;
}
/* .viewportContainer .tblDft,
.tblDft {
  max-height: calc(100vh - 33.5vh);
} */
/* .viewportContainer .farmTabsTbl .infinite-scroll-component,
.farmTabsTbl .infinite-scroll-component,
.snapshotContent .infinite-scroll-component {
  height: calc(100vh - 335px)!important;
  height: calc(100vh - 37.5vh);
  max-height: calc(100vh - 37.5vh);
} */
.viewportContainer .farmTabsTbl tr.ant-table-measure-row {
  height: 2.5vh !important;
}
.dbWidget .ant-card-body .ant-table-tbody > tr.ant-table-measure-row > td {
  height: 0 !important;
}
.dbWidget .ant-card-body .ant-table-tbody > tr > td {
  /* height: 5.7vh!important; */
  height: 5.9vh !important;
  /* padding: 0.6vh 0.83vw; */
}
.ant-table-thead > tr > th {
  height: 5.9vh !important;
  /* padding: 0.6vh 0.83vw; */
}
.viewportContainer .dFlex {
  display: flex;
  align-items: center;
}
/* .viewportContainer .searchSec .ant-input-group-addon button,
.searchSec .ant-input-group-addon button {
  height: 50px !important;
} */
.viewportContainer div#total,
div#total {
  top: 35px;
}
.lMapCont {
  height: calc(100vh - 19.7vh) !important;
  margin-bottom: 0 !important;
}
/* .analyticsTab .elTabs .ant-tabs-content{
  height: calc(100vh - 15.1vh)!important;
} */
@media (max-width: 1439px) {
  .emailAlign .ant-avatar {
    min-width: 28px !important;
    width: 28px !important;
    min-height: 28px !important;
    height: 28px !important;
    /* font-size: 12px !important; */
  }
  .empName .ant-avatar-string {
    top: 50%;
    transform: translate(-50%, -50%) !important;
  }
  .ant-select-single .ant-select-selector {
    align-items: center;
    padding-top: 0 !important;
  }
  .proPic img {
    width: 100% !important;
    height: 100% !important;
  }
  .editFarmIcon .proPic .ant-avatar.defaulticon {
    width: 175px !important;
    height: 175px !important;
  }
  .editFarmIcon .proPic .defaulticon img {
    width: 100% !important;
    height: 100% !important;
  }
  .editImgs {
    bottom: 15px;
  }
  .impAutonomyBtn button {
    bottom: 20px;
    font-size: 12px;
    padding: 6px 12px;
  }
  .mbvh20 {
    margin-bottom: 20px !important;
  }
}
@media (min-width: 1440px) and (max-width: 1920px) {
  .ant-select-single .ant-select-selector {
    align-items: center;
    padding-top: 0 !important;
  }
  .emailAlign .ant-avatar {
    width: 1.77vw !important;
    height: 1.77vw !important;
    /* font-size: 0.72vw !important; */
  }
  .empName .ant-avatar-string {
    top: 50%;
    transform: translate(-50%, -50%) !important;
  }
  /* .proPic .ant-avatar {
    width: 9.322vw !important;
    height: 9.322vw !important;
  } */
  .proPic img {
    width: 100% !important;
    height: 100% !important;
  }
  .editFarmIcon .proPic .ant-avatar.defaulticon {
    width: 15.2vw !important;
    height: 15.2vw !important;
  }
  .editFarmIcon .proPic .defaulticon img {
    width: 100% !important;
    height: 100% !important;
  }
  .editImgs {
    top: 90%;
    left: 77%;
    right: unset;
    bottom: unset;
    transform: translate(-50%, -50%);
  }
}
@media (min-width: 1921px) {
  /* .viewportContainer .searchSec .ant-input-group-addon button,
  .searchSec .ant-input-group-addon button {
    max-height: 50px !important;
  } */
  .viewportContainer .searchSec .ant-input-search,
  .searchSec .ant-input-search {
    max-height: 50px !important;
  }
  .viewportContainer .searchSec .ant-input-search input,
  .searchSec .ant-input-search input {
    min-height: 50px !important;
    max-height: 50px !important;
  }
  .ant-select-single .ant-select-selector {
    align-items: center;
    padding-top: 0 !important;
  }
  .viewportContainer
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    max-height: 50px !important;
  }
  .dropdownStyle {
    max-height: 50px !important;
  }
  .elTabs > .ant-tabs-nav {
    margin-bottom: 20px !important;
  }
  .viewportContainer .tblTopOutSec {
    margin-bottom: 40px !important;
  }
  .mbv40 {
    margin-bottom: 40px !important;
  }
  /* .elTabs .ant-tabs-content {
    margin-top: 27px !important;
  } */
  /* .elTabs .ant-tabs-content {
    height: calc(100vh - 162px) !important;
  } */
  /* .dbWidget.analyticsTab .elTabs .ant-tabs-content {
    height: calc(100vh - 135px) !important;
  } */
  .lMapCont {
    height: calc(100vh - 185px) !important;
  }
  .elTabs > .ant-tabs-nav {
    height: 111px !important;
  }
  /* .viewportContainer .farmTabsTbl .infinite-scroll-component,
  .farmTabsTbl .infinite-scroll-component,
  .snapshotContent .infinite-scroll-component {
    height: calc(100vh - 326px);
    max-height: calc(100vh - 326px);
  } */
  /* .viewportContainer .tblDft,
  .tblDft {
    max-height: calc(100vh - 313px);
  } */
  .dbWidget .ant-card-body .ant-table-tbody > tr > td {
    /* padding: 15px !important; */
    height: 62px !important;
  }
  .proPic .ant-avatar {
    width: 179px;
    height: 179px;
  }
  .proPic .ant-avatar.defaulticon {
    width: 179px !important;
    height: 179px !important;
  }
  /* .proRoleHead {
    font-size: 34px !important;
  } */
  .proRole {
    font-size: 20px !important;
  }
  .proRoleType {
    font-size: 20px !important;
  }
  .proRoleValue {
    font-size: 18px !important;
  }
  .proRoleTypeHead {
    font-size: 20px !important;
  }
  .rightSideWidegt {
    width: 486px;
    right: -496px;
  }
  .proeditBtn button {
    font-size: 14px;
  }
  /* .fleetlistBlk {
    width: 18.33vw;
    max-width: 460px;
  } */
  /* .parentAccordion .fleetOverallBlk {
    max-height: calc(100vh - 370px);
  } */
  /* .parentAccordion .fleetBlk {
    padding: 0.83vw 1.77vh;
  } */
  .fleetTractorsInrBlk {
    height: 39vh;
  }
  .lMapCont .searchOperationsBlk {
    width: 18.33vw !important;
    max-width: 460px;
  }
  .rightSideDataLayers {
    width: 17.79vw !important;
    max-width: 460px !important;
    right: -17.79vw !important;
  }
  .analyticsRightside {
    width: 18.75vw !important;
    max-width: 460px !important;
    right: -18.75vw !important;
  }
  .editFarmIcon .proPic .ant-avatar.defaulticon {
    width: 292px !important;
    height: 292px !important;
  }
  .editFarmIcon .proPic .defaulticon img {
    width: 100% !important;
    height: 100% !important;
  }
  .editImgs {
    right: 37px;
  }
  .impAutonomyBtn button {
    bottom: 40px !important;
    font-size: 14px !important;
    padding: 10px 16px !important;
  }
  .mbvh20 {
    margin-bottom: 20px !important;
  }
  .implementsWidget .proPic .ant-avatar.defaulticon {
    width: 145px !important;
    height: 145px !important;
  }
}
/* .editFarmDetails .editFarmTitle {
  font-family: Montserrat-SemiBold;
  font-size: 20px;
  letter-spacing: 0.15px;
  color: #000;
  line-height: 24px;
} */
.pt24 {
  padding-top: 24px;
}
.editFarmDetails input,
.editFarmDetails input:hover,
.editFarmDetails input:focus {
  font-family: Montserrat-Medium;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: #5c5c5c;
  line-height: 19px;
  border: 1px solid #366376 !important;
  border-radius: 10px;
  height: 44px;
}
.width448 {
  /* width: 23.33vw; */
  width: 448px;
}
.width216 {
  /* width: 11.25vw; */
  width: 216px;
}
.width80 {
  /* width: 4.16vw; */
  width: 80px;
}
.width122 {
  /* width: 6.35vw; */
  width: 122px;
}
.farmSaveBtn {
  font-family: Montserrat-SemiBold;
  font-size: 16px;
  letter-spacing: 1.43px;
  color: #366376;
  line-height: 19px;
  text-align: right;
  cursor: pointer;
  border: 0px !important;
  float: right;
}
.farmSaveBtn:hover {
  color: #67afb7;
}
.dropdowndta {
  /* border: 1px solid #366376; */
  border-top: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.dropdowndta .cropsDropdown {
  padding: 5px 19px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.15px;
  line-height: 19px;
  color: #5c5c5c;
}
.dropdowndta .cropsDropdown .anticon-plus {
  margin-right: 12px;
}
.cropsSelect {
  border: 1px solid #366376 !important;
  border-radius: 10px;
  overflow: hidden;
}
.cropsInput {
  padding-left: 19px;
  padding-right: 19px;
}
.cropsSelect input,
.cropsSelect input:hover,
.cropsSelect input:focus {
  border: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #e7e7e7 !important;
  border-radius: 0;
  padding-left: 0 !important;
}
.proPic .ant-avatar.defaulticon {
  border: 1px solid #cececf;
}
.defaultprofileimg {
  height: 55px;
  width: 68px;
  position: absolute;
  border-radius: 10px !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.defaultprofileimg img {
  border-radius: 10px !important;
}
.editprofile {
  max-width: 100%;
  border-radius: 150px;
  border: none;
  box-shadow: none;
  border-radius: 75%;
  border: 1px solid #ccc;
  object-fit: cover;
  max-width: 100%;
  position: relative;
}
.mapimage {
  width: 35px !important;
  height: 35px !important;
}
.unavalibleImg {
  /* width: 47.77vw;
  height: 53.5vh; */
  width: 100%;
  min-height: 335px;
  height: calc(100vh - 470px);
  max-height: 610px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cececf;
  border-radius: 10px;
  flex-direction: column;
}

.unavalibleImg p {
  font-size: 16px;
  color: #5c5c5c;
  letter-spacing: 1.71px;
  line-height: 19px;
  text-transform: uppercase;
  padding-top: 12px;
  font-family: Montserrat-SemiBold;
}
@media (max-height: 849px) {
  .ant-table-thead > tr > th {
    height: 50px !important;
  }
  .dbWidget .ant-card-body .ant-table-tbody > tr > td {
    height: 50px !important;
  }
}
.profilePic img {
  height: 100%;
}
.aptSuitBuildNo::placeholder {
  font: normal normal medium 16px/18px Montserrat;
  color: #959595;
}
.profileSideTab .ant-tag {
  font-size: 12px !important;
  letter-spacing: 1.29px !important;
  line-height: 15px !important;
  text-transform: uppercase !important;
  margin-top: 7px;
  padding: 6px 8px !important;
}
.mbvh24 {
  margin-bottom: 2.66vh;
}
.mbvh34 {
  margin-bottom: 3.77vh;
}
.mbvh40 {
  margin-bottom: 4.42vh !important;
}
.mb11 {
  margin-bottom: 11px;
}
.editFarmIcon .anticon-arrow-left {
  color: #366376;
  border-radius: 9px;
  box-shadow: 0 0 6px #00000029;
  padding: 8px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
.editFarmIcon .anticon-arrow-left svg {
  width: 14px;
  height: 14px;
}
.editFarmIcon h4 {
  display: flex;
  align-items: center;
}

.SSSec {
  height: calc(100vh - 230px) !important;
}

.mapMakerBtn {
  position: absolute;
  height: 32px;
  border: none;
  border-radius: 4px;
  background-color: #e16d018c;
  color: #fff;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  right: 15px;
  top: 15px;
  padding: 5px 14px;
  cursor: pointer;
  z-index: 2;
}

.autoCmp {
  font-family: Montserrat-SemiBold;
}

.autoCmp
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  margin-top: 8px;
}

.ml {
  margin-left: 50px !important;
}
.csvbtn,
.csvbtn:hover {
  color: #fff;
  background: #366376 !important;
  border-color: #366376 !important;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  display: inline-block;
  cursor: pointer;
  padding: 10px 12px;
  font-family: 'Montserrat-SemiBold';
}
.csvrow {
  align-items: center;
}

.numberField .ant-input-number {
  width: 100%;
  background-color: transparent;
}

.pwdField .ant-input-password {
  background-color: transparent;
}

.numberField input {
  height: 47px !important;
  padding: 4px 11px;
}

.empTable tr td:last-child div {
  word-break: break-word;
  white-space: inherit !important;
}

.autoHeads {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.padr30 {
  padding-right: 30px;
}

@media (min-width: 1440px) and (max-width: 1920px) {
  .editImage_sec .editImgs {
    right: 15px;
    bottom: 20px;
    transform: inherit;
    top: inherit;
    left: inherit;
  }
}
@media (max-width: 1439px) {
  .editImage_sec .editImgs {
    bottom: 10px;
  }
}

.emp-hov table tbody tr:hover .employee-avatar {
  border: 2px solid #ec9322 !important;
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
  .profileView {
    height: calc(100vh - 20vh);
  }
}

.permissionsTeamTbl tbody tr:first-child td .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  margin-left: -2px;
}

.startaConBtn .list.fleetOverallBlk {
  max-height: calc(100vh - 355px);
}
.mlockHead {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.mlockHead img {
  margin-right: 10px;
}
.mlockTxt {
  /* font-size: 15px;
  font-family: Montserrat-Medium;
  letter-spacing: 0px; */
  text-transform: uppercase;
  color: #606060;
}
.mlockHelpTxt {
  font-size: 10px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.33pt;
  margin-bottom: 40px;
  color: #474747;
}
.mlockHelpTxt2 {
  font-size: 12px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.4pt;
  margin-bottom: 15px;
  text-align: left;
  color: #474747;
}
.mlockTextBox {
  border: 1px solid #707070;
  border-radius: 11px;
  min-height: 275px;
  width: 100%;
  margin-bottom: 35px;
  padding: 20px;
  font-size: 14px !important;
}

.mLockout .ant-modal-footer {
  padding: 0 !important;
  display: flex;
  justify-content: space-evenly;
}

.mlockBtn,
.mlockBtn:focus,
.mlockBtn:hover {
  border: none;
  outline: none;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.07pt;
  color: #fff;
  width: 147px;
  height: 31px;
  border-radius: 6px;
  text-transform: uppercase;
}
.mlockBtnCancel {
  background-color: #b6b6b7 !important;
}
.mlockBtnConnect {
  background-color: #546cb2 !important;
}
button:disabled.mlockBtnConnect {
  opacity: 0.5;
  color: #fff;
}
.mlockBtnDisConnect {
  background-color: #343b5e !important;
  min-width: 167px !important;
}
.mLockContainer {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mlockHelpTxt3 {
  font-size: 12px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.4pt;
  margin-bottom: 25px;
  text-align: center;
  color: #474747;
}
.mLockSec {
  position: absolute;
  top: 10px;
  width: 100%;
}
.mLockSec img {
  width: 40px !important;
  height: 40px;
}
.ts_off_color .tractor_status_img,
.ts_available_color .tractor_status_img,
.ts_faulty_color .tractor_status_img,
.ts_service_color .tractor_status_img {
  display: flex;
  align-items: center;
}
/* .ts_off_color .tractor_status_img .ts_off,
.ts_available_color .tractor_status_img .ts_available,
.ts_faulty_color .tractor_status_img .ts_faulty
.ts_service_color .tractor_status_img .ts_service
 {
  margin-top: 30px !important;
} */

button:disabled.ppSelBtn {
  opacity: 0.5;
  color: #fff;
  cursor: default !important;
}

.ant-modal.grPopup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0px;
  top: 0;
}

.grPopup .ant-modal-content {
  margin-left: 0;
}
.baseModalContainer.grPopup .ant-modal-footer {
  border: none;
}
.unavailable-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.farm_profile_container .loader {
  height: inherit !important;
}
