.pad16 {
  padding: 16px;
}
.centerBlock {
  width: '60%' !important;
  margin: 'auto' !important;
}
.container1 {
  margin: 0 3vw;
}
.flex {
  display: flex; /* or inline-flex */
  justify-content: space-around;
}
.flex-pad {
  display: flex; /* or inline-flex */
  justify-content: space-between;
  padding: 0vh 3vw;
  margin: 3vh 0vw;
}
.flex-row {
  display: flex; /* or inline-flex */
  flex-direction: row; /* row-reverse | column | column-reverse; */
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-item {
  margin: 2vh 0;
  /* padding: 0 2vw; */
}
.flex-bg {
  background-color: #f7f7f7;
  padding: 16px;
  border-radius: 15px;
}
.ml2vw {
  margin-left: 2vw;
}
.w100 {
  width: 100% !important;
}
.mb16 {
  margin-bottom: 16px;
}
.center-text {
  text-align: center;
}
.center {
  width: 100%;
  text-align: center;
}
.mtb20 {
  margin: 20px;
}
.mlr2vw {
  margin: 0 2vw 0 2vw;
}

/* new styles 18112021 */
.searchAlert .ant-input-search {
  background-color: #fff;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  border-radius: 6px;
  min-height: 36px;
  height: 50px;
  display: flex;
  align-items: center;
  width: 351px !important;
  margin-right: 24px;
}
.searchAlert .ant-input-search .ant-input {
  border: 0;
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
  color: #959595;
  letter-spacing: 0.1px;
  outline: none;
  font-size: 16px;
  font-family: 'Montserrat-Medium';
}
.searchAlert .ant-input-search .ant-input:focus {
  outline: none;
  font-size: 16px;
  font-family: 'Montserrat-Medium';
}
.searchAlert .ant-input-search .ant-input-search-button {
  border: 0 !important;
  box-shadow: none !important;
}
.tcktsBlk .ant-tag,
.profileView .ant-tag {
  border-radius: 10px;
  /* background-color: #366376!important; */
  font-size: 14px;
  letter-spacing: 1.25px;
  font-family: 'Montserrat-SemiBold';
  line-height: 18px;
  /* color: #fff; */
  padding: 9px 8px;
  margin-bottom: 5px;
  word-break: break-word;
  white-space: normal;
}
.battConnBlk {
  box-shadow: 0px 0px 6px #00000029;
  padding: 20px 32px;
  min-height: 125px;
  justify-content: space-between;
  display: flex;
  text-align: center;
  width: 286px;
}
.battConnBlk .ant-space {
  width: 100%;
  justify-content: space-between;
}
.battConnBlk .indicatorImg {
  margin: auto;
  padding-top: 2px;
}
.battConnTxt {
  color: #959595;
  font-size: 13px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.battConnPer {
  font-size: 16px;
  letter-spacing: 0.56px;
  font-family: 'Montserrat-Medium';
}
.tractorHealthBlk {
  padding: 20px 64px;
  box-shadow: 0px 0px 6px #00000029;
}
.tractorHealthTitle {
  letter-spacing: 1.25px !important;
  line-height: 18px;
  color: #959595;
  text-transform: uppercase;
}
.tractorHealthTxt {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .tractorHealthBlk img{
  width: 100px
} */
.tractorHealthBlk .indicatorImg {
  margin: auto;
  /* margin-bottom: 20px; */
  width: 42px;
  height: 43px;
}
.tractorHealthBlk .anticon-check svg {
  color: #fff;
}
.tractorHealthBlk .anticon-close svg {
  color: #fff;
  padding: 2px;
}
.tractorHealthBlk .anticon-check {
  width: 20px;
  height: 20px;
  background: #a9bf51;
  border-radius: 50%;
  padding: 3px 4px;
}
.tractorHealthBlk .anticon-close {
  width: 20px;
  height: 20px;
  background: #d33c39;
  border-radius: 50%;
  padding: 3px;
}
.profileEdit h2 {
  font-size: 34px;
  font-family: 'Montserrat-SemiBold';
  text-align: center;
  line-height: 42px;
  letter-spacing: 0.25px;
  color: #000;
}
.mt32 {
  margin-top: 32px;
}
.profileEdit .ant-select-selector,
.profileEdit .ant-input,
.profileView .ant-select-selector,
.profileView .ant-input,
.input {
  border-radius: 10px !important;
  border: 1px solid #366376 !important;
  height: 47px;
}

.addImp .ant-input{
  border-radius: 10px !important;
  border: 1px solid #366376 !important;
  height: 45px;
}

.input .ant-input {
  border: none !important;
  height: 37px !important;
}

.ant-select-selector.errorClass,
.ant-input.errorClass,
.ant-select-selector.errorClass,
.ant-input.errorClass {
  /* border-radius: 10px!important; */
  border: 1px solid red !important;
  /* height: 47px!important; */
}

.errorClass.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.errorClass.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid red !important;
}

/* .formStyles
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 45px !important;
} */
.profileEdit .ant-select-selector .ant-select-selection-item,
.profileView .ant-select-selector .ant-select-selection-item {
  padding: 0px !important;
  font-size: 16px;
  /* color: #959595; */
  color: #5c5c5c;
  letter-spacing: 0.15px;
  font-family: 'Montserrat-Medium';
  display: flex;
  align-items: center;
}
.tcktSaveBtn button:hover {
  color: #67afb7 !important;
}
/* new styles 18112021 */

.ant-picker-input > input {
  font-size: 16px;
  font-family: 'Montserrat-Medium';
}
.mt32 {
  margin-top: 32px;
}
.mb32 {
  margin-bottom: 32px !important;
}
.tractorHealthTxt img {
  margin-right: 10px;
}
.mr20 {
  margin-right: 20px;
}
.tcktsBlk .ant-space-item {
  margin-bottom: 20px;
}
.selectTractorDiag .ant-select-selector {
  font-size: 16px;
  font-family: 'Montserrat-Medium';
}

/* rightside section styles */

.mt14 {
  margin-top: 14px;
}
.mt16 {
  margin-top: 16px;
}
.mb34 {
  margin-bottom: 34px;
}
.mb38 {
  margin-bottom: 38px !important;
}
.flexBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subFlex {
  display: flex;
}
.subFlex span.subTitles {
  width: 50%;
}
.subFlex span.ant-checkbox {
  width: 30px !important;
}
.subFlex span.ant-checkbox .ant-checkbox + span {
  padding-left: 0 !important;
}
.subHead {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.71px;
  line-height: 19px;
  margin-bottom: 10px;
}
.subdesc {
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 22px;
  font-family: 'Montserrat-Regular';
  width: 50%;
  word-break: break-word;
}
/* .subdesc img{
  width: 25px;
  height: 25px;
} */
.createTcktBtn {
  color: #366376;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  line-height: 15px;
  letter-spacing: 0.95px;
  cursor: pointer;
  text-transform: uppercase;
}
.errorcodesFlex {
  margin-bottom: 10px;
}
.errorcodesFlex span {
  width: 25%;
}
.profileOperBlk {
  background-color: #fff;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  /* width: 368px; */
  width: 19.1vw;
  height: 59px;
  padding: 20px 18px;
  font-size: 16px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.15px;
  position: relative;
}
.profileOperBlk span {
  font-family: 'Montserrat-SemiBold';
}
.profileView .attachemtBlk .ant-upload {
  border: 6px;
}
.rcIcon {
  position: absolute;
  top: 8px;
  right: 8px;
}
.eCodeIcon {
  position: absolute;
  top: -8px;
  right: 0px;
}
.addCBtn {
  margin-right: 8px;
}
.attachemtBlk .ant-upload {
  border: 1px dashed #366376 !important;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
  border-radius: 8px;
}
.tags.tag-selected:not(:hover) {
  background-color: #67afb7;
  color: #fff !important;
}
.center-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 230px);
  padding-top: 5%;
}

.center-block img {
  /* width: 35vw !important;
  height: 70vh !important; */
  max-width: 100%;
  object-fit: cover;
  height: 80%;
}

.profileEdit {
  overflow-y: auto;
  height: calc(100vh - 185px);
  padding: 0px 38px 20px 38px;
}
.dW292 {
  width: 15.2vw !important;
}
.dw384 {
  width: 20vw !important;
}

.mh35 {
  min-height: 35px !important;
}

.slkthgt .ant-select-selector {
  min-height: 35px !important;
}

.slkthgt .ant-select-selector .ant-select-selection-item {
  justify-content: space-between;
}
.wd100 {
  width: 100%;
}
.pr10 {
  padding-right: 10px;
}
.selTtxt {
  font-size: 0.94vw;
  letter-spacing: 0.56px;
  color: #000;
  /* position: absolute;
  bottom: 90px; */
  font-family: Montserrat-Regular;
}

/* new styles 24112021 */
.mb0 {
  margin-bottom: 0;
}
.mt20 {
  margin-top: 20px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.mb14 {
  margin-bottom: 14px !important;
}
.mb40 {
  margin-bottom: 40px;
}
.mt40 {
  margin-top: 40px;
}
.alignCenter {
  align-items: center;
}
.flexWrap {
  flex-wrap: wrap;
}
.profileEdit .ant-tag-checkable,
.tagsBlk .ant-tag-checkable {
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #67afb7 !important;
  color: #474747;
  padding: 8px 16px;
  margin-bottom: 10px;
  margin-right: 12px;
  /* font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  line-height: 18px;
  letter-spacing: 0.1px; */
  width: auto;
}
.profileEdit .ant-tag-checkable-checked,
.tagsBlk .ant-tag-checkable-checked,
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  background-color: #e4efea;
  border: 1px solid #e4efea !important;
  color: #474747 !important;
}
.profileView .ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
  border-color: #366376;
}
.profileView .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.profileView .ant-checkbox:hover .ant-checkbox-inner,
.profileView .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #366376;
}
.profileView .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #366376;
  border-top: 0;
  border-left: 0;
}
.attachemtBlk .ant-upload-list-picture-card {
  padding-top: 16px;
}
.attachemtBlk .ant-upload-list-picture .ant-upload-list-item,
.attachemtBlk .ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0px !important;
}
.attachemtBlk .ant-upload-list-picture-card-container {
  width: 53px;
  height: 53px;
}
.profileView .tcktCommentBox textarea {
  background-color: #e7e7e7 !important;
  border-radius: 8px !important;
  border: 0 !important;
  padding-top: 20px;
  padding-right: 32px;
}
.tcktCommentBox {
  position: relative;
  padding-bottom: 30px;
}
.tcktCommentBox .anticon-close {
  position: absolute;
  right: 20px;
  top: 15px;
  color: #b6b6b7;
}
.commentSaveBtn {
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 7px 22px;
  display: inline-block;
  float: right;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.95px;
  line-height: 15px;
  margin-top: 16px;
}
.tcktSaveBtn {
  height: auto;
}
.profileEdit
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.profileView
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  background: transparent;
}
.errorTcktTitle img {
  cursor: pointer;
  margin-bottom: -10px;
  position: absolute;
  top: 20px;
}
.attachSaveBtn {
  color: #366376;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
  cursor: pointer;
}

@media screen and (max-width: 1440px) {
  /* .rightSideWidegt {
    width: 420px;
    right: -420px;
    transition: 1s;
  } */
  .hide {
    right: -420px;
    transition: 1s;
  }
  .profileOperBlk {
    width: 100%;
  }
}
.verDtl {
  margin-bottom: 10px;
}

.verNo {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  color: #000;
  border-right: 1px solid #b6b6b7;
  padding-right: 8px;
}

.verDate {
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  color: #000;
  padding-left: 8px;
}
.verDec {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  color: #000000;
  line-height: 22px;
  letter-spacing: 0.25px;
  padding-bottom: 10px;
  width: auto;
}
.w100Per {
  width: 100% !important;
}

.verResetBtn,
.verResetBtn:hover,
.verResetBtn.ant-btn[disabled] {
  background: #366376;
  border-radius: 10px;
  min-height: 34px;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  color: #fff;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  min-width: 136px;
  justify-content: center;
  text-transform: uppercase;
}

.verResetBtn.ant-btn[disabled]:hover{
  background: #4c778a;
  color: #fff;
}

.mb12 {
  margin-bottom: 12px !important;
}
.verNoteHead {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  margin-right: 32px;
  min-width: 105px;
}
.releaseNotes {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.verDec2 {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  color: #000000;
  line-height: 22px;
  letter-spacing: 0.25px;
  padding-bottom: 10px;
}
.verTractorList {
  margin: 0px 0 0 140px;
  padding: 0px;
  list-style: none;
}
.verTractorList .ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
  position: relative;
  top: 2px;
}
.verTractorList .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}
.verBtnSec {
  position: relative;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
}

.verResetBtn,
.verResetBtn:hover,
.verResetBtn:focus {
  background: #366376;
  border-radius: 10px;
  min-height: 34px;
  min-width: 136px;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
  outline: none;
  border: none;
  text-transform: uppercase;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 29%);
}
.verCancelBtn,
.verCancelBtn:hover,
.verCancelBtn:focus {
  background: #fff;
  border-radius: 10px;
  min-height: 34px;
  min-width: 136px;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
  outline: none;
  border: none;
  margin-right: 32px;
  color: #366376;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.29);
  text-transform: uppercase;
}
.functionalityTag .ant-tag {
  border-radius: 10px;
  background-color: #366376 !important;
  font-size: 14px;
  letter-spacing: 1.25px;
  font-family: 'Montserrat-SemiBold';
  line-height: 18px;
  color: #fff;
  padding: 10px 12px;
  margin-bottom: 5px;
  margin-right: 12px;
  text-transform: uppercase;
  display: inline-block;
}
.counterMeasureTxt ul li {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.25px;
  line-height: 24px;
}
.counterMeasureTxt ul {
  padding-left: 22px;
  list-style: none;
}
.counterMeasureTxt ul li::before {
  content: '\2022';
  color: #366376;
  font-weight: bold;
  display: inline-block;
  width: 15px;
  margin-left: -1em;
  font-size: 26px;
}
.counterMeasureTxt ul li:nth-child(2) {
  padding-left: 25px;
}
.ant-checkbox-checked::after {
  border: 0 !important;
}

@media screen and (max-width: 1400px) {
  .tractorHealthGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
    gap: 20px 5px;
  }
  .tractorHealthBlk {
    padding: 24px 32px;
  }
}

.ant-notification-notice {
  border-bottom: 2px solid #366376;
  min-width: 448px !important;
  padding: 24px 30px !important;
}
.ant-notification-notice-close {
  top: 10px !important;
  left: 10px !important;
  right: unset !important;
  color: #366376;
}
.notificationDate {
  /* font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.71px;
  line-height: 19px; */
  color: #959595;
  text-transform: uppercase;
  margin-bottom: 0;
}
.notificationImg {
  width: 65px;
  height: 65px;
}
.notificationInrBlk {
  margin-left: 2.29vw;
  width: 80%;
}
.notificationMsg {
  /* font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 22px; */
  color: #000;
  margin-bottom: 0;
}
.notificationMsg span {
  font-family: 'Montserrat-SemiBold';
}
.notificationBlk h6 {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  line-height: 13px;
  letter-spacing: 0.89px;
  color: #959595;
  margin-top: 10px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.notifyBadge {
  background-color: #fff;
  box-shadow: 0px 0px 6px #00000029;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 35px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.notifiySeemoreBtn {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  color: #366376;
  line-height: 15px;
  letter-spacing: 0.95px;
  text-transform: uppercase;
  text-align: right;
  cursor: pointer;
}
.flexAlignStart {
  align-items: flex-start;
  flex-direction: initial;
}
.ml20 {
  margin-left: 20px;
}
.notifyBadge .flagIcon {
  width: 10px;
  height: 10px;
}
.attachemtBlk img,
.attachemtBlk .ant-upload-list-picture-card .ant-upload-list-item {
  border-radius: 8px;
}

.btSecTxt {
  position: relative;
  top: 15px;
}
.btSecTxt img {
  margin-left: 20px !important;
}
.tagsName {
  background: #366376;
  color: #fff;
  text-transform: uppercase;
}

.afSection {
  width: calc(100% - 45px);
  display: inline-block;
}
.errorCodesBlk .errorCodeIcon {
  float: right;
  cursor: pointer;
}
.errorCodesBlk table tr td {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.25px;
  line-height: 22px;
  background-color: #f7f7f7;
  color: #000;
}
.errorCodesBlk img {
  border-radius: 0;
  margin-right: 4px;
}
.profileEdit textarea {
  font-size: 16px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.15px;
  line-height: 19px;
  color: #5c5c5c;
}
.profileView .ant-input:focus,
.profileView .ant-input-focused,
.profileEdit .ant-input:focus,
.profileEdit .ant-input-focused {
  box-shadow: none;
}

.description {
  word-break: break-word;
  white-space: pre-wrap;
}
.pointer {
  cursor: pointer;
  text-align: -webkit-center;
}

.pointerEvents {
  height: 64px;
  width: 92px;
  display: flex;
  background-color: #565353;
  border-radius: 10px;
  margin-bottom: 10px;
}

/* .pointerEvents1 {
  height: 64px;
  width: 92px;
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid black;
} */

.pointerEventsDefault {
  height: 64px;
  width: 92px;
  display: flex;
  margin-bottom: 10px;
}

.selectTractorDiag1 span.ant-select-selection-item {
  margin-top: 32px;
}

.flxend {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  margin-bottom: 4vh !important;
}

.userManualCont .ant-modal-content {
  width: 740px !important;
  height: 882px;
  border-radius: 14px;
  position: relative;
  top: 52px;
  overflow: hidden;
  font-family: Montserrat-SemiBold;
}

.userManualCont .ant-modal-body {
  overflow: auto;
  height: 825px;
  text-align: -webkit-center;
}

.cursor {
  cursor: pointer;
}
.tckComt:after {
  width: 60px !important;
  text-align: right !important;
}
.mt5 {
  margin-top: 5px;
}
button#saveDiagnostic:disabled {
  color: gray;
  cursor: not-allowed;
}
