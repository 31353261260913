.zoomIn {
  background: transparent;
  width: 39px;
  height: 39px;
  position: relative;
  left: 9px;
  border: none;
  top: 8px;
  cursor: pointer;
  padding: 0;
}
.zoomOut {
  background: transparent;
  width: 39px;
  height: 37px;
  position: relative;
  left: 9px;
  border: none;
  top: 4px;
  cursor: pointer;
  padding: 0;
}
.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 150px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: #cccccc;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: '✖';
}

/* new styles in livemap component */
.searchOperationsBlk {
  position: absolute;
  left: 32px;
  top: 48px;
  z-index: 4;
  width: 18.5vw;
}

.searchMapBlk .anticon-search {
  position: absolute;
  left: 10px;
  z-index: 1;
  top: 40%;
  color: #939393;
  width: 12px;
  height: 12px;
}
.searchOperationsBlk .ant-input {
  min-width: 320px;
  width: 100%;
  height: 50px;
  background-color: #f7f7f7 !important;
  border-radius: 10px !important;
  padding-left: 45px;
}
.searchOperationsBlk .ant-input::placeholder {
  color: #959595;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  line-height: 18px;
  letter-spacing: 0.1px;
}
.operationsBlk {
  width: 100%;
}
.parentAccordion {
  background-color: #E7E7E7;
  border-radius: 10px;
}
.parentAccordion .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid #E7E7E7 !important;
}

.childAccordion .ant-collapse-header {
  font-size: 16px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.15px;
  color: #5c5c5c !important;
  line-height: 19px !important;
  padding: 16px !important;
}
.childAccordion2 .ant-collapse-header {
  color: #000000 !important;
  line-height: 15px !important;
  padding: 18px 16px !important;
}
.parentAccordion .ant-collapse-header .ant-collapse-extra {
  padding-right: 10px;
}
.parentAccordion .ant-collapse-item {
  border-bottom: 0 !important;
}
.parentAccordion .ant-collapse-content-box {
  background-color: #E7E7E7;
  border-radius: 10px;
  padding: 0;
  padding-bottom: 1px;
}
.parentAccordion .childAccordion .ant-collapse-content-box {
  padding: 0px 14px 8px 14px;
}
.parentAccordion .childAccordion {
  background: transparent !important;
  border: 0;
  border-bottom: 1px solid #366376;
  padding-bottom: 7px;
}
/* .parentAccordion .childAccordion2 {
  padding-top: 30px;
  padding-bottom: 0;
  border-bottom: 0;
} */
.parentAccordion .ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 10px;
}
.parentAccordion .childAccordion .ant-collapse-item {
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px !important;
  margin-bottom: 13px;
  border: 0 !important;
  margin-left: 32px;
  margin-right: 32px;
}
.parentAccordion .ant-collapse-content {
  border-top: 0;
  border-radius: 10px;
}
.childAccordion .ant-collapse-content-box {
  background-color: #fff;
}
.selectTractorOpt {
  margin-left: 20px !important;
  margin-right: 40px !important;
}
.childAccordion p {
  font-size: 12px;
  font-family: Montserrat-Medium;
  line-height: 15px;
  letter-spacing: 0.4px;
  color: #000;
}
.operationTitle {
  font-family: Montserrat-SemiBold;
  font-weight: bold;
}
.moreBtn {
  font-family: Montserrat-SemiBold;
  font-size: 14px;
  letter-spacing: 1.25px;
  line-height: 18px;
  color: #366376;
  text-align: center;
  margin: 0;
}
.childAccordion2 {
  min-height: 120px;
  /* min-height: auto; */
  max-height: calc(100vh - 380px);
  overflow-y: auto;
}
/* Scrollbar width */
.childAccordion2::-webkit-scrollbar {
  width: 5px;
}
/* Scrollbar Track */
.childAccordion2::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Scrollbar Handle */
.childAccordion2::-webkit-scrollbar-thumb {
  background: #959595;
}

/* Scrollbar Handle on hover */
.childAccordion2::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* new styles in livemap component end */

.rightSideDataLayers {
  position: fixed;
  top: 0px;
  right: -380px;
  width: 380px;
  min-width: 380px;
  background: #fff;
  height: 100vh;
  z-index: 99;
  box-shadow: -1px 0px 6px rgba(0, 0, 0, 0.29);
  transition: 1s;
}
.expand {
  right: 0px !important;
  box-shadow: -2px 0px 6px rgba(0, 0, 0, 0.29);
}

.expand1 {
  right: 0px !important;
}

.dataLayerWrapper {
  padding: 56px 38px 56px 38px;
}
/* .dataIcon {
  margin-left: 15px;
} */

.dataLayerRow {
  display: flex;
  align-items: center;
}
.mb28 {
  margin-bottom: 28px;
}
.checkBoxItem {
  border-bottom: 1px solid #CECECF;
  padding: 0px 12px 10px 0px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /* margin-left: 30px; */
}
.dataLayerInnerRow .checkBoxItem {
  border: none;
}
.checkBoxItem span.layerName {
  width: 100%;
  display: inline-block;
  font-size: 14px;
  font-family: Montserrat-Medium;
  color: #000;
  letter-spacing: 1.25px;
}
.checkBoxItem .ant-checkbox-checked .ant-checkbox-inner,
.checkBoxItem .ant-checkbox-input,
.checkBoxItem .ant-checkbox-inner {
  background-color: #fff;
  border-color: #366376;
  width: 22px;
  height: 22px;
  border-radius: 5px;
}
.checkBoxItem .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #1e3a46;
}
.ant-checkbox-inner::after {
  left: 30%;
}
.dataLayerInnerRow {
  display: flex;
  align-items: center;
}
.checkBoxItemInner {
  border-bottom: 1px solid #b6b6b7;
  padding: 0px 12px 12px 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 50px;
}
.ml50 {
  margin-left: 8.5px;
}
.dataLayerBtn {
  width: 183px;
  height: 51px;
  position: absolute;
  top: 95px;
  right: -100px;
  /* background: #e4efea; */
  background: #ffffff;
  border-radius: 10px 0 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  transition: 0.5s;
  animation-duration: 1.5s;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;
}
.dataLayerBtn:hover {
  display: flex-block;
  width: 230px;
  right: 0px;
}

.dLTxt,
.dataArrow {
  display: none;
}
.dataLayerBtn:hover .dLTxt,
.dataLayerBtn:hover .dataArrow {
  display: flex;
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  margin-top: 2px;
}
.dLIcon {
  margin-right: 5px;
}
.dataArrow {
  font-size: 12px;
}
.dLTxt {
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  line-height: 15px;
  text-transform: uppercase;
  margin-right: 20px;
  letter-spacing: 1.25px;
  color: #366376;
  white-space:nowrap;
}

.mapView {
  position: 'absolute';
  right: 0;
  top: 0;
  z-index: 1;
  cursor: pointer;
}
.zoom_in_out {
  position: relative;
  bottom: -2px;
  left: 6px;
}
.operation-record {
  border-bottom: 1px solid #a3d5d8;
  border-radius: 10px;
  padding: 7px;
  cursor: pointer;
  margin-bottom: 5px;
  background: #f3f9f5;
}
.mapRecenter {
  margin-left: 6px;
}
.beforeCollapseAnaMap .mapRecenter {
  position: relative;
  top: 10px;
}

@media screen and (max-width: 1440px) {
  .childAccordion .ant-collapse-header {
    font-size: 14px;
  }
  .childAccordion2 {
    /* min-height: 120px; */
    min-height: auto;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    position: relative;
    z-index: 0;
  }
  .operationsTab .childAccordion2 {
    max-height: calc(100vh - 535px);
  }
}
@media screen and (max-width: 1800px) {
  .childAccordion2 {
    max-height: calc(100vh - 300px);
}
}

/* .operationsTab{
  border-top: 1px solid #A3D5D8;
} */
.operationsTab .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 2px !important;
}
.operationsTab .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: flex-start !important;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 20px;
  border-bottom: 1px solid #a3d5d8;
}
.operationsTab .ant-tabs-tab,
.operationsTab .ant-tabs-tab:hover {
  padding: 9px 32px 6px 32px;
  background-color: #e7e7e7 !important;
  border-radius: 10px 10px 0 0 !important;
  min-height: 34px;
  color: #000 !important;
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
  line-height: 18px;
}
.operationsTab .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #fff !important;
}
.operationsTab .ant-tabs-ink-bar {
  background: transparent;
}
.operationsTab .ant-tabs-nav {
  height: auto !important;
}
.operationsTab .ant-tabs-content {
  height: auto !important;
}
.operationsTab .childAccordion .ant-collapse-item {
  margin-left: 0;
  margin-right: 0;
}

.dataLayerSec {
  overflow-y: auto;
  height: calc(100vh - 90px);
}

/* Scrollbar width */
.dataLayerSec::-webkit-scrollbar {
  width: 5px;
}
/* Scrollbar Track */
.dataLayerSec::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Scrollbar Handle */
.dataLayerSec::-webkit-scrollbar-thumb {
  background: #959595;
}

/* Scrollbar Handle on hover */
.dataLayerSec::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.fullsearchOperationsBlk {
  left: 130px;
}





.mpClose .dataLayerSec {
  display: none;
}
.mpLayerHead {
  display: flex;
  align-items: center;
}

/* @media (min-width: 768px) and (max-width: 1440px) {
  .mapLayerIcon, .mapLayerIcon:hover {
    background-size: 60px 60px;
    position: absolute;
    bottom: 15px;
    left: 28px;
    z-index: 5;
    width: 60px;
    height: 60px;
    cursor: pointer;
  } */
  /* .bottomMapLayers.mpOpen {
    height: calc(100vh - 90px);
  } */
  /* .bottomMapLayers .dataLayerSec {
    height: calc(100vh - 200px);
  }
} */

@media (min-width: 1441px) and (max-width: 4320px) {
  /* .mapLayerIcon, .mapLayerIcon:hover {
    background-size: 55px 55px;
    position: absolute;
    bottom: 24px;
    left: 32px;
    z-index: 5;
    width: 55px;
    height: 55px;
    cursor: pointer;
  } */
  .mpOpen {
    width: 360px;
    max-width: 460px;
}
}


.mpClose .dataLayerRowHead {
  display: none;
}

.dataArrow {
  background-image: url(../../assets/images/mpl_lArrow.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 5px;
  height: 9px;
}



.adConnectBtnSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.adConnectBtnSec .btnBlock {
  border: none;
  box-shadow: none;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.btnBlock img {
  width: 55px;
  height: 55px;
  margin-right: 5px;
}
.btnBlock span {
  line-height: 16px;
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  margin-top: -5px;
}
/* .adConnectBtnSec .btnBlock:hover span {
  display: block;
} */

.discBtnOne {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

@media screen and (min-width: 1801px) and (max-width: 4320px) {
/* .dmenHandle .fleetTabs .ant-tabs-content-holder {
  max-height: calc(100vh - 475px) !important;
} */
/* .dmenHandle .childAccordion2 {
  max-height: calc(100vh - 475px) !important;
} */
}

.mplChkBox .ant-checkbox-inner::after {
  width: 4.714286px;
  height: 7.142857px;
  border-radius: 0 1px 2px 0px;
}
  .mplChkBox .ant-checkbox-inner::after {
    left: 25%;
}
.mplChkBox .checkBoxItem .ant-checkbox-checked .ant-checkbox-inner, 
.mplChkBox .checkBoxItem .ant-checkbox-input, 
.mplChkBox .checkBoxItem .ant-checkbox-inner {
    width: 15px;
    height: 15px;
    border-radius: 3px;
}
.lChkBox {
  height:15px !important;
}

.mplChkBox .checkBoxItem .ant-checkbox-checked .ant-checkbox-inner, 
.mplChkBox .checkBoxItem .ant-checkbox-input, 
.mplChkBox .checkBoxItem .ant-checkbox-inner {
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.mplChkBox .checkBoxItem span.layerName {
  line-height: 15px;
  text-transform: uppercase;
}
.mplChkBox .ant-checkbox-wrapper {
  height: 15px;
}
.mplChkBox .ant-checkbox {
  top: 0;
}
.mplChkBox .ant-checkbox-inner {
  border: 1px solid #366376 !important;
}
.mplChkBox .checkBoxItem span.layerName {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
}
.mplChkBox .checkBoxItem {
  margin-bottom: 15px;
  padding-bottom: 8px;
}
.dataLayerInnerRow .layerName {
  font-size: 12px;
  font-family: 'Montserrat-Medium' !important;
  text-transform: capitalize !important;
}
.mb7 {
  margin-bottom: 7px;
}

.mplStatus {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 10px;
  width: 100%;
  margin-bottom: 12px;
}
.mplStatus2 {
  display: grid;
  grid-template-columns: auto;
  gap: 10px;
  width: 100%;
  margin-bottom: 12px;
}
.mplItemlbl {
  font-size: 11px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.55px;
}
.mr15 {
  margin-right: 15px;
}
.dblock {
  display: block;
}
.mplMl12 {
  margin-left: -12px;
  margin-right: 6px;
}
.pdr0 {
  padding-right: 0px;
}
.mplChkBox .fleet-minus {
  margin-left: -8px;
  position: relative;
  top: -2px;
  left: -1px;
  margin-top:2px;
}
.mplChkBox .fleet-minus svg {
  width: 8px;
  position: relative;
  left: -2px;
}