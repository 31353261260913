.snapContainer {
  display: inline-flex;
  flex-wrap: wrap;
}

.videoSnaps {
  width: 24%;
  max-height: 200px;
  margin: 0 1% 1% 0;
  box-shadow: 4px 7px 15px -10px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  border: 1px solid #ccccccbd;
  position: relative;
}

.videoSnaps video {
  border-radius: 4px;
}

.snapText {
  position: absolute;
  min-height: 35px;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  width: 100%;
  padding-left: 10px;
  padding-top: 8px;
  font-family: 'Montserrat-SemiBold';
  border-bottom: 1px solid #ccc;
  z-index: 10;
}

.barDisplay {
  width: 100% !important;
  display: flex;
}

.progress {
  flex: 1;
  position: relative;
  display: flex;
  flex-basis: 100%;
  height: 20px;
  background: #38647466;
  cursor: pointer;
  margin: 4px 14px;
  border-radius: 10px;
}

.progress__filled {
  width: 100%;
  background: #386474;
  flex: 0;
  flex-basis: 0%;
  border-radius: 10px;
}

.fullScreenVideo {
  position: absolute;
  top: 11px;
  font-size: 14px;
  right: 16px;
  z-index: 10;
}

.libraryContainer .fc .fc-toolbar.fc-header-toolbar {
  min-height: 62px;
  padding: 0px 15px;
  margin-bottom: inherit;
}

.recordedVideoTab .fc .fc-view-harness {
  height: calc(100vh - 300px) !important;
}

.videoDbCon .ant-card-body {
  display: inline-block;
  width: 100%;
  /* padding: 20px 20px 20px 20px !important; */
  padding: 0px;
}

.videoDbCon .ant-card-head {
  height: 52px;
}

.videosCon .fc-scroller-liquid-absolute{
  right: -4px !important;
  left: -1px !important;
}

.recordVediosprogress {
  flex: 1;
  position: relative;
  display: flex;
  flex-basis: 100%;
  height: 20px;
  background: #38647466;
  cursor: pointer;
  margin: 4px 14px;
  border-radius: 10px;
}

.recordVediosprogress__filled {
  width: 100%;
  background: #386474;
  flex: 0;
  flex-basis: 0%;
  border-radius: 10px;
}
.day-view-height .fc .fc-view-harness{
  height: calc(100vh - 320px) !important;
}
@media screen and (min-width: 1600px) and (max-width:1920px) {
  .day-view-height .fc .fc-view-harness{
    height: calc(100vh - 340px) !important;
  }
}


@media screen and (min-width: 768px) and (max-width:990px) {
  .day-view-height .fc .fc-view-harness{
    height: calc(100vh - 345px) !important;
  }
}