.liveZoomIn {
  background: transparent;
  width: 40px;
  height: 40px;
  position: relative;
  left: 9px;
  border: none;
  top: 8px;
  cursor: pointer;
  padding: 0;
}

.liveZoomOut {
  background: transparent;
  width: 40px;
  height: 37px;
  position: relative;
  left: 8px;
  border: none;
  top: 4px;
  cursor: pointer;
  padding: 0;
}



/* .autodriveButton img {
  width: 25px !important;
} */

.routeIcon {
  padding: 0px 5px 0px 5px;
}

.routeIcon2 {
  padding: 0px 5px 0px 5px;
  display: none;
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  border: 1px solid grey;
  padding: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeIcon:hover {
  cursor: pointer;
}

.autodriveClose {
  display: flex;
  justify-content: flex-end;
  /* padding: 26px 26px 0px 0px; */
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1;
}

.alignCenter {
  display: flex;
  justify-content: center;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 150px;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: #cccccc;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: '✖';
}

/* new styles in livemap component */

.searchOperationsBlk {
  position: absolute;
  left: 32px;
  top: 48px;
  z-index: 4;
  width: 18.5vw;
}
/* 
.searchMapBlk {
  position: relative;
  margin-bottom: 19px;
} */

.searchMapBlk .anticon-search {
  position: absolute;
  left: 10px;
  z-index: 1;
  top: 40%;
  color: #fff;
  width: 12px;
  height: 12px;
}

.searchOperationsBlk .ant-input {
  min-width: 320px;
  width: 100%;
  height: 50px;
  background-color: #f7f7f7 !important;
  border-radius: 10px !important;
  padding-left: 45px;
}

.searchOperationsBlk .ant-input::placeholder {
  color: #959595;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  line-height: 18px;
  letter-spacing: 0.1px;
}

.operationsBlk {
  width: 100%;
}

.parentAccordion {
  background-color: #e4efea;
  border-radius: 10px;
}

.autoDriveHeader {
  background-color: #e4efea;
  border-radius: 10px;
}

.noBorderRadius {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.list {
  margin-top: 20px;
  padding-top: 30px;
  padding-bottom: 0;
  border-bottom: 0;
  max-height: 350px;
  overflow-y: scroll;
}

/* .operationsTitle .ant-collapse-header {
  justify-content: flex-end;
  display: flex;
  flex-direction: row-reverse;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  line-height: 17px !important;
  letter-spacing: 1.25px;
  color: #333333 !important;
  padding: 16px !important;
  height: 50px;
} */

.childAccordion .ant-collapse-header {
  font-size: 16px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.15px;
  color: #5c5c5c !important;
  line-height: 19px !important;
  padding: 16px !important;
}

.childAccordion2 .ant-collapse-header {
  color: #000000 !important;
  line-height: 15px !important;
  padding: 18px 16px !important;
}

.parentAccordion .ant-collapse-header .ant-collapse-extra {
  padding-right: 10px;
}

.parentAccordion .ant-collapse-item {
  border-bottom: 0 !important;
}

.parentAccordion .ant-collapse-content-box {
  background-color: #e7e7e7;
  border-radius: 10px;
  padding: 0;
  padding-bottom: 1px;
}

.parentAccordion .childAccordion .ant-collapse-content-box {
  padding: 0px 14px 8px 14px;
}

.parentAccordion .childAccordion {
  background: transparent !important;
  border: 0;
  border-bottom: 1px solid #366376;
  padding-bottom: 7px;
}

/* .parentAccordion .childAccordion2 {
  padding-top: 30px;
  padding-bottom: 0;
  border-bottom: 0;
} */

.parentAccordion .ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 10px;
}

.parentAccordion .childAccordion .ant-collapse-item {
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px !important;
  margin-bottom: 13px;
  border: 0 !important;
  margin-left: 12px;
  margin-right: 18px;
}

.parentAccordion .ant-collapse-content {
  border-top: 0;
  border-radius: 10px;
}

.childAccordion .ant-collapse-content-box {
  background-color: #fff;
}

.selectTractorOpt {
  margin-left: 20px !important;
  margin-right: 40px !important;
}

.childAccordion p {
  font-size: 12px;
  font-family: Montserrat-Medium;
  line-height: 15px;
  letter-spacing: 0.4px;
  color: #000;
}

.operationTitle {
  font-family: Montserrat-SemiBold;
  font-weight: bold;
}

.moreBtn {
  font-family: Montserrat-SemiBold;
  font-size: 14px;
  letter-spacing: 1.25px;
  line-height: 18px;
  color: #366376;
  text-align: center;
  margin: 0;
}

/* new styles in livemap component end */

.rightSideDataLayers {
  position: fixed;
  top: 0px;
  right: -380px;
  width: 380px;
  min-width: 380px;
  background: #fff;
  height: 100vh;
  z-index: 99;
  box-shadow: -1px 0px 6px rgba(0, 0, 0, 0.29);
  transition: 1s;
}

/* .expand{
  right: 0px !important;
} */

.expand1 {
  right: 0px !important;
}

.dataLayerWrapper {
  padding: 56px 38px 56px 38px;
}

/* .dataIcon {
  margin-left: 15px;
} */

/* .dataHead {
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  margin-left: 10px;
} */

.dataLayerRow {
  display: flex;
  align-items: center;
}

.mb28 {
  margin-bottom: 28px;
}

.checkBoxItem {
  border-bottom: 1px solid #b6b6b7;
  padding: 0px 12px 12px 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /* margin-left: 30px; */
}

.checkBoxItem span.layerName {
  width: 100%;
  display: inline-block;
  font-size: 14px;
  font-family: Montserrat-Medium;
  color: #000;
  letter-spacing: 1.25px;
}

.checkBoxItem .ant-checkbox-checked .ant-checkbox-inner,
.checkBoxItem .ant-checkbox-input,
.checkBoxItem .ant-checkbox-inner {
  background-color: #fff;
  border-color: #366376;
  width: 22px;
  height: 22px;
  border-radius: 5px;
}

.checkBoxItem .ant-checkbox-checked .ant-checkbox-inner::after,
.ant-checkbox-inner {
  border-color: #1e3a46;
}

.ant-checkbox-inner::after {
  left: 30%;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  outline: none !important;
  border-color: #1e3a46 !important;
  box-shadow: none !important;
}

.dataLayerInnerRow {
  display: flex;
  align-items: center;
}

.checkBoxItemInner {
  border-bottom: 1px solid #b6b6b7;
  padding: 0px 12px 12px 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 50px;
}

.ml50 {
  margin-left: 50px;
}

.dataLayerBtn {
  width: 183px;
  height: 51px;
  position: absolute;
  top: 95px;
  right: -104px;
  background: #e4efea;
  border-radius: 10px 0 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  transition: 0.5s;
  animation-duration: 1.5s;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;
}

.dataLayerBtn:hover {
  display: flex-block;
  width: 183px;
  right: 0px;
}

.dLTxt,
.dataArrow {
  display: none;
}

.dataLayerBtn:hover .dLTxt,
.dataLayerBtn:hover .dataArrow {
  display: flex;
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
}

.dLIcon {
  margin-right: 5px;
}

.dataArrow {
  font-size: 12px;
}

.dLTxt {
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  line-height: 15px;
  text-transform: uppercase;
  margin-right: 20px;
  white-space: nowrap;
}

.mapView {
  position: 'absolute';
  right: 0;
  top: 0;
  z-index: 1;
  cursor: pointer;
  margin-left: 8px;
}
.mapView img {
  width: 53px;
}
.zoom_in_out {
  position: relative;
  bottom: 2px;
}

/* new styles in livemap component */

/* 
.searchMapBlk {
  position: relative;
} */

.searchMapBlk .anticon-search {
  position: absolute;
  left: 10px;
  z-index: 1;
  top: 19px;
  color: #939393;
  width: 12px;
  height: 12px;
}

.fleetlistBlk .ant-input {
  width: 100%;
  height: 50px;
  background-color: #f7f7f7 !important;
  border-radius: 10px !important;
  padding-left: 45px;
  /* backdrop-filter: blur(1px) brightness(54%) grayscale(0%);
  -webkit-backdrop-filter: blur(1px) brightness(54%) grayscale(0%);
  background-color: rgba(247, 247, 247, 1); 
  border: none !important; */
}

.fleetlistBlk .ant-input::placeholder {
  color: #959595;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  line-height: 18px;
  letter-spacing: 0.1px;
}

.bottomButton {
  display: flex;
  justify-content: center;
  background: #e7e7e7;
  align-items: center;
  padding: 5px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 12px;
}

.parentAccordion {
  background-color: #e4efea;
  border-radius: 10px;
}

/* .operationsTitle .ant-collapse-header {
  justify-content: flex-end;
  display: flex;
  flex-direction: row-reverse;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  line-height: 18px !important;
  letter-spacing: 1.25px;
  color: #333333 !important;
  padding: 16px !important;
} */

.parentAccordion .ant-collapse-header .ant-collapse-extra {
  padding-right: 10px;
}

.parentAccordion .ant-collapse-item {
  border-bottom: 0 !important;
}

.parentAccordion .ant-collapse-content-box {
  background-color: #e7e7e7;
  border-radius: 10px;
  padding: 0;
  padding-bottom: 1px;
}

.parentAccordion .ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 10px;
}



.fleetBlk:first-child {
  margin-top: 0px;
}

.parentAccordion .ant-collapse-content {
  border-top: 0;
  border-radius: 10px;
}

/* .fleetTabs .ant-tabs-nav {
  padding: 0 16px;
  margin-bottom: 0;
} */

.fleetTabs .ant-tabs-nav-list {
  overflow-x: auto;
}

.fleetTabs .ant-tabs-nav-operations {
  display: none !important;
}

/* .fleetTabs .tabTractorImg{
    width: 25px;
    height: 25px
  } */

/* .fleetTabs .tabLockImg{
    width: 16px;
    height: 23px;
  } */

.ant-tabs-dropdown-menu-item img {
  width: 35px;
  height: 35px;
}

.ant-tabs-dropdown-menu-item:nth-child(2) img {
  width: 20px;
  height: 23px;
}

.fleetTabs .ant-tabs-ink-bar {
  border-bottom: 2px solid #e7e7e7;
}

/* .fleetTabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 6px;
} */



.fleetTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
  border: 1px solid #cececf;
  border-radius: 5px;
  background: #b6b6b7;
  /* padding: 10px 8px; */
  min-width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 3px 3px 3px #00000020;
}

/* .fleetBlk .tractorImg{
    width: 35px;
    height: 30px;
  } */

/* .fleetBlk .connectivityImg{
    width: 27px;
    height: 27px;
  } */

.fleetBlk .batteryImg {
  width: 34px;
  height: 23px;
}

.flexStart {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0;
  gap: 5px;
}

.flexBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.flexAround {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.fleetMsgTxt1 {
  width: 75px;
  height: 19px;
}

.fleetMsgTxt {
  padding: 0 10px;
}

.availableTxt {
  background-color: #ec9322;
  border-radius: 9px;
}

.inUseTxt {
  background-color: #67afb7;
  border-radius: 9px;
}

.offlineTxt {
  background-color: #dddddd;
  border-radius: 9px;
}

.offlineText {
  background-color: #929292;
  border-radius: 9px;
}

.chargingTxt {
  background-color: #8a9a48;
  border-radius: 9px;
}

.faultyTxt {
  background-color: #d33c39;
  border-radius: 9px;
}

.batteryPercentageTxt {
  font-family: Montserrat-Medium;
  font-size: 15px;
  color: #4d4d4d;
  margin-left: 3px;
  width: 45px;
  display: inline-block;
  text-align: right;
}

/* Scrollbar width */

.childAccordion2::-webkit-scrollbar {
  width: 5px;
}

/* Scrollbar Track */

.childAccordion2::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Scrollbar Handle */

.childAccordion2::-webkit-scrollbar-thumb {
  background: #959595;
}

/* Scrollbar Handle on hover */

.childAccordion2::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.subTitle2 {
  font-family: Montserrat-SemiBold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #000;
}

/* fleet item popup */

.editTabs .ant-tabs-nav {
  /* margin-top: 20px; */
  margin-bottom: 0;
}

.editTabs .ant-tabs-content {
  margin-top: 0;
}

.editTabs .ant-tabs-tab {
  border-radius: 10px 10px 0 0 !important;
  background-color: #e7e7e7 !important;
}

.editTabs .ant-tabs-tab.ant-tabs-tab-active {
  background: #fff !important;
}

.editTabs .ant-tabs-tab-btn {
  color: #000 !important;
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
  line-height: 18px;
}

.editTabs .ant-tabs-nav-add {
  background-color: #e7e7e7 !important;
  border-radius: 20px !important;
  min-width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  margin-top: 8px !important;
  margin-left: 5px !important;
}

.editTabs .anticon-plus {
  color: #366376;
}

.fleetPopupBlk {
  background-color: #fff;
  border-radius: 10px;
  margin-top: 0 !important;
  border-top-left-radius: 0;
}

.closeBtn {
  display: flex;
  justify-content: end;
  height: 27px;
  padding-right: 13px;
  /* padding-top: 13px; */
  padding-top: 1.62vh;
  cursor: pointer;
  color: #366376;
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}

.fleetItemHeader {
  width: 100%;
  /* padding: 3px 24px 24px 16px; */
  padding: 0.37vh 1.691vw 2.992vh 1.12vw;
  /* box-shadow: 1px 5px 6px #00000029; */
}

.fleetItemHeadBtm {
  /* padding-top: 28px; */
  padding-top: 3.1vh;
}

.fleetItemHeader img {
  width: 30px;
  height: 30px;
}

.fleetItemIcon {
  width: 40px;
  height: 40px;
}

.fleetItemTitle {
  font-family: Montserrat-SemiBold;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

.captionTxt {
  font-size: 12px;
  font-family: Montserrat-Medium;
  line-height: 15px;
  letter-spacing: 0.4px;
  margin: 0;
}

.fleetItemDuration {
  font-size: 12px;
  font-family: Montserrat-Medium;
  line-height: 15px;
  letter-spacing: 0px;
  color: #070707;
}

.batteryPercentage {
  color: #4d4d4d;
  font-size: 12px;
  font-family: Montserrat-Medium;
}

.fleetItemBody {
  background-color: #67afb727;
  /* padding: 22px 44px 52px 34px; */
  padding: 2.74vh 3.1vw 6.48vh 2.39vw;
  box-shadow: inset 1px 5px 6px #00000029;
}

.gearBox {
  display: flex;
  align-items: center;
  justify-items: flex-start;
  gap: 12px;
  width: 100%;
}

.levelGear {
  /* text-align: center; */
  width: 25%;
}

.levelGear h4 {
  font-family: Montserrat-Medium;
  font-size: 24px;
  line-height: 15px;
  letter-spacing: -2px;
  margin-bottom: 5px;
}

.levelGear h4 span {
  font-size: 34px;
}

.levelGear span {
  font-size: 12px;
  font-family: Montserrat-Regular;
  line-height: 15px;
}

.speedometerOuter {
  background-color: #a3d5d8;
  width: 120px;
  height: 120px;
  border: 1px solid #a3d5d8;
  border-radius: 50%;
  text-align: center;
}

.speedometerInner {
  width: 102px;
  height: 102px;
  border: 1px solid #366376;
  border-radius: 50%;
  background-color: #fff;
  margin: 8px;
  padding: 15px;
}

.speedometerInner h6 {
  font: 12px;
  font-family: Montserrat-SemiBold;
  line-height: 15px;
  color: #000;
  margin: 0;
}

.speedometerInner h3 {
  font-family: Montserrat-Medium;
  font-size: 34px;
  line-height: 31px;
  color: #000;
  margin: 0;
}

.speedometerInner span {
  font-family: Montserrat-Regular;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
}

.fleetItemFooter {
  box-shadow: 1px -5px 6px #00000029;
  /* padding: 18px 22px 24px 22px; */
  padding: 2.24vh 1.55vw 2.24vh 1.55vw;
}

.subTitle1 {
  font-family: Montserrat-Medium;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 19px;
  color: #000;
  margin: 0;
}

.fleetItemFooter .anticon-camera {
  color: #366376;
  float: right;
  cursor: pointer;
}

.ptoWrench {
  /* padding-top: 23px; */
  padding-top: 2.86vh;
}

.ptoBlk {
  width: 35%;
  text-align: center;
  position: relative;
  left: -25px;
}

.ptoBlk img {
  width: 30px;
  height: 30px;
}

.ptoBlk p {
  margin: 0;
  padding-top: 18px;
  font-size: 15px;
  font-family: 'Montserrat-Medium';
  color: #474747;
  line-height: 11px;
}

.wrenchIcon {
  width: 50%;
  position: relative;
  left: 10px;
}

.wrenchIcon .ant-progress-inner {
  transform: rotate(270deg);
  margin-left: 40px;
  background-color: #cececf;
}

.wrenchIcon .ant-progress-success-bg,
.wrenchIcon .ant-progress-bg {
  background-color: #366376;
}

.wrenchIcon .ant-progress-text {
  position: absolute;
  left: 100px;
  bottom: -20px;
  font-family: 'Montserrat-Medium';
  font-size: 16px;
  line-height: 8px;
}

.wrenchIcon img {
  width: 26px;
  height: 26px;
  position: absolute;
  left: 110px;
  top: -16px;
}

.cameras {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding-top: 16px;
    padding-bottom: 8px; */
  padding-top: 1.995vh;
  padding-bottom: 0.997vh;
  gap: 12px;
  overflow-x: auto;
}

.cameras .cameraIcon {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 2px;
  cursor: pointer;
}

.cameras .cameraIcon span {
  font-family: Montserrat-Medium;
  font-size: 9px;
  line-height: 13px;
}

.hidePointer {
  cursor: default !important;
}

/* Scrollbar width */

.cameras::-webkit-scrollbar,
.fleetTabs .ant-tabs-nav-list::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Scrollbar Track */

.cameras::-webkit-scrollbar-track,
.fleetTabs .ant-tabs-nav-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Scrollbar Handle */

.cameras::-webkit-scrollbar-thumb,
.fleetTabs .ant-tabs-nav-list::-webkit-scrollbar-thumb {
  background: #959595;
  border-radius: 6px;
}

/* Scrollbar Handle on hover */

.cameras::-webkit-scrollbar-thumb:hover,
.fleetTabs .ant-tabs-nav-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.childAccordion2 {
  min-height: 240px;
  max-height: 415px;
  overflow-y: auto;
}

/* Scrollbar width */

.childAccordion2::-webkit-scrollbar {
  width: 5px;
}

/* Scrollbar Track */

.childAccordion2::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Scrollbar Handle */

.childAccordion2::-webkit-scrollbar-thumb {
  background: #959595;
  border-radius: 6px;
}

.childAccordion2::-webkit-scrollbar-thumb {
  background: #959595 !important;
}

.childAccordion2::-webkit-scrollbar {
  width: 3px !important;
}

/* Scrollbar Handle on hover */

.childAccordion2::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* new styles in livemap component end */

.dataLayerContainer {
  padding: 0px 40px 0px 40px;
}

.fleetMsgTxt {
  font-family: Montserrat-SemiBold;
  font-size: 8px;
  color: #fff;
  line-height: 19px;
  letter-spacing: 0.29px;
  text-transform: uppercase;
  text-align: center;
  width: 75px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  min-width: 72px;
}

/* add Tractor fleet list */

.tractorTabs {
  position: relative;
}

.fleetTractorsBlk {
  padding: 20px 32px 40px 32px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0 10px 10px 10px !important;
  z-index: 999;
  position: absolute;
  width: 100%;
  top: 35px;
}

.fleetTractorsBlk h4 {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
  color: #000;
  margin-bottom: 8px;
}

.fleetTractorsBlk .addTractorsBtn h5 {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.29px;
  line-height: 15px;
  color: #366376;
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
  margin-bottom: 0;
}

.fleetTractorsBlk .addTractorsBtn h5 .anticon-plus {
  margin-right: 8px;
}

.fleetTractorsInrBlk {
  min-height: 385px;
  height: 385px;
  overflow-y: auto;
}

.fleetTractorsInrBlk .fleetBlk {
  border: 2px solid #fff !important;
  margin-top: 0;
  margin-bottom: 16px;
  padding: 16px;
}

.fleetTractorsInrBlk .fleetBlk {
  border: 2.3px solid #cececf !important;
}

.fleetTractorsInrBlk .fleetBlk:hover {
  border: 2.3px solid #366376 !important;
}

/* .fleetOverallBlk .fleetBlk:hover{
  box-shadow: inset 0px 2px 6px #00000029;
  border: 1px solid #E7E7E7;
} */

/* .fleetTractorsInrBlk .fleetBlk-selected{
  border: 2px solid #366376!important;
}

.fleetOverallBlk .fleetBlk-selected{
  box-shadow: inset 0px 2px 6px #00000029;
  border: 1px solid #E7E7E7;
} */

.fleetTractorsInrBlk::-webkit-scrollbar {
  width: 4px;
}

.fleetTractorsInrBlk::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.fleetTractorsInrBlk::-webkit-scrollbar-thumb {
  background: #959595;
}

.fleetTractorsInrBlk::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mb4 {
  margin-bottom: 4px;
}

@media (min-width: 768px) and (max-width: 1440px) {



  .fleetTractorsBlk {
    padding: 2.21vh 1.66vw 4.43vh 1.66vw;
  }
  /* .fleetlistBlk {
    top: 32px;
  } */
  .searchMapBlk {
    margin-bottom: 15px !important;
  }
  .auto-drive-block {
    top: 52px !important;
  }
}
@media (min-width: 768px) and (max-width: 1800px) {
  /* .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 300px) !important;
  } */
  /* .childAccordion2 {
    max-height: calc(100vh - 300px) !important;
  } */
}

.camera-icon {
  cursor: default !important;
}

.fleetTabs .ant-tabs-nav {
  border: none !important;
}

/* available Status */

.availableStatus .availableTxt {
  background-color: #ec9325;
  border-radius: 9px;
}

.availableStatus .fleetItemBody {
  background-color: #f8f3ed;
  padding: 2.74vh 3.1vw 6.48vh 2.39vw;
}

.availableStatus .speedometerOuter {
  background-color: transparent;
  width: 120px;
  height: 120px;
  border: 1px solid transparent;
  border-radius: 50%;
  text-align: center;
}

.availableStatus .speedometerInner {
  width: 102px;
  height: 102px;
  border: 1px solid #f1ce90;
  border-radius: 50%;
  background-color: #fff;
  margin: 8px;
  padding: 15px;
}

.availableStatus .wrenchIcon .ant-progress-success-bg,
.availableStatus .wrenchIcon .ant-progress-bg {
  background-color: #ec9325;
}

/* inUse status */

.inuseStatus .inUseTxt {
  background-color: #67afb7;
  border-radius: 9px;
}

.inuseStatus .fleetItemBody {
  background-color: #f2f7f4;
  padding: 2.74vh 3.1vw 6.48vh 2.39vw;
}

.inuseStatus .speedometerOuter {
  background-color: #9dc8c9;
  width: 120px;
  height: 120px;
  border: 1px solid #9dc8c9;
  border-radius: 50%;
  text-align: center;
}

.inuseStatus .speedometerInner {
  width: 102px;
  height: 102px;
  border: 1px solid #67afb7;
  border-radius: 50%;
  background-color: #fff;
  margin: 8px;
  padding: 15px;
}

.inuseStatus .wrenchIcon .ant-progress-success-bg,
.inuseStatus .wrenchIcon .ant-progress-bg {
  background-color: #67afb7;
}

/* off status */

.offStatus .offlineTxt {
  background-color: #dddddd;
  border-radius: 9px;
}

.offStatus .fleetItemBody {
  background-color: #e7e7e7;
  padding: 2.74vh 3.1vw 6.48vh 2.39vw;
}

.offStatus .speedometerOuter {
  background-color: transparent;
  width: 120px;
  height: 120px;
  border: 1px solid transparent;
  border-radius: 50%;
  text-align: center;
}

.offStatus .speedometerInner {
  width: 102px;
  height: 102px;
  border: 1px solid #cececf;
  border-radius: 50%;
  background-color: #e7e7e7;
  margin: 8px;
  padding: 15px;
}

.offStatus .wrenchIcon .ant-progress-success-bg,
.offStatus .wrenchIcon .ant-progress-bg {
  background-color: #cececf;
}

/* charging status */

.chargingStatus .chargingTxt {
  background-color: #aabe38;
  border-radius: 9px;
}

.chargingStatus .fleetItemBody {
  background-color: #f7f8ed;
  padding: 1.74vh 3.1vw 6.48vh 2.39vw;
}

.chargingStatus .speedometerOuter {
  background-color: transparent;
  width: 120px;
  height: 120px;
  border: 1px solid transparent;
  border-radius: 50%;
  text-align: center;
}

.chargingStatus .speedometerInner {
  width: 102px;
  height: 102px;
  border: 1px solid #8a9a48;
  border-radius: 50%;
  background-color: #fff;
  margin: 8px;
  padding: 15px;
}

.chargingStatus .wrenchIcon .ant-progress-success-bg,
.chargingStatus .wrenchIcon .ant-progress-bg {
  background-color: #aabe38;
}

.chargingPercentage {
  height: 9px;
}

.chargingPercentage .ant-progress-inner {
  background-color: #e7e7e7;
  border-radius: 0 !important;
  margin-top: -15px;
}

.chargingPercentage .ant-progress-success-bg,
.chargingPercentage .ant-progress-bg {
  height: 9px;
  border-radius: 0 !important;
  background-color: #a9bf51 !important;
}

.mr5 {
  margin-right: 5px;
}

.charging-container {
  max-width: 150px;
  width: 14px;
  height: 8px;
  border: 1px solid #000000;
  border-radius: 1px !important;
  position: relative;
}

.charging-container::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 6px;
  /* background: #474747; */
  right: -4px;
  top: 50%;
  margin-top: -3px;
  border-radius: 2px;
  border: 1px solid #474747;
  border-left: 1px solid #fff;
}

.charging-container::after {
  content: '';
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  /* right: 10px; */
  background: #474747;
  transition: all 0.3s;
  -webkit-animation: charging 2s steps(1) infinite;
  -moz-animation: charging 2s steps(1) infinite;
  animation: charging 2s steps(1) infinite;
  animation: charging 2s steps(1) infinite;
}

.charging-container:hover::after {
  animation-play-state: paused;
}

.battery-blk {
  width: 20px;
}

.battery-percentage {
  height: 4px;
  padding: 2px;
  margin-top: 1px;
  margin-left: 0;
  margin-right: 2px;
}

.fleetmap .batteryPercentage {
  margin-left: 5px;
}

.fleet-minus {
  margin-left: -18px;
  margin-top: 8px;
  position: absolute;
}

.no-inuse-tractors {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14% 0;
  font-family: 'Montserrat-Medium';
  color: #5c5c5c;
}

.afterMapRightAlign .positionMap-inner {
  right: 493px;
  transition: 1s;
}

.connectBtnOrg {
  border-radius: 60px;
  background: #ec9325;
  border: 4px solid #f1ce90;
  width: 75px;
  height: 75px;
  color: #fff;
  position: absolute;
  font-family: 'Montserrat-Bold';
  font-size: 8.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}

.connectBtnGry {
  border-radius: 60px;
  background: #9dc8c9;
  border: 4px solid #9dc8c9;
  width: 75px;
  height: 75px;
  color: #fff;
  position: absolute;
  font-family: 'Montserrat-Bold';
  font-size: 8.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
  margin-top: 10px;
  /* box-shadow: 0 3px 6px rgba(0,0,0, .16); */
}

.connectBtnOrg:focus-within,
.connectBtnGry:focus-within {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
}

.connectBtnGrn {
  border-radius: 60px;
  background: #a9bf51;
  border: 4px solid #c4d36f;
  width: 70px;
  height: 70px;
  color: #fff;
  position: absolute;
  font-family: 'Montserrat-Bold';
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
}

.autoDrivePopup {
  top: 0px;
  width: 88% !important;
  /* height: 80vh; */
  display: inline-grid;
  padding-bottom: 0;
}

.autoDrivePopup .ant-modal-body {
  height: calc(100vh - 100px);
}

.autoDrivePopup .autoDriveContainer {
  height: calc(100vh - 250px);
}

.autoDrivePopup .autoDriveWrapper {
  height: 400px;
}

.autoDrivePopup .ant-modal-content,
.autoDrivePopup .ant-modal-header {
  border-radius: 15px;
}

.autoDrivePopup .autoDriveWrapper {
  height: calc(100vh - 240px);
}

.autoDrivePopup .autoDriveContainer {
  margin: 30px 18px 0px 18px;
}

.autoDrivePopup .autoDrivemapHight {
  height: 100% !important;
}

.autoDrivePopup .autoDriveWrapper .cardBox .cardBody,
.autoDrivePopup .cardBox {
  padding: 0;
  height: 100% !important;
}

.autoDrivePopup .autoDriveWrapper .cardBox {
  height: calc(100vh - 244px) !important;
}

.autoDrivePopup .sideImplementBlock {
  height: calc(100vh - 240px);
}

.autoDrivePopup .dSetSpeedBg {
  background-size: 100%;
  width: 130px;
  height: 130px;
}

.autoDrivePopup .speedSecTitle,
.autoDrivePopup .speedSecmph {
  font-size: 10px;
}

.autoDrivePopup .speedSecNo {
  font-size: 28px;
}

.autoDrivePopup .dsMinusBtn,
.autoDrivePopup .dsPlusBtn {
  background-size: 100%;
  width: 46px;
  height: 44px;
}

.autoDrivePopup .driveSettingBox {
  min-height: 260px;
  max-width: 350px;
}

.autoDrivePopup .slider {
  padding-top: 6px;
  padding-left: 0px;
  padding-right: 0px;
}

.passCodePopup .formInputs,
.passCodePopup .codeEnterSec {
  display: grid;
  width: 80%;
  text-align: center;
  align-items: center;
  margin: 0 auto;
}

.passCodePopup .formInputs input {
  width: 25%;
  display: inline-block;
  border: none;
  border-bottom: 1px solid #f1f1f1;
  height: 50px;
  margin-bottom: 40px;
  font-size: 36px;
  text-align: center;
}

.passCodePopup .formInputs input:focus {
  outline: none;
}

.buttonsSec {
  align-items: center;
  justify-content: center;
  display: grid;
  padding-bottom: 20px;
}

.enterLbl {
  font-size: 18px;
}

.buttonsSec .keyboardRow {
  display: flex;
  grid-auto-columns: 25%;
  display: in;
  margin: 0 auto;
  gap: 20px;
  margin-bottom: 20px;
}

.buttonsSec .keyboardButton {
  border-radius: 60px;
  width: 58px;
  height: 58px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border: none;
  font-size: 24px;
  cursor: pointer;
  font-family: 'Montserrat-semiBold';
}

.passCodePopup .ant-modal-content,
.passCodePopup .ant-modal-header {
  border-radius: 15px;
}

.tractorRemoteAvPop {
  position: fixed;
  background: white;
  right: 100px;
  top: 0px;
  z-index: 10;
  padding: 27px;
}

.resumeBtn {
  min-width: 186px;
  height: 44px;
  border-radius: 25px;
  background: green;
  color: white;
  font-family: 'Montserrat-Bold';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  outline: none;
  border: none;
  cursor: pointer;
  margin-right: 14px;
}

.liveEndBtn {
  min-width: 186px;
  height: 44px;
  border-radius: 25px;
  background: #d33c39;
  color: white;
  font-family: 'Montserrat-Bold';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  outline: none;
  border: none;
  cursor: pointer;
  margin-right: 19px;
}

.autoDriveTxt {
  font-family: 'Montserrat-Bold';
  font-size: 20px;
  letter-spacing: 0.76px;
  color: #2e333b;
  margin-bottom: 48px;
}

.mb33 {
  margin-bottom: 33px;
}

.buttonsSec .keyboardButton:focus-within {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
}

.contTool {
  display: none;
}

.connectBtnOrg:hover .contTool {
  display: block;
  background: #fff;
  /* box-shadow: 0px 3px 6px rgba(0,0,0, .16); */
  border-radius: 20px;
  position: absolute;
  z-index: 99;
  top: 55px;
  padding: 3px 8px;
  border-radius: 12px;
  margin-left: 115px;
  height: auto;
  border: 1px solid #e7e5e5;
}

.contItem {
  margin-bottom: 5px;
  font-size: 12px;
  color: #606060;
  min-width: 170px;
  font-family: 'Montserrat-medium';
  padding: 5px;
  display: flex;
  align-items: center;
}

.contItem:last-child {
  margin-bottom: 0px;
}

/* .contItem img {
  margin-right: 10px;
} */

.contItem:hover {
  background: #f1f1f1;
}

.cIcon {
  background: #ec9322;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 60px;
  margin-right: 15px;
}

.trcListSec {
  position: absolute;
  right: 100px;
  padding: 0px;
  width: 180px;
  height: 175px;
  overflow-y: auto;
  z-index: 99;
  top: 50px;
  padding-right: 5px;
}

.tcListItem {
  border-radius: 60px;
  width: 100%;
  height: 40px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  background: rgba(242, 232, 221, 0.8);
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  margin-bottom: 5px;
}

.tcNm {
  font-size: 12px;
  width: calc(100% - 20px);
}

.actBtnTgl {
  border-radius: 60px;
  width: 24px;
  height: 24px;
  background: #ec9322;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.actBtnTgl img {
  width: 16px;
  height: 16px;
}

/* Scrollbar width */

.trcListSec::-webkit-scrollbar {
  width: 5px;
}

/* Scrollbar Track */

.trcListSec::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Scrollbar Handle */

.trcListSec::-webkit-scrollbar-thumb {
  background: #959595;
}

/* Scrollbar Handle on hover */

.trcListSec::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.incorrectCode {
  margin-bottom: 20px;
  color: #f00;
}

.ant-modal-wrap {
  display: flex;
}

.buttonsSec .keyboardRow:last-child {
  gap: 0;
}

.passCodePopup .ant-modal {
  padding-bottom: 0px;
}

.autoDrivePopup .selRowTypeWrapper {
  margin-top: 40px;
  margin-bottom: 70px;
}

.autoDrivePopup .rowsSideBlock {
  height: calc(100vh - 0px);
  width: 200px;
}

.autoDrivePopup .driveSettingBox {
  min-height: 260px;
  max-width: 350px;
  max-height: 260px;
}

.autoDrivePopup .dsOutrow,
.autoDrivePopup .dsInrow {
  margin-bottom: 15px;
}

.autoDrivePopup .sideImpSecNArpm {
  margin-top: 25px;
  margin-bottom: 25px;
}

.autoDrivePopup .sideRpmSec {
  margin-bottom: 25px;
}

.autoDrivePopup .sideInsideWrapper {
  padding-top: 20px;
}

.autoDrivePopup .selectRowsHead {
  font-size: 16px;
}

.lineSep {
  display: block;
  width: 100%;
  height: 1.5px;
  background: #ededed;
  padding: 0;
  margin: 5px 0;
}

.cStopBtn {
  border-radius: 7px;
  width: 100%;
  height: 40px;
  text-align: center;
  background: #d33c39;
  font-family: Montserrat-SemiBold;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  border: none;
  letter-spacing: 1.2px;
}

.cStopBtn:focus {
  outline: none;
}

.fleetItemBody {
  position: relative;
}

.conBtnSec {
  position: absolute;
  z-index: 2;
  top: 20px;
  right: 10px;
  width: 100px;
}

.conBtnSec span img {
  height: 12px;
  margin-right: 5px;
}

.conItemBtn {
  border: none;
  background: #eb9426;
  border-radius: 60px;
  padding: 0 5px 0px 6px;
  display: flex;
  align-items: center;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 9px;
  height: 24px;
  width: 100%;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat-Medium;
  margin-bottom: 5px;
}

/* .conItemBtn:focus-within{
  box-shadow: 0 3px 6px rgba(0,0,0, .16);
} */

.conItemBtnDbl {
  border: none;
  background: #dbdbdb;
  border-radius: 60px;
  padding: 0 5px 0px 6px;
  display: flex;
  align-items: center;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 9px;
  height: 24px;
  width: 100%;
  color: #8b8b8b;
  cursor: pointer;
  font-family: Montserrat-Medium;
  margin-bottom: 5px;
}

.conItemBtn:hover,
.conItemBtnDbl:hover {
  outline: none;
}

.flistrCenter img {
  margin-left: 0px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.indicatorimges .flistrCenter {
  display: flex;
  align-items: center;
}

.frcIcon {
  margin-left: 10px !important;
}

.objRow {
  background: #f9efe6;
  border: 1px solid #eaccb1;
  padding: 20px;
  font-size: 16px;
  font-family: Montserrat-Regular;
  line-height: 22px;
  text-align: left;
  margin-bottom: 10px;
}

.objRowDate {
  font-size: 18px;
  font-family: Montserrat-SemiBold;
  line-height: 22px;
  text-align: left;
  margin-bottom: 15px;
  display: block;
}

.jsonCont {
  /* height: 500px; */
  overflow-y: auto;
}

.jsonpopup .ant-modal-content {
  width: 1200px !important;
  height: 750px !important;
}

.jsonpopup {
  width: inherit !important;
  padding-bottom: 0px !important;
}

.HeartBeatModalContainer .ant-modal-body {
  min-width: 700px;
  text-align: center;
  height: 715px;
  overflow: hidden;
}

.HeartBeatModalContainer .ant-modal-content {
  min-width: 700px;
  border: 0;
  font-family: Montserrat-SemiBold;
}

.hbPopup {
  margin-top: 100px;
}

.hbPopup .ant-tabs-nav-wrap {
  margin-top: 0px;
  margin-bottom: 30px;
}

.heartbeatcopy {
  height: 18px;
  width: 18px;
  display: inline-block !important;
  float: right !important;
}

.clearBtn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.clearBtn button {
  background-color: #366376;
  color: #fff;
  border-radius: 3px;
}

.livemapIconsec {
  left: -72px;
  right: unset;
}

.appline {
  font-family: sans-serif;
  text-align: center;
  height: 42vh;
  overflow-y: auto;
}

.appline canvas {
  height: 395px !important;
  padding-bottom: 20px;
}

.hbPopup .ant-modal-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.hbPopup .ant-tabs-content {
  padding-left: 24px;
  padding-right: 24px;
  height: 400px;
  overflow-y: auto;
}

.hbPopup .ant-tabs-nav {
  padding-left: 24px;
  padding-right: 24px;
}

.numberCls {
  width: 200px;
  height: 5vh !important;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-color: #d9d9d9 !important;
}

.numberCls:focus,
.numberCls:hover {
  border-color: #40a9ff !important;
}

.connectBtnOrg {
  border-radius: 60px;
  background: #ec9325;
  border: 4px solid #f1ce90;
  width: 75px;
  height: 75px;
  color: #fff;
  position: absolute;
  font-family: 'Montserrat-Bold';
  font-size: 8.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}

.connectBtnGry {
  border-radius: 60px;
  background: #9dc8c9;
  border: 4px solid #9dc8c9;
  width: 75px;
  height: 75px;
  color: #fff;
  position: absolute;
  font-family: 'Montserrat-Bold';
  font-size: 8.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
  margin-top: 10px;
  /* box-shadow: 0 3px 6px rgba(0,0,0, .16); */
}

.connectBtnOrg:focus-within,
.connectBtnGry:focus-within {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
}

.connectBtnGrn {
  border-radius: 60px;
  background: #a9bf51;
  border: 4px solid #c4d36f;
  width: 70px;
  height: 70px;
  color: #fff;
  position: absolute;
  font-family: 'Montserrat-Bold';
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
}

.autoDrivePopup {
  top: 0px;
  width: 88% !important;
  /* height: 80vh; */
  display: inline-grid;
  padding-bottom: 0;
}

.autoDrivePopup .ant-modal-body {
  height: calc(100vh - 100px);
}

.autoDrivePopup .autoDriveContainer {
  height: calc(100vh - 250px);
}

.autoDrivePopup .autoDriveWrapper {
  height: 400px;
}

.autoDrivePopup .ant-modal-content,
.autoDrivePopup .ant-modal-header {
  border-radius: 15px;
}

.autoDrivePopup .autoDriveWrapper {
  height: calc(100vh - 240px);
}

.autoDrivePopup .autoDriveContainer {
  margin: 30px 18px 0px 18px;
}

.autoDrivePopup .autoDrivemapHight {
  height: 100% !important;
}

.autoDrivePopup .autoDriveWrapper .cardBox .cardBody,
.autoDrivePopup .cardBox {
  padding: 0;
  height: 100% !important;
}

.autoDrivePopup .autoDriveWrapper .cardBox {
  height: calc(100vh - 244px) !important;
}

.autoDrivePopup .sideImplementBlock {
  height: calc(100vh - 240px);
}

.autoDrivePopup .dSetSpeedBg {
  background-size: 100%;
  width: 130px;
  height: 130px;
}

.autoDrivePopup .speedSecTitle,
.autoDrivePopup .speedSecmph {
  font-size: 10px;
}

.autoDrivePopup .speedSecNo {
  font-size: 28px;
}

.autoDrivePopup .dsMinusBtn,
.autoDrivePopup .dsPlusBtn {
  background-size: 100%;
  width: 46px;
  height: 44px;
}

.autoDrivePopup .driveSettingBox {
  min-height: 260px;
  max-width: 350px;
}

.autoDrivePopup .slider {
  padding-top: 6px;
  padding-left: 0px;
  padding-right: 0px;
}

/* .passCodePopup {
  top: 0px;
  width: 450px !important;
  align-items: center;
  display: flex;
} */

.passCodePopup .formInputs,
.passCodePopup .codeEnterSec {
  display: grid;
  width: 80%;
  text-align: center;
  align-items: center;
  margin: 0 auto;
}

.passCodePopup .formInputs input {
  width: 25%;
  display: inline-block;
  border: none;
  border-bottom: 1px solid #f1f1f1;
  height: 50px;
  margin-bottom: 30px;
  font-size: 36px;
  text-align: center;
}

.passCodePopup .formInputs input:focus {
  outline: none;
}

.buttonsSec {
  align-items: center;
  justify-content: center;
  display: grid;
  padding-bottom: 20px;
}

.buttonsSec .keyboardRow {
  display: flex;
  grid-auto-columns: 25%;
  display: in;
  margin: 0 auto;
  gap: 20px;
  margin-bottom: 20px;
}

.buttonsSec .keyboardButton {
  border-radius: 60px;
  width: 58px;
  height: 58px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border: none;
  font-size: 24px;
  cursor: pointer;
  font-family: 'Montserrat-semiBold';
}

.passCodePopup .ant-modal-content,
.passCodePopup .ant-modal-header {
  border-radius: 15px;
}

.tractorRemoteAvPop {
  position: fixed;
  background: white;
  right: 100px;
  top: 0px;
  z-index: 10;
  padding: 27px;
}

.resumeBtn {
  min-width: 186px;
  height: 44px;
  border-radius: 25px;
  background: green;
  color: white;
  font-family: 'Montserrat-Bold';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  outline: none;
  border: none;
  cursor: pointer;
  margin-right: 14px;
}

.liveEndBtn {
  min-width: 186px;
  height: 44px;
  border-radius: 25px;
  background: #d33c39;
  color: white;
  font-family: 'Montserrat-Bold';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  outline: none;
  border: none;
  cursor: pointer;
  margin-right: 19px;
}

.autoDriveTxt {
  font-family: 'Montserrat-Bold';
  font-size: 20px;
  letter-spacing: 0.76px;
  color: #2e333b;
  margin-bottom: 48px;
}

.mb33 {
  margin-bottom: 33px;
}

.buttonsSec .keyboardButton:focus-within {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
}

.contTool {
  display: none;
}

.connectBtnOrg:hover .contTool {
  display: block;
  background: #fff;
  /* box-shadow: 0px 3px 6px rgba(0,0,0, .16); */
  border-radius: 20px;
  position: absolute;
  z-index: 99;
  top: 55px;
  padding: 3px 8px;
  border-radius: 12px;
  margin-left: 115px;
  height: auto;
  border: 1px solid #e7e5e5;
}

.contItem {
  margin-bottom: 5px;
  font-size: 12px;
  color: #606060;
  min-width: 170px;
  font-family: 'Montserrat-medium';
  padding: 5px;
  display: flex;
  align-items: center;
}

.contItem:last-child {
  margin-bottom: 0px;
}

/* .contItem img {
  margin-right: 10px;
} */

.contItem:hover {
  background: #f1f1f1;
}

.cIcon {
  background: #ec9322;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 60px;
  margin-right: 15px;
}

.trcListSec {
  position: absolute;
  right: 100px;
  padding: 0px;
  width: 180px;
  height: 175px;
  overflow-y: auto;
  z-index: 99;
  top: 50px;
  padding-right: 5px;
}

.tcListItem {
  border-radius: 60px;
  width: 100%;
  height: 40px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  background: rgba(242, 232, 221, 0.8);
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  margin-bottom: 5px;
}

.tcNm {
  font-size: 12px;
  width: calc(100% - 20px);
}

.actBtnTgl {
  border-radius: 60px;
  width: 24px;
  height: 24px;
  background: #ec9322;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.actBtnTgl img {
  width: 16px;
  height: 16px;
}

/* Scrollbar width */

.trcListSec::-webkit-scrollbar {
  width: 5px;
}

/* Scrollbar Track */

.trcListSec::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Scrollbar Handle */

.trcListSec::-webkit-scrollbar-thumb {
  background: #959595;
}

/* Scrollbar Handle on hover */

.trcListSec::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.incorrectCode {
  margin-bottom: 30px;
  color: #f00;
}

.ant-modal-wrap {
  display: flex;
}

.buttonsSec .keyboardRow:last-child {
  gap: 0;
}

.passCodePopup .ant-modal {
  padding-bottom: 0px;
}

.autoDrivePopup .selRowTypeWrapper {
  margin-top: 40px;
  margin-bottom: 70px;
}

.autoDrivePopup .rowsSideBlock {
  height: calc(100vh - 0px);
  width: 200px;
}

.autoDrivePopup .driveSettingBox {
  min-height: 260px;
  max-width: 350px;
  max-height: 260px;
}

.autoDrivePopup .dsOutrow,
.autoDrivePopup .dsInrow {
  margin-bottom: 15px;
}

.autoDrivePopup .sideImpSecNArpm {
  margin-top: 25px;
  margin-bottom: 25px;
}

.autoDrivePopup .sideRpmSec {
  margin-bottom: 25px;
}

.autoDrivePopup .sideInsideWrapper {
  padding-top: 20px;
}

.autoDrivePopup .selectRowsHead {
  font-size: 16px;
}

.lineSep {
  display: block;
  width: 100%;
  height: 1.5px;
  background: #ededed;
  padding: 0;
  margin: 5px 0;
}

.cStopBtn {
  border-radius: 7px;
  width: 100%;
  height: 40px;
  text-align: center;
  background: #d33c39;
  font-family: Montserrat-SemiBold;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  border: none;
  letter-spacing: 1.2px;
}

.cStopBtn:focus {
  outline: none;
}

.fleetItemBody {
  position: relative;
}

.conBtnSec {
  position: absolute;
  z-index: 2;
  top: 20px;
  right: 10px;
  width: 100px;
}

.conBtnSec span img {
  height: 12px;
  margin-right: 5px;
}

.conItemBtn {
  border: none;
  background: #eb9426;
  border-radius: 60px;
  padding: 0 5px 0px 6px;
  display: flex;
  align-items: center;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 9px;
  height: 24px;
  width: 100%;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat-Medium;
  margin-bottom: 5px;
}

/* .conItemBtn:focus-within{
  box-shadow: 0 3px 6px rgba(0,0,0, .16);
} */

.conItemBtnDbl {
  border: none;
  background: #dbdbdb;
  border-radius: 60px;
  padding: 0 5px 0px 6px;
  display: flex;
  align-items: center;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 9px;
  height: 24px;
  width: 100%;
  color: #8b8b8b;
  cursor: pointer;
  font-family: Montserrat-Medium;
  margin-bottom: 5px;
}

.conItemBtn:hover,
.conItemBtnDbl:hover {
  outline: none;
}

.flistrCenter img {
  margin-left: 0px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.indicatorimges .flistrCenter {
  display: flex;
  align-items: center;
}

.frcIcon {
  margin-left: 10px !important;
}

.objRow {
  background: #f9efe6;
  border: 1px solid #eaccb1;
  padding: 20px;
  font-size: 16px;
  font-family: Montserrat-Regular;
  line-height: 22px;
  text-align: left;
  margin-bottom: 10px;
}

.objRowDate {
  font-size: 18px;
  font-family: Montserrat-SemiBold;
  line-height: 22px;
  text-align: left;
  margin-bottom: 15px;
  display: block;
}

.jsonCont {
  height: calc(100vh - 250px);
  overflow-y: auto;
}

.jsonpopup .ant-modal-content {
  width: 1200px !important;
  height: 600px !important;
}

.jsonpopup {
  width: inherit !important;
  padding-bottom: 0px !important;
}

.HeartBeatModalContainer .ant-modal-body {
  min-width: 700px;
  text-align: center;
  height: 520px;
  overflow: hidden;
}

.HeartBeatModalContainer .ant-modal-content {
  min-width: 700px;
  border: 0;
  font-family: Montserrat-SemiBold;
}

.mb20 {
  margin-bottom: 20px;
}

.fullChargeTxt {
  font-family: Montserrat-Medium;
  color: #231f20;
  font-size: 11px;
  text-align: center;
  margin-bottom: 6px;
}

.hbPopup .visulizationtab .ant-tabs-content {
  padding-left: 24px;
  padding-right: 24px;
  height: 475px !important;
  overflow-y: auto;
}

.hearBeatPopup {
  width: 1200px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.hearBeatPopup .ant-modal-content {
  height: calc(100vh - 60px);
  width: 100%;
  border-radius: 15px;
}

.hearBeatPopup .ant-modal-header {
  border-radius: 15px 15px 0 0;
}

.hearBeatPopup .ant-modal-body {
  height: calc(100% - 55px);
  padding-top: 10px;
  padding-bottom: 10px;
}

.hearBeatPopup .ant-tabs {
  height: calc(100vh - 140px);
  width: 100%;
}

.hearBeatPopup .appline {
  font-family: sans-serif;
  text-align: center;
  height: inherit;
  overflow-y: inherit;
}

.hearBeatPopup .ant-select .ant-select-selector {
  max-height: 36px !important;
}

.hearBeatPopup .appline .ant-select-selection-overflow {
  max-height: 36px !important;
  border-radius: 8px;
  height: 40px;
}

.hearBeatPopup .appline .ant-select .ant-select-selector {
  border-radius: 8px;
}

.hearBeatPopup .appline .ant-select-selection-overflow-item {
  height: 40px;
}

.hearBeatPopup .appline .ant-select .ant-select-selector {
  height: 36px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
}

.hearBeatPopup .appline .ant-select-multiple .ant-select-selection-item {
  height: 28px;
}

.hearBeatPopup .ant-tabs-content {
  margin-top: 0px;
}

.hearBeatPopup #chartdiv {
  width: 100%;
  height: calc(100vh - 220px) !important;
}

/* =========== new latest design styles 19-04-2022 =============== */

.fleetTabs .ant-tabs-tab {
  padding: 5px 0 0 0;
}
/* 
.parentAccordion .fleetBlk {
  border: 2px solid #cececf;
} */

.fleetTabs .ant-tabs-content {
  margin-top: 0px;
}

.fleetTabs .ant-tabs-tab {
  padding: 0px;
}






/* 
.fleetOverallBlk .fleetBlk-selected {
  border: 2px solid #366376;
} */

/* .parentAccordion .fleetBlk {
  position: relative;
} */

.charging_status {
  width: calc(100% - 0px);
  /* height: 7px; */
  /* background: #A9BF51; */
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px 12px 0 0;
}



.tractorDtlSec {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.tractorDtlSec1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tPos {
  display: flex;
}




.mr4 {
  margin-right: 4px;
}

.mr12 {
  margin-right: 12px;
}

.t_available {
  background: #343b5e;
}

.t_service {
  background: #CFCFCF;
}

.t_off {
  background: #CFCFCF;
}

.t_faulty {
  background: #d33c39;
}

.t_routines {
  background: #ec9322;
}

.t_charging {
  background: #a9bf51;
}

.t_manual {
  background: #67afb7;
}

.t_copycat {
  background: #ec9322;
}

.t_follow_me {
  background: #67afb7;
}

.t_vinerow {
  background: #67afb7;
}

.t_remote_av {
  background: #ec9322;
}

.mt15 {
  margin-top: 15px;
}
.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px;
}
.mt12 {
  margin-top: 12px;
}

.mb18 {
  margin-bottom: 15px;
}

.t_time {
  font-size: 10px;
  font-family: Montserrat-Medium;
  color: #1e1e1e;
  letter-spacing: 0.6px;
  line-height: 13px;
  display: flex;
  align-items: center;
}



.debug-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0px 8px;
  cursor: pointer;
  font-size: 20px;
  font-family: Montserrat-Bold;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebe9e9;
  border-radius: 20px 20px 10px 20px;
}

span.debug-icon:hover {
  background: #dddada;
  border-radius: 20px 20px 10px 20px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offline_color {
  color: #dddddd !important;
}

.w65 {
  min-width: 65px;
  display: flex !important;
  justify-content: flex-start !important;
}

.overlay {
  position: absolute;
  color: white;
  top: 50%;
  width: 100%;
  text-align: center;
}

.internetSpeed {
  background: rgba(255, 255, 255, 0.85);
  border-radius: 50px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 8px 16px;
  min-width: 115px;
  font-family: Montserrat-SemiBold;
}

.ispeedImg {
  height: 15px;
  margin-right: 3px;
  margin-top: -3px;
}

.iMBPS {
  font-family: Montserrat-Medium !important;
  font-size: 10px !important;
}

.avatarGroup {
  border-radius: 50px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  min-width: 115px;
  font-family: Montserrat-SemiBold;
  z-index: 99;
}

.avatarGroup {
  top: 58px !important;
}

.ant-avatar-group-popover .ant-popover-inner {
  min-width: 110px !important;
  padding: 5px 10px !important;
  height: 50px !important;
}

/* selected tractor tabs styles */

.t_container_sec {
  border-radius: 0 10px 10px 10px;
  max-height: calc(100vh - 160px);
  min-height: 100%;
  padding: 24px 16px;
  overflow-y: auto;
}

.t_info_detail {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 71px;
}

.t_name_info {
  color: #000;
  font-size: 20px;
  font-family: Montserrat-Medium;
  line-height: 24px;
}

.t_driver_name_info {
  font-size: 10px;
  font-family: Montserrat-Medium;
  color: #333333;
  display: flex;
  align-items: center;
  line-height: 13px;
}

.mt-5 {
  margin-top: -5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb12 {
  margin-bottom: 12px;
}

.t_date_signal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.t_time_info,
.t_signals_info {
  font-size: 10px;
  font-family: Montserrat-Medium;
  color: #1e1e1e;
  letter-spacing: 0.6px;
  line-height: 13px;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 12px;
  padding: 3px 5px;
  border: 1px solid #e7e7e7;
  min-width: 63px;
  justify-content: center;
  min-height: 28px;
}

.t_info_progress_circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: 200px;
}

.trip_dtl {
  font-size: 10px;
  font-family: Montserrat-Medium;
  color: #474747;
  margin-top: 5px;
}

.mb5 {
  margin-bottom: 5px;
}

.imp_name {
  font-size: 12px;
  font-family: Montserrat-Semibold;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.95px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mt20 {
  margin-top: 20px;
}

.t_camera_sec {
  display: block;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.t_camera_head {
  font-size: 12px;
  font-family: Montserrat-Semibold;
  color: #000;
  margin-bottom: 18px;
  text-transform: uppercase;
  letter-spacing: 0.95px;
}

.camera_box_container {
  display: flex;
  justify-content: space-between;
}

.camera_box {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  padding: 10px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.t_camera_lbl {
  color: #474747;
  margin: 0;
  text-transform: uppercase;
}

.t_position {
  display: block;
  margin-top: 24px;
}

.t_pos_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.t_pos_col {
  border-bottom: 1px solid #a9bf51;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  min-width: 46%;
}

.t_pto {
  background-repeat: no-repeat;
  width: 25px;
  height: 26px;
  margin-right: 6px;
  background-size: 21px;
}

.t_loc {
  background-repeat: no-repeat;
  width: 25px;
  height: 22px;
  margin-right: 6px;
  background-size: 15px;
}

.t_hitch {
  background-repeat: no-repeat;
  width: 25px;
  height: 20px;
  margin-right: 6px;
  background-size: 18px;
}

.t_float {
  background-repeat: no-repeat;
  width: 25px;
  height: 16px;
  margin-right: 6px;
  background-size: 24px;
}

.t_float_custom > svg {
  background-repeat: no-repeat;
  width: 30px !important;
  height: 20px !important;
  margin-right: 6px !important;
  background-size: 24px !important;
}

.t_pto_speed {
  font-size: 20px;
  font-family: Montserrat-Medium;
  color: #000;
  margin-right: 6px;
}

.t_pto_speed2 {
  font-size: 16px;
  font-family: Montserrat-Medium;
  color: #000;
  margin-right: 6px;
}

.t_pto_lbl {
  font-size: 10px;
  font-family: Montserrat-Medium;
  color: #000;
  text-transform: uppercase;
}

/* status color scheme general styles */



.t_implement_sec {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  min-height: 42px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.t_camera_type {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.imp_icon {
  background-size: cover;
  width: 21px;
  height: 16px;
  background-repeat: no-repeat;
}

/* available */

.t_status_available {
  background: #f8f3ed;
}

.t_status_available .ttractor_state {
  background: #ec9322;
}

.t_status_available .t_implement_sec {
  background: #f8f3ed;
  border: 1px solid #f1ce90;
}

.t_status_available .t_camera_type {
  background: #ec9322;
}

.t_status_available .imp_icon {
  background-image: url(../../assets/images/Imp_hitch_avl.svg);
}

/* off */

.t_status_off {
  background: #e7e7e7;
}

.t_status_off .ttractor_state {
  background: #CFCFCF;
}

.t_status_off .t_implement_sec {
  background: #e7e7e7;
  border: 1px solid #b6b6b7;
}

.t_status_off .t_camera_type {
  background: #b6b6b7;
}
/* Service starts */

.t_status_faulty {
  background: #e7e7e7;
}

.t_status_faulty .ttractor_state {
  background: #CFCFCF;
}

.t_status_faulty .t_implement_sec {
  background: #e7e7e7;
  border: 1px solid #b6b6b7;
}

.t_status_faulty .t_camera_type {
  background: #b6b6b7;
}

/* Service ends */

/* faulty */

.t_status_faulty {
  background: #e7e7e7;
}

.t_status_faulty .ttractor_state {
  background: #d33c39;
}

.t_status_faulty .t_implement_sec {
  background: #e7e7e7;
  border: 1px solid #b6b6b7;
}

.t_status_faulty .t_camera_type {
  background: #b6b6b7;
}

/* charging */

.t_status_charging {
  background: #f7f8ed;
}

.t_status_charging .ttractor_state {
  background: #a9bf51;
}

.t_status_charging .t_implement_sec {
  background: #f7f8ed;
  border: 1px solid #a9bf51;
}

.t_status_charging .t_camera_type {
  background: #8a9a48;
}

.t_status_charging .imp_icon {
  background-image: url(../../assets/images/Imp_hitch_cha.svg);
}

.t_status_charging .t_pto {
  background-image: url(../../assets/images/t_pto_cha.svg);
}

.t_status_charging .t_loc {
  background-image: url(../../assets/images/t_loc_cha.svg);
}

.t_status_charging .t_hitch {
  background-image: url(../../assets/images/t_hitch_cha.svg);
}

.t_status_charging .t_float {
  background-image: url(../../assets/images/t_float_cha.svg);
}

.t_status_charging .t_pos_col {
  border-bottom: 1px solid #a9bf51 !important;
}

/* routines */

.t_status_routines {
  background: #f2f2fa;
}

.t_status_routines .ttractor_state {
  background: #546cb2;
}

.t_status_routines .t_implement_sec {
  background: #f2f2fa;
  border: 1px solid #8595ca;
}

.t_status_routines .t_camera_type {
  background: #8595ca;
}

.t_status_routines .imp_icon {
  background-image: url(../../assets/images/Imp_hitch_rou.svg);
}

.t_status_routines .t_pto {
  background-image: url(../../assets/images/t_pto_rou.svg);
}

.t_status_routines .t_loc {
  background-image: url(../../assets/images/t_loc_rou.svg);
}

.t_status_routines .t_hitch {
  background-image: url(../../assets/images/t_hitch_rou.svg);
}

.t_status_routines .t_float {
  background-image: url(../../assets/images/t_float_rou.svg);
}

.t_status_routines .t_pos_col {
  border-bottom: 1px solid #8595ca !important;
}

/* manual */

.t_status_manual {
  background: #f5f7f6;
}

.t_status_manual .ttractor_state {
  background: #67afb7;
}

.t_status_manual .t_implement_sec {
  background: #f5f7f6;
  border: 1px solid #67afb7;
}

.t_status_manual .t_camera_type {
  background: #67afb7;
}

.t_status_manual .imp_icon {
  background-image: url(../../assets/images/Imp_hitch_man.svg);
}

.t_status_manual .t_pto {
  background-image: url(../../assets/images/t_pto_man.svg);
}

.t_status_manual .t_loc {
  background-image: url(../../assets/images/t_loc_man.svg);
}

.t_status_manual .t_hitch {
  background-image: url(../../assets/images/t_hitch_man.svg);
}

.t_status_manual .t_float {
  background-image: url(../../assets/images/t_float_man.svg);
}

.t_status_manual .t_pos_col {
  border-bottom: 1px solid #67afb7 !important;
}

.t_status_manual .t_float {
  background-image: url(../../assets/images/t_float_man.svg);
}

/* Shadow */

.t_status_shadow {
  background: #f5f7f6;
}

.t_status_shadow .ttractor_state {
  background: #a4d5d8;
}

.t_status_shadow .t_implement_sec {
  background: #f5f7f6;
  border: 1px solid #a4d5d8;
}

.t_status_shadow .t_camera_type {
  background: #a4d5d8;
}

.t_status_shadow .imp_icon {
  background-image: url(../../assets/images/Imp_hitch_man.svg);
}

.t_status_shadow .t_pto {
  background-image: url(../../assets/images/t_pto_man.svg);
}

.t_status_shadow .t_loc {
  background-image: url(../../assets/images/t_loc_man.svg);
}

.t_status_shadow .t_hitch {
  background-image: url(../../assets/images/t_hitch_man.svg);
}

.t_status_shadow .t_float {
  background-image: url(../../assets/images/t_float_man.svg);
}

.t_status_shadow .t_pos_col {
  border-bottom: 1px solid #a4d5d8 !important;
}

.t_status_shadow .t_float {
  background-image: url(../../assets/images/t_float_man.svg);
}

.Off_camera_type {
  background: #b6b6b7 !important;
  cursor: default;
}

/* Scrollbar width */

.t_container_sec::-webkit-scrollbar {
  width: 4px;
}

/* Scrollbar Track */

.t_container_sec::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Scrollbar Handle */

.t_container_sec::-webkit-scrollbar-thumb {
  background: #959595;
}

/* Scrollbar Handle on hover */

.t_container_sec::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.container .card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
}

.container .card:before {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
  pointer-events: none;
  z-index: 1;
}

.ant-progress,
.ant-progress-outer {
  height: 7px !important;
  top: 0px;
  position: absolute;
  border-radius: 20px 20px 0 0;
}

.ant-progress .ant-progress-inner {
  position: absolute;
  top: 0;
  border-radius: 20px 20px 0 0;
}

.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #a6bc50 !important;
  border-radius: 0;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}

.fleetBlk.fleetBlk-selected:hover {
  /* border: none; */
}
/* 
.fleetBlk {
  min-height: 91px;
} */

.progressbar {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  /* background: radial-gradient(
      closest-side,
      white 75%,
      transparent 0 99.9%,
      white 0
    ),
    conic-gradient(#61ce6c 60%, #F8F3ED 0); */
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  /* color: #61ce6c; */
  box-shadow: 6px 6px 10px rgb(0 0 0 / 20%) inset;
}

.progressbar {
  position: relative;
  margin-bottom: 4px;
}

.outCircle {
  width: 200px;
  height: 200px;
  background-color: lightblue;
  left: 270px;
  position: absolute;
  top: 50px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

.rotate {
  width: 100%;
  height: 100%;
  -webkit-animation: circle 10s infinite linear;
}

.counterrotate {
  width: 40px;
  height: 40px;
  -webkit-animation: ccircle 10s infinite linear;
  position: absolute;
  left: 35%;
  top: -4%;
}

.inner {
  width: 40px;
  height: 40px;
  background: #fff;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 100px;
  position: absolute;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  left: 0%;
  top: 0%;
  font-size: 10px;
  font-family: Montserrat-Medium;
  color: #000;
  content: counter(percentage) '%';
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerInner {
  width: 37px;
  height: 37px;
  border: 2px solid #34ac3f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: #fff;
}

.tractor_status_img {
  position: absolute;
  width: calc(100% - 44px);
  height: calc(100% - 44px);
  background: #fff;
  padding: 20px;
  border-radius: 110px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
}

.ts_off {
  background-image: url(../../assets/images/ts_off.png);
  width: 125px;
  height: 85px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: block;
  margin: 0px auto 0 auto;
}

.ts_available {
  background-image: url(../../assets/images/ts_service.svg);
  width: 125px;
  height: 85px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: block;
  margin: 0px auto 0 auto;
}

.ts_service {
  background-image: url(../../assets/images/ts_service.png);
  width: 88px;
  height: 108px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: block;
  margin: 0px auto 0 auto;
}
.ts_faulty {
  background-image: url(../../assets/images/ts_available.png);
  width: 125px;
  height: 85px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: block;
  margin: 12px auto 0 auto;
}

.ts_routine {
  background-image: url(../../assets/images/ts_routine.png);
  width: 125px;
  height: 85px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: block;
  margin: 12px auto 0 auto;
}

.ts_charging {
  background-image: url(../../assets/images/ts_charging.png);
  width: 125px;
  height: 125px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center center;
  display: block;
  margin: 12px auto 0 auto;
}

.ts_manual {
  background-image: url(../../assets/images/ts_manual.png);
  width: 125px;
  height: 85px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: block;
  margin: 12px auto 0 auto;
}

.ts_autodrive {
  /* background-image: url(../../assets/images/ts_routine.png); */
  background-image: url(../../assets/images/ts_autodrive.png);
  width: 125px;
  height: 85px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: block;
  margin: 12px auto 0 auto;
}

.ts_shadow {
  background-image: url(../../assets/images/ts_shadow.png);
  width: 125px;
  height: 85px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: block;
  margin: 12px auto 0 auto;
}

.ts_rowfollow {
  background-image: url(../../assets/images/ts_rowfollow.png);
  width: 125px;
  height: 85px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: block;
  margin: 12px auto 0 auto;
}

.t_speed_cont {
  line-height: 12px;
  font-family: Montserrat-Medium;
  display: flex;
  align-items: baseline;
  justify-content: center;
  position: relative;
  top: -20px;
  color: #000;
}

.t_speed_cont .type {
  font-size: 13px;
  font-family: Montserrat-Bold !important;
  margin-right: 5px;
  color: #1e1e1e;
}

.t_speed_cont .speed {
  font-size: 25px;
  font-family: Montserrat-Medium;
  margin-right: 5px;
  color: #1e1e1e;
}

.t_speed_cont .measure {
  font-size: 9px;
  font-family: Montserrat-Medium;
  color: #1e1e1e;
}

.t_speed_cont .faulty {
  font-size: 10px;
  margin-top: 10px;
  color: red;
  font-family: Montserrat-Medium;
}

.faulty-icon {
  position: absolute;
  left: 45%;
  top: 10%;
}

.ful_sze_txt {
  float: right;
  cursor: pointer;
  color: #366376;
  margin: 5px;
}

.connectButton {
  float: center;
  cursor: pointer;
  color: #366376;
  margin: 5px;
  text-transform: uppercase;
  font-size: 16px;
}

.connectBtnSec {
  width: 75px;
  height: 75px;
  background: #ec9325;
  border: 7px solid #f1ce90;
  border-radius: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.disconnectBtnSec {
  max-width: 140px;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  background: #ec9325;
  border: 7px solid #f1ce90;
  border-radius: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.conCommandList {
  display: flex;
  align-items: center;
  justify-content: center;
}

.connectTxt {
  font-size: 9px;
  font-family: Montserrat-Bold;
  letter-spacing: 0.36px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  line-height: 17px;
}

.connectTxt img {
  margin-right: 6px;
}

.liveCommandBtn {
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  height: 40px;
  width: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  font-size: 11px;
  font-family: Montserrat-SemiBold;
}

.routinesBtn {
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  height: 40px;
  width: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 11px;
  font-family: Montserrat-SemiBold;
}

.liveCommandBtn:hover,
.routinesBtn:hover {
  border: 1px solid #636363;
}

.mr8 {
  margin-right: 8px !important;
}

.bShadow {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

h1.iframe-title {
  position: absolute;
  padding: 8px 16px;
  font-size: 16px;
  background: black;
  color: white;
  opacity: 0.6;
  border-radius: 10px 0 0 0;
  min-width: 100px;
  z-index: 1;
}

.mapShow {
  display: block !important;
}

.mapHide {
  display: none !important;
}

.findIconSec {
  position: absolute;
  right: 0;
  bottom: 20px;
  cursor: pointer;
}

.t_info_progress_circle {
  position: relative;
}

/* All Camera View Styles */

.flex-container {
  /* background-color: DodgerBlue; */
  position: relative;
  height: 100vh;
  padding: 2.31vh 0.78vw;
}

.flex-container > div {
  position: absolute;
  background-color: #f1f1f1;
  margin: 2.31vh 0.78vw 0px 0px;
  /* padding: 20px; */
  font-size: 30px;
}

.flex-container > div.chld {
  margin-bottom: 15px;
  border-radius: 10px;
  margin-right: 0.78vw;
  margin-left: 0.78vw;
}

.flex-container > .main {
  height: calc(100vh - 50px);
  width: 78.54vw;
  margin-top: 2.31vh;
  margin-right: 0px !important;
  border-radius: 10px;
}

.flex-container > .main div {
  margin-right: 0px !important;
}

.flex-container > div.chld {
  right: 0px;
  width: 18vw;
  height: 22.4vh;
}

.clear {
  clear: left;
}

/* * {
  box-sizing: border-box;
} */

.camerasContainer {
  background-color: #1f3a46;
  width: 100vw;
  height: 100vh;
}

/* overide remote av */

.remoteDrive .camerasContainer {
  width: 100%;
  height: 80vh;
}

/* .remoteDrive .child {
  width: 15.2vw;
  height: 15.02vh;
} */

.remoteDrive .mainCont {
  /* width: 60vw; */
  /* height: 81.3vh; */
}

.main {
  max-height: inherit !important;
}

.childCont {
  display: grid;
  gap: 15px;
}

.flex-container iframe video {
  max-width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  width: 100%;
  height: 100%;
}

.flex-container iframe,
.flex-container > div.chld iframe {
  border-radius: 10px;
  border: none;
  outline: none;
}

/* ================= camera views grid styles ================= */

.mainCont {
  grid-column: 1/3;
  grid-row: 1/5;
  /* background-color: #f00; */
}

.remoteDrive .mainCont {
  grid-column: 1/3;
  grid-row: 1/6;
  /* background-color: #f00; */
}

.responsive-iframe {
  /* position: absolute; */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

video {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  object-fit: fill;
}

.child {
  position: relative;
}

.child .iframe-title {
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}

.hideMap {
  display: none;
}

.showMap {
  display: block;
}

.mainCont h6 {
  font-size: 22px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  z-index: 1;
  margin-top: 15px;
  margin-left: 20px;
}

/* .videoGrid .mainCont {
  grid-row: 1/8;
} */

.videoGrid .layoutOne {
  grid-template-columns: 1fr 4fr 1fr;
}

.remoteDrive .remotcameraBlk {
  gap: 0px !important;
}

.child h6 {
  font-size: 12px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  z-index: 1;
  margin-top: 10px;
  margin-left: 10px;
}

.passCodePopup {
  top: 160px;
  left: 108px;
  position: absolute;
  width: 350px !important;
}

.passDel {
  position: absolute;
  right: 20px;
  bottom: 20px;
  border: none;
  outline: none;
  background: transparent;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 16px;
}

.listExpand .childAccordion2 {
  max-height: calc(100vh - 38vh) !important;
}

.noVideo {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #ccc;
  border-radius: 5px;
  z-index: 9;
}

.cam-head {
  padding: 8px 16px;
  background: #fff;
  margin-top: 10px;
  border: 1px solid #ccc2c2;
  font-family: Montserrat-Semibold;
}

.auto-drive-block {
  position: absolute;
  width: 100%;
  top: 68px;
  /* padding: 16px 0px; */
}

.auto-drive-block .operationsBlk {
  padding: 32px 0px 16px 0px;
  border-radius: 10px;
  background-color: #e7e7e7;
}

.drive-box-card {
  border: 1px solid #b7b0b0;
  padding: 10px;
}

.savedCommand .ant-input {
  padding-left: 5px !important;
}

/* .searchMapBlk input {
  font-family: 'Montserrat-Medium' !important;
  font-size: 10px !important;
} */

/* .searchMapBlk input::placeholder {
  color: #959595 !important;
  font-family: 'Montserrat-Medium' !important;
  font-size: 10px !important;
} */

.livemapTopBarTwo {
  /* z-index: 10 !important; */
  /* z-index: 99 !important; */
  height: 100px !important;
}

.radioOptions {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.livemapTopBarTwo .psaveBtn,
.livemapTopBarTwo .psaveBtn:hover,
.livemapTopBarTwo .psaveBtn:focus,
.livemapTopBarTwo .pcancelBtn,
.livemapTopBarTwo .pcancelBtn:hover,
.livemapTopBarTwo .pcancelBtn:focus {
  height: 35px !important;
}

.livemapTopBarTwo .drawBtnsSec {
  height: 100px !important;
}

.livemapTopBarTwo .drawBtnsSec .pBtnsRow {
  justify-content: center;
}

.radioOptions label {
  color: #fff;
  font-family: 'Montserrat-SemiBold';
}

.radioOptions
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #8a9a48 !important;
}

.radioOptions
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #8a9a48 !important;
  border-color: #8a9a48 !important;
  color: #fff !important;
}

.radioOptions .ant-radio-checked .ant-radio-inner {
  border-color: #8a9a48 !important;
}

.radioOptions .ant-radio-inner::after {
  background-color: #8a9a48 !important;
}

.posRel {
  position: relative;
}
.diagnosticModal .ant-modal-title {
  text-align: left !important;
}
.diagnosticsdesc {
  width: 100%;
  margin-left: 5px;
  overflow: hidden;
  font-family: 'Montserrat-Regular';
}
.video-download-btn {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.wVw {
  width: calc(100vw - 220px) !important;
}

.wVw .ant-modal-body {
  min-height: 500px;
  height: inherit;
}

.ant-tabs-tab.tractorTab {
  /* width: 100px; */
  height: 35px;
}

.ant-tabs-tab.tractorLockTab {
  width: 100px;
  height: 40px;
  display: block;
  border: 1px solid transparent;
  margin-left: 10px !important;
}


.tractorTab .ant-tabs-tab-btn {
  height: 100% !important;
  width: 100% !important;
  padding: 0 9px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tractorLockTab .ant-tabs-tab-btn {
  height: 100% !important;
  width: 100% !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fleetTabs .ant-tabs-nav-wrap,
.fleetTabs .ant-tabs-nav-list {
  width: 100%;
}

.ant-tabs-tab.tractorBSTab {
  height: 40px !important;
  display: block;
  border: 1px solid transparent;
  margin-left: 10px !important;
}
.tractorBSTab .ant-tabs-tab-btn {
  height: 100% !important;
  width: 100% !important;
  padding: 0 8px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flIcons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flIcons img {
  margin-right: 4px;
}




/* .operationsTitle .ant-collapse-header {
  align-items: center !important;
} */



.mpCloseIcon {
  background-image: url(../../assets/images/mpCloseIcon.svg);
  width: 11px;
  height: 11px;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 25px;
}

.mapRecenter img {
  width: 57px;
}

.aDriveProgress .ant-progress-circle-path {
  stroke: #ec9328 !important;
}
.aDriveProgress .ant-progress-circle-trail {
  stroke: #e7e7e7 !important;
}

.aDrivePSec {
  display: flex;
  width: 21px;
}
.aDrivePSec .ant-progress {
  top: inherit;
}

.wIcon50 {
  min-width: 50px;
}

.fleetlistBlk .fListSearchMain .ant-input,
.fListSearchMain .ant-input {
  width: 100%;
  height: 50px;
  border-radius: 10px !important;
  padding-left: 45px !important;
  backdrop-filter: blur(1px) brightness(54%) grayscale(0%);
  -webkit-backdrop-filter: blur(1px) brightness(54%) grayscale(0%);
  background: none !important;
  border: none !important;
}
.fleetlistBlk .fListSearchMain .ant-input::placeholder,
.fListSearchMain .ant-input::placeholder {
  font-size: 14px;
  color: #cececf;
}
.fleetlistBlk .fListSearchMain .anticon-search {
  position: absolute;
  left: 15px;
  z-index: 1;
  top: 36%;
  color: #fff;
  width: 16px;
  height: 16px;
}

#loaderborder {
  border: 1px solid #8595ca;
  border-radius: 10px;
}

@media (min-width: 768px) and (max-width: 1800px) {
  /* .fleetlistBlk .fListSearchMain .ant-input,
  .fListSearchMain .ant-input, */
  /* .operationsTitle .ant-collapse-header {
    height: 40px !important;
  } */
  /* .fleetlistBlk {
    left: 25px !important;
    top: 25px !important;
  } */
  /* .mapLayerIcon,
  .mapLayerIcon:hover {
    bottom: 10px !important;
    left: 20px !important;
  } */
  /* .bottomMapLayers {
    bottom: 15px !important;
    left: 25px !important;
  } */
  /* .fleetTabs .ant-tabs-nav {
    margin-top: 10px !important;
    margin-bottom: 8px !important;
  } */
  /* .parentAccordion .fleetBlk {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  } */

  /* .dmenHandle .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 405px) !important;
  } */
  /* .dmenHandle .childAccordion2 {
    max-height: calc(100vh - 405px) !important;
  } */

  .dmenHandle .list.fleetOverallBlk {
    max-height: calc(100vh - 435px);
  }
}

.mb18 {
  margin-bottom: 18px !important;
}

.parentAccordion .ant-tabs-ink-bar {
  background: none !important;
}

.list.fleetOverallBlk {
  max-height: calc(100vh - 315px);
}

@media (min-width: 768px) and (max-width: 1439px) {
  .searchMapBlk {
    margin-bottom: 10px !important;
  }
  .auto-drive-block {
    top: 52px;
  }
  .dmenHandle .list.fleetOverallBlk {
    max-height: calc(100vh - 425px);
  }
}

.parentIcon .ant-collapse-header{
   cursor: default !important;
}

.parentIcon .anticon{
  height: 32px;
  width: 32px;
  padding-top: 10px;
}

.autodriveButton:hover .routeIcon{
  display: none;
}

.autodriveButton:hover .routeIcon2{
  display: block;
}

video#remotevideov,
video.rounded.centered {
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100% !important;
  object-fit: fill;
}

video {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  object-fit: fill;
}

.talertPopup {
  top: 0;
  padding-bottom: 0px;
}

.talertPopup .ant-modal-content {
  height: calc(100vh - 125px);
}

.termspopup.talertPopup .ant-modal-title {
  font-size: 24px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.25px;
  line-height: 29px;
}

.talertPopup .tblDft.farmTabsTbl.dsTbl {
  height: calc(100vh - 260px);
  max-height: calc(100vh - 260px);
}
.talertPopup .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
  max-height: calc(100vh -285px);
}
.talertPopup.tblDft div#empTbl {
  min-height: calc(100vh - 285px);
}
