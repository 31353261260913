.hm_listitems{
  height: 100vh;
}
.left-section{
  width: 15vw;
  padding-right: 0;
}
.right-section{
  width: calc(100vw - 15vw);
  /* padding-left: 16px; */
}
.height-overflow {
  height: 100vh;
  overflow-y: scroll;
}
.section-container{
  display: flex;
}
.section-container .ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title{
  width: calc(100% - 0px) !important;
}
.right-section .quill {
  height: 100vh !important;
  width: calc(100vw - 18vw) !important;
}
.how-to-manual .react-pdf__Page__canvas{
  margin: auto !important;
}
.how-to-manual .ant-card-head{
  border-bottom: 2px solid #f0f0f0 !important;
}
.how-to-manual .topic-selected{
  background: #1e3a46 !important;
  color: #FFF !important;
}
.how-to-manual .ant-menu-item:not(.topic-selected):hover{
  color: #000 !important;
}