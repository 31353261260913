.imgStyles {
  background-color: black;
}
.editBtn {
  width: 70px;
  height: 35px;
  border: none;
  border-radius: 5px;
  background-color: #eb921f;
  color: #fff;
}
.orgDetailsStyles {
  /* width: 100%;
    height: 250px;
    margin: 5px; */
  font-family: Montserrat-SemiBold;
  /* border: 0.01px solid #cccddc;
    border-radius: 5px 5px 5px 5px; */
}
.container {
  display: flex;
}
.col1 {
  width: 250px;
}
.col2 {
  width: 750px;
}
.col2-styles {
  display: flex;
  margin-bottom: 8px;
}
.orgDetails {
  width: 150px;
  display: flex;
  /* font-size: 12px;
    font-family: Montserrat-ExtraLight; */
}
.orgData {
  width: 650px;
  font-size: 12px;
  font-family: Montserrat-Medium;
}
.editBtn {
  width: 70px;
  height: 35px;
  border: none;
  border-radius: 5px;
  background-color: #eb921f;
  color: #fff;
}
.employeeCreate {
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #1e3a46 !important;
  color: #fff;
  font-size: 14px;
  padding: 4px 15px;
}
.dbWidget .ant-btn-primary:hover,
.dbWidget .ant-btn-primary:focus {
  height: 40px;
  border: none;
  border-radius: 10px !important;
  background: #1e3a46 !important;
  color: #fff;
  font-size: 14px;
  padding: 4px 15px;
}
/* .imgStyles {
  width: 100px;
  height: 100px;
  margin-top: -10px;
  margin-bottom: 18px;
} */

.modalContainer .ant-modal-footer {
  padding: 18px 16px;
}
.modalContainer .ant-modal-body {
  text-align: center;
}
.modalContainer .ant-modal-content {
  min-width: 700px;
  border: 0;
  font-family: Montserrat-SemiBold;
}
/* .btnSave {
  width: 100px;
  height: 32px;
  background-color: #eb921f;
  font-size: 14px;
  color: #fff;
  border-radius: 2px;
} */
/* .btnCancel {
  width: 100px;
  height: 32px;
  font-size: 14px;
  color: #545454;
  border-radius: 5px;
  border-radius: 2px;
} */
.modalBox {
  display: flex;
}
.uploadImg {
  margin-right: 40px;
  position: relative;
}
.uploadTxt {
  margin-right: 115px;
  margin-bottom: 15px;
  font-family: Montserrat-Medium;
}
.btnUpload {
  width: 200px;
  height: 36px;
  background-color: #f7f7f7;
  margin-top: -23px;
  margin-right: -195px;
  border-radius: 2px;
  border: 1px solid #d6d6d6;
}
.btnUpload:hover,
.btnUpload:focus {
  color: #eb921f !important;
  border-color: #eb921f !important;
}
.ant-upload {
  width: 200px !important;
  height: 36px !important;
  margin-top: 10px !important;
  margin-left: -32px !important;
}
.btnRemove {
  margin-right: 40px;
  font-family: Montserrat-Medium;
  color: #ff5252;
}
.modalContainer .ant-tooltip-inner {
  display: none;
}
.modalContainer .ant-upload.ant-upload-select {
  display: flex;
}
.modalContainer .ant-upload.ant-upload-select-picture-card {
  border: none;
  background-color: transparent;
}
.modalContainer .ant-upload-list-picture-card .ant-upload-list-item {
  right: 15px;
}

.ackText {
  color: #00000070;
  margin-right: 10px;
}

.levelTxt {
  background-color: gray;
  color: #ffffff;
}

/* .attContainer .ant-tabs-ink-bar {
  border-bottom: 2px solid #f17e03;
}

.attContainer .ant-tabs-tab-btn {
  color: #212121bd !important;
  font-size: 15px !important;
} */

.attContainer .ant-tabs-tab-disabled .ant-tabs-tab-btn {
  color: #cececf !important;
  cursor: initial;
}

.custom-loader-profile {
  padding-top: 54px;
  position: fixed;
}

.attContainer .mainContent {
  padding: 0;
}

.attContainer .attTableHeight {
  /* height: calc(100vh - 180px)!important; */
  overflow-y: auto;
  /* height: calc(100vh - 130px)!important; */
}

/* .attContainer .infinite-scroll-component {
  height: auto !important;
  max-height: calc(100vh - 335px);
} */

.jmgoContainer,
.mBottom0 .dbWidget {
  margin-bottom: 0 !important;
}

.jmgoContainer .infinite-scroll-component {
  height: auto !important;
  /* max-height: calc(100vh - 285px); */
  max-height: calc(100vh - 233px);
}

.jumpgoheight .infinite-scroll-component {
  max-height: calc(100vh - 500px);
}

.tblScl2 {
  overflow-x: auto !important;
}

.attContainer .nfo {
  max-height: calc(100vh - 335px);
}
.notificationheight .nfo {
  max-height: calc(100vh - 500px) !important;
}
.notificationheight .dbWidget {
  margin-bottom: 0 !important;
}

.jumpgoheight .dbWidget {
  margin-bottom: 0 !important;
}

.notificationheight .infinite-scroll-component {
  max-height: calc(100vh - 500px) !important;
}

.attContainer .pb-15 {
  padding: 0 !important;
}

/* .attContainer .loader {
  display: none;
} */

.orgData .ant-input {
  border: 1px solid #fff;
  background-color: #fff !important;
  max-width: 130px;
  padding: 0;
}
.orgData button {
  border: none;
  background: #fff;
  font-size: 16px;
}
.vuLoad button {
  background-color: rgba(0, 0, 0, 0.25);
  min-width: 44px !important;
  height: 22px !important;
  line-height: 22px;
}

.vuLoad .ant-switch-handle {
  position: absolute;
  top: 2px;
}
.vuLoad .ant-switch-handle::before {
  position: absolute;
  right: 0px;
  left: 0px;
}
.vuLoad .ant-switch-checked .ant-switch-handle::before {
  background-color: #fff;
}
.vuLoad .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
.vuLoad .ant-switch-checked {
  background-color: #eb921f;
  height: 14px;
}

.profileSec {
  margin: 25px 0;
}

.profileSec .orgDetails {
  color: #ababab;
  min-width: 170px;
}

.df_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mtrb {
  margin: 20px;
}

.modal-col1 .ant-upload-list-picture-card-container {
  display: block;
  margin: 0px auto 32px;
}

.profCont {
  width: 94%;
  margin: 0 auto !important;
}

.profileUpdatePopup {
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}
.profileUpdatePopup .ant-modal {
  padding-bottom: 0;
}

.profileUpdatePopup .formRow .formLabel {
  font-family: Montserrat-SemiBold !important;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #000;
  line-height: 24px;
  text-align: left;
}
.flexnone {
  display: inherit !important;
}
.proUploadButton {
  display: block;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
}
.proUploadButton button {
  text-align: center;
  margin: 0 auto;
  border-radius: 5px;
  width: inherit;
  height: 40px;
}
.modalContainer .ant-upload.ant-upload-select {
  display: flex;
  justify-content: center;
}

.profileUpdatePopup .formCol {
  text-align: left;
}
.profileUpdatePopup .formRow .formCol:first-child {
  text-align: left;
}
.profileUpdatePopup .ant-input:focus,
.profileUpdatePopup .ant-input:hover {
  border: 1px solid #366376 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}
.profileUpdatePopup .ant-modal-content,
.profileUpdatePopup .ant-modal-header {
  border-radius: 15px;
}
.profileUpdatePopup .btnCancel {
  height: 40px;
  width: 100px;
  border-radius: 5px;
  align-items: center;
  justify-self: center;
  background-color: #b6b6b7;
  margin-right: 5px;
  outline: none;
  border: none;
  color: #000;
  font-size: 14px;
  font-family: Montserrat-Medium;
}
.profileUpdatePopup .btnSave {
  height: 40px;
  width: 100px;
  border-radius: 5px;
  align-items: center;
  justify-self: center;
  background-color: #8a9a48;
  margin-left: 0 !important;
  outline: none;
  border: none;
  color: #fff;
  font-size: 14px;
  font-family: Montserrat-Medium;
  text-transform: uppercase;
}

.profileUpdatePopup {
  position: relative;
}
.profileUpdatePopup .custom-loader-profile {
  position: absolute;
  top: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 48%;
  padding-top: 0px;
}

/* profile styles goes here */

.w30Per {
  width: 400px;
  flex: 0 0 400px;
}
.w50Per {
  width: 50%;
}
.w60Per {
  width: 60%;
}
.w70Per {
  width: 70%;
}
.w80Per {
  width: 80%;
}
.w100Per {
  width: 100%;
}
.profileWrapper .dbBox {
  border: 1px solid #e7e7e7;
  /* margin-top: 5px; */
  box-shadow: none !important;
  border-radius: 10px;
  width: 100%;
}
.anaDashboardCont {
  /* padding: 20px; */
  display: block;
  width: 94%;
  margin: 0 auto;
}
.w100per {
  width: 100% !important;
}
.boxHead {
  font-size: 24px;
  font-family: Montserrat-SemiBold;
  border-bottom: 1px solid #e7e7e7;
  padding: 0px 20px 20px 20px;
  text-align: center;
}

.dbRow {
  display: flex;
}
.jcEnd {
  justify-content: flex-end;
}
.jcCenter {
  justify-content: center;
}
.jcBetween {
  justify-content: space-between;
}
.jcEvenly {
  justify-content: space-evenly;
}
.aiCenter {
  align-items: center;
}

.leftLbl {
  display: inline-block;
  min-width: 155px;
  margin-right: 20px;
  color: #ababab;
}
.rightLbl {
  display: inline-block;
  min-width: 120px;
  margin-right: 20px;
  color: #000;
}

.pad30 {
  padding: 30px;
}

.pad20 {
  padding: 20px;
}
.pad5 {
  padding: 5px;
}
.pad8 {
  padding: 8px;
}
.pad10 {
  padding: 10px;
}

.pwdEye button,
.pwdEye button:hover,
.pwdEye button:focus,
.pwdEye button:focus-visible {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
}
.mr10 {
  margin-right: 10px;
}
.w160 {
  min-width: 160px !important;
}
.wBg {
  background: #fff;
  height: 100vh;
}
.notificationEdit {
  height: calc(100vh - 24vh) !important;
  padding: 20px 0 0px 0 !important;
}
.profCont .infinite-scroll-component {
  height: calc(100vh - 30vh);
  max-height: calc(100vh - 30vh);
}

.profileWrapper .notificationEdit .infinite-scroll-component {
  height: calc(100vh - 30vh) !important;
  max-height: calc(100vh - 30vh) !important;
}
.profileWrapper .profCont.pad0 {
  padding: 0px !important;
  margin: 0px !important;
  width: 100%;
}
.profileWrapper button.employeeCreate {
  font-size: 14px !important;
  font-family: 'Montserrat-SemiBold';
}

.profileWrapper {
  gap: 15px;
  margin-bottom: 15px;
}

.profileUpdatePopup .ant-modal-content {
  border-radius: 15px !important;
}
.profileUpdatePopup .ant-modal-header {
  border-radius: 15px 15px 0 0 !important;
}

.tcktsBlk .ant-space-item:last-child > .dropdownStyle {
  margin-right: 0px !important;
}
.mr0 {
  margin-right: 0px !important;
}
.tcktsBlk .ant-space-item {
  margin-bottom: 0 !important;
}

.newChangeStyle .ant-input:focus,
.newChangeStyle .ant-input:hover {
  border: none !important;
}
.newChangeStyle .ant-modal-close {
  display: none;
}

.change-ps {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efefef;
  border-radius: 0 0 16px 16px;
}

.change-ps h3 {
  /* font-family: 'Montserrat-SemiBold'; */
  text-transform: uppercase;
  color: #266378;
  /* letter-spacing: 1.52px; */
  text-align: center;
  cursor: pointer;
  /* font-size: 12px; */
}

.change-ps h3:hover {
  text-transform: uppercase;
  color: #4ab2b9;
  text-decoration: underline;
}

.profile-head {
  /* font-size: 1.45vw;
  line-height: 34px; */
  color: #000000;
  /* letter-spacing: 0.21px;
  font-family: 'Montserrat-SemiBold'; */
  margin-bottom: 20px;
}

.newChangeStyle .ant-modal-content {
  min-width: 500px;
  border: 0;
  font-family: Montserrat-SemiBold;
}

.profileEditImg {
  position: absolute;
  left: 55%;
  cursor: pointer;
  background: white;
  bottom: 60px !important;
  margin-left: 24px;
  border-radius: 50px;
  padding: 2px;
}

.profileContacts {
  margin-bottom: 32px;
}

.profileContacts h1 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000;
  font-family: 'Montserrat-SemiBold';
  margin-bottom: 12px;
}

.profileContacts h3 {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.56px;
  color: #000000;
  font-family: 'Montserrat-Regular';
  margin-bottom: 12px;
  padding-left: 30px;
  word-break: break-all;
}

.changeBtnCancel {
  width: 136px;
  height: 34px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 6px #00000029;
  color: #366376 !important;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
  margin-right: 20px;
  outline: none;
  text-transform: uppercase;
}

.changeBtnSave {
  width: 136px;
  height: 34px;
  background-color: #366376;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 6px #00000029;
  color: #fff;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  align-items: center;
  justify-self: center;
  letter-spacing: 1.25px;
  line-height: 18px;
  margin-left: 0 !important;
  outline: none;
  text-transform: uppercase;
}

.changeBtnSave.ant-btn[disabled] {
  background-color: #d9d9d9 !important;
  color: #fff;
}

.changeBtnSave:focus,
.changeBtnSave:hover,
.changeBtnSave:active {
  background-color: #366376;
  color: #fff;
}

.profile-avatar {
  position: relative;
}

.fieldIconBlk {
  position: relative;
}
.fieldIconBlk .fieldeye {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.fieldeye .anticon-eye-invisible,
.fieldeye .anticon-eye-visible {
  font-size: 14px !important;
  cursor: pointer;
}

.newChangeStyle .fieldIconBlk .ant-input:hover {
  border-radius: 10px !important;
  border: 1px solid #366376 !important;
}

.my-profile-container {
  width: 88%;
  margin: 0 40px 0 auto !important;
}
/* .my-profile {
  padding-bottom: 25px;
} */
/* .my-profile .employee-profile {
  max-width: 480px;
} */
.my-profile .permission-sub {
  max-height: calc(100vh - 530px);
}
.my-profile .employee-team-sub {
  max-height: 115px;
  min-height: 115px;
  overflow-y: auto;
}
.my-profile .profileContacts h1 {
  font-size: 0.9375vw;
}
.my-profile .profileContacts h3 {
  font-size: 0.8333vw;
}
.my-profile .profileContacts {
  margin-bottom: 20px;
}

@media (min-width: 992px) and (max-width: 1440px) {
  .my-profile .profileContacts h1 {
    font-size: 16px;
  }
  .my-profile .profileContacts h3 {
    font-size: 14px;
  }
}
.profile-image-upload .ant-upload.ant-upload-select-picture-card {
  width: unset !important;
  height: unset !important;
  border: none !important;
  margin: 0 !important;
}

.profile-image-upload .ant-upload {
  width: unset !important;
  height: unset !important;
  margin-top: 0 !important;
}

.profile-image-upload .profileEditImg {
  bottom: 50px;
}

.profile-image-upload .ant-upload-list-text-container {
  display: none;
}

.profile-name {
  margin-top: -20px;
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .profile-image-upload .profileEditImg {
    bottom: 45px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .profile-image-upload .profileEditImg {
    bottom: 42px;
  }
}
.showPwdIcon {
  cursor: pointer;
}
.showPwdIcon .anticon-eye path:first-child {
  fill: transparent !important;
}
.showPwdIcon .anticon-eye path:nth-child(2) {
  fill: transparent !important;
}
.showPwdIcon .anticon-eye path {
  fill: #366376 !important;
  -webkit-fill: #366376 !important;
}
.wordBreak {
  word-break:break-word;
}

.userProfileBlk .loader {
  height: inherit !important;
}