/* .teamarrow {
  color: #366376;
  border-radius: 9px;
  box-shadow: 0 0 6px rgb(0 0 0 / 16%);
  padding: 8px;
  cursor: pointer;
  width: 30px;
  height: 36px;
  margin-right: 15px;
  margin-top: 5px;
} */

.mr20 {
  margin-right: 20px;
}

.teamTitle {
  font-size: 24px;
  color: #000;
  line-height: 29px;
}

.teambtn button {
  background-color: #366376;
  border-radius: 20px;
  box-shadow: 0 0 6px #00000029;
  color: #fff;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  height: auto;
  letter-spacing: 1.25px;
  line-height: 18px;
  margin-right: 0;
  padding: 15px 28px;
  text-transform: uppercase;
}
.teambtn button:hover {
  background-color: #366376 !important;
  color: #fff !important;
}
.teambtn button:focus {
  background-color: #366376 !important;
  color: #fff !important;
}

.teamDesc {
  color: #000;
  /* font-size: 16px;
  font-family: 'Montserrat-Regular';
  line-height: 22px;
  letter-spacing: 0.25px; */
  margin: 0;
}
.teamdashboard {
  display: inline-block;
  width: 100%;
}
.teamsContainer {
  width: 90.5%;
}
.teamsBlk {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-right: 9vw !important;
  width: calc(100% - (0px + 8.5vw));
}
.teamsTitle {
  display: flex;
}
.teamsTitle .teamTitle {
  width: 100% !important;
}
.teamsTitle .teamTitle h4 {
  /* font-size: 1.25vw;
  font-family: 'Montserrat-Medium';
  line-height: 29px; */
  color: #000000;
}

.teamlist {
  /* padding: 20px 0 0px 0 !important; */
}

.teamsgrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(447px, 1fr));
  gap: 30px 20px;
  /* width: 93%; */
  margin-left: auto;
  margin-bottom: 0px;
  margin-top: 10px;
}
.teamsgridbox:last-child {
  margin-bottom: 30px;
}

.teamsgridbox {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  cursor: pointer;
  padding: 20px 32px;
  border: 1px solid #fff;
  position: relative;
  min-height: 180px;
  max-height: 180px;
  overflow-y: auto;
}
.teamsgridbox h4 {
  /* font-size: 1.25vw; 
  font-family: 'Montserrat-Medium';
  line-height: 1.35em; */
  color: #000;
  width: 69%;
  float: left;
  margin-right: 15px;
  word-break: break-word !important;
  text-transform: capitalize;
}
.teamsgridbox p {
  color: #5c5c5c;
  /* font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 22px; */
  margin-top: 15px;
  clear: both;
}

/* .teamsgridbox span {
  color: #000000;
  font-size: 20px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.15px;
  line-height: 24px;
} */

.teamsgridbox .team-users {
  color: #000000;
  font-size: 20px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-top: 5px;
}

.teamsgrid .teamsgridbox:hover {
  border: 1px solid #67afb7;
  transition: 0.5s;
}
.teamsgrid .teamsgridbox:hover h4 {
  color: #366376;
}
.teamsgrid .teamsgridbox:hover h4 span {
  color: #366376;
}

/* modal styles */
.teammodal .ant-modal-footer {
  border-top: 0;
  text-align: center;
  padding: 0px 16px !important;
}

.teammodal .ant-modal-content {
  min-width: 590px !important;
  border-radius: 15px;
  padding: 56px 64px;
}

.mLockout .ant-modal-content {
  min-width: 450px !important;
  border-radius: 15px;
  padding: 30px !important;
}

.teammodal .ant-modal-body {
  padding: 0 !important;
}

.createteamContainer .title {
  text-align: left;
  font-size: 24px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  line-height: 29px;
  margin-bottom: 0px;
}
.createteamContainer .ant-input,
.createteamContainer .team-text-area-2 .ant-input,
.teammodal .createteamContainer .ant-select-multiple .ant-select-selector {
  border: 1px solid #b6b6b7 !important;
}
.createteamContainer .ant-input:hover,
.createteamContainer .ant-input:focus,
.teammodal .createteamContainer .ant-select-multiple .ant-select-selector:hover,
.teammodal
  .createteamContainer
  .ant-select-multiple
  .ant-select-selector:focus {
  border: 1px solid #366376 !important;
}
.createteamContainer .dropdownStyle {
  border: 0 !important;
  box-shadow: none !important;
}

.createtitle {
  display: inline-block;
  width: 100%;
}

.teamdescription {
  font-size: 16px;
  color: #000000;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.25px;
  text-align: left;
  margin-bottom: 32px;
  white-space: initial;
}

.teamdescription .teambold {
  font-weight: bold;
}

.team-input {
  border: #b6b6b7 1px solid !important;
  border-radius: 10px;
  height: 49px;
  margin-bottom: 24px;
}

.team-input:last-child {
  margin-bottom: 48px;
}

.btnteamCancel {
  font-family: 'Montserrat-SemiBold';
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  padding: 8px 35px;
  color: #366376 !important;
  text-transform: uppercase;
  margin-right: 20px;
  height: 34px;
  background: #fff !important;
  border: 1px solid #fff !important;
  width: 136px;
  letter-spacing: 0.76px;
}
.btnteamCancel:focus {
  background: #fff !important;
  color: #366376 !important;
  border: 1px solid #fff !important;
}

.btnteamSave {
  font-family: 'Montserrat-SemiBold';
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  background: #366376 ;
  color: #fff ;
  height: 34px;
  padding: 8px 35px;
  width: 136px;
  letter-spacing: 0.86px;
  text-transform: uppercase;
  margin-left: 0px !important;
}

.btnteamSave:focus {
  background: #366376 !important;
  color: #fff !important;
  border: 1px solid #b6b6b7 !important;
}
.btnteamSave-1 {
  font-family: 'Montserrat-SemiBold';
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  background: #366376 !important;
  color: #fff !important;
  height: 34px;
  padding: 8px 35px;
  width: 136px;
  letter-spacing: 0.86px;
  text-transform: uppercase;
  margin-left: 0px !important;
}

.btnteamSave-1:focus {
  background: #366376 !important;
  color: #fff !important;
  border: 1px solid #b6b6b7 !important;
}

.btnteamSave:hover {
  background: #366376 !important;
  color: #fff !important;
  border: 1px solid #b6b6b7 !important;
}

.team-text-area-2 .ant-input {
  border: #b6b6b7 1px solid !important;
  border-radius: 10px;
  height: 81px !important;
  padding: 15px 15px 15px 7px !important;
}
.team-select {
  width: 100% !important;
  margin-bottom: 0px;
  min-height: 45px !important;
  margin-top: 0px;
}
.dropdownStyle {
  border: 1px solid #b6b6b7 !important;
  border-radius: 10px;
}
.dropdownStyle.dW250_date,
.dropdownStyle.dW250 {
  border: none !important;
}
.team-select .ant-select-selector {
  height: 100% !important;
  border-radius: 10px !important;
  padding-top: 0 !important;
}
.dropdownStyle.team-select .ant-select-selection-item,
.dropdownStyle.team-select .ant-select-selection-placeholder {
  padding: 0 5px !important;
}
.team-select .ant-select-selection-placeholder {
  color: #bfbfbf !important;
  text-align: left !important;
}
.team-checkbox {
  color: #3e3e3e !important;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.11px;
  line-height: 15px;
  margin-bottom: 48px;
  text-align: left;
  width: 100%;
}

.team-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #366376 !important;
  border-top: 0 !important;
  border-left: 0 !important;
}
.team-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff !important;
}

.teammodal .ant-select-multiple .ant-select-selector {
  border: #b6b6b7 1px solid !important;
  border-radius: 10px;
  height: 49px !important;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.teammodal .ant-select-multiple .ant-select-selection-placeholder {
  right: unset !important;
}

.teammodal .ant-select-selection-item-content .emailAlign .ant-avatar {
  display: none;
}

.teammodal .ant-select-item-option {
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 22px;
  color: #000000 !important;
}

.teammodal .optionname {
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 15px;
  color: #000000 !important;
  /* background: #e7e7e7;
  border-radius: 4px; */
}

.teammodal .ant-select-multiple .ant-select-selection-item {
  background: #e7e7e7;
  border-radius: 4px;
}

.teammodal .ant-select-item-option-selected {
  font-weight: 400;
  background: #f7f7f7 !important;
}

/* these styles need be checked as it will globally effect */
.ant-select-item {
  padding: 15px 25px !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #000000;
  font-weight: 400;
  background-color: #f7f7f7;
  letter-spacing: 0.25px;
  font-size: 14px;
  line-height: 22px;
  /* margin-bottom: 2px; */
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #366376 !important;
}

/*till this one */
.teammodal .ant-input-textarea-show-count::after {
  font-family: 'Montserrat-Medium';
  font-size: 10px;
  color: #5c5c5c;
  line-height: 18px;
}

/* .teammodal .ant-input:placeholder-shown {
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  color: #5c5c5c;
  line-height: 22px;
} */
.teammodal .ant-input-textarea {
  margin-bottom: 24px;
}

/* .teammodal .ant-select-multiple .ant-select-selection-placeholder {
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  color: #5c5c5c;
  line-height: 18px;
} */

.teammodal input::placeholder {
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  color: #5c5c5c;
  line-height: 22px;
}

.teammodal textarea::placeholder {
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  color: #5c5c5c;
  line-height: 22px;
}

.teammodal .ant-select-selection-placeholder {
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  color: #5c5c5c;
  line-height: 22px;
}

.teammodal input:focus {
  border: 1px solid #366376;
}

.teammodal textarea {
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  color: #000000;
  line-height: 22px;
}

.input-text {
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  color: #000000;
  line-height: 22px;
}

.permissionFlex {
  display: flex;
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  /*need to confirm */
  /* height: calc(100vh - 280px) !important; */
  /* min-width: calc(100vw - 18%);
  width: 97%; */
  /* min-height: 451px; */
  height: calc(100vh - 33vh) !important;
  /* margin-left: auto; */
  width: calc(100% - 3.64vw);
  margin-left: 3.64vw;
}
.employeesTeams {
  min-width: 375px;
  border-right: 1px solid #e7e7e7;
  position: relative;
}
.employeesTeams h4 {
  border-bottom: 1px solid #e7e7e7;
  padding: 12px;
  /* font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.71px;
  line-height: 19px; */
  color: #000000;
  padding-left: 24px;
  padding-top: 24px;
  padding-right: 24px;
}
.employeesTeams ul {
  list-style: none;
  padding-left: 0px;
  overflow-y: auto;
  height: calc(100vh - 46vh);
}
.permissionTeam {
  width: 100%;
}
.permissionTeam h4 {
  border-bottom: 1px solid #e7e7e7;
  padding: 10px;
  /* font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.71px;
  line-height: 19px; */
  color: #000000;
  padding-left: 24px;
  padding-top: 24px;
}
.teamschildrow td:first-child {
  padding-left: 35px !important;
}
/* .permissionTeam tr td {
  font-size: 18px;
  letter-spacing: 0.56px;
  font-family: 'Montserrat-Regular';
  line-height: 26px;
} */
.permissionTeam tr td:first-child {
  /* width: 350px; */
  min-width: 435px;
  width: 26vw;
  /* font-size: 16px;
  letter-spacing: 0.25px;
  font-family: 'Montserrat-Regular';
  line-height: 22px; */
}
.employeePermission {
  background-color: #f7f7f7;
  border-radius: 0 0 0 14px;
  color: #366376;
  cursor: pointer;
  /* font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px; */
  padding: 19px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.employeePermission:hover {
  color: #67afb7;
}
.employeePermission2 {
  /* height: 225px; */
  position: absolute;
  background: #fff;
  border: 1px solid #366376;
  padding: 0;
}
.employeePermission2 ul {
  min-height: 175px;
  height: 27.4vh !important;
  /* max-height: 120px; */
  text-align: left;
}
.employeePermission2 ul li {
  text-transform: capitalize;
}

.employeePermission2 ul li:last-child {
  margin-bottom: 30px;
}
.employeePermission2 .addEmployeeTeam {
  padding: 10px;
  color: #366376;
  border-bottom: 1px solid #e7e7e7;
}
.employeePermission2 .addEmployeeTeam:hover {
  color: #67afb7;
}
/* .employeePermission2 .searchicon {
  position: absolute;
  bottom: 0;
  padding: 0px;
} */
.employeePermission2 .searchicon .ant-input-search {
  width: 100% !important;
  height: 45px !important;
}
.permissionsTeamTbl {
  padding: 0 24px 12px 24px;
  overflow-y: auto;
  min-height: 375px;
  height: calc(100vh - 44vh);
}
.exclamationicon {
  position: absolute;
  bottom: 0;
  right: 0;
  background: url('../../assets/images/team-shapebg2.svg') no-repeat;
  border-radius: 0 0 10px 0;
  color: #fff !important;
  padding: 5px;
  width: 50px;
  height: 50px;
}
.ellipsiIcon {
  font-size: 24px;
  color: #366376;
  box-shadow: 0 0 6px #00000029;
  padding: 0 8px;
  border-radius: 10px;
}
.ellipsiIcon svg {
  min-height: 5.5vh;
}
.permissionTitle {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 30px; */
}
.subPermissionTitle {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  width: auto;
  /* padding-left: 52px; */
  margin-left: 3.64vw;
}
.subPermissionTitle h4 {
  /* font-size: 1.45vw; 
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.21px;
  line-height: 3.14vh; */
  color: #000000;
  margin-bottom: 8px;
  text-transform: capitalize;
  /* margin-top: 3px !important; */
}
.searchPermissions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.teambtn .searchSec .anticon-search {
  font-size: 10px !important;
  padding-left: 5px !important;
}

.teambtn .searchSec button:hover,
.teambtn .searchSec button:focus {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.45) !important;
}

.prmsrch .searchSec .anticon-search {
  font-size: 12px !important;
  padding-top: 7px !important;
}

.teambtn .searchSec .ant-input-search {
  margin-left: 15px;
}

.teambtn .anticon-search {
  margin-left: -5px !important;
}

.employeePermission2 .searchSec .ant-input-search {
  border-radius: 0px 0px 15px 15px !important;
}
.searchPermissions .searchSec .ant-input-search {
  min-width: 250px;
}
.listemployees {
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 22px;
  color: #000000;
  font-family: 'Montserrat-Regular';
  padding: 12px 24px;
  position: relative;
  word-break: break-all;
}

.searchicon {
  width: 100%;
}

/* .employee-details-main .permission-sub {
  min-height: 300px;
  height: calc(100vh - 700px);
} */

.permissions-title h1 {
  /* font-size: 1.45vw; 
  line-height: 34px;
  letter-spacing: 0.21px;
  font-family: 'Montserrat-SemiBold'; */
  margin-bottom: 8px;
  color: #000000;
}
.permissions-title span {
  color: #000000;
  /* font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.25px;
  font-family: 'Montserrat-Regular'; */
}

.employee-team-sub {
  /* overflow-y: auto;
  min-height: 100px;
  max-height: 210px; */
  padding-left: 40px;
  padding-right: 40px;
}
.empoyee-team {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}

.empoyee-team h1 {
  /* font-size: 1.04vw; 
  line-height: 24px;
  letter-spacing: 0.15px;
  font-family: 'Montserrat-SemiBold'; */
  margin-bottom: 4px;
  color: #000000;
}

.employee-team span {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.44px;
  color: #474747;
  font-family: 'Montserrat-Regular';
  padding-left: 10px;
}

.empoyee-team:last-child {
  margin-bottom: 0px;
}

.operation-permissions .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #366376 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  width: 7px !important;
  height: 10px !important;
}
.operation-permissions .ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent !important;
  border: 0 !important;
}

.empEllip {
  font-size: 26px;
  color: #366376;
}

.operation-title h2 {
  /* min-width: 325px; */
  min-width: 365px;
  width: 26vw;
  color: #000000;
  /* font-size: 18px;
  line-height: 26px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.56px; */
  margin: 0 !important;
}

.operation-features {
  /* margin-left: 45px; */
  margin-left: 2.34vw;
}
.operation-features .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #366376 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  width: 7px !important;
  height: 10px !important;
}
.operation-features .ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent !important;
  border: 0 !important;
}

.operation-feature {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.operation-feature h3 {
  color: #000000;
  /* font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.25px;
  font-family: 'Montserrat-Regular'; */
  /* width: calc(65% - 45px); */
  width: 23.65vw;
  min-width: 340px;
  margin: 0 !important;
}

.feature-waring {
  color: #fbb313;
  width: 22px;
  height: 17px;
}

.opeation-warning {
  display: flex;
  padding-left: 20px;
  align-items: center;
}
.opeation-warning h5 {
  font-size: 12px;
  letter-spacing: 1.29px;
  line-height: 15px;
  color: #366376;
  font-family: 'Montserrat-SemiBold';
  margin-left: 7px;
}

.employee-team-title {
  width: 97%;
}
.ant-select-selection-overflow {
  color: black;
}
.seeteamBtn {
  cursor: pointer;
  /* font-size: 12px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.11px;
  line-height: 15px; */
  color: #366376;
}
.seeteamBtn:hover {
  color: #67afb7;
}
.proRoleTypeHead_details {
  text-transform: capitalize;
}
.teamsView .ant-select-selector .ant-select-selection-item {
  height: 27px !important;
  padding: 8px !important;
}
.mt10 {
  margin-top: 10px;
}
.teamsView .anticon-close {
  color: #fff !important;
}
.duplicateTeamModal .ant-modal-footer {
  margin-top: 30px;
}
.duplicateTeamModal .team-checkbox {
  color: #3e3e3e !important;
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  float: left;
  letter-spacing: 0.11px;
  line-height: 15px;
  margin-right: 0;
  align-items: center;
}
.duplicateTeamModal .ant-checkbox + span {
  margin-top: 7px;
}
.teambtn {
  display: flex;
  align-items: center;
  gap: 10px;
}
/* .searchSec .ant-input-search {
  min-width: 200px !important;
} */
.teambtn .ant-btn-icon-only > .anticon {
  margin-left: -16px;
}

/* @media screen and (min-width: 1024px) and (max-width: 1100px) {
  .teamTitle {
    width: 67%;
  }
} */
@media screen and (min-width: 1024px) and (max-width: 1250px) {
  /* .teamsgridbox h4 {
    font-size: 16px;
  } */
  /* .employee-profile {
    min-width: 330px;
  } */
  /* .employee-permissions {
    margin-left: 2vw;
  } */
  .operator-code h1 {
    margin-right: 40px;
  }
  /* .profile-details h1 {
    font-size: 26px;
  } */
}
@media screen and (min-width: 992px) and (max-width: 1023px) {
  /* .teamTitle {
    width: 67%;
  } */
  .teamsgridbox h4 {
    font-size: 16px;
    width: 66%;
  }
  .employee-profile {
    width: 50%;
    margin: 0 auto 20px auto;
    padding: 0 20px 20px 30px;
  }
  .profile-details .ant-avatar {
    width: 140px;
    height: 140px;
  }
  .profile-contacts {
    margin-bottom: 0px;
  }
  .profile-contacts h3 {
    font-size: 16px;
  }
  /* .employee-details {
    display: block;
  } */
  .employee-main-permissions {
    margin-bottom: 30px;
  }
  .teamarrow {
    float: left;
  }
  .operation-feature h3 {
    min-width: 326px;
  }
  .operation-title h2 {
    min-width: 350px;
  }
  .teambtn button {
    padding: 12px 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .teamTitle {
    width: 100%;
  }
  .teamsgridbox h4 {
    font-size: 16px;
  }
  /* .teamsgridbox {
    float: left;
    /* width: calc(50% - 20px); */
  /* margin-right: 20px;
    margin-bottom: 20px; 
    min-height: 120px;
  } */
  .teamsgrid {
    /* display: block; */
    /* grid-template-columns: 1fr 1fr; */
    gap: 20px 0px;
  }
  .employeesTeams {
    min-width: 300px;
  }
  .perTeamSec .searchSec .ant-input-group-addon button {
    height: 50px !important;
  }
  .permissionTitle {
    flex-direction: column;
    gap: 20px 0;
    /* margin-bottom: 20px; */
  }

  .employee-profile {
    width: 50%;
    margin: 0 auto 20px auto;
    padding: 0 20px 20px 30px;
  }
  .profile-details .ant-avatar {
    width: 140px;
    height: 140px;
  }
  .profile-contacts {
    margin-bottom: 0px;
  }
  .profile-contacts h3 {
    font-size: 16px;
  }
  /* .employee-details {
    display: block;
  } */
  .employee-main-permissions {
    margin-bottom: 30px;
  }
  .teamarrow {
    float: left;
  }
  .operation-feature h3 {
    min-width: 347px;
  }
  .operation-title h2 {
    min-width: 365px;
  }
  .teambtn button {
    padding: 12px 20px;
  }
  .permissionTeam tr td:first-child {
    min-width: 250px;
    width: 43vw;
  }
  .teambtn {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .teamsBlk {
    flex-wrap: wrap;
    justify-content: start;
    gap: 20px;
  }
  .searchSec .ant-input-search {
    min-width: 200px !important;
  }
  /* .employee-permissions {
    margin-bottom: 75px;
  } */
}

.teams-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1601px) and (max-width: 1920px) {
  /* .employee-main-permissions {
    height: calc(100vh - 53vh);
  } */
  /* .employee-teams {
    height: calc(100vh - 78vh);
  } */
  /* .permission-sub {
    height: calc(100vh - 62vh);
  } */
}
@media (min-width: 1921px) and (max-width: 2880px) {
  /* .employee-main-permissions {
    height: calc(100vh - 35vh);
  } */
  /* .employee-teams {
    height: calc(100vh - 85vh);
  } */
  /* .permission-sub {
    height: calc(100vh - 42vh);
  } */
}

@media (min-width: 768px) and (max-width: 1300px) {
  .subPermissionTitle h4 {
    font-size: 18px;
    line-height: 34px;
  }
  .teamsgridbox h4 {
    font-size: 16px;
  }
  /* .teamsTitle .teamTitle h4 {
    font-size: 16px;
  } */
  .teamsTitle .teamDesc {
    font-size: 14px;
  }
  /* .profile-details h1 {
    font-size: 20px;
  } */
  .profile-details span {
    font-size: 16px;
  }
  /* .permissions-title h1 {
    font-size: 20px;
  } */
  .empoyee-team h1 {
    font-size: 15px;
  }
  .profile-details .ant-avatar-string {
    font-size: 42px;
  }
}

.permissionsTeamTbl .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff !important;
}

.permissionsTeamTbl .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #366376 !important;
  border-top: 0 !important;
  border-left: 0 !important;
}

.personeeltab .profile .ant-avatar {
  background: #67afb7 !important;
  border: 1px solid #67afb7 !important;
}

.save_change_sec a {
  color: #366376;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
}
.save_change_sec a:hover {
  color: #67afb7;
}
.ant-dropdown-menu-item {
  padding: 10px !important;
  font-family: 'Montserrat-Medium';
}
.teamsRel {
  height: 100%;
}
.rc-virtual-list-holder-inner .ant-select-item {
  border-bottom: 1px solid #e7e7e7;
}
.rc-virtual-list-holder-inner span.teamsInrTitle {
  margin: 0 !important;
  font-size: 16px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.12px;
  line-height: 19px;
  color: #000000;
}
.teamsDropdown span.teamsInrTitle {
  margin: 0;
}
.rc-virtual-list-holder-inner span.teamsInrDesc {
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.19px;
  line-height: 20px;
  color: #333333;
  display: block;
  white-space: break-spaces;
}
.rc-virtual-list-holder-inner .anticon-check {
  color: #366376;
}
.teamsDropdown .ant-select-selector .ant-select-selection-item-content {
  font-size: 12px !important;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.4px;
  line-height: 15px;
}
.teamsDropdown
  .ant-select-selector
  .ant-select-selection-item-content
  .teamsInrDesc {
  display: none;
}

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.attContainer .teamlist .infinite-scroll-component {
  height: auto !important;
  max-height: calc(100vh - 300px) !important;
  padding-right: 15px;
}

.save_change_sec {
  position: absolute;
  right: 80px;
  bottom: 80px;
  margin-bottom: 20px;
}
@media (min-width: 768px) and (max-width: 1100px) {
  .save_change_sec {
    bottom: 5px;
  }
}

.ellipsiIcon.active {
  background-color: #366376;
  color: #fff;
}

.btnprivileges {
  width: 100% !important;
  border-radius: 20px !important;
  height: auto !important;
}

/* .createteamContainer .titleName {
  margin-bottom: 30px !important;
} */
.discardmodal .ant-modal-content {
  left: 45%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  position: absolute;
  padding: 56px 64px;
}
.discardmodal {
  top: 0 !important;
}
.textareaheight .ant-input {
  height: 190px !important;
}

/* .operation-feature .ant-checkbox-inner{
  border: none !important;
  background-color: #fff;
}   */

/* .operation-feature .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after{
  border-color: #1e3a46;
} */

.listemployees .emp {
  display: flex;
  align-items: center;
}

.listemployees .optnwrap {
  display: inline-block;
  width: 173px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.teamCntContainer {
  display: flex;
  align-items: center;
}
.teamCnt {
  /* font-size: 1.042vw;
  font-family: Montserrat-SemiBold; */
  color: #000;
  margin-right: 4px;
}
@media (max-width: 1440px) {
  .teamCnt {
    font-size: 15px;
  }
}

.teams-permissions .employee-team-title span {
  color: #474747;
  display: inline-block;
  /* font-size: 14px;
  font-family: Montserrat-Regular;
  letter-spacing: 0.44px;
  line-height: 26px; */
  padding-left: 10px;
}

.personeeltab .profile .profile-avatar {
  border: 2px solid #ec9322 !important;
}

.perTeamSec .searchSec .ant-input-search input {
  height: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  max-width: 228px !important;
}
.perTeamSec .searchSec .ant-input-group-addon button {
  height: 42px !important;
}
.perTeamSec .searchSec .ant-input-search {
  width: 230px !important;
  height: 42px !important;
}
.ellipseBox {
  height: 42px;
  width: 46px;
}
.ellipseBox .ellipsiIcon {
  width: 46px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.captalize {
  text-transform: uppercase !important;
}
.createTeamOption {
  border-bottom: none !important;
}
.rc-virtual-list-holder {
  padding: 10px;
}

.teamEmpContainer.ant-modal {
  margin: auto auto !important;
}
