.elTabs .ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  /* font-size: 14px; */
  background: 0 0;
  border: 0;
  outline: none;
  padding: 8px 8px;
  cursor: pointer;
}
.elTabs .ant-tabs-ink-bar {
  position: absolute;
  background: #67AFB7;
  pointer-events: none;
  min-height: 3px;
}
.elTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000;
  text-shadow: 0 0 .25px currentColor;
  z-index: 1;
}
/* .elTabs .ant-tabs-nav {
  height: 94px;
} */
.elTabs .ant-tabs-nav-wrap {
  padding-left: 30px;
  padding-right: 30px;
  align-items: flex-end;
  justify-content: center;
  /* padding-bottom: 20px; */
}
/*
.elTabs .ant-tabs-nav {
height: 94px;
border-top: 200px solid #DBDBDB;
border-left: 100px solid transparent;
border-right: 100px solid transparent;
} */
.elTabs > .ant-tabs-nav {
  /* height: 111px; */
  border-bottom: 1px solid #E7E7E7;
  width: auto;
  /* margin-bottom: 20px; */
}

.elTabs .ant-tabs-nav::before {
  border-bottom: none !important;
}

.padt0 .ant-card-body {
  padding-top: 0px !important;
}
/* .elTabs .ant-tabs-tab-btn {
  text-transform: uppercase;
}
.elTabs .ant-tabs-tab:hover{
  color: #959595 !important;
  font-family: Montserrat-SemiBold;
}
.elTabs .ant-tabs-tab, .elTabs .ant-tabs-tab-btn:active{
  color: #959595 !important;
  font-family: Montserrat-SemiBold;
} */

.dftBtn {
  background:#fff;
}
.ant-card-body {
  padding:0px !important;
}
.elTabs .ant-tabs-tab + .ant-tabs-tab {
  margin:0 0 0 8.35vw;
}

.elTabs .ant-tabs-tab.ant-tabs-tab-disabled{
  color: #CECECF !important;
  cursor: initial;
}