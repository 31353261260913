@import url('../common/navigation.css');

.parentBlk {
  z-index: 9;
  position: absolute;
  bottom: 0;
  right: 0%;
  background: #222;
  min-height: 10px;
  max-height: 165px;
}
.downloadfile {
  position: absolute;
  bottom: 3px !important;
  right: 20px;
  top: 12px;
  z-index: 9999;
}
.b160 {
  bottom: 160px;
}
.blkHead {
  list-style: none;
  display: flex;
}
.blk {
  margin: 5px;
  display: inline;
  float: left;
  position: relative;
}
li:last-child.blk {
  margin-right: 28px;
}
li:first-child.blk {
  margin-left: 28px;
}
.slider {
  width: 100%;
  margin: auto;
  max-height: auto;
  overflow-y: hidden;
  overflow-x: auto !important;
  padding: 12px 12px 6px 12px;
  transform: scroll;
}
.slide {
  position: relative;
}
.control-prev-btn {
  position: absolute;
  top: 45%;
  left: '-3%';
  background-color: rgba(255, 255, 255, 1);
  height: 100px;
  line-height: 100px;
  width: 30px;
  text-align: center;
  box-shadow: 0 1px 3px #888;
  user-select: none;
  color: #444;
  cursor: pointer;
  transform: translateY(-50%);
}
.control-next-btn {
  position: absolute;
  top: 45%;
  right: 0%;
  background-color: rgba(255, 255, 255, 1);
  height: 100px;
  line-height: 100px;
  width: 30px;
  text-align: center;
  box-shadow: 0 1px 3px #888;
  user-select: none;
  color: #444;
  cursor: pointer;
  transform: translateY(-50%);
}
.blkHead img,
.blkHead video {
  width: 200px;
  height: 110px;
  border-radius: 4px;
}
.menuIcon {
  background-image: url(../../assets/images/menu.svg);
  width: 42px;
  height: 42px;
  top: 15px;
  position: absolute;
  cursor: pointer;
  right: 7px;
  z-index: 999;
}
.menuHolder {
  position: relative;
}
.menuContBox {
  border: 1px solid #ccc;
  box-shadow: 0 3px 4px rgb(0 0 0 / 10%);
  padding: 10px;
  width: 230px;
  position: absolute;
  right: 12px;
  top: 55px;
  background: #fff;
  border-radius: 4px;
  z-index: 1;
}

.mnRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 4px 0px;
}
.mItem {
  width: 70%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
  font-family: Montserrat-Medium;
}
.mSwitch .toggleSwitchCon .ant-switch-handle {
  position: absolute;
  top: -3px;
}

.mSwitch .toggleSwitchCon .ant-switch-handle::before {
  position: absolute;
  right: 1px;
  left: -2px;
}
.mSwitch .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
ul.menuTabs {
  list-style-type: none;
  width: 250px;
  position: absolute;
  top: -36px;
  right: 50px;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
ul.menuTabs li {
  display: inline-block;
  margin-right: 5px;
  min-width: 120px;
}
ul.menuTabs li button {
  display: inline-block;
  width: 100%;
  background: #222;
  border: none;
  min-height: 36px;
  border-radius: 4px 4px 0px 0px;
  color: #fff;
  border-bottom: 2px solid transparent;
}
ul.menuTabs li:last-child {
  display: inline-block;
  margin-right: 0px;
  min-width: 120px;
}
ul.menuTabs li button.btnsel {
  border-bottom: 2px solid #fc7e09;
}
.imgNameCont {
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-size: 12px;
  position: absolute;
  line-height: normal;
  padding: 12px 0px 6px 0px;
  bottom: 0px;
  left: 0;
  width: 100%;
  background-image: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  text-indent: 0px;
  height: 24px;
  text-shadow: rgb(0 0 0 / 70%) 0px 1px 8px;
  font-family: Montserrat-Medium;
}
.imgNameCont label {
  display: block;
  cursor: pointer;
  vertical-align: middle;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  /* width: 175px; */
  height: 15px;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  margin: 0 auto;
  width: 100%;
}
.tBoxDtlCont {
  border: 1px solid #d2d3d4;
  padding: 5px;
  line-height: normal;
  border-radius: 3px;
  font-size: 11px;
  margin-bottom: 5px;
}

.tBoxDtlCont:last-child {
  margin-bottom: 0px;
}

.tContWp .ant-menu-inline {
  width: 100%;
  border-right: 0px;
}

.tContWp li div.ant-menu-submenu-title {
  padding-right: 0px !important;
}
.tContWp .ant-radio-group.ant-radio-group-outline {
  width: 100%;
}
.tContWp .ant-menu-submenu-title {
  padding-left: 0px !important;
}
.topter,
.jngStime {
  margin-bottom: 5px;
}
.topter p:last-child {
  margin-bottom: 5px !important;
}
.clrOrg {
  color: #fc7e09;
}
.tOTxt {
  margin-right: 5px;
  min-width: 80px;
  font-family: Montserrat-SemiBold;
  color: #000000a6;
}
.tBoxDtlCont {
  cursor: pointer;
}
.tSel {
  border: 1px solid #fc7e09;
}
.tTxtC {
  text-align: center;
  margin-bottom: 0px !important;
}
.tsDatePic {
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}

.tsDatePic .dpickerWidth,
.react-datepicker-wrapper {
  width: 100% !important;
}
.tContWp span.-title-content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 185px;
}

.mItem img {
  margin-right: 8px;
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 15px);
}

.menuCls div.ant-menu-submenu-title {
  position: relative;
  z-index: 1;
}
.menuCls .ant-menu-submenu-arrow {
  right: 45px;
}
.menuCls .ant-menu-item:active,
.menuCls .ant-menu-submenu-title:active {
  background: none !important;
}
.menuCls .downloadfile {
  filter: opacity(70%);
}
.menuCls .downloadfile:hover {
  filter: opacity(100%);
}
.ol-details-popup {
  font-size: 10px !important;
  min-width: 220px !important;
}

.ol-details-popup span {
  color: orange;
}

.mb20 {
  margin-bottom: 15px;
}

.mhover {
  text-decoration: underline;
}
.pathLegend {
  margin-top: 10px;
  border-top: 1px solid #ccc;
}
.pathHead {
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.pathItemRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pathItem {
  font-size: 10px;
  font-family: Montserrat-SemiBold;
  margin-right: 8px;
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
span.grayClr {
  background: #c7c7c7;
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
  display: inline-block;
  margin-right: 5px;
  box-shadow: 0 2px 2px rgb(0 0 0 / 30%);
}
span.orangeClr {
  background: #ffb76b;
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
  display: inline-block;
  margin-right: 5px;
  box-shadow: 0 2px 2px rgb(0 0 0 / 30%);
}
span.pinkClr {
  background: #f36fc6;
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
  display: inline-block;
  margin-right: 5px;
  box-shadow: 0 2px 2px rgb(0 0 0 / 30%);
}
span.blueClr {
  background: #00b7ff;
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
  display: inline-block;
  margin-right: 5px;
  box-shadow: 0 2px 2px rgb(0 0 0 / 30%);
}
span.lBlueClr {
  background: #63f3fe;
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
  display: inline-block;
  margin-right: 5px;
  box-shadow: 0 2px 2px rgb(0 0 0 / 30%);
}

.dftBtn,
.dftBtn:hover,
.dftBtn:focus,
.dftBtn:active {
  background: #6e6e6e;
  border-radius: 19px;
  color: #fff;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  min-height: 38px;
  padding: 0px 30px;
  border: none;
  font-family: Montserrat-SemiBold;
}
.dftBtn span,
.dftBtn span:hover {
  padding: 0px;
  font-size: 14px;
  box-sizing: border-box;
}
.text_uppercase {
  text-transform: uppercase !important;
}
.tblDft .ant-layout {
  background: #fff;
  border-radius: 15px !important;
}
.tblDft .ant-table,
.tblDft .ant-table-sticky-holder {
  border-radius: 15px 15px 0px 0px !important;
}
.farmTabsTbl table tr td .iconProfile img {
  width: 34px;
  height: 34px;
  margin-right: 25px;
  border-radius: 75%;
  border: 1px solid #67afb7;
}

.farmTabsTbl tr.ant-table-measure-row {
  height: 30px !important;
}

.addTxt {
  display: flex;
}

.addBtn {
  margin-left: 6px;
  letter-spacing: 0.6px;
}

.lMapCont {
  height: calc(100vh - 160px);
  margin-top: 0;
  /* margin-top:20px; */
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  border-radius: 20px;
  z-index: 1;
}
.lMapCont .ol-viewport {
  border-radius: 20px;
}
.searchOperationsBlk {
  top: 34px !important;
  width: 352px !important;
}
.analyticsRightside {
  top: 0px !important;
  position: absolute !important;
  height: 100%;
  border-radius: 0px 20px 20px 0px;
  right: -360px;
  width: 360px;
  min-width: 360px;
  /* background: #e4efea; */
  background: #fff;
  z-index: 12;
  transition: 1s;
}
.analyticsRightside .expand {
  right: 0px;
  box-shadow: -1px 0px 6px rgba(0, 0, 0, 0.29);
}
.posHd {
  position: relative;
  overflow: hidden;
  z-index: 3;
}
.afterAnaMap {
  bottom: 240px;
}
.beforeCollapseAnaMap {
  bottom: 240px;
}
.positionMap-inner {
  bottom: 20px !important;
  transition: 1s;
}

.datalayerContent {
  padding-left: 22px;
}
.datalayerContent h4 {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.7px;
}
.datalayerContent ul {
  list-style: none;
  padding-top: 12px;
  padding-bottom: 24px;
  padding-left: 20px;
  margin-bottom: 0;
}
.datalayerContent ul li {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  line-height: 49px;
  letter-spacing: 0.7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.datalayerContent ul li img {
  margin-right: 15px;
}
.datalayerContent ul li .ant-switch {
  float: right;
}
.datalayerContent ul li .ant-switch-checked {
  background: #366376;
}
.datalayerContent ul.pathData li {
  justify-content: flex-start;
  line-height: 30px;
  color: #474747;
}
.pathBox {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 7px;
}
.manualBox {
  background-color: #67afb7;
}
.operatorAssist {
  background-color: #7adcda;
}
.autodrive {
  background-color: #ec9322;
}
.shadowModeBox {
  background-color: #fead66;
}
.rowFollowBox {
  background-color: #5cf2fe;
}
.routinesBox {
  background-color: #f065bc;
}
.standbyBox {
  background-color: #c0c0c0;
}
.liveCommandBox {
  background-color: #ffffff;
}
.datalayerContent .ant-select {
  border-radius: 10px !important;
  width: 100% !important;
}
.datalayerContent .ant-select-selector {
  height: 39px !important;
  border-radius: 10px !important;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.4px;
  font-family: 'Montserrat-Medium';
  padding: 4px 20px !important;
}
.dataLayerWrapper {
  padding: 0px !important;
}

.dataLayerRow {
  padding: 0px;
}
.datalayerContent {
  height: calc(100vh - 38vh);
  padding: 0px 38px 20px 65px;
  overflow-y: auto;
}
.datalayerContent2 {
  height: calc(100vh - 30vh);
  padding: 20px 0px 20px 40px;
  /* overflow-y: auto; */
}
/* .ant-select-dropdown {
  z-index: 9999 !important;
} */

.dlItem {
  width: 40px;
}
.analyticsRightside .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff !important;
}
.analyticsRightside .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #1e3a46;
}
.analyticsRightside .ant-checkbox-input,
.analyticsRightside .checkBoxItem .ant-checkbox-inner {
  width: 15px;
  height: 15px;
  border-radius: 3px;
}
.dlItemTxt {
  text-align: left;
  width: 70%;
}

.imgVideoTabs {
  position: inherit;
  /* margin-top: -193px; */
  margin-left: 0;
  position: absolute;
  height: 207px;
  z-index: 14;
  bottom: 0px;
  width: 100%;
}
.imgVideoTabs .ant-tabs {
  height: 207px;
  /* width: 86vw; */
}
.imgVideoTabs .ant-tabs-nav {
  margin-bottom: 0;
  height: auto;
}
.imgVideoTabs .ant-tabs-top > .ant-tabs-nav::before,
.imgVideoTabs .ant-tabs-bottom > .ant-tabs-nav::before {
  border-bottom: 0 !important;
}
.imgVideoTabs .ant-tabs-tab {
  padding: 19px 22px;
  border-radius: 10px 10px 0 0;
  color: #333333 !important;
  background: #b6b6b7 !important;
}
.imgVideoTabs .ant-tabs-tab:hover {
  color: #333333 !important;
}
.imgVideoTabs .ant-tabs-tab-active {
  background: #e4efea !important;
}
.imgVideoTabs .ant-tabs-ink-bar {
  background: #e4efea !important;
}
.imgVideoTabs .ant-tabs-tab-btn {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1.25px;
  font-family: 'Montserrat-SemiBold';
}
.imgVideoTabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0;
}
.imgVideoTabs .ant-tabs-nav-wrap {
  flex: inherit !important;
  padding-left: 50px !important;
}
.imgVideoTabs .ant-tabs-content {
  background: #e4efea;
  /* box-shadow: 0px 3px 6px #00000029; */
  height: 159px;
  position: relative;
  top: -7px;
  border-radius: 0px 0px 15px 15px;
}
.thumbImgBlk {
  padding: 16px 66px 16px 66px;
  display: flex;
  overflow-x: hidden;
  position: relative;
  height: 159px;
  /* text-align: center; */
}
.thumbImgBlk ul {
  list-style: none;
  display: inline;
  padding: 0;
  white-space: nowrap;
  margin-bottom: 0;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.imageBox {
  max-width: 203px;
  height: 106px;
  margin-right: 24px;
  background: #fff;
  display: inline-block;
  white-space: nowrap;
  position: relative;
}
.imageBox:last-child {
  margin-right: 0px;
}
.thumbImgVid .anticon-left {
  padding-left: 16px;
  padding-right: 32px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #b6b6b7;
}
.thumbImgVid .anticon-right {
  padding-left: 32px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #366376;
}

.operationsBlock {
  padding: 10px 34px;
}

.tsDatePic input {
  background: #fff;
  border: none;
  height: 43px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.29);
  font-size: 16px;
  font-family: Montserrat-Medium;
}
.tsDatePic input:focus-visible {
  border: none !important;
  outline: none;
}
.tsDatePic {
  margin-bottom: 16px;
}
.react-datepicker__month-container {
  width: 100%;
}
.react-datepicker {
  width: 100%;
}
.jumpAndGoList .react-datepicker {
  width: 118%;
}
.jumpAndGoList .react-datepicker__month-container {
  width: 73% !important ;
}
.jumpAndGoList .react-datepicker {
  z-index: 10000 !important;
}
.react-datepicker-popper {
  width: 80%;
}
.selectTractor .ant-select {
  border: none;
  height: 43px;
  border-radius: 10px;
  padding: 0px;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.29);
  font-size: 16px;
  font-family: Montserrat-Medium;
  width: 100% !important;
  margin-bottom: 16px;
  color: #5c5c5c;
}

.selectTractor .ant-select-selector input {
  height: 43px !important;
  padding: 6px 20px 6px ​20px !important;
}
.selectTractor
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none !important;
  border-radius: 10px !important;
  height: 43px !important;
  padding: 6px 20px;
}
.dataLayerRowHead {
  padding: 26px 8px 0px 24px;
}
.dataLayerRowHead2 {
  padding: 25px 8px 0px 38px;
}

.allDay {
  /* height: 43px; */
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.allDay .checkBoxItem {
  padding: 0px;
  width: 100%;
  margin-left: 0px !important;
  margin-bottom: 0px;
  justify-content: flex-start;
  border-bottom: none !important;
}
.allDay .layerName {
  margin-left: 12px;
  letter-spacing: inherit !important;
}

.imageBox video,
.imageBox img {
  /* width: 100% !important; */
  height: 106px;
  object-fit: cover;
  min-width: 203px;
}

.selectReport .ant-select-selector {
  border-radius: 10px !important;
  width: 214px !important;
  height: 47px !important;
  /* padding: 8px 21px !important; */
  display: flex;
  align-items: center;
}

.selectReport .ant-select-selection-item {
  color: #5c5c5c;
  letter-spacing: 0.15px;
  font-family: Montserrat-Medium;
  font-size: 16px;
}

/* .selectReport ::-webkit-scrollbar {
  width: 5px;
} */

/* .selectReport ::-webkit-scrollbar-thumb {
  background: #959595;
  border-radius: 10px;
} */

/* operation accordions */

.childAccordion .ant-collapse-content-box {
  background-color: #fff;
}
.selectTractorOpt {
  margin-left: 20px !important;
  margin-right: 40px !important;
}
.childAccordion p {
  font-size: 12px;
  font-family: Montserrat-Medium;
  line-height: 15px;
  letter-spacing: 0.4px;
  color: #000;
}
.operationTitle {
  font-family: Montserrat-SemiBold;
  font-weight: bold;
}
.moreBtn {
  font-family: Montserrat-SemiBold;
  font-size: 14px;
  letter-spacing: 1.25px;
  line-height: 18px;
  color: #366376;
  text-align: center;
  margin: 0;
}

/* Analytics operational more button expand code */

.operationAnalyticsInrBlk::-webkit-scrollbar {
  width: 4px;
}
.operationAnalyticsInrBlk::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.operationAnalyticsInrBlk::-webkit-scrollbar-thumb {
  background: #959595;
}
.operationAnalyticsInrBlk::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* .operationAnalyticsInrBlk{
  min-height: 250px;
  height: 250px;
  overflow-y: auto;
} */

/* .operationAnalyticsBlk .ant-collapse-content {
  overflow-y: auto;
} */

.cardAccordion {
  border-bottom: 0 !important;
}
.cardHeader {
  padding: 16px 18px;
}
.cardHeader h4 {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
  color: #333333;
}
.cardHeader h4 img {
  margin-right: 20px;
}
.cardHeader h4 .anticon-close {
  float: right;
  color: #366376;
  cursor: pointer;
}
.cardBody {
  padding: 0px 24px 0px 24px;
}
.childAccordion .operationAnalyticsBlk .ant-collapse-header {
  font-size: 14px !important;
  font-family: 'Montserrat-SemiBold' !important;
  line-height: 18px !important;
  letter-spacing: 1.25px !important;
  color: #333333 !important;
}
.operationAnalyticsBlk .ant-collapse-content-box {
  padding-left: 19px !important;
  padding-right: 19px !important;
}
.operationsBlk .cardBody table tr td:first-child {
  vertical-align: initial;
  /* width: 50%; */
}
.operationsBlk .cardBody table tr td:last-child {
  vertical-align: initial;
  font-size: 13px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.1px;
  line-height: 18px;
  color: #000;
}
.operationsBlk .cardBody table tr:nth-child(even) {
  background: transparent;
}
.cardBody .cardAccordion .ant-collapse-item {
  margin-top: 32px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.operationAnalyticsBlk h5 {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.07px;
  line-height: 15px;
  color: #000;
  margin-bottom: 8px;
}
.idleBox {
  background-color: #fbb313;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
}
.manualBoxForPie {
  background-color: #a9bf51;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
}
.manualBoxForPie {
  background-color: #a9bf51;
  display: inline-block;
}
.autonomousBox {
  background-color: #a3d5d8;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
}
.autodriveBox {
  background-color: #ec9322;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
}
.manualdriveBox {
  background-color: #67afb7;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
}
.operatorassitsBox {
  background-color: #7adcda;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
}
.timeDistBlk table tr td:first-child {
  font-size: 12px !important;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.09px;
  line-height: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 0;
  /* width: 62%!important; */
}
.timeDistBlk table tr td:last-child {
  font-size: 12px !important;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.09px;
  line-height: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
}
img.graphImg {
  width: 73px;
}
.speedEnergyBlk {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
}
.speedEnergyBlk h6 {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.07px;
  line-height: 15px;
  color: #000;
}
.speedEnergyBlk img {
  padding-top: 15px;
  padding-bottom: 15px;
}
.speedEnergyBlk span {
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.33px;
  line-height: 13px;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mt24 {
  margin-top: 24px;
}
.operationalBlk .opertionalInrBlk {
  padding-left: 25px;
  margin-bottom: 10px;
}
.operationalBlk .opertionalInrBlk span {
  width: 50%;
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.07px;
  line-height: 15px;
  color: #000;
}
.operationalBlk .opertionalInrBlk span:last-child {
  font-family: 'Montserrat-Medium';
}

.operationsBlk .ant-collapse {
  border: none !important;
}
.analyticsBlk {
  /* min-height: 31.07vh; */
  /* max-height: 39vh; */
  max-height: calc(100vh - 54vh);
  /* min-height: 31.07vh; */
  overflow-y: auto;
}

/* operation analytics scrollbar */
.analyticsBlk::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.analyticsBlk::-webkit-scrollbar-thumb {
  background: #959595 !important;
  border-radius: 6px;
}
.analyticsBlk::-webkit-scrollbar {
  width: 3px !important;
  height: 3px;
}
.analyticsBlk::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* datalayers scrollbar */
.datalayerContent::-webkit-scrollbar-track {
  background: #e4efea;
}
.datalayerContent::-webkit-scrollbar-thumb {
  background: #959595 !important;
  border-radius: 6px;
}
.datalayerContent::-webkit-scrollbar {
  width: 3px !important;
}
.datalayerContent::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media screen and (min-width: 1500px) and (max-width: 3000px) {
  .beforeCollapseAnaMap {
    bottom: 275px;
  }
}

@media screen and (max-width: 1440px) {
  .imgVideoTabs .ant-tabs-content {
    height: 136px;
  }
  .thumbImgBlk ul::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  .thumbImgBlk ul::-webkit-scrollbar-track {
    background: #e4efea;
  }
  .thumbImgBlk ul::-webkit-scrollbar-thumb {
    background: #959595;
  }
  .thumbImgBlk ul::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .imgVideoTabs .ant-tabs-tab {
    padding: 14px 22px;
  }
  .imgVideoTabs {
    height: 175px;
  }
  .thumbImgBlk {
    height: 136px;
  }
  .imageBox {
    min-width: 143px;
    height: 91px;
  }
  .imageBox video,
  .imageBox img {
    min-width: 143px;
    height: 91px;
  }
}

.searchSec
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 5.4vh !important;
  border-radius: 10px;
}
.searchSecPD
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 5.4vh !important;
  border-radius: 10px;
}
.searchSec .ant-input-search {
  position: relative;
}

.selectRow {
  display: flex;
}
.searchSec.selectRow {
  padding-top: 5px;
}

.datePickerStyle {
  min-width: 120px;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  letter-spacing: 0.15px;
  color: #5c5c5c;
  padding: 0 12px;
  border: 1px solid #cccccc91;
}
.pieGraphSec {
  display: flex;
  align-items: center;
  height: inherit;
  justify-content: space-between;
  position: relative;
}
.graphWrapper {
  width: 150px;
}
.graphTbl {
  width: 250px;
  position: relative;
  left: 0px;
  top: 15px;
}
.w115 {
  width: 125px !important;
  display: inline-block;
  padding: 0px !important;
  margin: 0px 0px 6px 0px !important;
}
.w55 {
  width: 125px;
  display: inline-flex;
  padding: 0px !important;
  margin: 0px 0px 6px 0px !important;
}

.graphTbl table td {
  font-size: 14px;
  letter-spacing: normal !important;
  /* line-height: 20px !important; */
}

.operationsInrAccordion p {
  display: flex;
}
.operationsInrAccordion p span.operationTitle {
  width: 40%;
}
.operationsInrAccordion p span {
  width: 60%;
}
.pieChartClass {
  /* width: 232px; */
  /* margin-left: -85px; */
  /* height: 113px !important; */
  position: absolute;
  right: 25px;
  bottom: -11px;
  z-index: 0;
}

.allDay .ant-checkbox + span {
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
  top: -3px;
}
.controlPrev .controlNext {
  z-index: 9999 !important;
}

.positionMap-inner {
  bottom: 20px !important;
  transition: 1s;
}
.afterAnaMapRightAlign .positionMap-inner {
  right: 377px;
  transition: 1s;
}
.afterAnaMapAlign .positionMap-inner {
  bottom: 20px !important;
  transition: 1s;
}
.afterAnaMapRightAlign .positionMap-inner {
  right: 367px;
  transition: 1s;
}
.afterAnaMapAlign .positionMap-inner {
  bottom: 20px !important;
  transition: 1s;
}

span.taskRecTitle {
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.gCloseIcon {
  position: relative;
  width: 10px;
  right: -14px;
  top: -9px;
}
/* .analyticsTab .elTabs .ant-tabs-content {
  margin-top: 0 !important;
  height: calc(100vh - 135px);
} */
/* .reportsTbl .infinite-scroll-component {
  max-height: calc(100vh - 326px);
} */

.thumbImgVid .ant-tabs-nav-wrap {
  padding-bottom: 7px !important;
}

.imgTb .ant-carousel .slick-track {
  width: 100% !important;
  display: flex;
}

.imgTb .slick-slide {
  width: auto !important;
  margin-right: 10px;
}

.emptyPanelBlock {
  height: 50px;
  width: 100%;
  border-radius: 10px;
  margin: 10px 0px;
  color: #000000;
  line-height: 15px;
  padding: 18px 16px;
  background-color: #fafafa !important;
  font-family: 'Montserrat-Medium';
  font-size: 14px;
}

.fullscreen .ant-layout {
  border-radius: 0 !important;
  /* height: 100vh; */
}

.fullscreen.fullscreen-enabled .lMapCont {
  height: 100vh !important;
}
.fullscreen.fullscreen-enabled .lMapCont .ol-viewport {
  border-radius: 0;
}
.fullscreen.fullscreen-enabled .tblContainer {
  width: 100% !important;
}

.opPopup {
  border-radius: 15px !important;
}
.opPopup .ant-modal-content {
  border-radius: 15px;
}

.anaOpup .cardHeader {
  border-bottom: 1px solid #e7e7e7;
}

.anaOpup {
  background: none !important;
}
.anaOpup .cardHeader {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.anaOpup .cardBody {
  padding-left: 0px;
  padding-right: 0px;
}
.anaOpup .cardBody table {
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid #e7e7e7;
}
.anaOpup .cardAccordion {
  margin-top: -10px;
}
.anaGraphSec {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  margin-bottom: 30px;
}

.pieChartClass > div {
  top: 160px;
  left: -115px;
}

/* .analeftSec {
  display: flex;
} */
.anarightSec {
  display: flex;
  align-content: flex-start;
  justify-content: center;
  border-bottom: 1px solid #e7e7e7;
}
.anarightSec div {
  width: 30%;
  text-align: center;
}
.anarightSec h6 {
  font-size: 12px;
  /* margin-bottom: 20px; */
  font-family: 'Montserrat-SemiBold';
}
.anarightSec h5 {
  font-size: 10px;
  margin-bottom: 20px;
  letter-spacing: 1px;
  font-family: Montserrat-Medium;
}
.anarightSec img {
  margin-bottom: 10px;
}
.anaOefcSec {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}
.w5per {
  width: 48%;
  display: inline-block;
}

.sTcr .ant-select-selector,
.sOps .ant-select-selector {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* Wave Loader  */

.wave-loader {
  position: absolute;
  top: 7%;
  right: 5%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  z-index: 1;
}
.wave-loader > span {
  height: 30px;
  width: 7px;
  margin-right: 10px;
  background-color: #f1f1f1;
  animation: loading 1s linear infinite;
}
.wave-loader > span:nth-child(1) {
  animation-delay: 0.1s;
}
.wave-loader > span:nth-child(2) {
  animation-delay: 0.2s;
}
.wave-loader > span:nth-child(3) {
  animation-delay: 0.3s;
}
.wave-loader > span:nth-child(4) {
  animation-delay: 0.4s;
}
.wave-loader > span:nth-child(5) {
  animation-delay: 0.5s;
}

@keyframes loading {
  0% {
    height: 0;
  }
  25% {
    height: 25px;
  }
  50% {
    height: 50px;
  }
  100% {
    height: 0;
  }
}
.fullScreen {
  width: 100vw;
  height: 100vh;
  position: fixed !important;
  /* background: #f00; */
  z-index: 300 !important;
  /* z-index: 9999 !important; */
  top: 0px !important;
  left: 0px !important;
}

.normalCl {
  width: 100%;
  height: 100%;
  position: relative !important;
}
.fullScreen .tblContainer {
  width: 100%;
}
.fullScreen .lMapCont,
.fullScreen .lMapCont .ol-viewport {
  border-radius: 0 !important ;
}

.fullScreen .afterAnaMap {
  bottom: 95px !important;
}
.fullScreen .beforeCollapseAnaMap {
  bottom: 95px !important;
}

.fullScreen .react-datepicker-popper {
  transform: translate3d(-35.4062px, 162px, 0px) !important;
}

.jag-head {
  position: absolute;
  z-index: 1;
  background-color: black;
  padding: 8px 16px;
  color: white;
  top: 2vh;
  right: 4vw;
  opacity: 0.7;
  font-weight: 600;
}

.anaOpup .cardBody {
  min-height: 200px;
  height: auto;
}

.tbTabs .ant-tabs-nav-list {
  width: 100%;
}
/* .tbTabs .ant-tabs-nav-list .ant-tabs-tab {
  width: 50%;
} */
.tbTabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 32px;
}
.tbTabs .ant-tabs-nav {
  height: auto;
}

.tbTabs .ant-tabs-nav-wrap {
  padding: 0px;
}
.tbTabs.ant-tabs-tab {
  justify-content: center;
}

.datalayerContent2 .tbTabs .ant-tabs-content {
  height: calc(100vh - 40vh) !important;
  overflow-y: auto;
  padding-right: 10px;
}

.thumbWraper {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
  padding: 0px;
}
.noVideos {
  display: block !important;
}
.noVideos .ant-row {
  flex-flow: column !important;
}
.thumbItem {
  font-size: 30px;
  text-align: center;
  position: relative;
  height: 90px;
}

.thumbItem img,
.thumbItem video {
  max-width: 100%;
  display: block;
  height: 100%;
  width: 100%;
}
.thumbItem video {
  width: 135px;
  height: 90px;
  object-fit: cover;
}

.thumbItem .ant-image {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.thumbItem .ant-image-mask-info {
  font-size: 18px;
}

.parentmapIcon {
  width: 60px;
  position: absolute;
  height: 100vh;
  margin-top: 200px;
  z-index: 9 !important;
  right: 10px !important;
}
/* 
.anaJngTabs .ant-tabs-content-holder {
  height: calc(100vh - 60vh);
  overflow-y: auto;
}

.anaJngTabs .ant-tabs-content {
  height: inherit !important;
  overflow-y: inherit !important;
} */

.anaJngTabSec {
  height: calc(100vh - 110px);
  overflow-y: auto;
}
.fullScreen .anaJngTabSec {
  height: calc(100vh - 40px);
  overflow-y: auto;
}

.anaJngTabs .ant-tabs-content.ant-tabs-content-top {
  height: inherit !important;
  overflow-y: inherit !important;
}
/* .anaJngTabSec .thumbWraper {
  grid-template-columns: auto !important
} */

.anaJngTabs .thumbWraper .ant-card {
  background: #e4efea !important;
}

.anaJngTabs .videoDbCon .ant-card-extra {
  display: flex !important;
  justify-content: center;
  width: 100%;
  letter-spacing: 1px;
}
.anaJngTabs .videoDbCon .ant-card-head {
  height: 48px;
}

.anaJngTabs .fullWidth > .videoSnaps {
  margin-bottom: 10px;
}
.anaJngTabs .videoSnaps {
  min-height: 150px;
  max-height: 150px;
}

.anaJngTabs .videoSnaps .snapText {
  background: #c7d6ce;
  border: 1px solid #c7d6ce;
  font-family: 'Montserrat-Medium';
  padding-top: 5px;
}

.anaJngTabs .ant-btn {
  height: 28px;
  padding: 3px 10px;
}
.anaJngTabs .progress {
  margin-right: 10px;
  flex-basis: 55%;
}
.anaJngTabs .barDisplay:last-child div {
  font-size: 10px;
  width: 85px !important;
}
.anaJngTabs .barDisplay {
  align-items: center;
}

/* .anaJngTabs .dbWidget {
  border: 1px solid #c7d6cd !important;
} */

/* datalayers scrollbar */
.anaJngTabSec::-webkit-scrollbar-track {
  background: #e4efea;
}
.anaJngTabSec::-webkit-scrollbar-thumb {
  background: #959595 !important;
  border-radius: 6px;
}
.anaJngTabSec::-webkit-scrollbar {
  width: 3px !important;
}
.anaJngTabSec::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.anaJngTabSec {
  position: relative;
}
.tbTabs .ant-tabs-nav {
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 99999;
  background: #c7d6ce;
  margin-right: 24px;
  border-radius: 6px 6px 0px 0px;
}
.anaJngTabs {
  overflow: unset;
  position: relative !important;
}
.thumbWraper {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
  padding: 0px;
  margin-right: 15px;
}
#rc-tabs-1-panel-Videos .thumbWraper {
  margin-right: 0px !important;
}

.fullScreenVideoThumb {
  position: absolute;
  top: 11px;
  font-size: 14px;
  left: 294px;
  z-index: 10;
}

.videosFull {
  width: 95% !important;
  height: 100vh;
}

.videosFull .ant-card-body {
  height: calc(100vh - 200px);
}
.videosFull {
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
}

.videosFull .ant-modal-content {
  border-radius: 10px;
}

.videosFull .videoSnaps {
  height: 30vh;
  max-height: inherit;
}

.videosFull button.ant-modal-close {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-size: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videosFull .ant-card-extra {
  position: absolute;
  right: 35px;
}
.videosFull .ant-card-head {
  padding-left: 20px !important;
  padding-right: 0px !important;
  text-align: left;
  display: flex;
}

.vedioprogress {
  flex: 1;
  position: relative;
  display: flex;
  flex-basis: 100%;
  height: 20px;
  background: #38647466;
  cursor: pointer;
  margin: 4px 14px;
  border-radius: 10px;
}

.vedioprogress__filled {
  width: 100%;
  background: #386474;
  flex: 0;
  flex-basis: 0%;
  border-radius: 10px;
}

.btn-rgt-align {
  position: absolute;
  right: 0;
}

.relative {
  position: relative;
}

.table-center > thead > tr > th,
.table-center > tbody > tr > td {
  text-align: center;
  border: 1px solid;
}

.fleetAnalyticsTab .ant-tabs-nav {
  z-index: 1;
}

.opPopup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
  top: 0;
}

/* =========== thubnailVideosFull styles goes here ============== */

.thubnailVideosFull {
  padding-bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thubnailVideosFull .ant-modal-content {
  width: 85vw;
  border-radius: 10px;
  height: 95vh;
}

.thubnailVideosFull .ant-card-extra {
  position: absolute !important;
  right: 20px !important;
}

.videoDbCon .ant-card-extra {
  position: absolute !important;
  right: 20px !important;
}

.videoGrid.camerasContainer {
  height: calc(95vh - 140px);
  width: calc(85vw - 48px);
}

.library-video-container > .videoGrid.camerasContainer {
  height: calc(80vh - 150px);
  width: 100%;
}

.library-video-container .containerMain {
  grid-auto-rows: minmax(calc(60vh / 5), auto) !important;
}

.thubnailVideosFull .ant-modal-close-x {
  width: 30px;
  height: 30px;
  position: relative;
  top: -10px;
  right: -10px;
  background-color: #e5e5e5;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainCont {
  position: relative;
}

.mainCont .snapText {
  min-height: 36px;
  background-color: rgb(82 138 149 / 80%);
  border-radius: 5px 5px 0 0;
  width: 100%;
  z-index: 10;
  color: #fff;
  border-bottom: none;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: inherit;
}
.child .snapText {
  min-height: 30px;
  background-color: transparent;
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: #fff;
  border-bottom: none;
  margin-bottom: 0;
  display: flex;
  padding-top: inherit;
}

.videoGrid .containerMain {
  grid-template-columns: 1fr 3fr 1fr;
}
/* .videoGrid .mainCont {
  grid-column: 2/3;
    grid-row: 1/4;
} */
.videoGrid .containerMain {
  height: 100% !important;
}
.thubnailVideosFull .barDisplay {
  height: 39px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}
.thubnailVideosFull .okBtn {
  padding: inherit;
  height: 24px;
  width: 32px;
}
.thubnailVideosFull .vedioprogress {
  height: 12px;
  margin-left: 0px;
}
.videoGrid .mainCont video,
.videoGrid .child video {
  border-radius: 10px;
}

.videoDbCon .barDisplay {
  height: 39px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}
.videoDbCon .okBtn {
  padding: inherit;
  height: 24px;
  width: 32px;
}

.videoDbCon .progress {
  height: 12px;
  margin-left: 0px;
}
.acreshead {
  height: 30px;
}

.anaOvf {
  /* height: calc(100vh - 35vh); */
  max-height: calc(100vh - 35vh);
  overflow-y: auto;
}
/* Scrollbar width */
.anaOvf::-webkit-scrollbar {
  width: 3px;
}
/* Scrollbar Track */
.anaOvf::-webkit-scrollbar-track {
  background: #e4efea;
  border-radius: 3px;
}

/* Scrollbar Handle */
.anaOvf::-webkit-scrollbar-thumb {
  background: #959595;
  border-radius: 3px;
}

/* Scrollbar Handle on hover */
.anaOvf::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* new styles in livemap component end */
.moreOptions {
  position: relative;
}

.moreDiv ul {
  z-index: 999;
  bottom: 0px;
  right: 0px;
  position: absolute;
  padding: 10px;
}

.checkmark {
  padding-left: 30px;
}
.mb20 {
  margin-bottom: 20px !important;
}
