.dropdownContainer {
  margin-right: 10px;
}

.snapWidget .ant-space {
  margin-top: 10px;
}

.snapWidget .anticon-down {
  float: right;
  padding-top: 6px;
}

.snapWidget .ant-select-arrow {
  margin-top: -12px;
}
.subItem {
  position: absolute;
  height: 30px;
  width: 178px;
  top: 0px;
  left: 0px;
  padding-left: 8px;
  color: #fff;
  letter-spacing: 1.2px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 11px;
  font-family: Montserrat-SemiBold;
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 1%,
    rgba(0, 0, 0, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 1%,
    rgba(0, 0, 0, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 1%,
    rgba(0, 0, 0, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.menuContainer .ant-tabs-tab {
  border: 1px solid #e6e6e6;
  height: 130px !important;
  width: 180px;
  margin-top: 7px !important;
}
.menuContainer .ant-tabs-content-holder {
  display: flex;
}
.snapWidget .ant-card-body {
  /* padding: 5px 30px 5px 30px; */
}
.menuContainer .ant-tabs-ink-bar {
  opacity: 0;
}
/* .ant-tabs-tab.ant-tabs-tab-active {
  border: 3px solid #EB921F !important;
} */
.imageView img {
  /* width: 138px; */
  height: 128px;
  width: 178px;
  background-repeat: no-repeat;
  background-size: auto;
  position: relative;
  /* right: 25px; */
}
/* .ant-tabs-content {
  margin-top: 8px;
} */
.mainItem {
  position: absolute;
  color: #fff;
  z-index: 100;
  padding-left: 8px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  min-width: 180px;
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-family: Montserrat-SemiBold;
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 1%,
    rgba(0, 0, 0, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 1%,
    rgba(0, 0, 0, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 1%,
    rgba(0, 0, 0, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.ant-tabs-nav-wrap {
  flex-direction: inherit !important;
}
.ant-tabs-tab.ant-tabs-tab-active img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuContainer .ant-tabs-tab.ant-tabs-tab-active,
.menuContainer .ant-tabs-tab {
  padding: 0px !important;
}

/* new snapshot UI 28-06-2021 */

.camWrapper {
  width: 100%;
  height: auto;
  display: flex;
  margin-left: -8px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.snapsContainer .camBox {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  width: 85px;
  height: 85px;
  margin: 18px 0px 15px 15px;
  float: left;
  box-sizing: border-box;
}
.snapsContainer .camBox1 {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  width: 300px;
  height: 210px;
  margin: 0 0px 15px 15px;
  float: left;
  box-sizing: border-box;
}

.snapsContainer .camBoxHeads {
  font-size: 12px;
  font-family: Montserrat-Regular;
  text-align: center;
}

.snapsContainer .camBoxContent {
  width: 85px;
  height: 85px;
  background: #fff;
  border-radius: 8px !important;
}

.snapsContainer .camBoxContent img {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 85px;
  border-radius: 8px;
}

.mt0 {
  margin-top: 0px !important;
}

/* .snapshotContent .ant-select {
  border: 1px solid #dfe1e6 !important;
  border-radius: 3.5px !important;
  height: 34px !important;
  font-family: 'Montserrat-Medium' !important;
  font-size: 14px !important;
  color: #172b4d !important;
} */

/* .snapshotContent .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 34px !important;

}
.snapshotContent .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f4f5f7 !important;
  border-radius: 3.5px !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #dfe1e6 !important;
} */

.snapshotContent .ant-card-body {
  padding: 20px 20px 20px 20px !important;
}

/* .libraryContainer .snapshotContent {
  height: calc(100vh - 218px);
} */
.snapshotContent .infinite-scroll-component {
  height: auto !important;
  /* max-height: calc(100vh - 440px); */
  max-height: calc(100vh - 330px);
}
/* .searchUnit{
  margin-right: 20px !important;
} */

.inline {
  margin-bottom: 16px;
  display: inline-flex;
}

.mt36 {
  margin-top: 60px;
}

.snapsContainer .proRowType {
  margin-bottom: 10px;
}

.analyticsTxt {
  font-size: 14px;
  text-align: right;
  color: #366376;
  font-family: 'Montserrat-SemiBold';
  cursor: pointer;
  right: 38px;
  /* position: absolute; */
  bottom: 20px;
  text-transform: uppercase;
}

.snapsContainer .ant-image {
  display: block;
}

/* .reportsTbl.h42vh .infinite-scroll-component {
  height: calc(100vh - 42vh)!important;
}  */

/* .mx-ht {
  max-height: calc(100vh - 44.5vh)!important;
} */

.mx-ht #total {
  top: 20px !important;
}
.p-relative {
  position: relative;
}
.snapshot-download-btn {
  width: 100%;
  position: relative;
}
.snapshot-download-btn > span.anticon {
  /* width: 100%; */
  font-size: 32px;
  position: absolute;
  right: 16px;
  top: 0;
  cursor: pointer;
}
.datepicker-error-class {
  border-color: red;
}
.load-snap-refresh {
  position: absolute;
  right: 4rem;
  top: 2rem;
}
