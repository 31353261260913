/* Large Screen Typography Styles */
@media (min-width: 1300px) and (max-width: 1599px) {
  .headline1 {
    font-size: 20px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.25px !important;
    line-height: 24px !important;
  }

  .headline2 {
    font-size: 16px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0 !important;
    line-height: 19px !important;
  }

  .headline2_B {
    font-size: 16px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0 !important;
    line-height: 19px !important;
  }

  .headline3 {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.15px !important;
    line-height: 19px !important;
  }

  .headline3_R {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.1px !important;
    line-height: 22px !important;
  }

  .headline4 {
    font-size: 10px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.1px !important;
    line-height: 13px !important;
  }

  .headline5 {
    font-size: 8px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.4px !important;
    line-height: 10px !important;
  }

  .overline1 {
    font-size: 14px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.1px !important;
    line-height: 18px !important;
  }

  .overline2 {
    font-size: 12px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.125px !important;
    line-height: 15px !important;
  }

  .overline3 {
    font-size: 8px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.075px !important;
    line-height: 10px !important;
  }

  .body1 {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 18px !important;
  }

  .pinBody {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.25px !important;
    line-height: 18px !important;
  }

  .body2 {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.25px !important;
    line-height: 14px !important;
  }

  .body3 {
    font-size: 10px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.4px !important;
    line-height: 13px !important;
  }

  .body4 {
    font-size: 8px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.4px !important;
    line-height: 10px !important;
  }

  .Button {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.25px !important;
    line-height: 15px !important;
  }

  .Button2 {
    font-size: 10px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.95px !important;
    line-height: 13px !important;
  }

  .tractorState {
    font-size: 8px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.25px !important;
    line-height: 10px !important;
  }

  .dbWidget .ant-card-body .ant-table-tbody > tr > td,
  .customTbl tbody > tr > td,
  .userPf table tr > td {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 18px !important;
  }

  .dbWidget .ant-card-body .ant-table-thead > tr > th,
  .customTbl thead > tr > th,
  .userPf table tr > th {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.15px !important;
    line-height: 19px !important;
  }

  div#total {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
  }

  .termspopup.talertPopup .ant-modal-title {
    font-size: 20px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.25px !important;
    line-height: 24px !important;
  }

  .tblStyles .ant-table-thead > tr > th {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.15px !important;
    line-height: 19px !important;
  }
  .tblStyles .ant-table-tbody > tr > td {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 18px !important;
  }
  .popupStyles .ant-modal-title {
    font-size: 16px !important;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 0;
    line-height: 19px !important;
  }
  .popupStyles .formRow .ant-form-item-label > label {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.125px !important;
    line-height: 15px !important;
  }
  .popupStyles .cfqRadioBtn .ant-radio-wrapper {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.15px !important;
    line-height: 19px !important;
  }
  .autoDempty {
    margin-top: 12px;
  }
  .mb36 {
    margin-bottom: 30px;
  }
}

/* Large Screen Typography Styles End */

/* Medium Screen Typography Styles */
@media (min-width: 768px) and (max-width: 1299px) {
  .headline1 {
    font-size: 16px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.25px !important;
    line-height: 19px !important;
  }

  .headline2 {
    font-size: 14px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0 !important;
    line-height: 18px !important;
  }

  .headline2_B {
    font-size: 14px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0 !important;
    line-height: 18px !important;
  }

  .headline3 {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.15px !important;
    line-height: 15px !important;
  }

  .headline3_R {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.1px !important;
    line-height: 22px !important;
  }

  .headline4 {
    font-size: 8px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.1px !important;
    line-height: 10px !important;
  }

  .headline5 {
    font-size: 8px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.4px !important;
    line-height: 10px !important;
  }

  .overline1 {
    font-size: 12px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.1px !important;
    line-height: 15px !important;
  }

  .overline2 {
    font-size: 10px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.125px !important;
    line-height: 13px !important;
  }

  .overline3 {
    font-size: 8px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.075px !important;
    line-height: 10px !important;
  }

  .body1 {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }

  .pinBody {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.25px !important;
    line-height: 14px !important;
  }

  .body2 {
    font-size: 10px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.25px !important;
    line-height: 10px !important;
  }

  .body3 {
    font-size: 8px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.4px !important;
    line-height: 10px !important;
  }

  .body4 {
    font-size: 8px;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.4px;
    line-height: 10px;
  }

  .Button {
    font-size: 10px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.25px !important;
    line-height: 13px !important;
  }

  .Button2 {
    font-size: 8px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.95px !important;
    line-height: 10px !important;
  }

  .tractorState {
    font-size: 7px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.25px !important;
    line-height: 9px !important;
  }

  .dbWidget .ant-card-body .ant-table-tbody > tr > td,
  .customTbl tbody > tr > td,
  .userPf table tr > td {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }

  .dbWidget .ant-card-body .ant-table-thead > tr > th,
  .customTbl thead > tr > th,
  .userPf table tr > th {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.15px !important;
    line-height: 15px !important;
  }

  div#total {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
  }
  .termspopup.talertPopup .ant-modal-title {
    font-size: 16px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.25px !important;
    line-height: 19px !important;
  }

  .tblStyles .ant-table-thead > tr > th {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.15px !important;
    line-height: 15px !important;
  }
  .tblStyles .ant-table-tbody > tr > td {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }
  .popupStyles .ant-modal-title {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 0;
    line-height: 18px !important;
  }
  .popupStyles .formRow .ant-form-item-label > label {
    font-size: 10px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.125px !important;
    line-height: 13px !important;
  }
  .popupStyles .cfqRadioBtn .ant-radio-wrapper {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.15px !important;
    line-height: 15px !important;
  }
  .autoDempty {
    margin-top: 8px;
  }
  .mb36 {
    margin-bottom: 24px;
  }
}

/* Medium Screen Typography Styles End */

/* media query for screens */

@media (min-width: 1681px) and (max-width: 1920px) {
  .implementImage {
    max-height: 237px !important;
    min-height: 200px !important;
    border-radius: 0px !important;
  }

  .addEmpBlk .addEmployeeSelect.multiSelectField .ant-select-selector {
    min-height: 50px !important;
    height: auto !important;
    max-height: 60px !important;
  }

  .profile-details .profileEditImg {
    left: unset !important;
    right: 1vw;
    bottom: 56px !important;
  }

  .popupStyles .formRow .commInput,
  .popupStyles .formRow .ant-select {
    height: 50px !important;
  }
  .popupStyles .ant-form-item-control .ant-input {
    height: 50px !important;
  }

  .ticketsSearchBar .anticon-search svg {
    width: 16px !important;
    height: 16px !important;
  }
  .searchSec.ticketsSearchBar .ant-input-search input {
    padding-left: 16px !important;
  }

  .seeDtl {
    height: calc(100vh - 200px) !important;
  }

  .talertPopup .tblDft.farmTabsTbl.dsTbl {
    height: calc(100vh - 260px) !important;
    max-height: calc(100vh - 260px) !important;
  }
  .talertPopup .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
    max-height: calc(100vh - 285px) !important;
  }
  .talertPopup .tblDft.farmTabsTbl.dsTbl div#empTbl {
    min-height: calc(100vh - 285px) !important;
  }

  /* autonomy settings popup styles ********************/
  .autonomy_modal_sec .ant-input {
    height: 50px;
  }
  .autonomy_modal_sec .ant-select-selector {
    height: 50px;
  }
  .autonomy_modal_sec .generictoggle {
    height: 50px;
  }
  .okBtn.autonomySaveBtn {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 1.25px !important;
    line-height: 18px !important;
  }
  .AD_term_content .ql-editor {
    font-size: 16px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 22px !important;
  }
  .autonomy_modal_sec .ant-input {
    height: 50px;
  }
  .autonomy_modal_sec .ant-select-selector {
    height: 50px !important;
  }
  .autonomy_modal_sec .generictoggle {
    height: 50px;
  }
  .okBtn.autonomySaveBtn {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 1.25px !important;
    line-height: 18px !important;
  }
  .AD_term_content .ql-editor {
    font-size: 16px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 22px !important;
  }

  /* model popup styles goes here *********************** */

  .teamEmpContainer .ant-input::placeholder,
  .teamEmpContainer .ant-select-selection-placeholder,
  .teammodal input::placeholder,
  .teammodal .ant-select-selection-placeholder {
    font-size: 16px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 22px !important;
  }
  .team-text-area .ant-input,
  .teammodal .ant-input,
  .team-text-area .ant-input::placeholder {
    font-size: 16px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 22px !important;
  }

  .team-text-area-2 .ant-input,
  .teammodal .ant-input,
  .team-text-area-2 .ant-input::placeholder {
    font-size: 16px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 22px !important;
  }

  /* model popup styles end here *********************** */

  .userPf .employee-details {
    height: calc(100vh - 230px) !important;
  }

  .analyzerContainer {
    top: 80px !important;
  }

  .tractorOperDtls {
    width: 448px !important;
  }

  .sparyMapContainer {
    width: calc(100% - 448px) !important;
  }

  .employee-profile {
    padding: 56px !important;
  }

  .employee-permissions {
    margin-left: 2.45vw !important;
    width: calc(100% - 650px) !important;
  }

  /* side menu css goes here */
  .main-menu,
  .sideMenuSec {
    width: 97px !important;
    flex: 0 0 97px !important;
    min-width: 97px !important;
  }

  .main-menu:hover,
  nav.main-menu.expanded {
    width: 224px !important;
  }

  .iconBox {
    margin-left: 26px !important;
    margin-right: 26px !important;
    width: 45px !important;
    height: 45px !important;
  }

  .iconBoxProfile {
    margin-left: 26px !important;
    margin-right: 26px !important;
  }

  .iconBoxProfile .ant-avatar {
    width: 47px !important;
    height: 47px !important;
  }

  .iconBox div {
    background-size: 80% !important;
    background-position: center center;
  }

  /* side menu css goes end */

  /* tabs css goes here */
  .elTabs > .ant-tabs-nav {
    height: 123px !important;
    /* margin-bottom: 28px !important; */
  }

  .elTabs .ant-tabs-nav-wrap {
    padding-bottom: 36px;
  }

  .elTabs .ant-tabs-tab-btn,
  .attContainer .ant-tabs-tab-btn {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.25px !important;
    /* line-height: 18px !important; */
  }

  /* dropdown css *********************/
  .searchField .ant-input-search,
  .searchSec .ant-input-search {
    width: 327px !important;
  }

  .viewportContainer .searchSec .ant-input-group-addon button,
  .searchSec .ant-input-group-addon button {
    width: auto;
    height: 50px !important;
    padding-left: 16px;
  }

  .searchSec .ant-input-search input {
    font-size: 14px !important;
    min-height: 50px;
    max-height: 50px;
    padding-left: 51px !important;
  }

  .searchSec .ant-input-search input::placeholder {
    font-size: 14px !important;
    letter-spacing: 0.22px !important;
    line-height: 18px !important;
  }

  .searchFilters .tab-label,
  .searchFilters .tab-label:hover {
    height: 50px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .searchFilters .flbTopFixed {
    font-size: 8px !important;
    letter-spacing: 0.08px !important;
    line-height: 10px !important;
  }

  .searchFilters .flbSelected {
    font-size: 14px !important;
    letter-spacing: 0.22px !important;
    line-height: 18px !important;
  }

  .searchFilters .dateFilter .ant-picker-input > input {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular';
    letter-spacing: 0.22px !important;
    line-height: 18px !important;
  }

  .searchFilters .dateFilter {
    margin-left: 17px !important;
    margin-right: 0 !important;
  }

  .smallFilter .flblWrapper {
    min-width: 158px !important;
    width: 158px !important;
    height: 50px !important;
    margin-left: 17px !important;
  }

  .medFilter .flblWrapper {
    min-width: 209px !important;
    width: 209px !important;
    height: 50px !important;
    margin-left: 20px !important;
  }

  .singleDatePicker {
    height: 50px !important;
  }

  .searchFilters .tractorField .flblWrapper {
    width: 200px !important;
    height: 50px !important;
  }

  .searchFilters .customField {
    margin-left: 24px !important;
  }

  .searchFilters .customField .ant-select-selector {
    height: 50px !important;
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 18px;
    margin-right: 0 !important;
  }

  .m_tb {
    margin-top: 12px !important;
    margin-bottom: 40px !important;
  }

  .dsTbl div#empTbl {
    min-height: calc(100vh - 560px);
  }

  .reportsTbl .infinite-scroll-component {
    max-height: calc(100vh - 330px) !important;
  }

  .reportsTbl.tblDft {
    max-height: calc(100vh - 320px) !important;
  }

  .viewportContainer .tblDft {
    height: calc(100vh - 325px) !important;
    max-height: calc(100vh - 325px) !important;
  }

  .viewportContainer .tblDft .infinite-scroll-component {
    max-height: calc(100vh - 345px) !important;
  }

  .viewportContainer .tblDft div#empTbl {
    min-height: calc(100vh - 345px) !important;
  }

  .tblDft.farmTabsTbl {
    height: calc(100vh - 320px) !important;
    max-height: calc(100vh - 320px) !important;
  }

  .tblDft.farmTabsTbl .infinite-scroll-component {
    max-height: calc(100vh - 345px) !important;
  }

  .tblDft.farmTabsTbl div#empTbl {
    min-height: calc(100vh - 345px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl {
    height: calc(100vh - 530px) !important;
    max-height: calc(100vh - 530px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
    max-height: calc(100vh - 555px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl div#empTbl {
    min-height: calc(100vh - 555px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl {
    height: calc(100vh - 340px) !important;
    max-height: calc(100vh - 340px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl .infinite-scroll-component {
    max-height: calc(100vh - 350px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl div#empTbl {
    min-height: calc(100vh - 350px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl {
    height: calc(100vh - 310px) !important;
    max-height: calc(100vh - 310px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl .infinite-scroll-component {
    max-height: calc(100vh - 325px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl div#empTbl {
    min-height: calc(100vh - 325px) !important;
  }

  /* liveops css media styles goes here ****************************/

  .fleetlistBlk {
    position: absolute;
    left: 24px;
    top: 50px;
    z-index: 9;
    width: 358px;
  }

  .searchMapBlk {
    position: relative;
    margin-bottom: 18px;
  }

  .operationsTitle .ant-collapse-header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    color: #6a6a6a !important;
    padding: 16px 16px 15px 16px !important;
    transition: all 0.3s, visibility 0s;
    font-size: 14px;
    font-family: 'Montserrat-Medium';
    letter-spacing: 1.25px;
    line-height: 18px;
  }

  .parentIcon .anticon {
    right: 10px !important;
  }

  .autodriveButton {
    /* margin-left: 100px; */
    border: 1px solid #959595;
    border-radius: 18px;
    color: #5c5c5c;
    background-color: transparent;
    display: flex;
    margin: auto;
    align-items: center;
    min-width: 155px;
    font-size: 10px;
    font-family: Montserrat-Medium;
    letter-spacing: 0.33px;
  }

  .autodriveButton:hover,
  .autodriveButton:focus,
  .autodriveButton:active {
    color: #fff !important;
    background: #ec9322 !important;
    border: 1px solid #ec9322 !important;
  }

  .autodriveButton .routeIcon {
    margin-right: 7px;
  }

  .routeIcon .autodriveButton:hover {
    color: #fff;
    background: #ec9322;
    border: 1px solid #ec9322 !important;
  }

  .fleetTabs .ant-tabs-nav {
    padding: 0 16px;
    margin-top: 14px;
    margin-bottom: 22px;
  }

  .fleetTabs .ant-tabs-ink-bar {
    border: none !important;
  }

  .parentAccordion .fleetOverallBlk {
    padding-left: 15px;
    padding-right: 14px;
    padding-bottom: 6px !important;
    padding-top: 0 !important;
    /* height: 392px; */
  }

  .parentAccordion .fleetBlk {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cececf;
    border-radius: 10px !important;
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 12px 10px 5px 9px;
    cursor: pointer;
    min-height: 92px;
    max-height: 97px;
    box-sizing: border-box;
    position: relative;
    /* width: 326px; */
  }

  .fleetOverallBlk .fleetBlk:hover {
    border: 1px solid #366376;
  }
  .fleetOverallBlk .fleetBlk-selected,
  .fleetTractorsInrBlk .fleetBlk-selected {
    border: 2px solid #366376 !important;
  }

  .fleetBlk .tractorDtlSec.mb18 {
    margin-bottom: 16px;
  }

  .fleetBlk .tractorDtlSec.mbottom.mtop {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .fleetBlk .tractorDtlSec.mbottom {
    margin-bottom: 16px;
  }

  .t_name {
    color: #000;
    width: 185px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.125px;
    line-height: 18px;
  }

  .t_driver_name {
    color: #333333;
    display: flex;
    align-items: center;
    min-height: 13px;
    font-size: 10px;
    font-family: 'Montserrat-Regular';
    letter-spacing: 0.075px;
    line-height: 13px;
  }

  .ttractor_state {
    border-radius: 12px;
    min-height: 20px;
    color: #fff;
    text-transform: uppercase;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 94px;
    font-size: 10px;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 1.25px;
    line-height: 13px;
  }

  .tImplementSec {
    width: 111px;
    height: 20px;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    color: #000;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 10px;
    font-family: 'Montserrat-Regular';
    letter-spacing: 0.075px;
    line-height: 13px;
  }

  .tImplementSec img {
    margin-right: 6px;
  }

  .tSpeedtSec {
    display: flex;
    align-items: center;
  }

  .tBatterySec {
    display: flex;
    align-items: center;
  }

  .tSignalSec {
    display: flex;
    align-items: center;
  }

  .fleetBlk .t_signals.t_error {
    margin: 0;
    padding: 0;
  }

  .fleetBlk .t_signals.t_error .mr4 {
    margin: 0;
  }

  .battery-blk {
    display: flex;
  }

  .speedTxt {
    font-family: Montserrat-Medium;
    font-size: 15px;
    color: #4d4d4d;
    margin-left: 3px;
    width: auto;
    display: inline-block;
    text-align: right;
  }

  .tspeed {
    font-family: 'Montserrat-Medium';
    font-size: 12px;
    letter-spacing: 0.4px;
    margin-left: 3px;
    margin-right: 3px;
    line-height: 10px;
  }

  .tsMPH {
    font-family: 'Montserrat-Medium';
    font-size: 8px;
    letter-spacing: 0.48px;
    padding-top: 4px;
    line-height: 10px;
  }

  .t_battery {
    font-size: 10px;
    font-family: Montserrat-Medium;
    color: #1e1e1e;
    letter-spacing: 0.6px;
    line-height: 13px;
    display: flex;
    align-items: center;
  }

  .bPer {
    font-size: 12px;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.4px;
    line-height: 15px;
    margin-left: 3px;
  }

  .charging_remain {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #959595;
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 8px;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 0.95px;
  }

  .hImpNameSel {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
    color: #000000;
  }

  .hImpNameDbl {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
    color: #b6b6b7;
  }

  .mapLayerIcon {
    background-image: url(../../assets/images/mapLayersIcon.png);
    position: absolute;
    bottom: 15px;
    left: 25px;
    z-index: 5;
    width: 81px;
    height: 81px;
    background-repeat: no-repeat;
    display: block;
    cursor: pointer;
  }

  .mapLayerIcon:hover {
    background-image: url(../../assets/images/mapLayersIcon_hv.png);
    position: absolute;
    bottom: 15px;
    left: 25px;
    z-index: 5;
    width: 81px;
    height: 81px;
    background-repeat: no-repeat;
    display: block;
    cursor: pointer;
  }

  .bottomMapLayers {
    position: absolute;
    bottom: 23px;
    left: 25px;
    width: 0px;
    height: 0;
    /* min-width: 360px; */
    background: #eaeaea;
    height: 0;
    z-index: 99;
    box-shadow: -1px 0px 6px rgb(0 0 0 / 29%);
    transition: 1s;
    border-radius: 10px;
  }

  .bottomMapLayers .dataLayerSec {
    overflow-y: auto;
    height: calc(100vh - 230px);
  }

  .mpOpen {
    visibility: visible;
    animation: resize 0.2s;
    width: 358px;
    height: calc(100vh - 142px);
  }

  .mpClose {
    animation: resizeClose 0.2s;
    visibility: hidden;
  }

  .fleetTabs .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    color: #5c5c5c;
    border: 1px solid transparent;
    padding: 10px 8px;
    font-size: 12px;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.4px;
    line-height: 15px;
  }

  .fleetTabs .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0px !important;
  }

  .fleetlistBlk .fListSearchMain .ant-input,
  .fListSearchMain .ant-input {
    font-family: 'Montserrat-Medium';
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 18px;
    color: #fff;
  }

  .fleetlistBlk .searchMapBlk.fListSearchMain input.fListSearch::placeholder {
    font-family: 'Montserrat-Medium';
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 18px;
    color: #cececf;
  }

  .fleetlistBlk .fListSearchMain .ant-input,
  .indicatorimges .t_signals {
    display: flex;
    align-items: center;
  }

  /* .fleetTabs .ant-tabs-content-holder {
  max-height: calc(100vh - 380px) !important;
} */
  .fleetBlk .tractorDtlSec1 .t_signals,
  .fleetBlk .t_signals {
    padding: 0px !important;
  }

  .fleetBlk .tractorDtlSec1 .t_signals img:first-child {
    margin-right: 0px !important;
  }

  .rightWidgetView .functionalityTag .ant-tag {
    font-size: 14px;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 1.25px;
    line-height: 18px;
    padding: 10px 12px !important;
  }

  .rightWidgetView .uploadImg .ant-upload {
    position: unset !important;
    margin-top: unset !important;
    margin-left: unset !important;
  }

  .rightWidgetView .ant-upload .editImgs {
    position: absolute;
    right: 20px !important;
    bottom: 0px !important;
    cursor: pointer;
  }

  .autonomyBtns {
    background: transparent;
    position: absolute;
    bottom: 35px !important;
    width: 100%;
    padding-left: 40px !important;
    padding-right: 40px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .rightWidgetView .input::placeholder {
    color: #b6b6b7;
    font-size: 14px;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.22px;
    line-height: 18px;
  }

  .rightWidgetView textarea::placeholder {
    color: #959595 !important;
    font-size: 14px;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.22px;
    line-height: 18px;
  }

  .rightWidgetView .ant-select-selection-placeholder {
    font-style: italic;
    font-size: 14px;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.22px;
    line-height: 18px;
  }

  .genric_toggle_sec {
    font-size: 14px;
    font-family: 'Montserrat-Regular';
    letter-spacing: 0.22px;
    line-height: 18px;
    height: 50px;
  }

  .tractorPic img {
    width: 200px;
    height: 200px;
  }

  .editFleetButtons {
    bottom: 40px !important;
  }

  .addEmpBlk.rightWidgetView .editImgs {
    right: 20px !important;
    bottom: 15px !important;
  }

  /* Right side widget details section styles goes here **********************/

  .rightSideWidegt {
    right: -486px !important;
    width: 486px !important;
  }

  .rightWidgetView {
    padding: 0px 40px 20px 40px !important;
  }

  .widgetCloseBtn {
    padding: 38px 38px 31px 38px !important;
  }

  .tcktInfo {
    margin-bottom: 43px;
  }

  .fwdBtnsSec .TktFwdBtn {
    padding: 14px 29px !important;
  }

  .fwdBtnsSec .TktRslBtn {
    padding: 14px 56px !important;
  }

  .ticketComments {
    font-size: 16px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.71px !important;
    line-height: 19px !important;
  }

  .createTcktTxt {
    font-size: 16px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.71px !important;
    line-height: 19px !important;
  }

  .profileEdit .ant-tag-checkable,
  .tagsBlk .ant-tag-checkable,
  .rightWidgetView .tag-chip-selected {
    padding: 8px 16px !important;
  }

  .rightWidgetView .tagsBlk .tags {
    font-size: 12px;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.4px;
    line-height: 15px;
    padding: 8px 16px !important;
  }

  .rightWidgetView .createTcktFlag img {
    padding: 12px 14px;
    margin-left: 24px;
  }

  .rightWidgetView .ant-select-selector,
  .rightWidgetView .ant-input {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.22px !important;
    line-height: 18px !important;
    min-height: 50px !important;
  }

  .rightWidgetView
    .selectImplementContainer
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px !important;
  }

  .rightWidgetView .ant-select-selector .ant-select-selection-item {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.22px !important;
    line-height: 18px !important;
    height: 50px !important;
  }

  .rightWidgetView textarea {
    font-size: 16px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 22px !important;
  }

  .rightWidgetView .proPic .ant-avatar {
    width: 179px !important;
    height: 179px !important;
  }

  .rightWidgetView .proPic img {
    width: 100% !important;
    height: 100% !important;
  }

  .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 385px) !important;
  }

  .fleetTabs .childAccordion2 {
    max-height: calc(100vh - 385px) !important;
  }

  .dmenHandle .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 510px) !important;
  }

  .dmenHandle .childAccordion2 {
    max-height: calc(100vh - 510px) !important;
  }

  /* personal tab view and edit employee styles goes here ******************/
  .addEmpBlk.rightWidgetView .editImgs {
    right: 20px !important;
    bottom: 8px !important;
  }

  .addEmpBlk.rightWidgetView .proPic .ant-avatar {
    width: 146px !important;
    height: 146px !important;
  }

  .rightWidgetView .teamTxt .ant-select-selector .ant-select-selection-item {
    min-height: 32px !important;
  }

  .rightWidgetView .proPic .ant-avatar .ant-avatar-string {
    width: 179px !important;
    height: 179px !important;
  }

  .filterGap40 {
    height: calc(128px - 70px) !important;
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
}

@media (min-width: 1441px) and (max-width: 1680px) {
  .implementImage {
    max-height: 220px !important;
    min-height: 200px !important;
    border-radius: 0px !important;
  }

  .addEmpBlk .addEmployeeSelect.multiSelectField .ant-select-selector {
    min-height: 50px !important;
    height: auto !important;
    max-height: 60px !important;
  }

  .profile-details .ant-avatar {
    width: 100px !important;
    height: 100px !important;
    min-width: 100px !important;
    min-height: 100px !important;
    margin-bottom: 15px !important;
  }
  .profile-details .profileEditImg {
    left: unset !important;
    right: 0 !important;
    bottom: 45px !important;
    width: 20px !important;
  }

  .popupStyles .formRow .commInput,
  .popupStyles .formRow .ant-select {
    height: 50px !important;
  }
  .popupStyles .ant-form-item-control .ant-input {
    height: 50px !important;
  }

  .searchSec.ticketsSearchBar .ant-input-search input {
    padding-left: 16px !important;
  }

  .tractorDetailsAlertBox {
    left: -6px !important;
    width: 370px !important;
  }
  .tractorDetailsAlertBox button {
    min-width: fit-content !important;
  }
  .localizationDown .terrImgBlock img {
    margin-top: 28px !important;
    width: 32px !important;
  }
  .tractorDetailsAlertBox .terrImgBlock img {
    margin-top: 28px !important;
    width: 32px !important;
  }

  .seeDtl {
    height: calc(100vh - 185px) !important;
  }

  .talertPopup .tblDft.farmTabsTbl.dsTbl {
    height: calc(100vh - 260px) !important;
    max-height: calc(100vh - 260px) !important;
  }
  .talertPopup .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
    max-height: calc(100vh - 285px) !important;
  }
  .talertPopup .tblDft.farmTabsTbl.dsTbl div#empTbl {
    min-height: calc(100vh - 285px) !important;
  }

  /* autonomy settings popup styles ********************/

  .autonomy_modal_sec .ant-input {
    height: 50px;
  }
  .autonomy_modal_sec .ant-select-selector {
    height: 50px;
  }
  .autonomy_modal_sec .generictoggle {
    height: 50px;
  }
  .okBtn.autonomySaveBtn {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 1.25px !important;
    line-height: 18px !important;
  }
  .AD_term_content .ql-editor {
    font-size: 16px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 22px !important;
  }

  /* model popup styles goes here *********************** */

  .teamEmpContainer .ant-input::placeholder,
  .teamEmpContainer .ant-select-selection-placeholder,
  .teammodal input::placeholder,
  .teammodal .ant-select-selection-placeholder {
    font-size: 16px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 22px !important;
  }
  .team-text-area .ant-input,
  .teammodal .ant-input,
  .team-text-area .ant-input::placeholder {
    font-size: 16px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 22px !important;
  }

  .team-text-area-2.ant-input,
  .teammodal .ant-input,
  .team-text-area-2 .ant-input::placeholder {
    font-size: 16px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 22px !important;
  }

  /* model popup styles end here *********************** */

  .change-ps {
    height: 50px !important;
  }

  .userPf .employee-details {
    height: calc(100vh - 230px) !important;
  }

  .analyzerContainer {
    top: 70px !important;
  }

  .tractorOperDtls {
    width: 390px !important;
  }

  .sparyMapContainer {
    width: calc(100% - 390px) !important;
  }

  .employee-profile {
    width: 422px !important;
    padding: 56px 48px !important;
  }

  .employee-permissions {
    margin-left: 2.45vw !important;
    width: calc(100% - 580px) !important;
  }

  /* side menu css goes here */

  .rowGap40 {
    margin-top: 30px !important;
  }

  .main-menu:hover,
  nav.main-menu.expanded {
    width: 224px !important;
  }

  .main-menu,
  .sideMenuSec {
    width: 83px !important;
    flex: 0 0 83px !important;
    min-width: 83px !important;
  }

  .iconBox {
    margin-left: 22.5px !important;
    margin-right: 22.5px !important;
    width: 38px !important;
    height: 38px !important;
  }

  .iconBoxProfile {
    margin-left: 22.5px !important;
    margin-right: 22.5px !important;
  }

  .iconBoxProfile .ant-avatar {
    width: 40px !important;
    height: 40px !important;
  }

  .iconBox div {
    background-size: 80% !important;
    background-position: center center;
  }

  /* side menu css goes end */

  /* tabs and table css goes here */
  .elTabs > .ant-tabs-nav {
    height: 123px !important;
    /* margin-bottom: 21px !important; */
  }

  .elTabs .ant-tabs-nav-wrap {
    padding-bottom: 30px;
  }

  .elTabs .ant-tabs-tab-btn,
  .attContainer .ant-tabs-tab-btn {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.25px !important;
    /* line-height: 18px !important; */
  }

  /* .farm_profile_container {
    height: calc(100vh - 189px) !important;
  } */

  /* .profMap img {
    height: calc(100vh - 472px);
  } */

  .tag-chip-selected {
    padding: 0px 12px !important;
    height: 29px !important;
  }

  .viewportContainer .tblDft {
    height: calc(100vh - 265px) !important;
    max-height: calc(100vh - 265px) !important;
  }

  .viewportContainer .tblDft .infinite-scroll-component {
    max-height: calc(100vh - 290px) !important;
  }

  .viewportContainer .tblDft div#empTbl {
    min-height: calc(100vh - 290px) !important;
  }

  .attContainer .elTabs .tblContainer {
    height: calc(100vh - 123px) !important;
  }

  /* dropdown css *********************/
  .searchField .ant-input-search,
  .searchSec .ant-input-search {
    width: 200px !important;
  }

  .viewportContainer .searchSec .ant-input-group-addon button,
  .searchSec .ant-input-group-addon button {
    width: auto;
    height: 50px !important;
    padding-left: 16px;
  }

  .searchSec .ant-input-search input {
    font-size: 14px !important;
    min-height: 50px;
    max-height: 50px;
    padding-left: 51px !important;
  }

  .searchSec .ant-input-search input::placeholder {
    font-size: 14px !important;
    letter-spacing: 0.22px !important;
    line-height: 18px !important;
  }

  .searchFilters .tab-label,
  .searchFilters .tab-label:hover {
    height: 50px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .searchFilters .flbTopFixed {
    font-size: 8px !important;
    letter-spacing: 0.08px !important;
    line-height: 10px !important;
  }

  .searchFilters .flbSelected {
    font-size: 14px !important;
    letter-spacing: 0.22px !important;
    line-height: 18px !important;
  }

  .searchFilters .dateFilter .ant-picker-input > input {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular';
    letter-spacing: 0.22px !important;
    line-height: 18px !important;
  }

  .searchFilters .dateFilter {
    margin-left: 17px !important;
    margin-right: 0 !important;
  }

  .smallFilter .flblWrapper {
    min-width: 158px !important;
    width: 158px !important;
    height: 50px !important;
    margin-left: 17px !important;
  }

  .medFilter .flblWrapper {
    min-width: 190px !important;
    width: 190px !important;
    height: 50px !important;
    margin-left: 20px !important;
  }

  .singleDatePicker {
    height: 50px !important;
  }

  .searchFilters .tractorField .flblWrapper {
    width: 200px !important;
    height: 50px !important;
  }

  .searchFilters .customField {
    margin-left: 24px !important;
  }

  .searchFilters .customField .ant-select-selector {
    height: 50px !important;
    font-size: 14px;
    letter-spacing: 0.22px;
    line-height: 18px;
    margin-right: 0 !important;
  }

  .tblContainer .m_tb {
    margin-top: 5px !important;
    margin-bottom: 26px !important;
  }

  .attContainer .elTabs .ant-tabs-content-holder,
  .attContainer .elTabs .ant-tabs-content {
    height: calc(100vh - 123px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl div#empTbl {
    min-height: calc(100vh - 345px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl .infinite-scroll-component {
    max-height: calc(100vh - 345px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl {
    height: calc(100vh - 330px) !important;
    max-height: calc(100vh - 330px) !important;
  }

  .tblDft.farmTabsTbl {
    height: calc(100vh - 320px) !important;
    max-height: calc(100vh - 320px) !important;
  }

  .tblDft.farmTabsTbl .infinite-scroll-component {
    max-height: calc(100vh - 345px) !important;
  }

  .tblDft.farmTabsTbl div#empTbl {
    min-height: calc(100vh - 345px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl {
    height: calc(100vh - 520px) !important;
    max-height: calc(100vh - 520px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
    max-height: calc(100vh - 545px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl div#empTbl {
    min-height: calc(100vh - 545px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl {
    height: calc(100vh - 300px) !important;
    max-height: calc(100vh - 300px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl .infinite-scroll-component {
    max-height: calc(100vh - 310px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl div#empTbl {
    min-height: calc(100vh - 310px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl {
    height: calc(100vh - 300px) !important;
    max-height: calc(100vh - 300px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl .infinite-scroll-component {
    max-height: calc(100vh - 325px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl div#empTbl {
    min-height: calc(100vh - 325px) !important;
  }

  /* liveops css media styles goes here ****************************/

  .fleetlistBlk {
    position: absolute;
    left: 24px;
    top: 50px;
    z-index: 9;
    width: 358px;
  }

  .searchMapBlk {
    position: relative;
    margin-bottom: 18px;
  }

  .operationsTitle .ant-collapse-header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    color: #6a6a6a !important;
    padding: 16px 16px 15px 16px !important;
    transition: all 0.3s, visibility 0s;
    font-size: 14px;
    font-family: 'Montserrat-Medium';
    letter-spacing: 1.25px;
    line-height: 18px;
  }

  .parentIcon .anticon {
    right: 10px !important;
  }

  .autodriveButton {
    /* margin-left: 100px; */
    border: 1px solid #959595;
    border-radius: 18px;
    color: #5c5c5c;
    background-color: transparent;
    display: flex;
    margin: auto;
    align-items: center;
    min-width: 155px;
    font-size: 10px;
    font-family: Montserrat-Medium;
    letter-spacing: 0.33px;
  }

  .autodriveButton:hover,
  .autodriveButton:focus,
  .autodriveButton:active {
    color: #fff !important;
    background: #ec9322 !important;
    border: 1px solid #ec9322 !important;
  }

  .autodriveButton .routeIcon {
    margin-right: 7px;
  }

  .routeIcon .autodriveButton:hover {
    color: #fff;
    background: #ec9322;
    border: 1px solid #ec9322 !important;
  }

  .fleetTabs .ant-tabs-nav {
    padding: 0 16px;
    margin-top: 14px;
    margin-bottom: 22px;
  }

  .fleetTabs .ant-tabs-ink-bar {
    border: none !important;
  }

  .parentAccordion .fleetOverallBlk {
    padding-left: 15px;
    padding-right: 14px;
    padding-bottom: 6px !important;
    padding-top: 0 !important;
    /* height: 392px; */
  }

  .parentAccordion .fleetBlk {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cececf;
    border-radius: 10px !important;
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 12px 10px 5px 9px;
    cursor: pointer;
    min-height: 92px;
    max-height: 97px;
    box-sizing: border-box;
    position: relative;
    /* width: 326px; */
  }

  .fleetOverallBlk .fleetBlk:hover {
    border: 1px solid #366376;
  }
  .fleetOverallBlk .fleetBlk-selected,
  .fleetTractorsInrBlk .fleetBlk-selected {
    border: 2px solid #366376 !important;
  }

  .fleetBlk .tractorDtlSec.mb18 {
    margin-bottom: 16px;
  }

  .fleetBlk .tractorDtlSec.mbottom.mtop {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .fleetBlk .tractorDtlSec.mbottom {
    margin-bottom: 16px;
  }

  .t_name {
    color: #000;
    width: 185px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.125px;
    line-height: 18px;
  }

  .t_driver_name {
    color: #333333;
    display: flex;
    align-items: center;
    min-height: 13px;
    font-size: 10px;
    font-family: 'Montserrat-Regular';
    letter-spacing: 0.075px;
    line-height: 13px;
  }

  .ttractor_state {
    border-radius: 12px;
    min-height: 20px;
    color: #fff;
    text-transform: uppercase;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 94px;
    font-size: 10px;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 1.25px;
    line-height: 13px;
  }

  .tImplementSec {
    width: 111px;
    height: 20px;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    color: #000;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 10px;
    font-family: 'Montserrat-Regular';
    letter-spacing: 0.075px;
    line-height: 13px;
  }

  .tImplementSec img {
    margin-right: 6px;
  }

  .tSpeedtSec {
    display: flex;
    align-items: center;
  }

  .tBatterySec {
    display: flex;
    align-items: center;
  }

  .tSignalSec {
    display: flex;
    align-items: center;
  }

  .fleetBlk .t_signals.t_error {
    margin: 0;
    padding: 0;
  }

  .fleetBlk .t_signals.t_error .mr4 {
    margin: 0;
  }

  .battery-blk {
    display: flex;
  }

  .speedTxt {
    font-family: Montserrat-Medium;
    font-size: 15px;
    color: #4d4d4d;
    margin-left: 3px;
    width: auto;
    display: inline-block;
    text-align: right;
  }

  .tspeed {
    font-family: 'Montserrat-Medium';
    font-size: 12px;
    letter-spacing: 0.4px;
    margin-left: 3px;
    margin-right: 3px;
    line-height: 10px;
  }

  .tsMPH {
    font-family: 'Montserrat-Medium';
    font-size: 8px;
    letter-spacing: 0.48px;
    padding-top: 4px;
    line-height: 10px;
  }

  .t_battery {
    font-size: 10px;
    font-family: Montserrat-Medium;
    color: #1e1e1e;
    letter-spacing: 0.6px;
    line-height: 13px;
    display: flex;
    align-items: center;
  }

  .bPer {
    font-size: 12px;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.4px;
    line-height: 15px;
    margin-left: 3px;
  }

  .charging_remain {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #959595;
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 8px;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 0.95px;
  }

  .hImpNameSel {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
    color: #000000;
  }

  .hImpNameDbl {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
    color: #b6b6b7;
  }

  .mapLayerIcon {
    background-image: url(../../assets/images/mapLayersIcon.png);
    position: absolute;
    bottom: 15px;
    left: 25px;
    z-index: 5;
    width: 81px;
    height: 81px;
    background-repeat: no-repeat;
    display: block;
    cursor: pointer;
  }

  .mapLayerIcon:hover {
    background-image: url(../../assets/images/mapLayersIcon_hv.png);
    position: absolute;
    bottom: 15px;
    left: 25px;
    z-index: 5;
    width: 81px;
    height: 81px;
    background-repeat: no-repeat;
    display: block;
    cursor: pointer;
  }

  .bottomMapLayers {
    position: absolute;
    bottom: 23px;
    left: 25px;
    width: 0px;
    height: 0;
    /* min-width: 360px; */
    background: #eaeaea;
    height: 0;
    z-index: 99;
    box-shadow: -1px 0px 6px rgb(0 0 0 / 29%);
    transition: 1s;
    border-radius: 10px;
  }

  .bottomMapLayers .dataLayerSec {
    overflow-y: auto;
    height: calc(100vh - 230px);
  }

  .mpOpen {
    visibility: visible;
    animation: resize 0.2s;
    width: 358px;
    height: calc(100vh - 142px);
  }

  .mpClose {
    animation: resizeClose 0.2s;
    visibility: hidden;
  }

  .fleetTabs .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    color: #5c5c5c;
    border: 1px solid transparent;
    padding: 10px 8px;
    font-size: 12px;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.4px;
    line-height: 15px;
  }

  .fleetTabs .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0px !important;
  }

  .fleetlistBlk .fListSearchMain .ant-input,
  .fListSearchMain .ant-input {
    font-family: 'Montserrat-Medium';
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 18px;
    color: #fff;
  }

  .fleetlistBlk .searchMapBlk.fListSearchMain input.fListSearch::placeholder {
    font-family: 'Montserrat-Medium';
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 18px;
    color: #cececf;
  }

  .fleetlistBlk .fListSearchMain .ant-input,
  .indicatorimges .t_signals {
    display: flex;
    align-items: center;
  }

  .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 380px) !important;
  }

  .fleetBlk .tractorDtlSec1 .t_signals,
  .fleetBlk .t_signals {
    padding: 0px !important;
  }

  .fleetBlk .tractorDtlSec1 .t_signals img:first-child {
    margin-right: 0px !important;
  }

  .rightWidgetView .functionalityTag .ant-tag {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.25px !important;
    line-height: 15px !important;
    padding: 10px 12px !important;
  }

  .rightWidgetView .uploadImg .ant-upload {
    position: unset !important;
    margin-top: unset !important;
    margin-left: unset !important;
  }

  .rightWidgetView .ant-upload .editImgs {
    position: absolute;
    right: 5px !important;
    /* bottom: 30px !important; */
    bottom: 6px !important;
    cursor: pointer;
  }

  .rightWidgetView .ant-upload .editImgs img {
    width: 16px !important;
  }

  .autonomyBtns {
    background: transparent;
    position: absolute;
    bottom: 35px !important;
    width: 100%;
    padding-left: 40px !important;
    padding-right: 40px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .rightWidgetView .input::placeholder {
    color: #b6b6b7;
    font-size: 14px;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.22px;
    line-height: 18px;
  }

  .rightWidgetView textarea::placeholder {
    color: #959595 !important;
    font-size: 14px;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.22px;
    line-height: 18px;
  }

  .rightWidgetView .ant-select-selection-placeholder {
    font-style: italic;
    font-size: 14px;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.22px;
    line-height: 18px;
  }

  .genric_toggle_sec {
    font-size: 14px;
    font-family: 'Montserrat-Regular';
    letter-spacing: 0.22px;
    line-height: 18px;
    height: 50px;
  }

  .tractorPic img {
    width: 173px !important;
    height: 173px !important;
  }

  .editFleetButtons {
    bottom: 40px !important;
  }

  .addEmpBlk .proPic .ant-avatar {
    width: 126px !important;
    height: 126px !important;
  }

  .addEmpBlk.rightWidgetView .editImgs {
    right: 12px !important;
    bottom: 8px !important;
  }

  /* Right side widget details section styles goes here **********************/

  .rightSideWidegt {
    right: -422px !important;
    width: 422px !important;
  }

  .rightWidgetView {
    padding: 0px 30px 20px 30px !important;
  }

  .widgetCloseBtn img {
    width: 13px !important;
    height: 13px !important;
  }

  .widgetCloseBtn {
    padding: 31px 31px 21px 31px !important;
  }

  .tcktInfo {
    margin-bottom: 35px;
  }

  .fwdBtnsSec .TktFwdBtn {
    padding: 14px 25px !important;
  }

  .fwdBtnsSec .TktRslBtn {
    padding: 14px 36px !important;
  }

  .ticketComments {
    font-size: 16px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.5px !important;
    line-height: 22px !important;
  }

  .createTcktTxt {
    font-size: 16px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.5px !important;
    line-height: 22px !important;
  }

  .saveTxtBtn button {
    right: 30px !important;
  }

  .impEditBtn button {
    right: 30px !important;
  }

  .impAutonomyBtn button {
    padding: 10px 14px !important;
  }

  .profileEdit .ant-tag-checkable,
  .tagsBlk .ant-tag-checkable,
  .rightWidgetView .tag-chip-selected {
    padding: 8px 16px !important;
  }

  .rightWidgetView .tagsBlk .tags {
    font-size: 12px;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.4px;
    line-height: 15px;
    padding: 8px 16px !important;
  }

  .rightWidgetView .createTcktFlag img {
    padding: 12px 14px;
    margin-left: 24px;
  }

  .rightWidgetView .ant-select-selector,
  .rightWidgetView .ant-input {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.22px !important;
    line-height: 18px !important;
    min-height: 50px !important;
  }

  .rightWidgetView
    .selectImplementContainer
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px !important;
  }

  .rightWidgetView .ant-select-selector .ant-select-selection-item {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.22px !important;
    line-height: 18px !important;
    height: 50px !important;
  }

  .rightWidgetView textarea {
    font-size: 16px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 22px !important;
  }

  .rightWidgetView .proPic .ant-avatar {
    width: 146px !important;
    height: 146px !important;
  }

  /* Autodrive **********************************************/

  .rautoDriveRightSec {
    width: 422px !important;
  }

  .tglexpand {
    right: 422px !important;
  }

  .afterMapRightAlign .positionMap-inner {
    right: 426px !important;
    transition: 1s !important;
  }

  .adtTopBox {
    margin: 0 15px;
  }

  .adTabsContentSec {
    padding: 0px 14px 0 14px !important;
  }

  .adTabsHeadSec {
    padding: 30px 14px 30px 14px !important;
  }

  .autoDriveCamViews iframe#cammms {
    height: 178px !important;
  }

  .aDname {
    width: 160px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .tractorSpeedometer .t_info_progress_circle {
    height: inherit !important;
  }

  .tractorSpeedometer .tractor_status_img {
    width: calc(100% - 50px) !important;
    height: calc(100% - 50px) !important;
  }

  .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 385px) !important;
  }

  .fleetTabs .childAccordion2 {
    max-height: calc(100vh - 385px) !important;
  }

  .dmenHandle .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 510px) !important;
  }

  .dmenHandle .childAccordion2 {
    max-height: calc(100vh - 510px) !important;
  }

  /* personal tab view and edit employee styles goes here ******************/

  .editRoles2 {
    right: 30px !important;
  }

  .addEmpBlk.rightWidgetView .proPic .ant-avatar {
    width: 126px !important;
    height: 126px !important;
  }

  .rightWidgetView .proPic .ant-avatar .ant-avatar-string {
    width: 156px !important;
    height: 156px !important;
  }

  .filterGap40 {
    height: calc(128px - 70px) !important;
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
}

@media (min-width: 1367px) and (max-width: 1440px) {
  .implementImage {
    max-height: 200px !important;
    min-height: 200px !important;
    border-radius: 0px !important;
  }

  .profileHeight {
    max-height: calc(100vh - 270px) !important;
  }
  .addEmpBlk .addEmployeeSelect.multiSelectField .ant-select-selector {
    min-height: 37px !important;
    height: auto !important;
    max-height: 60px !important;
  }

  .profile-details .profileEditImg {
    left: unset !important;
    right: 0 !important;
    bottom: 45px !important;
    width: 20px !important;
  }

  .popupStyles .formRow .commInput,
  .popupStyles .formRow .ant-select {
    height: 37px !important;
  }
  .popupStyles .ant-form-item-control .ant-input {
    height: 37px !important;
  }

  .ticketsSearchBar .anticon-search svg {
    width: 11px !important;
    height: 11px !important;
  }
  .searchSec.ticketsSearchBar .ant-input-search input {
    padding-left: 10px !important;
  }
  .tcktsBlk .ticketsSearchBar .anticon-search {
    padding-left: 0px !important;
    margin-top: -2px !important;
  }

  .tractorDetailsAlertBox .terrImgBlock {
    min-width: 50px !important;
  }

  .tractorDetailsAlertBox {
    left: -6px !important;
    min-height: 195px !important;
    padding: 20px 15px !important;
    width: 312px !important;
  }
  .tractorDetailsAlertBox1 {
    min-height: 130px !important;
  }
  .tractorDetailsAlertBox button {
    min-width: fit-content !important;
  }
  .tractorDetailsAlertBox .terrImgBlock img {
    margin-top: 24px !important;
    width: 24px !important;
  }
  .localizationDown .terrImgBlock img {
    margin-top: 24px !important;
    width: 24px !important;
  }
  .localizationDown .terrImgBlock {
    min-width: 55px !important;
  }

  .seeDtl {
    height: calc(100vh - 175px) !important;
  }

  .talertPopup .tblDft.farmTabsTbl.dsTbl {
    height: calc(100vh - 260px) !important;
    max-height: calc(100vh - 260px) !important;
  }
  .talertPopup .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
    max-height: calc(100vh - 285px) !important;
  }
  .talertPopup .tblDft.farmTabsTbl.dsTbl div#empTbl {
    min-height: calc(100vh - 285px) !important;
  }

  /* autonomy settings popup styles ********************/

  .autonomy_modal_sec .ant-input {
    height: 37px !important;
  }
  .autonomy_modal_sec .ant-select-selector {
    height: 37px !important;
  }
  .autonomy_modal_sec .generictoggle {
    height: 37px !important;
  }
  .okBtn.autonomySaveBtn {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 1.07px !important;
    line-height: 15px !important;
  }
  .AD_term_content .ql-editor {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 18px !important;
  }
  .tcBtns2,
  .tcBtns2:focus,
  .tcBtns2:hover {
    width: 165px !important;
    height: 40px !important;
  }

  /* model popup styles goes here *********************** */
  .teamEmpContainer .ant-input::placeholder,
  .teamEmpContainer .ant-select-selection-placeholder,
  .teammodal input::placeholder,
  .teammodal .ant-select-selection-placeholder {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 18px !important;
  }
  .team-text-area .ant-input,
  .teammodal .ant-input,
  .team-text-area .ant-input::placeholder {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 18px !important;
  }

  .team-text-area-2 .ant-input,
  .teammodal .ant-input,
  .team-text-area-2 .ant-input::placeholder {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 18px !important;
  }
  /* model popup styles end here *********************** */

  .change-ps {
    height: 50px !important;
  }

  .employee-teams {
    padding: 20px 0px !important;
    overflow-y: hidden !important;
    min-height: 160px !important;
  }

  .userPf .employee-details {
    height: calc(100vh - 190px) !important;
  }

  .rowGap50 {
    margin-top: 40px !important;
  }

  .analyzerContainer {
    top: 60px !important;
  }

  .tractorOperDtls {
    width: 330px !important;
  }

  .sparyMapContainer {
    width: calc(100% - 330px) !important;
  }

  .employee-profile {
    width: 363px !important;
    padding: 30px 30px !important;
  }

  .profile-details .ant-avatar {
    width: 100px !important;
    height: 100px !important;
    min-width: 100px !important;
    min-height: 100px !important;
    margin-bottom: 15px !important;
  }
  .profile-details {
    margin-bottom: 30px !important;
  }

  .employee-permissions {
    margin-left: 2.45vw !important;
    width: calc(100% - 515px) !important;
  }

  .filterGap40 {
    height: calc(99px - 56px) !important;
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .rowGap40 {
    margin-top: 30px !important;
  }

  /* side menu css goes here */

  .main-menu,
  .sideMenuSec {
    width: 71px !important;
    flex: 0 0 71px !important;
    min-width: 71px !important;
  }

  .main-menu:hover,
  nav.main-menu.expanded {
    width: 224px !important;
  }

  .iconBox {
    margin-left: 19px !important;
    margin-right: 19px !important;
    width: 33px !important;
    height: 33px !important;
  }

  .iconBoxProfile {
    margin-left: 19px !important;
    margin-right: 19px !important;
  }

  .iconBoxProfile .ant-avatar {
    width: 34px !important;
    height: 34px !important;
  }

  .iconBox div {
    background-size: 80% !important;
    background-position: center center;
  }

  /* side menu css goes end */

  /* tabs and table css goes here */
  .elTabs > .ant-tabs-nav {
    height: 102px !important;
    /* margin-bottom: 21px !important; */
  }

  .elTabs .ant-tabs-nav-wrap {
    padding-bottom: 30px;
  }

  .elTabs .ant-tabs-tab-btn,
  .attContainer .ant-tabs-tab-btn {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.07px !important;
    /* line-height: 15px !important; */
  }

  .tag-chip-selected {
    padding: 0px 12px !important;
    height: 29px !important;
  }

  /* dropdown css ************************************/

  .searchField .ant-input-search,
  .searchSec .ant-input-search {
    width: 246px !important;
    height: 35px !important;
  }

  .viewportContainer .searchSec .ant-input-group-addon button,
  .searchSec .ant-input-group-addon button {
    height: 35px !important;
    padding-left: 11px !important;
  }

  .searchFilters .searchSec .anticon-search {
    font-size: 11px !important;
  }

  .searchSec .ant-input-search input {
    font-size: 12px !important;
    min-height: 35px !important;
    max-height: 35px !important;
    padding-left: 36px !important;
  }

  .searchSec .ant-input-search input::placeholder {
    font-size: 12px !important;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.19px !important;
    line-height: 18px !important;
  }

  .searchFilters .tab-label,
  .searchFilters .tab-label:hover {
    height: 35px !important;
    padding-left: 17px !important;
    padding-right: 17px !important;
  }

  .searchFilters .flbTopFixed {
    font-size: 8px !important;
    letter-spacing: 0.08px !important;
    line-height: 10px !important;
  }

  .searchFilters .flbSelected {
    font-size: 11px !important;
    letter-spacing: 0.17px !important;
    line-height: 10px !important;
  }

  .searchFilters .selGray {
    font-size: 12px !important;
    letter-spacing: 0.19px !important;
    line-height: 10px !important;
  }

  .searchFilters .tab-label,
  .searchFilters .tab-label:hover {
    height: 35px !important;
  }

  .searchFilters .dateFilter {
    margin-left: 16px !important;
    margin-right: 0 !important;
    height: 35px !important;
  }

  .searchFilters .dW250_date {
    width: 270px !important;
  }

  .smallFilter .flblWrapper {
    min-width: 116px !important;
    width: 116px !important;
    height: 35px !important;
    margin-left: 17px !important;
  }

  .medFilter .flblWrapper {
    min-width: 156px !important;
    width: 156px !important;
    height: 35px !important;
    margin-left: 17px !important;
  }

  .singleDatePicker {
    height: 35px !important;
  }

  .searchFilters .tractorField .flblWrapper {
    height: 35px !important;
  }

  .searchFilters .customField {
    margin-left: 17px !important;
  }

  .searchFilters .customField .ant-select-selector {
    height: 35px !important;
    font-size: 11px !important;
    letter-spacing: 0.17px !important;
    line-height: 10px !important;
    margin-right: 0 !important;
  }

  .searchFilters .ant-picker-input > input {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.19px !important;
    line-height: 10px !important;
  }

  .tblContainer .m_tb {
    margin-top: 5px !important;
    margin-bottom: 26px !important;
  }

  .attContainer .elTabs .tblContainer {
    height: calc(100vh - 102px) !important;
  }

  .attContainer .elTabs .ant-tabs-content-holder,
  .attContainer .elTabs .ant-tabs-content {
    height: calc(100vh - 102px) !important;
  }

  .my-profile .permission-sub {
    min-height: calc(100vh - 530px) !important;
    max-height: calc(100vh - 530px) !important;
  }

  .tblDft.farmTabsTbl {
    height: calc(100vh - 260px) !important;
    max-height: calc(100vh - 260px) !important;
  }

  .tblDft.farmTabsTbl .infinite-scroll-component {
    max-height: calc(100vh - 285px) !important;
  }

  .tblDft.farmTabsTbl div#empTbl {
    min-height: calc(100vh - 285px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl {
    height: calc(100vh - 500px) !important;
    max-height: calc(100vh - 500px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
    max-height: calc(100vh - 525px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl div#empTbl {
    min-height: calc(100vh - 525px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl {
    height: calc(100vh - 300px) !important;
    max-height: calc(100vh - 300px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl .infinite-scroll-component {
    max-height: calc(100vh - 310px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl div#empTbl {
    min-height: calc(100vh - 310px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl {
    height: calc(100vh - 270px) !important;
    max-height: calc(100vh - 270px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl .infinite-scroll-component {
    max-height: calc(100vh - 295px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl div#empTbl {
    min-height: calc(100vh - 295px) !important;
  }

  /* liveops css media styles goes here ****************************/

  .fleetlistBlk {
    left: 17px !important;
    top: 36px !important;
    width: 267px !important;
  }

  .searchMapBlk {
    margin-bottom: 17px !important;
  }

  .fleetlistBlk .searchMapBlk .ant-input {
    height: 37.5px !important;
  }

  /* .fleetlistBlk .searchMapBlk .ant-input::placeholder {
  font-size: 20px !important;
} */

  .fleetlistBlk {
    position: absolute;
    left: 24px;
    top: 50px;
    z-index: 9;
    width: 358px;
  }

  .flIcons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flIcons img {
    margin-right: 4px;
  }

  .fleetTabs .ant-tabs-tab.tractorTab {
    /* width: 87px !important; */
    height: 27px !important;
  }

  .fleetTabs .tabTractorImg,
  .fleetTabs .tabLockImg {
    height: 10px !important;
  }

  .fleetTabs .tractorTab .ant-tabs-tab-btn {
    padding: 0px !important;
  }

  .mapLayerIcon {
    background-image: url(../../assets/images/mapLayersIcon.png);
    position: absolute;
    bottom: 15px;
    left: 25px;
    z-index: 5;
    width: 81px;
    height: 81px;
    background-repeat: no-repeat;
    display: block;
    cursor: pointer;
  }

  .mapLayerIcon:hover {
    background-image: url(../../assets/images/mapLayersIcon_hv.png);
    position: absolute;
    bottom: 25px;
    left: 25px;
    z-index: 5;
    width: 81px;
    height: 81px;
    background-repeat: no-repeat;
    display: block;
    cursor: pointer;
  }

  .bottomMapLayers {
    position: absolute;
    bottom: 23px;
    left: 25px;
    width: 0px;
    height: 0;
    /* min-width: 360px; */
    background: #eaeaea;
    height: 0;
    z-index: 99;
    box-shadow: -1px 0px 6px rgb(0 0 0 / 29%);
    transition: 1s;
    border-radius: 10px;
  }

  .bottomMapLayers .dataLayerSec {
    overflow-y: auto;
    height: calc(100vh - 230px);
  }

  .mpOpen {
    visibility: visible !important;
    animation: resize 0.2s !important;
    width: 267px !important;
    height: calc(100vh - 109px) !important;
  }

  .mpClose {
    animation: resizeClose 0.2s;
    visibility: hidden;
  }

  .bottomMapLayers {
    bottom: 24px !important;
    left: 16px !important;
  }

  .operationsTitle .ant-collapse-header {
    padding: 10px 12px 10px 12px !important;
    font-size: 12px !important;
    letter-spacing: 1.07px !important;
    line-height: 15px !important;
    height: 38px !important;
    display: flex !important;
    align-items: center !important;
  }

  .parentIcon .anticon {
    right: 8px !important;
  }

  .fleetTabs .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 10px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.33px !important;
    line-height: 13px !important;
    padding: 0 5px !important;
  }

  .fleetTabs .ant-tabs-nav {
    height: 27px !important;
    padding: 0 10px !important;
    margin-top: 16px !important;
    margin-bottom: 17px !important;
  }

  .parentAccordion .fleetBlk:first-child {
    margin-top: 0px !important;
  }

  .t_name {
    width: 130px !important;
    font-size: 12px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.4px !important;
    line-height: 15px !important;
  }

  .ttractor_state {
    min-height: 17px !important;
    min-width: 74px !important;
    font-size: 8px !important;
    letter-spacing: 0.71px !important;
    line-height: 10px !important;
  }

  .parentAccordion .fleetBlk {
    border-radius: 8px !important;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    padding: 8px 7px 8px 7px !important;
    min-height: 70px !important;
    max-height: 75px !important;
    /* width: 247px !important; */
  }

  .fleetBlk .ant-progress-bg {
    height: 6.5px !important;
  }

  .fleetBlk .tractorDtlSec.mbottom {
    margin-bottom: 7px !important;
  }

  .fleetBlk .tractorDtlSec.mbottom.mtop {
    margin-top: 12px !important;
    margin-bottom: 0px !important;
  }

  .fleetBlk .tractorDtlSec1 .t_signals,
  .fleetBlk .t_signals {
    padding: 0px !important;
  }

  .fleetOverallBlk .fleetBlk:hover {
    border: 2px solid #366376;
  }

  .fleetBlk .tractorDtlSec.mb18 {
    margin-bottom: 16px !important;
  }

  .fleetBlk .tractorDtlSec.mb18.mt10 {
    margin-top: 16px !important;
    margin-bottom: 8px !important;
    height: 16px !important;
  }

  .tractorDtlSec1 {
    height: 17px !important;
  }

  .fleetTabs .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0px !important;
  }

  .t_battery {
    font-size: 10px;
    font-family: Montserrat-Medium;
    color: #1e1e1e;
    letter-spacing: 0.6px;
    line-height: 13px;
    display: flex;
    align-items: center;
  }

  .bPer {
    font-size: 10px !important;
    letter-spacing: 0.6px !important;
    line-height: 13px !important;
    margin-left: 2px !important;
  }

  .tBatterySec img {
    height: 10px !important;
  }

  .fleetBlk .t_signals {
    padding: 0px !important;
  }

  .charging_remain {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #959595;
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 8px;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 0.95px;
  }

  .autodriveButton {
    height: 24px !important;
    min-width: 114px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px !important;
    font-size: 8px !important;
    letter-spacing: 0.48px !important;
  }

  .autodriveButton img {
    height: 10px !important;
  }

  .autodriveButton .routeIcon,
  .autodriveButton .routeIcon2 {
    margin-right: 0px !important;
  }

  .t_driver_name {
    font-size: 8px !important;
    letter-spacing: 0.08px !important;
    line-height: 10px !important;
  }

  .t_signals_img .mr4 {
    margin-right: 1px !important;
  }

  .parentAccordion .fleetOverallBlk {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .listExpand .alignCenter {
    /* font-size: 10px !important; */
    font-family: 'Montserrat-Regular';
  }

  .listExpand h2.alignCenter {
    /* font-size: 14px !important; */
    font-family: 'Montserrat-Medium';
  }

  .charging_remain {
    top: 8px !important;
  }

  .hImpNameSel {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
    color: #000000;
  }

  .hImpNameDbl {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
    color: #b6b6b7;
  }

  .tImplementSec {
    width: 66px !important;
    height: 17px !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
    font-size: 8px !important;
    letter-spacing: 0.08px !important;
    line-height: 10px !important;
  }

  .tImplementSec img {
    margin-right: 6px;
  }

  .tSpeedtSec {
    display: flex;
    align-items: center;
  }

  .tSpeedtSec img {
    width: 12px !important;
    height: 6px !important;
  }

  .tBatterySec {
    display: flex;
    align-items: center;
  }

  .tSignalSec {
    display: flex;
    align-items: center;
  }

  .fleetBlk .t_signals.t_error {
    margin: 0;
    padding: 0;
  }

  .fleetBlk .t_signals.t_error .mr4 {
    margin: 0;
  }

  .battery-blk {
    display: flex;
  }

  .speedTxt {
    font-family: Montserrat-Medium;
    font-size: 15px;
    color: #4d4d4d;
    margin-left: 3px;
    width: auto;
    display: inline-block;
    text-align: right;
  }

  .tspeed {
    font-size: 10px !important;
    letter-spacing: 0.6px !important;
    margin-left: 3px !important;
    margin-right: 2px !important;
    line-height: 10px !important;
  }

  .tsMPH {
    font-size: 6px !important;
    letter-spacing: 0.36px !important;
    padding-top: 4px !important;
    line-height: 8px !important;
  }

  .fleetlistBlk .fListSearchMain .ant-input,
  .fListSearchMain .ant-input {
    font-family: 'Montserrat-Medium';
    font-size: 12px !important;
    letter-spacing: 0.09px !important;
    line-height: 15px !important;
    color: #fff !important;
  }

  .parentIcon .ant-collapse-extra img {
    width: 15px !important;
    height: 11px !important;
  }

  .parentAccordion .ant-collapse-header .ant-collapse-extra {
    padding-right: 12px !important;
  }

  .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 300px) !important;
  }

  .fleetTabs .childAccordion2 {
    max-height: calc(100vh - 300px) !important;
  }

  .dataLayerRowHead {
    padding: 16px 5px 0px 14px !important;
  }

  .mpLayerHead .dataIcon img {
    height: 12px !important;
  }

  .mplStatus .mplItem img {
    height: 24px !important;
  }

  .mplStatus2 .mplItem img {
    height: 24px !important;
  }

  .mplItem img.mplMl12 {
    height: 34px !important;
  }

  .mplStatus2 .mplMl12 {
    margin-left: -4px;
    margin-right: 6px;
  }

  .dataHead {
    font-size: 12px !important;
    margin-left: 10px !important;
    letter-spacing: 0.1px !important;
    line-height: 10px !important;
  }

  .dataLayerContainer {
    padding: 0px 16px 0px 16px !important;
  }

  .mplChkBox .checkBoxItem span.layerName {
    font-size: 9px !important;
  }

  .mplItemlbl {
    font-size: 9px !important;
    letter-spacing: 0.55px !important;
  }

  .mplItem .mr15 {
    margin-right: 10px !important;
  }

  /* .fleetTabs .ant-tabs-content-holder {
  max-height: calc(100vh - 295px) !important;
}
.fleetTabs .childAccordion2 {
  max-height: calc(100vh - 295px) !important;
} */
  .mapLayerIcon,
  .mapLayerIcon:hover {
    background-size: 45px !important;
    width: 45px !important;
    height: 45px !important;
    bottom: 18px !important;
    left: 18px !important;
  }

  .rightWidgetView .functionalityTag .ant-tag {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.25px !important;
    line-height: 15px !important;
    padding: 8px 10px !important;
  }

  .rightWidgetView .ant-upload .editImgs {
    position: absolute;
    right: 20px !important;
    /* bottom: 25px !important; */
    bottom: 2px !important;
    cursor: pointer;
    transform: unset !important;
  }

  .rightWidgetView .ant-upload .editImgs img {
    width: 16px !important;
  }

  .autonomyBtns {
    bottom: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .rightWidgetView .input::placeholder {
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 13px;
  }

  .rightWidgetView textarea::placeholder {
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 13px;
  }

  .rightWidgetView .ant-select-selection-placeholder {
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 13px;
  }

  .genric_toggle_sec {
    font-size: 12px !important;
    letter-spacing: 0.19px !important;
    line-height: 13px !important;
    height: 37px !important;
  }

  .tractorPic img {
    width: 148px !important;
    height: 148px !important;
  }

  .tractorList {
    padding-left: 16px !important;
  }

  .editFleetButtons {
    bottom: 35px !important;
  }

  .proInputMain {
    margin-bottom: 66px !important;
  }

  .addEmpBlk .proPic .ant-avatar {
    width: 109px !important;
    height: 109px !important;
  }

  .addEmpBlk.rightWidgetView .editImgs {
    right: 8px !important;
    bottom: 8px !important;
  }

  /* Right side widget details section styles goes here **********************/

  .rightSideWidegt {
    right: -363px !important;
    width: 363px !important;
  }

  .rightWidgetView {
    padding: 0px 30px 20px 30px !important;
  }

  .widgetCloseBtn img {
    width: 11px !important;
    height: 11px !important;
  }

  .widgetCloseBtn {
    padding: 31px 31px 25px 31px !important;
  }

  .tcktInfo {
    margin-bottom: 38px;
  }

  .status_value {
    padding: 6px 12px !important;
  }

  .fwdBtnsSec .TktFwdBtn {
    padding: 12px 19px !important;
  }

  .fwdBtnsSec .TktRslBtn {
    padding: 12px 28px !important;
  }

  .ticketComments {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.12px !important;
    line-height: 19px !important;
  }

  .createTcktTxt {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.12px !important;
    line-height: 19px !important;
  }

  .widgetBtns {
    bottom: 30px !important;
  }

  .saveTxtBtn button {
    right: 30px !important;
    bottom: 44px !important;
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.07px !important;
    line-height: 15px !important;
  }

  .impEditBtn button {
    right: 30px !important;
    bottom: 44px !important;
    font-size: 12px;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 1.07px;
    line-height: 15px;
  }

  .impAutonomyBtn button {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 1.07px !important;
    line-height: 15px !important;
    padding: 8px 12px !important;
    bottom: 36px !important;
    left: 30px !important;
  }

  .profileEdit .ant-tag-checkable,
  .tagsBlk .ant-tag-checkable,
  .rightWidgetView .tag-chip-selected {
    padding: 8px 12px !important;
  }

  .rightWidgetView .tagsBlk .tags {
    font-size: 10px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.4px !important;
    line-height: 13px !important;
    padding: 8px 12px !important;
  }

  .rightWidgetView .createTcktFlag img {
    padding: 7px 9px !important;
    margin-left: 16px !important;
  }

  .rightWidgetView .ant-select-selector,
  .rightWidgetView .ant-input {
    font-size: 12px !important;
    letter-spacing: 0.19px !important;
    line-height: 13px !important;
    min-height: 37px !important;
  }

  .rightWidgetView
    .selectImplementContainer
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 37px !important;
  }

  .rightWidgetView .ant-select-selector .ant-select-selection-item {
    font-size: 12px !important;
    letter-spacing: 0.19px !important;
    line-height: 13px !important;
    height: 37px !important;
  }

  .rightWidgetView textarea {
    font-size: 14px !important;
    letter-spacing: 0.44px !important;
    line-height: 22px !important;
  }

  .rightWidgetView .proPic .ant-avatar {
    width: 134px !important;
    height: 134px !important;
  }

  /* autodrive details styles ***************************/

  .rautoDriveRightSec {
    width: 362px !important;
  }

  .tglexpand {
    right: 362px !important;
  }

  .afterMapRightAlign .positionMap-inner {
    right: 366px !important;
    transition: 1s !important;
  }

  .adtTopBox {
    margin: 0 15px;
  }

  .adTabsContentSec {
    padding: 0px 14px 0 14px !important;
  }

  .adTabsHeadSec {
    padding: 30px 14px 30px 14px !important;
  }

  .autoDriveCamViews iframe#cammms {
    height: 178px !important;
  }

  .tractorSpeedometer .progressbar {
    width: 179px !important;
    height: 179px !important;
  }

  .t_speed_cont .type {
    font-size: 10px !important;
  }

  .t_speed_cont .speed {
    font-size: 22px !important;
    letter-spacing: -1.5px;
  }

  .t_speed_cont .measure {
    font-size: 8px !important;
  }

  .ts_autodrive {
    background-size: 85% !important;
    margin-top: 0px !important;
  }

  .tractorSpeedometer .t_speed_cont {
    top: 0px !important;
  }

  .adtabsHeadText {
    width: 225px !important;
    min-width: 225px !important;
    max-width: 225px !important;
  }

  .tractorPasued {
    top: 2px;
  }

  .camera_box {
    padding: 10px 12px !important;
  }

  .autoDriveCamViews iframe#cammms {
    height: 150px !important;
  }

  .autoDrivetime {
    min-width: 65px !important;
  }

  .autoDrivetime .body2 {
    letter-spacing: -1px !important;
  }

  .autodriveStatusIcons .t_signals {
    width: 63px !important;
    box-sizing: border-box !important;
  }

  .aDname {
    width: 130px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .tractorSpeedometer .t_info_progress_circle {
    height: inherit !important;
  }

  .ts_manual {
    margin-top: 0px !important;
    width: 100px !important;
  }

  .tractorSpeedometer .tractor_status_img {
    width: calc(100% - 42px) !important;
    height: calc(100% - 42px) !important;
  }

  .tOperationDtlSec {
    width: calc(100% - 32px) !important;
    gap: 32px 32px !important;
  }

  .tOperationDtlSec {
    margin-bottom: 24px !important;
  }

  .tractorSpeedometer {
    margin-bottom: 24px !important;
  }

  .adTabsContentSec .rautoDriveStatus {
    margin-bottom: 24px !important;
  }

  .newCamBtns,
  .newCamBtns button.camera_box {
    height: 30px !important;
  }

  .camera_view_buttons {
    height: 33px !important;
  }

  .remoteOperationsPopup {
    padding: 5px 15px 5px 15px !important;
    min-height: 98px !important;
    left: 88px !important;
    width: 265px !important;
    bottom: 80px !important;
    min-height: 89px !important;
  }

  .adrOpTime {
    width: 80px !important;
  }

  .adroutineTimerBtns img {
    height: 30px !important;
  }

  .adrTime_rBtn {
    margin-bottom: 8px !important;
  }

  .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 295px) !important;
  }

  .fleetTabs .childAccordion2 {
    max-height: calc(100vh - 295px) !important;
  }

  .dmenHandle .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 410px) !important;
  }

  .dmenHandle .childAccordion2 {
    max-height: calc(100vh - 410px) !important;
  }

  /* personal tab view and edit employee styles goes here ******************/
  .editRoles2 {
    right: 30px !important;
    bottom: 44px !important;
  }

  .addEmpBlk.rightWidgetView .proPic .ant-avatar {
    width: 109px !important;
    height: 109px !important;
  }

  .rightWidgetView .proPic .ant-avatar .ant-avatar-string {
    width: 134px !important;
    height: 134px !important;
    font-size: 50px !important;
  }

  .teambtn button {
    padding: 9px 20px !important;
  }

  .rowGap55 {
    margin-top: 30px !important;
  }

  .filterGap3 {
    height: calc(120px - 50px) !important;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .company_logo {
    background-size: 70% !important;
  }
  .main-menu:hover .company_logo {
    background-size: 100% !important;
  }
}

@media (min-width: 1281px) and (max-width: 1366px) {
  .implementImage {
    max-height: 200px !important;
    min-height: 200px !important;
    border-radius: 0px !important;
  }
  .auto-drive-block {
    top: 52px !important;
  }
  .autodriveClose {
    top: 20px !important;
    right: 20px !important;
  }

  .employee-teams {
    overflow-y: hidden !important;
    min-height: 160px !important;
  }
  .profileHeight {
    max-height: calc(100vh - 270px) !important;
  }
  .addEmpBlk .addEmployeeSelect.multiSelectField .ant-select-selector {
    min-height: 37px !important;
    height: auto !important;
    max-height: 60px !important;
  }
  .profile-details .profileEditImg {
    left: unset !important;
    right: 0 !important;
    bottom: 45px !important;
    width: 20px !important;
  }

  .popupStyles .formRow .commInput,
  .popupStyles .formRow .ant-select {
    height: 37px !important;
  }
  .popupStyles .ant-form-item-control .ant-input {
    height: 37px !important;
  }

  .ticketsSearchBar .anticon-search svg {
    width: 10px !important;
    height: 10px !important;
  }
  .searchSec.ticketsSearchBar .ant-input-search input {
    padding-left: 8px !important;
  }
  .tcktsBlk .ticketsSearchBar .anticon-search {
    padding-left: 0px !important;
    margin-top: 0px !important;
  }

  .tractorDetailsAlertBox .terrImgBlock {
    min-width: 50px !important;
  }
  .tractorDetailsAlertBox .terrImgBlock img {
    margin-top: 18px !important;
    width: 24px !important;
  }

  .tractorDetailsAlertBox {
    left: -4px !important;
    min-height: 195px !important;
    padding: 20px 15px !important;
    width: 302px !important;
  }
  .tractorDetailsAlertBox1 {
    min-height: 130px !important;
  }
  .tractorDetailsAlertBox button {
    min-width: fit-content !important;
  }
  .localizationDown .terrImgBlock img {
    margin-top: 24px !important;
    width: 24px !important;
  }
  .localizationDown .terrImgBlock {
    min-width: 55px !important;
  }

  .seeDtl {
    height: calc(100vh - 190px) !important;
  }

  .talertPopup .tblDft.farmTabsTbl.dsTbl {
    height: calc(100vh - 260px) !important;
    max-height: calc(100vh - 260px) !important;
  }
  .talertPopup .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
    max-height: calc(100vh - 285px) !important;
  }
  .talertPopup .tblDft.farmTabsTbl.dsTbl div#empTbl {
    min-height: calc(100vh - 285px) !important;
  }

  /* autonomy settings popup styles ********************/

  .autonomy_modal_sec .ant-input {
    height: 37px !important;
  }
  .autonomy_modal_sec .ant-select-selector {
    height: 37px !important;
  }
  .autonomy_modal_sec .generictoggle {
    height: 37px !important;
  }
  .okBtn.autonomySaveBtn {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 1.07px !important;
    line-height: 15px !important;
  }
  .AD_term_content .ql-editor {
    font-size: 14px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 18px !important;
  }
  .tcBtns2,
  .tcBtns2:focus,
  .tcBtns2:hover {
    width: 165px !important;
    height: 40px !important;
  }

  /* model popup styles goes here *********************** */
  .teamEmpContainer .ant-input::placeholder,
  .teamEmpContainer .ant-select-selection-placeholder,
  .teammodal input::placeholder,
  .teammodal .ant-select-selection-placeholder {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }
  .team-text-area .ant-input,
  .teammodal .ant-input,
  .team-text-area .ant-input::placeholder {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }

  .team-text-area-2 .ant-input,
  .teammodal .ant-input,
  .team-text-area-2 .ant-input::placeholder {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }
  /* model popup styles end here *********************** */

  .change-ps {
    height: 50px !important;
  }

  .employee-teams {
    padding: 20px 0px !important;
  }

  .my-profile .permission-sub {
    min-height: calc(100vh - 550px) !important;
    max-height: calc(100vh - 550px) !important;
  }

  .analyzerContainer {
    top: 60px !important;
  }

  .tractorOperDtls {
    width: 330px !important;
  }

  .sparyMapContainer {
    width: calc(100% - 330px) !important;
  }

  .filterGap3 {
    height: calc(120px - 50px) !important;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .rowGap55 {
    margin-top: 30px !important;
  }

  .employee-profile {
    width: 342px !important;
    padding: 30px 30px !important;
  }
  .profile-details .ant-avatar {
    width: 100px !important;
    height: 100px !important;
    min-width: 100px !important;
    min-height: 100px !important;
    margin-bottom: 15px !important;
  }
  .profile-details {
    margin-bottom: 30px !important;
  }

  .employee-permissions {
    margin-left: 2.45vw !important;
    width: calc(100% - 495px) !important;
  }

  .teambtn button {
    padding: 9px 20px !important;
  }

  .filterGap40 {
    height: calc(97px - 56px) !important;
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .selectTractorDiag1 .tab-label,
  .selectTractorDiag1 .tab-label:hover {
    height: 50px !important;
  }

  .rowGap40 {
    margin-top: 30px !important;
  }

  /* side menu css goes here */

  .main-menu,
  .sideMenuSec {
    width: 68px !important;
    flex: 0 0 68px !important;
    min-width: 68px !important;
  }

  .main-menu:hover,
  nav.main-menu.expanded {
    width: 164px !important;
  }

  .iconBox {
    margin-left: 16px !important;
    margin-right: 16px !important;
    width: 36px !important;
    height: 36px !important;
  }

  .iconBoxProfile {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .iconBoxProfile .ant-avatar {
    width: 36px !important;
    height: 36px !important;
  }

  .overline2 {
    font-size: 12px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.125px !important;
    line-height: 15px !important;
  }

  .main-menu li {
    width: 164px !important;
  }

  .iconBox div {
    background-size: 80% !important;
    background-position: center center;
  }

  .company_logo {
    background-size: 70% !important;
  }
  .main-menu:hover .company_logo {
    background-size: 100% !important;
  }

  /* side menu css goes end */

  /* tabs and table css goes here */
  .elTabs > .ant-tabs-nav {
    height: 102px !important;
    /* margin-bottom: 19px !important; */
  }

  .elTabs .ant-tabs-nav-wrap {
    padding-bottom: 28px;
  }

  .elTabs .ant-tabs-tab-btn,
  .attContainer .ant-tabs-tab-btn {
    font-size: 10px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.89px !important;
    /* line-height: 13px !important; */
  }

  .tag-chip-selected {
    padding: 0px 12px !important;
    height: 27px !important;
  }

  .attContainer .elTabs .tblContainer {
    height: calc(100vh - 123px) !important;
  }

  /* dropdown css ************************************/

  .searchField .ant-input-search,
  .searchSec .ant-input-search {
    width: 228px !important;
    height: 32px !important;
  }

  .viewportContainer .searchSec .ant-input-group-addon button,
  .searchSec .ant-input-group-addon button {
    height: 32px !important;
    padding-left: 10px !important;
  }

  .searchFilters .searchSec .anticon-search {
    font-size: 10px !important;
  }

  .searchSec .ant-input-search input {
    font-size: 10px !important;
    min-height: 32px !important;
    max-height: 32px !important;
    padding-left: 33px !important;
  }

  .searchSec .ant-input-search input::placeholder {
    font-size: 10px !important;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.107px !important;
    line-height: 13px !important;
  }

  .searchFilters .tab-label,
  .searchFilters .tab-label:hover {
    height: 32px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .searchFilters .flbTopFixed {
    font-size: 8px !important;
    letter-spacing: 0.08px !important;
    line-height: 10px !important;
  }

  .searchFilters .flbSelected {
    font-size: 10px !important;
    letter-spacing: 0.16px !important;
    line-height: 8px !important;
  }

  .searchFilters .selGray {
    font-size: 10px !important;
    letter-spacing: 0.16px !important;
    line-height: 8px !important;
  }

  .tab-label,
  .tab-label:hover {
    height: 32px !important;
  }

  .searchFilters .dateFilter {
    margin-left: 15px !important;
    margin-right: 0 !important;
    height: 32px !important;
  }

  .searchFilters .dateFilter .ant-picker-input > input {
    font-size: 10px !important;
    font-family: 'Montserrat-Regular';
    letter-spacing: 0.16px !important;
    line-height: 8px !important;
  }

  .searchFilters .dW250_date {
    width: 270px !important;
  }

  .smallFilter .flblWrapper {
    min-width: 108px !important;
    width: 108px !important;
    height: 32px !important;
    margin-left: 16px !important;
  }

  .medFilter .flblWrapper {
    min-width: 145px !important;
    width: 145px !important;
    height: 32px !important;
    margin-left: 15px !important;
  }

  .singleDatePicker {
    height: 32px !important;
  }

  .searchFilters .tractorField .flblWrapper {
    height: 32px !important;
  }

  .searchFilters .customField {
    margin-left: 15px !important;
  }

  .searchFilters .customField .ant-select-selector {
    height: 32px !important;
    font-size: 10px !important;
    letter-spacing: 0.16px !important;
    line-height: 8px !important;
    margin-right: 0 !important;
  }

  .searchFilters .ant-picker-input > input {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.19px !important;
    line-height: 10px !important;
  }

  .tblContainer .m_tb {
    margin-top: 5px !important;
    margin-bottom: 24px !important;
  }

  .attContainer .elTabs .ant-tabs-content-holder,
  .attContainer .elTabs .ant-tabs-content {
    height: calc(100vh - 102px) !important;
  }

  .tblDft.farmTabsTbl {
    height: calc(100vh - 260px) !important;
    max-height: calc(100vh - 260px) !important;
  }

  .tblDft.farmTabsTbl .infinite-scroll-component {
    max-height: calc(100vh - 285px) !important;
  }

  .tblDft.farmTabsTbl div#empTbl {
    min-height: calc(100vh - 285px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl {
    height: calc(100vh - 485px) !important;
    max-height: calc(100vh - 485px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
    max-height: calc(100vh - 510px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl div#empTbl {
    min-height: calc(100vh - 510px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl {
    height: calc(100vh - 300px) !important;
    max-height: calc(100vh - 300px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl .infinite-scroll-component {
    max-height: calc(100vh - 310px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl div#empTbl {
    min-height: calc(100vh - 310px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl {
    height: calc(100vh - 270px) !important;
    max-height: calc(100vh - 270px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl .infinite-scroll-component {
    max-height: calc(100vh - 295px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl div#empTbl {
    min-height: calc(100vh - 295px) !important;
  }

  /* liveops css media styles goes here ****************************/

  .fleetlistBlk {
    left: 17px !important;
    top: 36px !important;
    width: 267px !important;
  }

  .searchMapBlk {
    margin-bottom: 17px !important;
  }

  .fleetlistBlk .searchMapBlk .ant-input {
    height: 37.5px !important;
  }

  /* .fleetlistBlk .searchMapBlk .ant-input::placeholder {
  font-size: 20px !important;
} */

  .fleetlistBlk {
    position: absolute;
    left: 24px;
    top: 50px;
    z-index: 9;
    width: 358px;
  }

  .flIcons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flIcons img {
    margin-right: 4px;
  }

  .fleetTabs .ant-tabs-tab.tractorTab {
    /* width: 87px !important; */
    height: 27px !important;
  }

  .fleetTabs .tabTractorImg,
  .fleetTabs .tabLockImg {
    height: 10px !important;
  }

  .fleetTabs .tractorTab .ant-tabs-tab-btn {
    padding: 0px !important;
  }

  .mapLayerIcon {
    background-image: url(../../assets/images/mapLayersIcon.png);
    position: absolute;
    bottom: 15px;
    left: 25px;
    z-index: 5;
    width: 81px;
    height: 81px;
    background-repeat: no-repeat;
    display: block;
    cursor: pointer;
  }

  .mapLayerIcon:hover {
    background-image: url(../../assets/images/mapLayersIcon_hv.png);
    position: absolute;
    bottom: 25px;
    left: 25px;
    z-index: 5;
    width: 81px;
    height: 81px;
    background-repeat: no-repeat;
    display: block;
    cursor: pointer;
  }

  .bottomMapLayers {
    position: absolute;
    bottom: 23px;
    left: 25px;
    width: 0px;
    height: 0;
    /* min-width: 360px; */
    background: #eaeaea;
    height: 0;
    z-index: 99;
    box-shadow: -1px 0px 6px rgb(0 0 0 / 29%);
    transition: 1s;
    border-radius: 10px;
  }

  .bottomMapLayers .dataLayerSec {
    overflow-y: auto;
    height: calc(100vh - 230px);
  }

  .mpOpen {
    visibility: visible;
    animation: resize 0.2s;
    width: 267px !important;
    height: calc(100vh - 108px) !important;
  }

  .mpClose {
    animation: resizeClose 0.2s;
    visibility: hidden;
  }

  .bottomMapLayers {
    bottom: 24px !important;
    left: 17px !important;
  }

  .operationsTitle .ant-collapse-header {
    padding: 10px 12px 10px 12px !important;
    font-size: 12px !important;
    letter-spacing: 1.07px !important;
    line-height: 15px !important;
    height: 38px !important;
    display: flex !important;
    align-items: center !important;
  }

  .parentIcon .anticon {
    right: 8px !important;
  }

  .fleetTabs .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 10px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.33px !important;
    line-height: 13px !important;
    padding: 0 3px !important;
  }

  .fleetTabs .ant-tabs-nav {
    height: 27px !important;
    padding: 0 10px !important;
    margin-top: 16px !important;
    margin-bottom: 17px !important;
  }

  .parentAccordion .fleetBlk:first-child {
    margin-top: 0px !important;
  }

  .t_name {
    width: 130px !important;
    font-size: 12px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.4px !important;
    line-height: 15px !important;
  }

  .ttractor_state {
    min-height: 17px !important;
    min-width: 74px !important;
    font-size: 8px !important;
    letter-spacing: 0.71px !important;
    line-height: 10px !important;
  }

  .parentAccordion .fleetBlk {
    border-radius: 8px !important;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    padding: 8px 7px 8px 7px !important;
    min-height: 70px !important;
    max-height: 75px !important;
    /* width: 247px !important; */
  }

  .fleetBlk .ant-progress-bg {
    height: 6.5px !important;
  }

  .fleetBlk .tractorDtlSec.mbottom {
    margin-bottom: 7px !important;
  }

  .fleetBlk .tractorDtlSec.mbottom.mtop {
    margin-top: 12px !important;
    margin-bottom: 0px !important;
  }

  .fleetBlk .tractorDtlSec1 .t_signals,
  .fleetBlk .t_signals {
    padding: 0px !important;
  }

  .fleetOverallBlk .fleetBlk:hover {
    border: 1px solid #366376;
  }
  .fleetOverallBlk .fleetBlk-selected,
  .fleetTractorsInrBlk .fleetBlk-selected {
    border: 2px solid #366376 !important;
  }

  .fleetBlk .tractorDtlSec.mb18 {
    margin-bottom: 16px !important;
  }

  .fleetBlk .tractorDtlSec.mb18.mt10 {
    margin-top: 16px !important;
    margin-bottom: 8px !important;
    height: 16px !important;
  }

  .tractorDtlSec1 {
    height: 17px !important;
  }

  .fleetTabs .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0px !important;
  }

  .t_battery {
    font-size: 10px;
    font-family: Montserrat-Medium;
    color: #1e1e1e;
    letter-spacing: 0.6px;
    line-height: 13px;
    display: flex;
    align-items: center;
  }

  .bPer {
    font-size: 10px !important;
    letter-spacing: 0.6px !important;
    line-height: 13px !important;
    margin-left: 2px !important;
  }

  .tBatterySec img {
    height: 10px !important;
  }

  .fleetBlk .t_signals {
    padding: 0px !important;
  }

  .charging_remain {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #959595;
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 8px;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 0.95px;
  }

  .autodriveButton {
    height: 24px !important;
    min-width: 114px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px !important;
    font-size: 8px !important;
    letter-spacing: 0.48px !important;
  }

  .autodriveButton img {
    height: 10px !important;
  }

  .autodriveButton .routeIcon,
  .autodriveButton .routeIcon2 {
    margin-right: 0px !important;
  }

  .t_driver_name {
    font-size: 8px !important;
    letter-spacing: 0.08px !important;
    line-height: 10px !important;
  }

  .t_signals_img .mr4 {
    margin-right: 1px !important;
  }

  .parentAccordion .fleetOverallBlk {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .listExpand .alignCenter {
    font-size: 10px !important;
    font-family: 'Montserrat-Regular';
  }

  .listExpand h2.alignCenter {
    /* font-size: 14px !important; */
    font-family: 'Montserrat-Medium';
  }

  .charging_remain {
    top: 8px !important;
  }

  .hImpNameSel {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
    color: #000000;
  }

  .hImpNameDbl {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
    color: #b6b6b7;
  }

  .tImplementSec {
    width: 66px !important;
    height: 17px !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
    font-size: 8px !important;
    letter-spacing: 0.08px !important;
    line-height: 10px !important;
  }

  .tImplementSec img {
    margin-right: 6px;
  }

  .tSpeedtSec {
    display: flex;
    align-items: center;
  }

  .tSpeedtSec img {
    width: 12px !important;
    height: 6px !important;
  }

  .tBatterySec {
    display: flex;
    align-items: center;
  }

  .tSignalSec {
    display: flex;
    align-items: center;
  }

  .fleetBlk .t_signals.t_error {
    margin: 0;
    padding: 0;
  }

  .fleetBlk .t_signals.t_error .mr4 {
    margin: 0;
  }

  .battery-blk {
    display: flex;
  }

  .speedTxt {
    font-family: Montserrat-Medium;
    font-size: 15px;
    color: #4d4d4d;
    margin-left: 3px;
    width: auto;
    display: inline-block;
    text-align: right;
  }

  .tspeed {
    font-size: 10px !important;
    letter-spacing: 0.6px !important;
    margin-left: 3px !important;
    margin-right: 2px !important;
    line-height: 10px !important;
  }

  .tsMPH {
    font-size: 6px !important;
    letter-spacing: 0.36px !important;
    padding-top: 4px !important;
    line-height: 8px !important;
  }

  .fleetlistBlk .fListSearchMain .ant-input,
  .fListSearchMain .ant-input {
    font-family: 'Montserrat-Medium';
    font-size: 12px !important;
    letter-spacing: 0.09px !important;
    line-height: 15px !important;
    color: #fff !important;
  }

  .parentIcon .ant-collapse-extra img {
    width: 15px !important;
    height: 11px !important;
  }

  .parentAccordion .ant-collapse-header .ant-collapse-extra {
    padding-right: 12px !important;
  }

  .dataLayerRowHead {
    padding: 16px 5px 0px 14px !important;
  }

  .mpLayerHead .dataIcon img {
    height: 12px !important;
  }

  .mplStatus .mplItem img {
    height: 24px !important;
  }

  .mplStatus2 .mplItem img {
    height: 24px !important;
  }

  .mplItem img.mplMl12 {
    height: 34px !important;
  }

  .mplStatus2 .mplMl12 {
    margin-left: -4px;
    margin-right: 6px;
  }

  .dataHead {
    font-size: 12px !important;
    margin-left: 10px !important;
    letter-spacing: 0.1px !important;
    line-height: 10px !important;
  }

  .dataLayerContainer {
    padding: 0px 16px 0px 16px !important;
  }

  .mplChkBox .checkBoxItem span.layerName {
    font-size: 9px !important;
  }

  .mplItemlbl {
    font-size: 9px !important;
    letter-spacing: 0.55px !important;
  }

  .mplItem .mr15 {
    margin-right: 10px !important;
  }

  .mapLayerIcon,
  .mapLayerIcon:hover {
    background-size: 53px !important;
    width: 53px !important;
    height: 53px !important;
    bottom: 16px !important;
    left: 10px !important;
  }

  .mpOpen {
    visibility: visible !important;
    animation: resize 0.2s !important;
    width: 267px !important;
    height: calc(100vh - 109px) !important;
  }

  .mapLayerIcon,
  .mapLayerIcon:hover {
    background-size: 45px !important;
    width: 45px !important;
    height: 45px !important;
    bottom: 18px !important;
    left: 18px !important;
  }

  /* .fleetTabs .ant-tabs-content-holder {
  max-height: calc(100vh - 300px) !important;
}
.fleetTabs .childAccordion2 {
  max-height: calc(100vh - 300px) !important;
} */

  .rightWidgetView .functionalityTag .ant-tag {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.25px !important;
    line-height: 15px !important;
    padding: 8px 10px !important;
  }

  .rightWidgetView .ant-upload .editImgs {
    position: absolute;
    right: 20px !important;
    /* bottom: 25px !important; */
    bottom: 4px !important;
    cursor: pointer;
  }

  .rightWidgetView .ant-upload .editImgs img {
    width: 13px !important;
  }

  .autonomyBtns {
    bottom: 30px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .rightWidgetView .input::placeholder {
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 13px;
  }

  .rightWidgetView textarea::placeholder {
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 13px;
  }

  .rightWidgetView .ant-select-selection-placeholder {
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 13px;
  }

  .genric_toggle_sec {
    font-size: 12px !important;
    letter-spacing: 0.19px !important;
    line-height: 13px !important;
    height: 37px !important;
  }

  .tractorPic img {
    width: 140px !important;
    height: 140px !important;
  }

  .tractorList {
    padding-left: 16px !important;
  }

  .editFleetButtons {
    bottom: 35px !important;
  }

  .proInputMain {
    margin-bottom: 66px !important;
  }

  .addEmpBlk .proPic .ant-avatar {
    width: 102px !important;
    height: 102px !important;
  }

  .addEmpBlk.rightWidgetView .editImgs {
    right: 8px !important;
    bottom: 8px !important;
  }

  /* Right side widget details section styles goes here **********************/

  .rightSideWidegt {
    right: -342px !important;
    width: 342px !important;
  }

  .rightWidgetView {
    padding: 0px 24px 20px 24px !important;
  }

  .widgetCloseBtn img {
    width: 11px !important;
    height: 11px !important;
  }

  .widgetCloseBtn {
    padding: 31px 30px 24px 30px !important;
  }

  .status_value {
    padding: 8px 12px !important;
  }

  .tcktInfo {
    margin-bottom: 38px;
  }

  .fwdBtnsSec .TktFwdBtn {
    padding: 12px 19px !important;
  }

  .fwdBtnsSec .TktRslBtn {
    padding: 12px 24px !important;
  }

  .ticketComments {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.12px !important;
    line-height: 19px !important;
  }

  .createTcktTxt {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.12px !important;
    line-height: 19px !important;
  }

  .widgetBtns {
    bottom: 30px !important;
  }

  .saveTxtBtn button {
    right: 30px !important;
    bottom: 43px !important;
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.07px !important;
    line-height: 15px !important;
  }

  .impEditBtn button {
    right: 30px !important;
    bottom: 43px !important;
    font-size: 12px;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 1.07px;
    line-height: 15px;
  }

  .impAutonomyBtn button {
    font-size: 12px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.07px !important;
    line-height: 15px !important;
    padding: 8px 10px !important;
    bottom: 36px !important;
    left: 24px !important;
  }

  .profileEdit .ant-tag-checkable,
  .tagsBlk .ant-tag-checkable,
  .rightWidgetView .tag-chip-selected {
    padding: 8px 12px !important;
  }

  .rightWidgetView .tagsBlk .tags {
    font-size: 10px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.4px !important;
    line-height: 13px !important;
    padding: 8px 12px !important;
  }

  .rightWidgetView .createTcktFlag img {
    padding: 7px 9px !important;
    margin-left: 16px !important;
  }

  .rightWidgetView .ant-select-selector,
  .rightWidgetView .ant-input {
    font-size: 12px !important;
    letter-spacing: 0.19px !important;
    line-height: 13px !important;
    min-height: 37px !important;
  }

  .rightWidgetView
    .selectImplementContainer
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 37px !important;
  }

  .rightWidgetView .ant-select-selector .ant-select-selection-item {
    font-size: 12px !important;
    letter-spacing: 0.19px !important;
    line-height: 13px !important;
    height: 37px !important;
  }

  .rightWidgetView textarea {
    font-size: 14px !important;
    letter-spacing: 0.44px !important;
    line-height: 22px !important;
  }

  .rightWidgetView .proPic .ant-avatar {
    width: 126px !important;
    height: 126px !important;
  }

  /* autodrive details styles ***************************/

  .rautoDriveRightSec {
    width: 343px !important;
  }

  .tglexpand {
    right: 343px !important;
  }

  .afterMapRightAlign .positionMap-inner {
    right: 347px !important;
    transition: 1s !important;
  }

  .adtTopBox {
    margin: 0 10px !important;
  }

  .adTabsContentSec {
    padding: 0px 12px 0 12px !important;
  }

  .adTabsHeadSec {
    padding: 30px 12px 30px 12px !important;
  }

  .autoDriveCamViews iframe#cammms {
    height: 178px !important;
  }

  .tractorSpeedometer .progressbar {
    width: 156px !important;
    height: 156px !important;
  }

  .t_speed_cont .type {
    font-size: 10px !important;
  }

  .t_speed_cont .speed {
    font-size: 16px !important;
    letter-spacing: -1.5px;
  }

  .t_speed_cont .measure {
    font-size: 8px !important;
  }

  .ts_autodrive {
    background-size: 85% !important;
    margin-top: 0px !important;
  }

  .tractorSpeedometer .t_speed_cont {
    top: -12px !important;
  }

  .adtabsHeadText {
    width: 210px !important;
    min-width: 210px !important;
    max-width: 210px !important;
  }

  .tractorPasued {
    top: 2px;
  }

  .camera_box {
    padding: 10px 12px !important;
  }

  .autoDriveCamViews iframe#cammms {
    height: 150px !important;
  }

  .autoDrivetime {
    min-width: 65px !important;
  }

  .autoDrivetime .body2 {
    letter-spacing: -1px !important;
  }

  .autodriveStatusIcons .t_signals {
    width: 63px !important;
    box-sizing: border-box !important;
  }

  .aDname {
    width: 125px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .tractorSpeedometer .t_info_progress_circle {
    height: inherit !important;
  }

  .ts_manual {
    margin-top: 0px !important;
    width: 100px !important;
    background-size: 80% !important;
    background-position: center 5px !important;
  }

  .tractorSpeedometer .tractor_status_img {
    width: calc(100% - 38px) !important;
    height: calc(100% - 38px) !important;
  }

  .tOperationDtlSec {
    width: calc(100% - 20px) !important;
    gap: 24px 20px !important;
  }

  .tOperationDtlSec {
    margin-bottom: 24px !important;
  }

  .tractorSpeedometer {
    margin-bottom: 24px !important;
  }

  .adTabsContentSec .rautoDriveStatus {
    margin-bottom: 20px !important;
  }

  .newCamBtns,
  .newCamBtns button.camera_box {
    height: 30px !important;
  }

  .camera_view_buttons {
    height: 33px !important;
  }

  .remoteOperationsPopup {
    padding: 5px 15px 5px 15px !important;
    min-height: 98px !important;
    left: 88px !important;
    width: 265px !important;
    bottom: 80px !important;
    min-height: 89px !important;
  }

  .adrOpTime {
    width: 90px !important;
  }

  .adroutineTimerBtns img {
    height: 30px !important;
  }

  .adrTime_rBtn {
    margin-bottom: 8px !important;
  }

  .remoteOperationsPopup p {
    width: 100px !important;
  }

  .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 295px) !important;
  }

  .fleetTabs .childAccordion2 {
    max-height: calc(100vh - 295px) !important;
  }

  .dmenHandle .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 410px) !important;
  }

  .dmenHandle .childAccordion2 {
    max-height: calc(100vh - 410px) !important;
  }

  /* personal tab view and edit employee styles goes here ******************/
  .editRoles2 {
    right: 30px !important;
    bottom: 43px !important;
  }

  .addEmpBlk.rightWidgetView .proPic .ant-avatar {
    width: 102px !important;
    height: 102px !important;
  }

  .rightWidgetView .proPic .ant-avatar .ant-avatar-string {
    width: 126px !important;
    height: 126px !important;
    font-size: 46px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .implementImage {
    max-height: 180px !important;
    min-height: 180px !important;
    border-radius: 0px !important;
  }

  .autodriveClose {
    top: 16px !important;
    right: 16px !important;
  }
  .employee-teams {
    overflow-y: hidden !important;
    min-height: 160px !important;
  }
  .profileHeight {
    max-height: calc(100vh - 260px) !important;
  }
  .userPf .profileHeight .profileDtlWrapper {
    max-height: inherit !important;
  }
  .addEmpBlk .addEmployeeSelect.multiSelectField .ant-select-selector {
    min-height: 37px !important;
    height: auto !important;
    max-height: 60px !important;
  }

  .proeditBtn .TktFwdBtn,
  .proeditBtn .TktRslBtn {
    min-height: 39px;
  }

  .profile-details .profileEditImg {
    left: unset !important;
    right: 2px !important;
    bottom: 45px !important;
    width: 16px !important;
  }

  .popupStyles .formRow .commInput,
  .popupStyles .formRow .ant-select {
    height: 37px !important;
  }
  .popupStyles .ant-form-item-control .ant-input {
    height: 37px !important;
  }

  .ticketsSearchBar .anticon-search svg {
    width: 10px !important;
    height: 10px !important;
  }
  .searchSec.ticketsSearchBar .ant-input-search input {
    padding-left: 8px !important;
  }
  .tcktsBlk .ticketsSearchBar .anticon-search {
    padding-left: 0px !important;
    margin-top: -2px !important;
  }

  .tractorDetailsAlertBox .terrImgBlock {
    min-width: 50px !important;
  }

  .tractorDetailsAlertBox {
    left: -4px !important;
    min-height: 195px !important;
    padding: 20px 15px !important;
    width: 302px !important;
  }
  .tractorDetailsAlertBox1 {
    min-height: 130px !important;
  }
  .tractorDetailsAlertBox button {
    min-width: fit-content !important;
  }
  .tractorDetailsAlertBox .terrImgBlock img {
    margin-top: 18px !important;
    width: 24px !important;
  }
  .localizationDown .terrImgBlock img {
    margin-top: 18px !important;
    width: 24px !important;
  }
  .localizationDown .terrImgBlock {
    min-width: 55px !important;
  }

  .seeDtl {
    height: calc(100vh - 175px) !important;
  }

  .talertPopup .tblDft.farmTabsTbl.dsTbl {
    height: calc(100vh - 260px) !important;
    max-height: calc(100vh - 260px) !important;
  }
  .talertPopup .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
    max-height: calc(100vh - 285px) !important;
  }
  .talertPopup .tblDft.farmTabsTbl.dsTbl div#empTbl {
    min-height: calc(100vh - 285px) !important;
  }

  /* autonomy settings popup styles ********************/
  .autonomy_modal_sec .ant-input {
    height: 37px !important;
  }
  .autonomy_modal_sec .ant-select-selector {
    height: 37px !important;
  }
  .autonomy_modal_sec .generictoggle {
    height: 37px !important;
  }
  .okBtn.autonomySaveBtn {
    font-size: 10px !important;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 0.89px !important;
    line-height: 13px !important;
  }
  .AD_term_content .ql-editor {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 18px !important;
  }
  .tcBtns2,
  .tcBtns2:focus,
  .tcBtns2:hover {
    width: 150px !important;
    height: 37px !important;
  }
  /* model popup styles goes here *********************** */

  .teamEmpContainer input::placeholder,
  .teamEmpContainer .ant-select-selection-placeholder,
  .teammodal input::placeholder,
  .teammodal .ant-select-selection-placeholder {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }
  .team-text-area .ant-input,
  .teammodal .ant-input,
  .team-text-area .ant-input::placeholder {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }
  .team-text-area-2 .ant-input,
  .teammodal .ant-input,
  .team-text-area-2 .ant-input::placeholder {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }

  .teamEmpContainer input::placeholder,
  .teamEmpContainer .ant-select-selection-placeholder,
  .teammodal input::placeholder,
  .teammodal .ant-select-selection-placeholder {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }
  .team-text-area .ant-input,
  .teammodal .ant-input,
  .team-text-area .ant-input::placeholder {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }
  .team-text-area-2 .ant-input,
  .teammodal .ant-input,
  .team-text-area-2 .ant-input::placeholder {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }
  /* model popup styles end here *********************** */

  .userProfileBlk .employee-teams {
    min-height: 120px !important;
    max-height: 120px !important;
  }
  .my-profile .employee-team-sub {
    max-height: 100% !important;
    min-height: 80px !important;
  }
  .employee-teams {
    padding: 20px 0px !important;
  }

  .change-ps {
    height: 50px !important;
  }

  .userPf .employee-details {
    height: calc(100vh - 185px) !important;
  }

  .my-profile .permission-sub {
    min-height: calc(100vh - 550px) !important;
    max-height: calc(100vh - 550px) !important;
  }

  .analyzerContainer {
    top: 60px !important;
  }

  .tractorOperDtls {
    width: 300px !important;
  }

  .sparyMapContainer {
    width: calc(100% - 300px) !important;
  }

  .filterGap3 {
    height: calc(120px - 50px) !important;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .employee-profile {
    width: 324px !important;
    padding: 20px 20px !important;
  }

  .profile-details .ant-avatar {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    margin-bottom: 15px !important;
  }
  .userPf .profileDtlWrapper {
    max-height: calc(100vh - 450px) !important;
  }
  .profile-details {
    margin-bottom: 30px !important;
  }
  .employee-permissions {
    margin-left: 2.45vw !important;
    width: calc(100% - 475px) !important;
  }

  .teambtn button {
    padding: 9px 20px !important;
  }

  .filterGap40 {
    height: calc(90px - 52px) !important;
    margin-top: 26px !important;
    margin-bottom: 26px !important;
  }

  .rowGap40 {
    margin-top: 30px !important;
  }

  /* side menu css goes here */

  .main-menu,
  .sideMenuSec {
    width: 68px !important;
    flex: 0 0 68px !important;
    min-width: 68px !important;
  }

  .main-menu:hover,
  nav.main-menu.expanded {
    width: 164px !important;
  }

  .iconBox {
    margin-left: 16px !important;
    margin-right: 16px !important;
    width: 36px !important;
    height: 36px !important;
  }

  .iconBoxProfile {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .iconBoxProfile .ant-avatar {
    width: 36px !important;
    height: 36px !important;
  }

  .main-menu li {
    width: 160px !important;
  }

  .main-menu:hover .login_logo_box .lLogo {
    min-width: 132px !important;
    max-width: 100% !important;
    min-height: 21px !important;
  }

  .overline2 {
    font-size: 12px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.125px !important;
    line-height: 15px !important;
  }

  .wingSpanAI .company_logo {
    width: 50px !important;
    height: 25px !important;
    background-size: 70% !important;
  }

  .company_logo {
    background-image: url(../../assets/images/login_logo.svg);
    /* width: 35px !important;
    height: 21px !important; */
    width: 50px !important;
    height: 25px !important;
    background-repeat: no-repeat;
    background-size: 70% !important;
    background-position: center center;
    cursor: pointer;
    display: block;
  }

  .wingSpanAI.main-menu:hover .company_logo {
    width: 80px !important;
    height: 37px !important;
    background-size: 100% !important;
  }

  .main-menu:hover .company_logo {
    background-image: url(../../assets/images/login_logo_large.svg);
    /* width: 132px !important;
    height: 21px !important; */
    width: 80px !important;
    height: 37px !important;
    background-repeat: no-repeat;
    background-size: 100% !important;
    background-position: center center;
    cursor: pointer;
    display: block;
  }

  .iconBox div {
    background-size: 80% !important;
    background-position: center center;
  }

  /* side menu css goes end */

  /* tabs and table css goes here */

  .elTabs > .ant-tabs-nav {
    height: 98px !important;
    /* margin-bottom: 16px !important; */
  }

  .elTabs .ant-tabs-nav-wrap {
    padding-bottom: 28px;
  }

  .elTabs .ant-tabs-tab-btn,
  .attContainer .ant-tabs-tab-btn {
    font-size: 10px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.89px !important;
    /* line-height: 13px !important; */
  }

  .elTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #333333 !important;
  }

  .profCropHead {
    min-width: 80px !important;
  }

  .profCropValue {
    min-width: 110px;
  }

  .tag-chip-selected {
    padding: 0px 12px !important;
    height: 27px !important;
  }

  /* dropdown css ************************************/

  .tcktsBlk.searchFilters .ant-space,
  .tcktsBlk .ant-space.searchFilters {
    gap: 10px 0 !important;
  }

  .searchField .ant-input-search,
  .searchSec .ant-input-search {
    width: 174px !important;
    height: 32px !important;
  }

  .viewportContainer .searchSec .ant-input-group-addon button,
  .searchSec .ant-input-group-addon button {
    height: 32px !important;
    padding-left: 10px !important;
  }

  .searchFilters .searchSec .anticon-search {
    font-size: 10px !important;
  }

  .searchSec .ant-input-search input {
    font-size: 10px !important;
    min-height: 32px !important;
    max-height: 32px !important;
    padding-left: 33px !important;
  }

  .searchSec .ant-input-search input::placeholder {
    font-size: 10px !important;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.07px !important;
    line-height: 13px !important;
  }

  .searchFilters .tab-label,
  .searchFilters .tab-label:hover {
    height: 32px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .searchFilters .flbTopFixed {
    font-size: 8px !important;
    letter-spacing: 0.08px !important;
    line-height: 10px !important;
  }

  .searchFilters .flbSelected {
    font-size: 10px !important;
    letter-spacing: 0.16px !important;
    line-height: 8px !important;
  }

  .searchFilters .selGray {
    font-size: 10px !important;
    letter-spacing: 0.16px !important;
    line-height: 8px !important;
  }

  .searchFilters .dateFilter {
    margin-left: 15px !important;
    margin-right: 0 !important;
    height: 32px !important;
  }

  .searchFilters .dateFilter .ant-picker-input > input {
    font-size: 10px !important;
    letter-spacing: 0.16px !important;
    line-height: 8px !important;
  }

  .searchFilters .dW250_date {
    width: 170px !important;
  }

  .smallFilter .flblWrapper {
    min-width: 108px !important;
    width: 108px !important;
    height: 32px !important;
    margin-left: 12px !important;
  }

  .medFilter .flblWrapper {
    min-width: 115px !important;
    width: 115px !important;
    height: 32px !important;
    margin-left: 16px !important;
  }

  .singleDatePicker {
    height: 32px !important;
  }

  .searchFilters .tractorField .flblWrapper {
    height: 32px !important;
  }

  .searchFilters .customField {
    margin-left: 16px !important;
  }

  .searchFilters .customField .ant-select-selector {
    height: 32px !important;
    font-size: 10px !important;
    letter-spacing: 0.16px !important;
    line-height: 8px !important;
    margin-right: 0 !important;
  }

  .searchFilters .ant-picker-input > input {
    font-size: 10px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.16px !important;
    line-height: 8px !important;
  }

  .elTabs.fleetHealth .ant-tabs-nav-wrap {
    justify-content: flex-start !important;
    margin-left: 50px;
    margin-right: 50px;
  }

  /* .fleetHealth .ant-tabs-nav-list:last-child .ant-tabs-tab {
    margin-right: 50px !important;
  } */

  .tblContainer .m_tb {
    margin-top: 5px !important;
    margin-bottom: 21px !important;
  }

  .attContainer .elTabs .tblContainer {
    height: calc(100vh - 123px) !important;
  }

  .attContainer .elTabs .ant-tabs-content-holder,
  .attContainer .elTabs .ant-tabs-content {
    height: calc(100vh - 98px) !important;
  }

  .tblDft.farmTabsTbl {
    height: calc(100vh - 260px) !important;
    max-height: calc(100vh - 260px) !important;
  }

  .tblDft.farmTabsTbl .infinite-scroll-component {
    max-height: calc(100vh - 285px) !important;
  }

  .tblDft.farmTabsTbl div#empTbl {
    min-height: calc(100vh - 285px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl {
    height: calc(100vh - 485px) !important;
    max-height: calc(100vh - 485px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
    max-height: calc(100vh - 510px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl div#empTbl {
    min-height: calc(100vh - 510px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl {
    height: calc(100vh - 300px) !important;
    max-height: calc(100vh - 300px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl .infinite-scroll-component {
    max-height: calc(100vh - 310px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl div#empTbl {
    min-height: calc(100vh - 310px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl {
    height: calc(100vh - 250px) !important;
    max-height: calc(100vh - 250px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl .infinite-scroll-component {
    max-height: calc(100vh - 275px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl div#empTbl {
    min-height: calc(100vh - 275px) !important;
  }

  /* liveops css media styles goes here ****************************/

  .fleetlistBlk {
    left: 14px !important;
    top: 32px !important;
    width: 242px !important;
  }

  .searchMapBlk {
    margin-bottom: 17px !important;
  }

  /* .fleetlistBlk .searchMapBlk .ant-input::placeholder {
  font-size: 20px !important;
 } */

  .fleetlistBlk {
    position: absolute;
    left: 24px;
    top: 50px;
    z-index: 9;
    width: 358px;
  }

  .flIcons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flIcons img {
    margin-right: 4px;
  }

  .fleetTabs .ant-tabs-tab.tractorTab {
    /* width: 87px !important; */
    height: 24px !important;
  }

  .fleetTabs .tabTractorImg,
  .fleetTabs .tabLockImg {
    height: 10px !important;
  }

  .fleetTabs .tractorTab .ant-tabs-tab-btn {
    padding: 0px !important;
  }

  .mapLayerIcon,
  .mapLayerIcon:hover {
    background-size: 45px !important;
    width: 45px !important;
    height: 45px !important;
    bottom: 18px !important;
    left: 14px !important;
  }

  .bottomMapLayers {
    position: absolute;
    bottom: 23px;
    left: 25px;
    width: 0px;
    height: 0;
    /* min-width: 360px; */
    background: #eaeaea;
    height: 0;
    z-index: 99;
    box-shadow: -1px 0px 6px rgb(0 0 0 / 29%);
    transition: 1s;
    border-radius: 10px;
  }

  .bottomMapLayers .dataLayerSec {
    overflow-y: auto;
    height: calc(100vh - 230px);
  }

  .operationsTitle .ant-collapse-header {
    padding: 10px 12px 10px 12px !important;
    font-size: 10px !important;
    letter-spacing: 0.89px !important;
    line-height: 13px !important;
    height: 33px !important;
    display: flex !important;
    align-items: center !important;
  }

  .parentIcon .anticon {
    right: 8px !important;
  }

  .fleetTabs .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 8px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.27px !important;
    line-height: 10px !important;
    padding: 0 6px !important;
  }

  .fleetTabs .ant-tabs-nav {
    height: 24px !important;
    padding: 0 10px !important;
    margin-top: 15px !important;
    margin-bottom: 16px !important;
  }

  .fleetTabs .ant-tabs-ink-bar {
    border: none !important;
  }

  .parentAccordion .fleetBlk:first-child {
    margin-top: 0px !important;
  }

  .t_name {
    width: 120px !important;
    font-size: 10px !important;
    letter-spacing: 0.33px !important;
    line-height: 13px !important;
  }

  .ttractor_state {
    min-height: 14px !important;
    min-width: 68px !important;
    font-size: 7px !important;
    letter-spacing: 0.63px !important;
    line-height: 9px !important;
  }

  .parentAccordion .fleetBlk {
    border-radius: 8px !important;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    padding: 8px 7px 8px 7px !important;
    min-height: 70px !important;
    max-height: 75px !important;
    /* width: 222px !important; */
  }

  .fleetBlk .ant-progress-bg {
    height: 6.5px !important;
  }

  .fleetBlk .tractorDtlSec.mbottom {
    margin-bottom: 7px !important;
  }

  .fleetBlk .tractorDtlSec.mbottom.mtop {
    margin-top: 12px !important;
    margin-bottom: 0px !important;
  }

  .fleetBlk .tractorDtlSec1 .t_signals,
  .fleetBlk .t_signals {
    padding: 0px !important;
  }

  .fleetOverallBlk .fleetBlk:hover {
    border: 1px solid #366376;
  }
  .fleetOverallBlk .fleetBlk-selected,
  .fleetTractorsInrBlk .fleetBlk-selected {
    border: 2px solid #366376 !important;
  }

  .fleetBlk .tractorDtlSec.mb18 {
    margin-bottom: 16px !important;
  }

  .fleetBlk .tractorDtlSec.mb18.mt10 {
    margin-top: 16px !important;
    margin-bottom: 8px !important;
    height: 16px !important;
  }

  .tractorDtlSec1 {
    height: 17px !important;
  }

  .fleetTabs .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0px !important;
  }

  .t_battery {
    font-size: 10px;
    font-family: Montserrat-Medium;
    color: #1e1e1e;
    letter-spacing: 0.6px;
    line-height: 13px;
    display: flex;
    align-items: center;
  }

  .bPer {
    font-size: 8px !important;
    letter-spacing: 0.48px !important;
    line-height: 8px !important;
    margin-left: 2px !important;
  }

  .tBatterySec img {
    height: 10px !important;
  }

  .fleetBlk .t_signals {
    padding: 0px !important;
  }

  .charging_remain {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #959595;
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 8px;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 0.95px;
  }

  .autodriveButton {
    height: 24px !important;
    min-width: 114px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px !important;
    font-size: 8px !important;
    letter-spacing: 0.48px !important;
  }

  .autodriveButton img {
    height: 10px !important;
  }

  .autodriveButton .routeIcon,
  .autodriveButton .routeIcon2 {
    margin-right: 0px !important;
  }

  .t_driver_name {
    font-size: 8px !important;
    letter-spacing: 0.08px !important;
    line-height: 10px !important;
  }

  .t_signals_img .mr4 {
    margin-right: 1px !important;
  }

  .parentAccordion .fleetOverallBlk {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .listExpand .alignCenter {
    font-size: 10px !important;
    font-family: 'Montserrat-Regular';
  }

  .listExpand h2.alignCenter {
    /* font-size: 14px !important; */
    font-family: 'Montserrat-Medium';
  }

  .charging_remain {
    top: 7px !important;
    font-size: 7px !important;
  }

  .hImpNameSel {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
    color: #000000;
  }

  .hImpNameDbl {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
    color: #b6b6b7;
  }

  .list.fleetOverallBlk .tractorDtlSec.mb15 {
    margin-bottom: 10px !important;
  }

  .list.fleetOverallBlk .fleetBlk {
    width: inherit !important;
  }

  .bottomMapLayers {
    bottom: 20px !important;
    left: 14px !important;
  }

  .mpOpen {
    visibility: visible !important;
    animation: resize 0.2s !important;
    width: 242px !important;
    height: calc(100vh - 142px) !important;
  }

  .bottomMapLayers.mpOpen {
    height: calc(100vh - 95px) !important;
  }

  .tImplementSec {
    width: 66px !important;
    height: 17px !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
    font-size: 8px !important;
    letter-spacing: 0.08px !important;
    line-height: 10px !important;
  }

  .tImplementSec img {
    margin-right: 6px;
  }

  .tSpeedtSec {
    display: flex;
    align-items: center;
  }

  .tSpeedtSec img {
    width: 12px !important;
    height: 6px !important;
  }

  .tBatterySec {
    display: flex;
    align-items: center;
  }

  .tSignalSec {
    display: flex;
    align-items: center;
  }

  .fleetBlk .t_signals.t_error {
    margin: 0;
    padding: 0;
  }

  .fleetBlk .t_signals.t_error .mr4 {
    margin: 0;
  }

  .battery-blk {
    display: flex;
  }

  .speedTxt {
    font-family: Montserrat-Medium;
    font-size: 15px;
    color: #4d4d4d;
    margin-left: 3px;
    width: auto;
    display: inline-block;
    text-align: right;
  }

  .tspeed {
    font-size: 10px !important;
    letter-spacing: 0.48px !important;
    margin-left: 3px !important;
    margin-right: 2px !important;
    line-height: 8px !important;
  }

  .tsMPH {
    font-size: 6px !important;
    letter-spacing: 0.36px !important;
    padding-top: 4px !important;
    line-height: 8px !important;
  }

  .fleetlistBlk .fListSearchMain .ant-input,
  .fListSearchMain .ant-input {
    font-family: 'Montserrat-Medium';
    font-size: 12px !important;
    letter-spacing: 0.09px !important;
    line-height: 15px !important;
    color: #fff !important;
  }

  .parentIcon .ant-collapse-extra img {
    width: 15px !important;
    height: 11px !important;
  }

  .parentAccordion .ant-collapse-header .ant-collapse-extra {
    padding-right: 12px !important;
  }

  .fleetlistBlk .fListSearchMain .ant-input,
  .fListSearchMain .ant-input {
    font-size: 10px !important;
    letter-spacing: 0.07px !important;
    line-height: 13px !important;
    color: #fff !important;
  }

  .fleetlistBlk .searchMapBlk.fListSearchMain input.fListSearch::placeholder {
    font-size: 10px !important;
    letter-spacing: 0.07px !important;
    line-height: 13px !important;
    color: #cececf !important;
  }

  .fleetlistBlk .searchMapBlk .ant-input {
    height: 33px !important;
  }

  .fleetlistBlk .fListSearchMain .anticon-search {
    top: 30% !important;
  }

  .dataLayerRowHead {
    padding: 16px 5px 0px 14px !important;
  }

  .mpLayerHead .dataIcon img {
    height: 12px !important;
  }

  .mplStatus .mplItem img {
    height: 24px !important;
  }

  .mplStatus2 .mplItem img {
    height: 24px !important;
  }

  .mplItem img.mplMl12 {
    height: 34px !important;
  }

  .mplStatus2 .mplMl12 {
    margin-left: -4px;
    margin-right: 6px;
  }

  .dataHead {
    font-size: 12px !important;
    margin-left: 10px !important;
    letter-spacing: 0.1px !important;
    line-height: 10px !important;
  }

  .dataLayerContainer {
    padding: 0px 16px 0px 16px !important;
  }

  .mplChkBox .checkBoxItem span.layerName {
    font-size: 9px !important;
  }

  .mplItemlbl {
    font-size: 9px !important;
    letter-spacing: 0.55px !important;
  }

  .mplItem .mr15 {
    margin-right: 10px !important;
  }

  .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 275px) !important;
  }

  .fleetTabs .childAccordion2 {
    max-height: calc(100vh - 275px) !important;
  }

  .rightWidgetView .functionalityTag .ant-tag {
    font-size: 10px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.25px !important;
    line-height: 13px !important;
    padding: 8px 10px !important;
  }

  .rightWidgetView .ant-upload .editImgs {
    position: absolute;
    right: 15px !important;
    /* bottom: 25px !important; */
    bottom: 6px !important;
    cursor: pointer;
  }

  .rightWidgetView .ant-upload .editImgs img {
    width: 13px !important;
  }

  .autonomyBtns {
    bottom: 30px !important;
    padding-left: 21px !important;
    padding-right: 21px !important;
  }

  .rightWidgetView .input::placeholder {
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 13px;
  }

  .rightWidgetView textarea::placeholder {
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 13px;
  }

  .rightWidgetView .ant-select-selection-placeholder {
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 13px;
  }

  .genric_toggle_sec {
    font-size: 12px !important;
    letter-spacing: 0.19px !important;
    line-height: 13px !important;
    height: 37px !important;
  }

  .tractorPic img {
    width: 132px !important;
    height: 132px !important;
  }

  .tractorList {
    padding-left: 10px !important;
  }

  .editFleetButtons {
    bottom: 35px !important;
  }

  .proInputMain {
    margin-bottom: 66px !important;
  }

  .addEmpBlk .proPic .ant-avatar {
    width: 97px !important;
    height: 97px !important;
  }

  .addEmpBlk.rightWidgetView .editImgs {
    right: 8px !important;
    bottom: 8px !important;
  }

  /* Right side widget details section styles goes here **********************/

  .rightSideWidegt {
    right: -323px !important;
    width: 323px !important;
  }

  .rightWidgetView {
    padding: 0px 27px 20px 21px !important;
  }

  .widgetCloseBtn img {
    width: 11px !important;
    height: 11px !important;
  }

  .widgetCloseBtn {
    padding: 29px 21px 20px 28px !important;
  }

  .tcktInfo {
    margin-bottom: 33px;
  }

  .status_value {
    padding: 8px 12px !important;
  }

  .fwdBtnsSec .TktFwdBtn {
    padding: 10px 22px !important;
  }

  .fwdBtnsSec .TktRslBtn {
    padding: 10px 24px !important;
  }

  .ticketComments {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.12px !important;
    line-height: 19px !important;
  }

  .createTcktTxt {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.12px !important;
    line-height: 19px !important;
  }

  .widgetBtns {
    bottom: 30px !important;
  }

  .saveTxtBtn button {
    right: 28px !important;
    bottom: 40px !important;
    font-size: 10px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.89px !important;
    line-height: 13px !important;
  }

  .impEditBtn button {
    right: 28px !important;
    bottom: 40px !important;
  }

  .impAutonomyBtn button {
    font-size: 8px !important;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 0.89px !important;
    line-height: 13px !important;
    padding: 8px 6px !important;
    bottom: 34px !important;
    left: 21px !important;
  }

  .profileEdit .ant-tag-checkable,
  .tagsBlk .ant-tag-checkable,
  .rightWidgetView .tag-chip-selected {
    padding: 8px 12px !important;
  }

  .rightWidgetView .tagsBlk .tags {
    font-size: 8px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.4px !important;
    line-height: 10px !important;
    padding: 8px 12px !important;
  }

  .rightWidgetView .createTcktFlag img {
    padding: 7px 9px !important;
    margin-left: 16px !important;
  }

  .rightWidgetView .ant-select-selector,
  .rightWidgetView .ant-input {
    font-size: 12px !important;
    letter-spacing: 0.19px !important;
    line-height: 13px !important;
    min-height: 37px !important;
  }

  .rightWidgetView
    .selectImplementContainer
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 37px !important;
  }

  .rightWidgetView .ant-select-selector .ant-select-selection-item {
    font-size: 12px !important;
    letter-spacing: 0.19px !important;
    line-height: 13px !important;
    height: 37px !important;
  }

  .rightWidgetView textarea {
    font-size: 14px !important;
    letter-spacing: 0.44px !important;
    line-height: 22px !important;
  }

  .rightWidgetView .proPic .ant-avatar {
    width: 119px !important;
    height: 119px !important;
  }

  /* autodrive details styles ***************************/

  .rautoDriveRightSec {
    width: 343px !important;
  }

  .tglexpand {
    right: 343px !important;
  }

  .afterMapRightAlign .positionMap-inner {
    right: 347px !important;
    transition: 1s !important;
  }

  .adtTopBox {
    margin: 0 10px !important;
  }

  .adTabsContentSec {
    padding: 0px 12px 0 12px !important;
  }

  .adTabsHeadSec {
    padding: 30px 12px 16px 12px !important;
  }

  .autoDriveCamViews iframe#cammms {
    height: 178px !important;
  }

  .tractorSpeedometer .progressbar {
    width: 156px !important;
    height: 156px !important;
  }

  .t_speed_cont .type {
    font-size: 10px !important;
  }

  .t_speed_cont .speed {
    font-size: 16px !important;
    letter-spacing: -1.5px;
  }

  .t_speed_cont .measure {
    font-size: 8px !important;
  }

  .ts_autodrive {
    background-size: 85% !important;
    margin-top: 0px !important;
  }

  .tractorSpeedometer .t_speed_cont {
    top: -12px !important;
  }

  .adtabsHeadText {
    width: 210px !important;
    min-width: 210px !important;
    max-width: 210px !important;
  }

  .tractorPasued {
    top: 2px;
  }

  .camera_box {
    padding: 10px 12px !important;
  }

  .autoDriveCamViews iframe#cammms {
    height: 150px !important;
  }

  .autoDrivetime {
    min-width: 65px !important;
  }

  .autoDrivetime .body2 {
    letter-spacing: -1px !important;
  }

  .autodriveStatusIcons .t_signals {
    width: 63px !important;
    box-sizing: border-box !important;
  }

  .aDname {
    width: 125px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .tractorSpeedometer .t_info_progress_circle {
    height: inherit !important;
  }

  .ts_manual {
    margin-top: 0px !important;
    width: 100px !important;
    background-size: 80% !important;
    background-position: center 5px !important;
  }

  .tractorSpeedometer .tractor_status_img {
    width: calc(100% - 38px) !important;
    height: calc(100% - 38px) !important;
  }

  .tOperationDtlSec {
    width: calc(100% - 24px) !important;
    gap: 24px !important;
  }

  .tOperationDtlSec {
    margin-bottom: 24px !important;
  }

  .tractorSpeedometer {
    margin-bottom: 24px !important;
  }

  .adTabsContentSec .rautoDriveStatus {
    margin-bottom: 20px !important;
  }

  .newCamBtns,
  .newCamBtns button.camera_box {
    height: 30px !important;
  }

  .camera_view_buttons {
    height: 33px !important;
  }

  .remoteOperationsPopup {
    padding: 5px 10px 5px 10px !important;
    min-height: 98px !important;
    left: 82px !important;
    width: 240px !important;
    bottom: 80px !important;
    min-height: 86px !important;
  }

  .adrOpTime {
    width: 85px !important;
  }

  .adroutineTimerBtns img {
    height: 24px !important;
  }

  .adrTime_rBtn {
    margin-bottom: 8px !important;
    justify-content: space-between !important;
    width: 100%;
  }

  .remoteOperationsPopup h6 {
    padding-left: 0px !important;
  }

  .remoteOperationsPopup p {
    width: 95px !important;
  }

  .adroutineTimerDtls {
    justify-content: space-between !important;
  }

  .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 270px) !important;
  }

  .fleetTabs .childAccordion2 {
    max-height: calc(100vh - 270px) !important;
  }

  .dmenHandle .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 390px) !important;
  }

  .dmenHandle .childAccordion2 {
    max-height: calc(100vh - 390px) !important;
  }

  /* personal tab view and edit employee styles goes here ******************/
  .editRoles2 {
    right: 28px !important;
    bottom: 40px !important;
  }

  .addEmpBlk.rightWidgetView .proPic .ant-avatar {
    width: 97px !important;
    height: 97px !important;
  }

  .rightWidgetView .proPic .ant-avatar .ant-avatar-string {
    width: 119px !important;
    height: 119px !important;
    font-size: 42px !important;
  }

  .teamsgridbox {
    min-height: 130px !important;
    max-height: 130px !important;
  }

  /* .employee-teams {
    min-height: 205px !important;
    max-height: 205px !important;
  } */

  .rowGap55 {
    margin-top: 30px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .implementImage {
    max-height: 180px !important;
    min-height: 180px !important;
    border-radius: 0px !important;
  }

  .autodriveClose {
    top: 16px !important;
    right: 16px !important;
  }
  .employee-teams {
    overflow-y: hidden !important;
    min-height: 150px !important;
  }

  .userProfileBlk .employee-teams {
    min-height: 120px !important;
    max-height: 120px !important;
  }
  .my-profile .employee-team-sub {
    max-height: 100% !important;
    min-height: 80px !important;
  }
  .profileHeight {
    max-height: calc(100vh - 260px) !important;
  }
  .userPf .profileHeight .profileDtlWrapper {
    max-height: inherit !important;
  }
  .addEmpBlk .addEmployeeSelect.multiSelectField .ant-select-selector {
    min-height: 37px !important;
    height: auto !important;
    max-height: 60px !important;
  }
  .profile-details .profileEditImg {
    left: unset !important;
    right: 2px !important;
    bottom: 45px !important;
    width: 16px !important;
  }

  .popupStyles .formRow .commInput,
  .popupStyles .formRow .ant-select {
    height: 37px !important;
  }
  .popupStyles .ant-form-item-control .ant-input {
    height: 37px !important;
  }

  .ticketsSearchBar .anticon-search svg {
    width: 10px !important;
    height: 10px !important;
  }
  .searchSec.ticketsSearchBar .ant-input-search input {
    padding-left: 9px !important;
  }
  .tcktsBlk .ticketsSearchBar .anticon-search {
    padding-left: 0px !important;
    margin-top: 0px !important;
    margin-left: -1px !important;
  }

  .tractorDetailsAlertBox .terrImgBlock {
    min-width: 50px !important;
  }

  .tractorDetailsAlertBox {
    left: -4px !important;
    min-height: 195px !important;
    padding: 20px 15px !important;
    width: 302px !important;
  }
  .tractorDetailsAlertBox1 {
    min-height: 130px !important;
  }
  .tractorDetailsAlertBox button {
    min-width: fit-content !important;
  }
  .tractorDetailsAlertBox .terrImgBlock img {
    margin-top: 18px !important;
    width: 24px !important;
  }
  .localizationDown .terrImgBlock img {
    margin-top: 18px !important;
    width: 24px !important;
  }
  .localizationDown .terrImgBlock {
    min-width: 55px !important;
  }

  .seeDtl {
    height: calc(100vh - 175px) !important;
  }

  .talertPopup .tblDft.farmTabsTbl.dsTbl {
    height: calc(100vh - 260px) !important;
    max-height: calc(100vh - 260px) !important;
  }
  .talertPopup .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
    max-height: calc(100vh - 285px) !important;
  }
  .talertPopup .tblDft.farmTabsTbl.dsTbl div#empTbl {
    min-height: calc(100vh - 285px) !important;
  }

  /* autonomy settings popup styles ********************/
  .autonomy_modal_sec .ant-input {
    height: 37px !important;
  }
  .autonomy_modal_sec .ant-select-selector {
    height: 37px !important;
  }
  .autonomy_modal_sec .generictoggle {
    height: 37px !important;
  }
  .okBtn.autonomySaveBtn {
    font-size: 10px !important;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 0.89px !important;
    line-height: 13px !important;
  }
  .AD_term_content .ql-editor {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 18px !important;
  }
  .tcBtns2,
  .tcBtns2:focus,
  .tcBtns2:hover {
    width: 150px !important;
    height: 37px !important;
  }

  /* model popup styles goes here *********************** */

  .teamEmpContainer input::placeholder,
  .teamEmpContainer .ant-select-selection-placeholder,
  .teammodal input::placeholder,
  .teammodal .ant-select-selection-placeholder {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }
  .team-text-area .ant-input,
  .teammodal .ant-input,
  .team-text-area .ant-input::placeholder {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }

  .team-text-area-2 .ant-input,
  .teammodal .ant-input,
  .team-text-area-2 .ant-input::placeholder {
    font-size: 12px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.5px !important;
    line-height: 14px !important;
  }
  /* model popup styles end here *********************** */

  .employee-teams {
    padding: 20px 0px !important;
  }

  .change-ps {
    height: 50px !important;
  }

  .userPf .employee-details {
    height: calc(100vh - 200px) !important;
  }

  .my-profile .permission-sub {
    min-height: calc(100vh - 550px) !important;
    max-height: calc(100vh - 550px) !important;
  }

  .analyzerContainer {
    top: 60px !important;
  }

  .tractorOperDtls {
    width: 300px !important;
  }

  .sparyMapContainer {
    width: calc(100% - 300px) !important;
  }

  .filterGap3 {
    height: calc(120px - 50px) !important;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .employee-profile {
    width: 324px !important;
    padding: 20px 20px !important;
  }

  .profile-details .ant-avatar {
    width: 100px !important;
    height: 100px !important;
    min-width: 100px !important;
    min-height: 100px !important;
    margin-bottom: 15px !important;
  }
  .profile-details {
    margin-bottom: 30px !important;
  }
  .employee-permissions {
    margin-left: 2.45vw !important;
    width: calc(100% - 475px) !important;
  }

  .teambtn button {
    padding: 9px 20px !important;
  }

  .filterGap40 {
    height: calc(90px - 52px) !important;
    margin-top: 26px !important;
    margin-bottom: 26px !important;
  }

  .rowGap40 {
    margin-top: 30px !important;
  }

  /* side menu css goes here */

  .main-menu,
  .sideMenuSec {
    width: 68px !important;
    flex: 0 0 68px !important;
    min-width: 68px !important;
  }

  .main-menu:hover,
  nav.main-menu.expanded {
    width: 164px !important;
  }

  .iconBox {
    margin-left: 16px !important;
    margin-right: 16px !important;
    width: 36px !important;
    height: 36px !important;
  }

  .iconBoxProfile {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .iconBoxProfile .ant-avatar {
    width: 36px !important;
    height: 36px !important;
  }

  .main-menu li {
    width: 160px !important;
  }

  .main-menu:hover .login_logo_box .lLogo {
    min-width: 132px !important;
    max-width: 100% !important;
    min-height: 21px !important;
  }

  .overline2 {
    font-size: 12px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.125px !important;
    line-height: 15px !important;
  }

  .wingSpanAI .company_logo {
    width: 45px !important;
  }

  .company_logo {
    background-image: url(../../assets/images/login_logo.svg);
    /* width: 35px !important; */
    width: 45px !important;
    height: 21px !important;
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center center;
    cursor: pointer;
    display: block;
  }

  .wingSpanAI.main-menu:hover .company_logo {
    width: 60px !important;
    height: 34px !important;
    background-size: 100% !important;
  }

  .main-menu:hover .company_logo {
    background-image: url(../../assets/images/login_logo_large.svg);
    /* width: 132px !important;
    height: 21px !important; */
    width: 60px !important;
    height: 34px !important;
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center center;
    cursor: pointer;
    display: block;
  }

  .iconBox div {
    background-size: 80% !important;
    background-position: center center;
  }

  /* side menu css goes end */

  /* tabs and table css goes here */

  .elTabs > .ant-tabs-nav {
    height: 98px !important;
    /* margin-bottom: 16px !important; */
  }

  .elTabs .ant-tabs-nav-wrap {
    padding-bottom: 28px;
  }

  .elTabs .ant-tabs-tab-btn,
  .attContainer .ant-tabs-tab-btn {
    font-size: 10px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.89px !important;
    /* line-height: 13px !important; */
  }

  .elTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #333333 !important;
  }

  .profCropHead {
    min-width: 80px !important;
  }

  .profCropValue {
    min-width: 110px;
  }

  .tag-chip-selected {
    padding: 0px 12px !important;
    height: 27px !important;
  }

  /* dropdown css ************************************/

  .tcktsBlk.searchFilters .ant-space,
  .tcktsBlk .ant-space.searchFilters {
    gap: 10px 0 !important;
  }

  .searchField .ant-input-search,
  .searchSec .ant-input-search {
    width: 174px !important;
    height: 32px !important;
  }

  .viewportContainer .searchSec .ant-input-group-addon button,
  .searchSec .ant-input-group-addon button {
    height: 32px !important;
    padding-left: 10px !important;
  }

  .searchFilters .searchSec .anticon-search {
    font-size: 10px !important;
  }

  .searchSec .ant-input-search input {
    font-size: 10px !important;
    min-height: 32px !important;
    max-height: 32px !important;
    padding-left: 33px !important;
  }

  .searchSec .ant-input-search input::placeholder {
    font-size: 10px !important;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.07px !important;
    line-height: 13px !important;
  }

  .searchFilters .tab-label,
  .searchFilters .tab-label:hover {
    height: 32px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .searchFilters .flbTopFixed {
    font-size: 8px !important;
    letter-spacing: 0.08px !important;
    line-height: 10px !important;
  }

  .searchFilters .flbSelected {
    font-size: 10px !important;
    letter-spacing: 0.16px !important;
    line-height: 8px !important;
  }

  .searchFilters .selGray {
    font-size: 10px !important;
    letter-spacing: 0.16px !important;
    line-height: 8px !important;
  }

  .searchFilters .dateFilter {
    margin-left: 15px !important;
    margin-right: 0 !important;
    height: 32px !important;
  }

  .searchFilters .dateFilter .ant-picker-input > input {
    font-size: 10px !important;
    letter-spacing: 0.16px !important;
    line-height: 8px !important;
  }

  .searchFilters .dW250_date {
    width: 170px !important;
  }

  .smallFilter .flblWrapper {
    min-width: 108px !important;
    width: 108px !important;
    height: 32px !important;
    margin-left: 12px !important;
  }

  .medFilter .flblWrapper {
    min-width: 115px !important;
    width: 115px !important;
    height: 32px !important;
    margin-left: 16px !important;
  }

  .singleDatePicker {
    height: 32px !important;
  }

  .searchFilters .tractorField .flblWrapper {
    height: 32px !important;
  }

  .searchFilters .customField {
    margin-left: 16px !important;
  }

  .searchFilters .customField .ant-select-selector {
    height: 32px !important;
    font-size: 10px !important;
    letter-spacing: 0.16px !important;
    line-height: 8px !important;
    margin-right: 0 !important;
  }

  .searchFilters .ant-picker-input > input {
    font-size: 10px !important;
    font-family: 'Montserrat-Regular' !important;
    letter-spacing: 0.16px !important;
    line-height: 8px !important;
  }

  .elTabs.fleetHealth .ant-tabs-nav-wrap {
    justify-content: flex-start !important;
    margin-left: 50px;
    margin-right: 50px;
  }

  /* .fleetHealth .ant-tabs-nav-list:last-child .ant-tabs-tab {
      margin-right: 50px !important;
    } */

  .tblContainer .m_tb {
    margin-top: 5px !important;
    margin-bottom: 21px !important;
  }

  .attContainer .elTabs .tblContainer {
    height: calc(100vh - 123px) !important;
  }

  .attContainer .elTabs .ant-tabs-content-holder,
  .attContainer .elTabs .ant-tabs-content {
    height: calc(100vh - 98px) !important;
  }

  .tblDft.farmTabsTbl {
    height: calc(100vh - 260px) !important;
    max-height: calc(100vh - 260px) !important;
  }

  .tblDft.farmTabsTbl .infinite-scroll-component {
    max-height: calc(100vh - 285px) !important;
  }

  .tblDft.farmTabsTbl div#empTbl {
    min-height: calc(100vh - 285px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl {
    height: calc(100vh - 485px) !important;
    max-height: calc(100vh - 485px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
    max-height: calc(100vh - 510px) !important;
  }

  .tblDft.farmTabsTbl.dsTbl div#empTbl {
    min-height: calc(100vh - 510px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl {
    height: calc(100vh - 300px) !important;
    max-height: calc(100vh - 300px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl .infinite-scroll-component {
    max-height: calc(100vh - 310px) !important;
  }

  .lbCont .tblDft.lbTbls.farmTabsTbl div#empTbl {
    min-height: calc(100vh - 310px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl {
    height: calc(100vh - 250px) !important;
    max-height: calc(100vh - 250px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl .infinite-scroll-component {
    max-height: calc(100vh - 275px) !important;
  }

  .tblDft.farmTabsTbl.reportsTbl div#empTbl {
    min-height: calc(100vh - 275px) !important;
  }

  /* liveops css media styles goes here ****************************/

  .fleetlistBlk {
    left: 14px !important;
    top: 32px !important;
    width: 242px !important;
  }

  .searchMapBlk {
    margin-bottom: 17px !important;
  }

  /* .fleetlistBlk .searchMapBlk .ant-input::placeholder {
    font-size: 20px !important;
   } */

  .fleetlistBlk {
    position: absolute;
    left: 24px;
    top: 50px;
    z-index: 9;
    width: 358px;
  }

  .flIcons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flIcons img {
    margin-right: 4px;
  }

  .fleetTabs .ant-tabs-tab.tractorTab {
    /* width: 87px !important; */
    height: 24px !important;
  }

  .fleetTabs .tabTractorImg,
  .fleetTabs .tabLockImg {
    height: 10px !important;
  }

  .fleetTabs .tractorTab .ant-tabs-tab-btn {
    padding: 0px !important;
  }

  .mapLayerIcon,
  .mapLayerIcon:hover {
    background-size: 45px !important;
    width: 45px !important;
    height: 45px !important;
    bottom: 18px !important;
    left: 14px !important;
  }

  .bottomMapLayers {
    position: absolute;
    bottom: 23px;
    left: 25px;
    width: 0px;
    height: 0;
    /* min-width: 360px; */
    background: #eaeaea;
    height: 0;
    z-index: 99;
    box-shadow: -1px 0px 6px rgb(0 0 0 / 29%);
    transition: 1s;
    border-radius: 10px;
  }

  .bottomMapLayers .dataLayerSec {
    overflow-y: auto;
    height: calc(100vh - 230px);
  }

  .operationsTitle .ant-collapse-header {
    padding: 10px 12px 10px 12px !important;
    font-size: 10px !important;
    letter-spacing: 0.89px !important;
    line-height: 13px !important;
    height: 33px !important;
    display: flex !important;
    align-items: center !important;
  }

  .parentIcon .anticon {
    right: 8px !important;
  }

  .fleetTabs .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 8px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.27px !important;
    line-height: 10px !important;
    padding: 0 6px !important;
  }

  .fleetTabs .ant-tabs-nav {
    height: 24px !important;
    padding: 0 10px !important;
    margin-top: 15px !important;
    margin-bottom: 16px !important;
  }

  .fleetTabs .ant-tabs-ink-bar {
    border: none !important;
  }

  .parentAccordion .fleetBlk:first-child {
    margin-top: 0px !important;
  }

  .t_name {
    width: 120px !important;
    font-size: 10px !important;
    letter-spacing: 0.33px !important;
    line-height: 13px !important;
  }

  .ttractor_state {
    min-height: 14px !important;
    min-width: 68px !important;
    font-size: 7px !important;
    letter-spacing: 0.63px !important;
    line-height: 9px !important;
  }

  .parentAccordion .fleetBlk {
    border-radius: 8px !important;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    padding: 8px 7px 8px 7px !important;
    min-height: 70px !important;
    max-height: 75px !important;
    /* width: 222px !important; */
  }

  .fleetBlk .ant-progress-bg {
    height: 6.5px !important;
  }

  .fleetBlk .tractorDtlSec.mbottom {
    margin-bottom: 7px !important;
  }

  .fleetBlk .tractorDtlSec.mbottom.mtop {
    margin-top: 12px !important;
    margin-bottom: 0px !important;
  }

  .fleetBlk .tractorDtlSec1 .t_signals,
  .fleetBlk .t_signals {
    padding: 0px !important;
  }

  .fleetOverallBlk .fleetBlk:hover {
    border: 1px solid #366376;
  }
  .fleetOverallBlk .fleetBlk-selected,
  .fleetTractorsInrBlk .fleetBlk-selected {
    border: 2px solid #366376 !important;
  }

  .fleetBlk .tractorDtlSec.mb18 {
    margin-bottom: 16px !important;
  }

  .fleetBlk .tractorDtlSec.mb18.mt10 {
    margin-top: 16px !important;
    margin-bottom: 8px !important;
    height: 16px !important;
  }

  .tractorDtlSec1 {
    height: 17px !important;
  }

  .fleetTabs .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0px !important;
  }

  .t_battery {
    font-size: 10px;
    font-family: Montserrat-Medium;
    color: #1e1e1e;
    letter-spacing: 0.6px;
    line-height: 13px;
    display: flex;
    align-items: center;
  }

  .bPer {
    font-size: 8px !important;
    letter-spacing: 0.48px !important;
    line-height: 8px !important;
    margin-left: 2px !important;
  }

  .tBatterySec img {
    height: 10px !important;
  }

  .fleetBlk .t_signals {
    padding: 0px !important;
  }

  .charging_remain {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #959595;
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 8px;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 0.95px;
  }

  .autodriveButton {
    height: 24px !important;
    min-width: 114px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px !important;
    font-size: 8px !important;
    letter-spacing: 0.48px !important;
  }

  .autodriveButton img {
    height: 10px !important;
  }

  .autodriveButton .routeIcon,
  .autodriveButton .routeIcon2 {
    margin-right: 0px !important;
  }

  .t_driver_name {
    font-size: 8px !important;
    letter-spacing: 0.08px !important;
    line-height: 10px !important;
  }

  .t_signals_img .mr4 {
    margin-right: 1px !important;
  }

  .parentAccordion .fleetOverallBlk {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .listExpand .alignCenter {
    font-size: 10px !important;
    font-family: 'Montserrat-Regular';
  }

  .listExpand h2.alignCenter {
    /* font-size: 14px !important; */
    font-family: 'Montserrat-Medium';
  }

  .charging_remain {
    top: 7px !important;
    font-size: 7px !important;
  }

  .hImpNameSel {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
    color: #000000;
  }

  .hImpNameDbl {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
    color: #b6b6b7;
  }

  .list.fleetOverallBlk .tractorDtlSec.mb15 {
    margin-bottom: 10px !important;
  }

  .list.fleetOverallBlk .fleetBlk {
    width: inherit !important;
  }

  .bottomMapLayers {
    bottom: 20px !important;
    left: 14px !important;
  }

  .mpOpen {
    visibility: visible !important;
    animation: resize 0.2s !important;
    width: 242px !important;
    height: calc(100vh - 142px) !important;
  }

  .bottomMapLayers.mpOpen {
    height: calc(100vh - 95px) !important;
  }

  .tImplementSec {
    width: 66px !important;
    height: 17px !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
    font-size: 8px !important;
    letter-spacing: 0.08px !important;
    line-height: 10px !important;
  }

  .tImplementSec img {
    margin-right: 6px;
  }

  .tSpeedtSec {
    display: flex;
    align-items: center;
  }

  .tSpeedtSec img {
    width: 12px !important;
    height: 6px !important;
  }

  .tBatterySec {
    display: flex;
    align-items: center;
  }

  .tSignalSec {
    display: flex;
    align-items: center;
  }

  .fleetBlk .t_signals.t_error {
    margin: 0;
    padding: 0;
  }

  .fleetBlk .t_signals.t_error .mr4 {
    margin: 0;
  }

  .battery-blk {
    display: flex;
  }

  .speedTxt {
    font-family: Montserrat-Medium;
    font-size: 15px;
    color: #4d4d4d;
    margin-left: 3px;
    width: auto;
    display: inline-block;
    text-align: right;
  }

  .tspeed {
    font-size: 10px !important;
    letter-spacing: 0.48px !important;
    margin-left: 3px !important;
    margin-right: 2px !important;
    line-height: 8px !important;
  }

  .tsMPH {
    font-size: 6px !important;
    letter-spacing: 0.36px !important;
    padding-top: 4px !important;
    line-height: 8px !important;
  }

  .fleetlistBlk .fListSearchMain .ant-input,
  .fListSearchMain .ant-input {
    font-family: 'Montserrat-Medium';
    font-size: 12px !important;
    letter-spacing: 0.09px !important;
    line-height: 15px !important;
    color: #fff !important;
  }

  .parentIcon .ant-collapse-extra img {
    width: 15px !important;
    height: 11px !important;
  }

  .parentAccordion .ant-collapse-header .ant-collapse-extra {
    padding-right: 12px !important;
  }

  .fleetlistBlk .fListSearchMain .ant-input,
  .fListSearchMain .ant-input {
    font-size: 10px !important;
    letter-spacing: 0.07px !important;
    line-height: 13px !important;
    color: #fff !important;
  }

  .fleetlistBlk .searchMapBlk.fListSearchMain input.fListSearch::placeholder {
    font-size: 10px !important;
    letter-spacing: 0.07px !important;
    line-height: 13px !important;
    color: #cececf !important;
  }

  .fleetlistBlk .searchMapBlk .ant-input {
    height: 33px !important;
  }

  .fleetlistBlk .fListSearchMain .anticon-search {
    top: 30% !important;
  }

  .dataLayerRowHead {
    padding: 16px 5px 0px 14px !important;
  }

  .mpLayerHead .dataIcon img {
    height: 12px !important;
  }

  .mplStatus .mplItem img {
    height: 24px !important;
  }

  .mplStatus2 .mplItem img {
    height: 24px !important;
  }

  .mplItem img.mplMl12 {
    height: 34px !important;
  }

  .mplStatus2 .mplMl12 {
    margin-left: -4px;
    margin-right: 6px;
  }

  .dataHead {
    font-size: 12px !important;
    margin-left: 10px !important;
    letter-spacing: 0.1px !important;
    line-height: 10px !important;
  }

  .dataLayerContainer {
    padding: 0px 16px 0px 16px !important;
  }

  .mplChkBox .checkBoxItem span.layerName {
    font-size: 9px !important;
  }

  .mplItemlbl {
    font-size: 9px !important;
    letter-spacing: 0.55px !important;
  }

  .mplItem .mr15 {
    margin-right: 10px !important;
  }

  .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 275px) !important;
  }

  .fleetTabs .childAccordion2 {
    max-height: calc(100vh - 275px) !important;
  }

  .rightWidgetView .functionalityTag .ant-tag {
    font-size: 10px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 1.25px !important;
    line-height: 13px !important;
    padding: 8px 10px !important;
  }

  .rightWidgetView .ant-upload .editImgs {
    position: absolute;
    right: 15px !important;
    /* bottom: 25px !important; */
    bottom: 6px !important;
    cursor: pointer;
  }

  .rightWidgetView .ant-upload .editImgs img {
    width: 13px !important;
  }

  .autonomyBtns {
    bottom: 30px !important;
    padding-left: 21px !important;
    padding-right: 21px !important;
  }

  .rightWidgetView .input::placeholder {
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 13px;
  }

  .rightWidgetView textarea::placeholder {
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 13px;
  }

  .rightWidgetView .ant-select-selection-placeholder {
    font-size: 12px;
    letter-spacing: 0.19px;
    line-height: 13px;
  }

  .genric_toggle_sec {
    font-size: 12px !important;
    letter-spacing: 0.19px !important;
    line-height: 13px !important;
    height: 37px !important;
  }

  .tractorPic img {
    width: 132px !important;
    height: 132px !important;
  }

  .tractorList {
    padding-left: 10px !important;
  }

  .editFleetButtons {
    bottom: 35px !important;
  }

  .proInputMain {
    margin-bottom: 66px !important;
  }

  .addEmpBlk .proPic .ant-avatar {
    width: 97px !important;
    height: 97px !important;
  }

  .addEmpBlk.rightWidgetView .editImgs {
    right: 8px !important;
    bottom: 8px !important;
  }

  /* Right side widget details section styles goes here **********************/

  .rightSideWidegt {
    right: -323px !important;
    width: 323px !important;
  }

  .rightWidgetView {
    padding: 0px 27px 20px 21px !important;
  }

  .widgetCloseBtn img {
    width: 11px !important;
    height: 11px !important;
  }

  .widgetCloseBtn {
    padding: 29px 21px 20px 28px !important;
  }

  .tcktInfo {
    margin-bottom: 33px;
  }

  .status_value {
    padding: 8px 12px !important;
  }

  .fwdBtnsSec .TktFwdBtn {
    padding: 10px 22px !important;
  }

  .fwdBtnsSec .TktRslBtn {
    padding: 10px 24px !important;
  }

  .ticketComments {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.12px !important;
    line-height: 19px !important;
  }

  .createTcktTxt {
    font-size: 14px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.12px !important;
    line-height: 19px !important;
  }

  .widgetBtns {
    bottom: 30px !important;
  }

  .saveTxtBtn button {
    right: 28px !important;
    bottom: 40px !important;
    font-size: 10px !important;
    font-family: 'Montserrat-SemiBold' !important;
    letter-spacing: 0.89px !important;
    line-height: 13px !important;
  }

  .impEditBtn button {
    right: 28px !important;
    bottom: 40px !important;
  }

  .impAutonomyBtn button {
    font-size: 8px !important;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 0.89px !important;
    line-height: 13px !important;
    padding: 8px 6px !important;
    bottom: 34px !important;
    left: 21px !important;
  }

  .profileEdit .ant-tag-checkable,
  .tagsBlk .ant-tag-checkable,
  .rightWidgetView .tag-chip-selected {
    padding: 8px 12px !important;
  }

  .rightWidgetView .tagsBlk .tags {
    font-size: 8px !important;
    font-family: 'Montserrat-Medium' !important;
    letter-spacing: 0.4px !important;
    line-height: 10px !important;
    padding: 8px 12px !important;
  }

  .rightWidgetView .createTcktFlag img {
    padding: 7px 9px !important;
    margin-left: 16px !important;
  }

  .rightWidgetView .ant-select-selector,
  .rightWidgetView .ant-input {
    font-size: 12px !important;
    letter-spacing: 0.19px !important;
    line-height: 13px !important;
    min-height: 37px !important;
  }

  .rightWidgetView
    .selectImplementContainer
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 37px !important;
  }

  .rightWidgetView .ant-select-selector .ant-select-selection-item {
    font-size: 12px !important;
    letter-spacing: 0.19px !important;
    line-height: 13px !important;
    height: 37px !important;
  }

  .rightWidgetView textarea {
    font-size: 14px !important;
    letter-spacing: 0.44px !important;
    line-height: 22px !important;
  }

  .rightWidgetView .proPic .ant-avatar {
    width: 119px !important;
    height: 119px !important;
  }

  /* autodrive details styles ***************************/

  .rautoDriveRightSec {
    width: 343px !important;
  }

  .tglexpand {
    right: 343px !important;
  }

  .afterMapRightAlign .positionMap-inner {
    right: 347px !important;
    transition: 1s !important;
  }

  .adtTopBox {
    margin: 0 10px !important;
  }

  .adTabsContentSec {
    padding: 0px 12px 0 12px !important;
  }

  .adTabsHeadSec {
    padding: 30px 12px 16px 12px !important;
  }

  .autoDriveCamViews iframe#cammms {
    height: 178px !important;
  }

  .tractorSpeedometer .progressbar {
    width: 156px !important;
    height: 156px !important;
  }

  .t_speed_cont .type {
    font-size: 10px !important;
  }

  .t_speed_cont .speed {
    font-size: 16px !important;
    letter-spacing: -1.5px;
  }

  .t_speed_cont .measure {
    font-size: 8px !important;
  }

  .ts_autodrive {
    background-size: 85% !important;
    margin-top: 0px !important;
  }

  .tractorSpeedometer .t_speed_cont {
    top: -12px !important;
  }

  .adtabsHeadText {
    width: 210px !important;
    min-width: 210px !important;
    max-width: 210px !important;
  }

  .tractorPasued {
    top: 2px;
  }

  .camera_box {
    padding: 10px 12px !important;
  }

  .autoDriveCamViews iframe#cammms {
    height: 150px !important;
  }

  .autoDrivetime {
    min-width: 65px !important;
  }

  .autoDrivetime .body2 {
    letter-spacing: -1px !important;
  }

  .autodriveStatusIcons .t_signals {
    width: 63px !important;
    box-sizing: border-box !important;
  }

  .aDname {
    width: 125px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .tractorSpeedometer .t_info_progress_circle {
    height: inherit !important;
  }

  .ts_manual {
    margin-top: 0px !important;
    width: 100px !important;
    background-size: 80% !important;
    background-position: center 5px !important;
  }

  .tractorSpeedometer .tractor_status_img {
    width: calc(100% - 38px) !important;
    height: calc(100% - 38px) !important;
  }

  .tOperationDtlSec {
    width: calc(100% - 24px) !important;
    gap: 24px !important;
  }

  .tOperationDtlSec {
    margin-bottom: 24px !important;
  }

  .tractorSpeedometer {
    margin-bottom: 24px !important;
  }

  .adTabsContentSec .rautoDriveStatus {
    margin-bottom: 20px !important;
  }

  .newCamBtns,
  .newCamBtns button.camera_box {
    height: 30px !important;
  }

  .camera_view_buttons {
    height: 33px !important;
  }

  .remoteOperationsPopup {
    padding: 5px 10px 5px 10px !important;
    min-height: 98px !important;
    left: 82px !important;
    width: 240px !important;
    bottom: 80px !important;
    min-height: 86px !important;
  }

  .adrOpTime {
    width: 85px !important;
  }

  .adroutineTimerBtns img {
    height: 24px !important;
  }

  .adrTime_rBtn {
    margin-bottom: 8px !important;
    justify-content: space-between !important;
    width: 100%;
  }

  .remoteOperationsPopup h6 {
    padding-left: 0px !important;
  }

  .remoteOperationsPopup p {
    width: 95px !important;
  }

  .adroutineTimerDtls {
    justify-content: space-between !important;
  }

  .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 270px) !important;
  }

  .fleetTabs .childAccordion2 {
    max-height: calc(100vh - 270px) !important;
  }

  .dmenHandle .fleetTabs .ant-tabs-content-holder {
    max-height: calc(100vh - 390px) !important;
  }

  .dmenHandle .childAccordion2 {
    max-height: calc(100vh - 390px) !important;
  }

  /* personal tab view and edit employee styles goes here ******************/
  .editRoles2 {
    right: 28px !important;
    bottom: 40px !important;
  }

  .addEmpBlk.rightWidgetView .proPic .ant-avatar {
    width: 97px !important;
    height: 97px !important;
  }

  .rightWidgetView .proPic .ant-avatar .ant-avatar-string {
    width: 119px !important;
    height: 119px !important;
    font-size: 42px !important;
  }

  .teamsgridbox {
    min-height: 130px !important;
    max-height: 130px !important;
  }

  /* .employee-teams {
    min-height: 205px !important;
    max-height: 205px !important;
  } */

  .rowGap55 {
    margin-top: 30px !important;
  }

  .teamsgridbox {
    min-height: 120px !important;
  }

  .perRoleBack {
    position: absolute;
    left: -6px !important;
    top: 70px !important;
  }

  .profileDetails {
    margin-top: 30px !important;
  }

  h3.profHeadTxt.mb46,
  .profilePic.editprofile.mb46,
  .profMap {
    margin-bottom: 30px !important;
  }

  .permissionBackBtn {
    left: 0px !important;
  }

  .employeesTeams {
    min-width: 270px !important;
  }

  .permissionTeam {
    width: calc(100% - 50px) !important;
  }

  .searchPermissions {
    margin-bottom: 20px !important;
  }

  .filterGap2 {
    margin-top: 30px !important;
    margin-bottom: 60px !important;
  }

  .addressSec.padt60 {
    padding-top: 0px !important;
  }

  .profPicSec {
    align-items: center !important;
  }

  .profDetails.editFarmDetails.width448.mb30.rowGapTop9 {
    margin: 0 auto;
  }

  .uploadImg.proRow.mr0.mb24 {
    margin: 0 auto;
    width: 100%;
  }

  .profileDetails .rowGapTop9 {
    margin-top: 0px !important;
  }

  /* .profileDetails {
    grid-template-columns: 1fr !important;
  } */

  .profPicSec.editFarmIcon .mb54 .proRoleHead {
    line-height: 56px;
  }

  .profPicSec.editFarmIcon .mb54 {
    margin-bottom: 20px;
  }

  .permissionTitle.perTeamSec.filterGap3 {
    margin-bottom: 60px;
  }

  .employee-details .employee-profile {
    width: 261px !important;
    padding: 30px 15px !important;
  }
  .profile-details .ant-avatar {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    margin-bottom: 15px !important;
  }
  .userPf .profileDtlWrapper {
    max-height: calc(100vh - 450px) !important;
  }
  .profile-details {
    margin-bottom: 30px !important;
  }

  .employee-details .employee-permissions {
    margin-left: 2.45vw !important;
    width: calc(100% - 410px) !important;
  }
}

/* popups styles goes hear **********************************/

@media (min-width: 740px) and (max-width: 1023px) {
  .mobile_view {
    background-image: url(../../assets/images/mobile-view.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    display: block !important;
  }
  .web_view {
    display: none !important;
  }
}

.tractorDetailsAlertBox1 {
  min-height: 130px !important;
}
