.personnelSec {
  margin-top: 5px;
}

.header {
  display: flex;
}

.videoFeed {
  background-color: #ffffff !important;
  margin: auto;
  padding: 15px 20px 15px 80px;
  height: 100vh;
  min-width: 275px;
}
.remoteDriveBlk {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 15px;
}

.settingsBlk {
  min-width: 170px;
  width: 18vw;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}

.settingsBlk .remoteTitle {
  box-shadow: 0px 1px 2px #00000029;
}

.settingsBlk span {
  font-size: 9px;
  font-family: Montserrat-Medium;
  color: #000000;
  margin-top: 3px;
}

.settingsBlk p {
  font-size: 8px;
  font-family: Montserrat-SemiBold;
  color: #000000;
  text-transform: uppercase;
}

.settingsBlk h6 {
  font-size: 7px;
  font-family: Montserrat-SemiBold;
  color: #707071;
  text-transform: uppercase;
}

.remoteSpeedometer {
  background: url('../../../assets/images/remoteSpeedCircle.svg') no-repeat;
  width: 77px;
  height: 77px;
  display: block;
  margin: 15px auto 20px auto;
}

.remoteSpeedometer p {
  font-size: 7px;
  font-family: 'Montserrat-SemiBold';
  color: #6a6a6a;
  line-height: 8px;
  text-align: center;
  padding-top: 16px;
  margin-bottom: 3px;
}

.remoteSpeedometer h2 {
  font-size: 18px;
  color: #474747;
  font-family: 'Montserrat-SemiBold';
  line-height: 20px;
  text-align: center;
  margin-bottom: 3px !important;
}

.remoteSpeedometer span {
  display: block;
  font-size: 7px;
  font-family: 'Montserrat-SemiBold';
  color: #707071;
  line-height: 8px;
  text-align: center;
  margin-top: 0;
}

.bushHog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.rpmRow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 15px;
}

.remotePin {
  margin-bottom: 5px;
}

.remotePin img {
  display: block;
  margin: auto;
}

.inoutBlk {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
  margin-bottom: 10px;
}

.inoutBlk .inBlk {
  display: flex;
  flex-direction: column;
}

.inoutBlk .outBlk {
  display: flex;
  flex-direction: column;
}

.inoutBlk p {
  margin-bottom: 0;
}

.inoutBlk h5 {
  margin-bottom: 0;
}

.inoutBlk h6 {
  margin-bottom: 0;
}

.cameraBlk {
  min-width: 370px;
  width: 42.27vw;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}

.cameraImgBlk {
  min-width: 369px;
  width: 41.21vw;
  min-height: 276px;
  height: 57.63vh;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 1px 2px #00000029;
}

.cameraBlk img {
  min-width: 369px;
  width: 41.21vw;
  min-height: 276px;
  height: 57.63vh;
  background-position: center;
  background-size: cover;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 1px 2px #00000029;
}

.mapPath {
  min-width: 306px;
  width: 34.93vw;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}

.mapPathInr {
  position: relative;
  /* min-width: 305px;
    width: 34vw; */
  /* min-height: 276px; */
  /* height: 57.63vh; */
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 1px 2px #00000029;
}
.subCam .mapPathInr {
  position: relative;
  /* min-width: 305px; */
  /* width: 34vw; */
  /* min-height: 276px; */
  /* height: 57.63vh; */
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 1px 2px #00000029;
  width: 15.2vw;
  height: 15.02vh;
}

.mapPathInr .mapRecenter {
  background: transparent;
  border-radius: 0;
  display: inline;
  position: absolute;
  bottom: 82px;
  right: 12px;
  margin: 0;
}

.mapPathInr .zoom_in_out {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-image: url(../../../assets/images/zoom_in_out.svg);
  width: 42px;
  height: 72px;
}

.mapPathInr .zoomIn {
  left: 4px;
  top: 3px;
}

.mapPathInr .zoomOut {
  left: 4px;
  top: 3px;
}

.mapPath img {
  width: 305px;
  height: 276px;
  background-position: center;
  background-size: cover;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 1px 2px #00000029;
}

.remoteTitle {
  color: #000000;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.5px;
  line-height: 18px;
  padding-top: 10px;
  padding-bottom: 12px;
  height: 40px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 0;
}

.textCenter {
  text-align: center;
}

.cameraTitle {
  padding-left: 18px;
}

.remoteDriveBtmBlk {
  padding: 0 15px 20px 15px;
}

.remoteDriveBtmBlk h5 {
  color: #000000;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.5px;
  line-height: 10px;
  margin-bottom: 20px;
}

.remoteCamerasBlk {
  display: flex;
  gap: 20px;
}

.camerasBlk {
  width: 59%;
  display: flex;
  gap: 20px;
}

.camPic {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.camPic img {
  width: 91px;
  height: 63px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
}

.camPic span {
  font-size: 12px;
  font-family: Montserrat-Regular;
  color: #000000;
  letter-spacing: 0.19px;
  line-height: 22px;
  text-align: center;
}

.remoteStartBtn {
  background-color: #899947;
  border-radius: 26px;
  width: 185px;
  text-align: center;
  font-size: 22px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 0px;
  line-height: 29px;
  text-transform: uppercase;
  color: #fff;
  padding-top: 9px;
  padding-bottom: 9px;
  cursor: pointer;
  margin: 4px 0 0 auto;
  margin-left: 100px;
}

.remoteStopBtn {
  background-color: #d33c39;
  color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  width: 140px;
  height: 40px;
  font-size: 12px;
  font-family: Montserrat-SemiBold;
  padding: 11px 0 11px 24px;
  cursor: pointer;
  text-transform: uppercase;
}

.remoteStopBtn img {
  padding-right: 12px;
}

.remotePauseBtn {
  background-color: #ffffff;
  color: #000000;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  width: 140px;
  height: 40px;
  font-size: 12px;
  font-family: Montserrat-SemiBold;
  margin-left: 24px;
  padding: 11px 0 11px 24px;
  cursor: pointer;
  text-transform: uppercase;
}

.remotePauseBtn img {
  padding-right: 12px;
}

.remoteResumeBtn {
  background-color: #8a9a48;
  color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  width: 140px;
  height: 40px;
  font-size: 12px;
  font-family: Montserrat-SemiBold;
  margin-left: 24px;
  padding: 11px 0 11px 24px;
  cursor: pointer;
  text-transform: uppercase;
}

.remoteResumeBtn img {
  padding-right: 12px;
}

.remoteActions {
  display: flex;
  margin-top: 4px;
}

.camPic .camInrBlk {
  /* width: 91px;
    height: 63px; */
  width: 113px;
  height: 67px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
}

@media (min-width: 901px) and (max-width: 1023px) {
  .settingsBlk span {
    font-size: 10px;
  }
  .settingsBlk h6 {
    font-size: 8px;
  }
  .bushHog img {
    width: 22px;
  }
  .rpmRow img {
    width: 16px;
  }
  .remotePin img {
    width: 15px;
  }
  .inBlk img {
    width: 18px;
  }
  .camPic .camInrBlk {
    /* width: 95px;
        height: 67px; */
    width: 12.65vw;
    height: 14.94vh;
  }
  .cameraImgBlk {
    width: 41.9vw;
  }
  .mapPathInr {
    width: 34.5vw;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .bushHog img {
    width: 22px;
  }
  .rpmRow img {
    width: 16px;
  }
  .remotePin img {
    width: 15px;
  }
  .inBlk img {
    width: 18px;
  }
  .remoteSpeedometer p {
    font-size: 9px;
  }
  .remoteSpeedometer h2 {
    font-size: 20px;
  }
  .remoteSpeedometer span {
    font-size: 9px !important;
  }
  .settingsBlk span {
    font-size: 12px;
  }
  .settingsBlk h6 {
    font-size: 9px;
  }
  .camPic .camInrBlk {
    /* width: 105px;
        height: 77px; */
    width: 13vw;
    height: 15.94vh;
  }
  .cameraImgBlk {
    width: 42.21vw;
  }
  .mapPathInr {
    width: 34.8vw;
  }
}

@media (min-width: 1000px) and (max-width: 1100px) {
  .mainCam {
    width: 50.5vw !important;
  }
}

@media (min-width: 1101px) and (max-width: 1175px) {
  .mainCam {
    width: 52.3vw !important;
  }
}
@media (min-width: 1176px) and (max-width: 1250px) {
  .mainCam {
    width: 53.5vw !important;
  }
}
@media (min-width: 1251px) and (max-width: 1375px) {
  .mainCam {
    width: 57vw !important;
  }
}
/* @media (min-width: 1376px) and (max-width: 1920px) {
  .mainCam {
    width: 59vw !important;
  }
} */
.mapPathInr {
  width: 100%;
  height: 100%;
}

@media (min-width: 1201px) and (max-width: 1920px) {
  .remoteTitle {
    font-size: 16px;
  }
  .remoteDriveBtmBlk h5 {
    font-size: 16px;
  }
  .bushHog img {
    width: 25px;
  }
  .rpmRow img {
    width: 19px;
  }
  .remotePin img {
    width: 18px;
  }
  .inBlk img {
    width: 21px;
  }
  .remoteSpeedometer p {
    font-size: 9px;
  }
  .remoteSpeedometer h2 {
    font-size: 20px;
  }
  .remoteSpeedometer span {
    font-size: 9px !important;
  }
  .settingsBlk span {
    font-size: 14px;
  }
  .settingsBlk h6 {
    font-size: 10px;
  }
  .settingsBlk p {
    font-size: 9px;
  }
  .camPic .camInrBlk {
    /* width: 160px;
        height: 130px; */
    width: 13.1vw;
    height: 15.94vh;
  }
  .cameraImgBlk {
    width: 42.21vw;
  }
  .mapPathInr {
    width: 100%;
    height: 100%;
  }
  .mapPathInr .ol-viewport {
    border-radius: 10px;
  }
}

@media (min-width: 1921px) and (max-width: 2200px) {
  .mapPathInr {
    width: 100%;
  }
  .mapPathInr .ol-viewport {
    border-radius: 10px;
  }
  .mainCam {
    width: 67.5vw !important;
  }
  /* .mainCam {
  height: 79vh !important;
} */
}

@media (min-width: 1921px) {
  .remoteTitle {
    font-size: 16px;
  }
  .remoteDriveBtmBlk h5 {
    font-size: 16px;
  }
  .remoteSpeedometer p {
    font-size: 9px;
  }
  .remoteSpeedometer h2 {
    font-size: 20px;
  }
  .remoteSpeedometer span {
    font-size: 9px !important;
  }
  .settingsBlk span {
    font-size: 14px;
  }
  .settingsBlk h6 {
    font-size: 10px;
  }
  .settingsBlk p {
    font-size: 9px;
  }
  .camPic .camInrBlk {
    width: 250px;
    height: 150px;
  }
  .settingsBlk {
    width: 345px;
  }
  .cameraBlk {
    width: 820px;
  }
  .cameraImgBlk {
    width: 819px;
  }
  .mapPath {
    width: 670px;
  }
}

.remoteLivemapBlk {
  width: 18vw;
  /* max-width: 314px; */
  background-color: #222741;
  box-shadow: 3px 3px 6px #00000029;
  border: 1px solid #546cb2;
  border-radius: 10px;
  /* padding: 20px 10px; */
  padding: 15px 10px;
  margin-right: 15px;
  min-width: 275px;
}

.alertBlk img {
  cursor: pointer;
  width: 15px;
}

.flexStart {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  min-width: 145px;
}

.flexBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.statusTxt {
  width: 125px;
  background-color: #546cb2;
  border-radius: 9px;
  color: #f7f7f7;
  font-size: 10px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 0.95px;
  line-height: 15px;
  padding: 6px 12px;
  text-align: center;
  text-transform: uppercase;
  display: block;
  /* margin: 40px auto 19px auto; */
  margin: 5px auto 15px auto;
}

.remotetTractorName {
  font-size: 22px;
  font-family: Montserrat-Medium;
  color: #ffffff;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
}

.remoteUsername {
  font-size: 12px;
  font-family: Montserrat-Medium;
  line-height: 19px;
  color: #e7e7e7;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.imgwhite {
  filter: brightness(0) invert(1);
}

.remoteUsername img {
  margin-right: 8px;
}

.remoteIndicatorIcons {
  background-color: #292f4b;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #00000029;
  display: table;
  /* margin: 28px 0 28px auto; */
  margin: 15px 0 15px auto;
  /* padding: 10px 20px; */
  padding: 5px 15px;
}

.remoteIndicatorIcons img {
  margin-right: 10px;
}

.tractorSpeedometer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tractorSpeedometer img {
  /* width: 130px; */
  width: 110px;
}

.tractorSpeedometer span {
  /* color: #CECECF; */
  /* font-size: 11px;
  font-family: Montserrat-Bold;
  letter-spacing: 0.66px;
  line-height: 13px; */
  /* margin-top: 19px; */
}

.tripDetails {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr;
  /* margin: 55px auto; */
  margin: 15px auto;
}

.tripDetails img {
  width: 15px;
}

.tripDetails h5 {
  /* font-size: 20px; */
  font-size: 12px;
  font-family: Montserrat-Medium;
  line-height: 24px;
  color: #ffffff;
}

/* .tripDetails h5 span {
    font-size: 8px;
    font-family: Montserrat-Medium;
    line-height: 20px;
    color: #546CB2;
} */

.playPause img {
  cursor: pointer;
  display: block;
  margin: auto;
  /* width: 100px; */
  width: 65px;
}

.playPause h6 {
  font-size: 10px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.6px;
  line-height: 13px;
  color: #8595ca;
  text-align: center;
}

.remotecamerasBlk {
  width: auto;
  /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; */
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 5px;
}

.remotcameraBlk {
  /* display: flex;
    align-items: flex-start;
    justify-content: center; */
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 15px;
}

.remotcameraBlk img {
  width: 100%;
  height: 100%;
}

.mainCam {
  border: 1px solid #707070;
  border-radius: 12px;
  position: relative;
  width: 59vw;
  height: 81.3vh;
}

.mainCam img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.mainCam h6 {
  position: absolute;
  left: 25px;
  top: 25px;
  font-size: 26px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 2.38px;
  line-height: 37px;
  text-transform: uppercase;
  color: #ffffff;
  z-index: 1;
}

.subCams {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.subCams img {
  /* min-width: 164px !important;
    max-width: 26px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.subCam {
  position: relative;
  cursor: pointer;
  border: 1px solid #707070;
  border-radius: 10px;
  width: 15.2vw;
  height: 15.02vh;
}

.subCam h6 {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.43px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px;
  z-index: 2;
}

.playerBlk img {
  width: 100%;
}

/* @media (min-width: 1500px) {
  .mainCam {
    width: 64vw;
  }
} */

.opImage {
  width: 20px;
  display: flex;
}
.opCount {
  width: 30px;
  font-size: 12px;
  font-family: Montserrat-Medium;
  line-height: 24px;
  color: #ffffff;
  display: flex;
}
.opMesure {
  width: 60px;
  font-size: 8px;
  font-family: Montserrat-Medium;
  line-height: 20px;
  color: #546cb2;
  display: flex;
}
.flexStart.aLeft {
  justify-content: flex-start;
  padding-left: 12px;
}

.isize svg {
  width: 20px !important;
  height: 25px !important;
}

.playStatus {
  width: 125px;
  font-size: 14px;
  text-transform: uppercase;
  color: #fbb313;
  letter-spacing: 0.52px;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-family: Montserrat-SemiBold;
}
.tractorSpeedometer .progressbar {
  width: 160px;
  height: 160px;
}

.tractorSpeedometer .tractor_status_img {
  position: absolute;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
}
.tractorSpeedometer .t_speed_cont {
  top: -20px;
}
.tractorSpeedometer .t_info_progress_circle {
  height: 180px;
}
.t_float_custom.mr5.isize {
  width: 20px;
  display: flex;
}

.playButtonsSec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 15px;
}
.playButtonBg {
  margin-bottom: 5px;
  background-image: url(../../../assets/images/playBg.svg);
  width: 77px;
  height: 77px;
  background-size: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ml5 {
  margin-left: 5px;
}
.palyActionTxt {
  font-size: 10px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.52px;
  color: #8595ca;
  text-transform: uppercase;
}

.endOperation {
  width: 75%;
  height: 40px;
  border-radius: 50px;
  border: 1.5px solid #d33c39;
  background: #161929;
  box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.27%);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.52px;
  font-family: Montserrat-SemiBold;
  cursor: pointer;
}

.mr10 {
  margin-right: 10px;
}

#cammms .camsLoad {
  width: 2em !important;
  height: 1em !important;
}

.subCam iframe {
  border: none;
  border-radius: 10px;
}

.isize path {
  fill: #8595ca;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}

.mainCam iframe {
  border-radius: 8px;
  outline: none;
  border: none;
}

.remotecameraBlk2 {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 11px;
}

.remotecameraBlk2 .equalGrid {
  width: auto;
  height: 40.4vh;
  border-radius: 10px;
  position: relative;
  border: 1px solid #707070;
}

.equalGrid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.equalGrid h6 {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.43px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
}

.subCams2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
}

.subCam2 {
  position: relative;
  cursor: pointer;
  border: 1px solid #707070;
  border-radius: 10px;
  width: 15.2vw;
  height: 15.02vh;
}

.subCams2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.subCam2 h6 {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.43px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
}

.camandmap {
  display: grid;
  grid-template-columns: auto auto;
  gap: 11px;
  margin-top: 11px;
}

.camandmap .camandmapGrid {
  width: auto;
  height: 65.8vh;
  border-radius: 10px;
  position: relative;
  border: 1px solid #707070;
}

.camandmapGrid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.camandmapGrid h4 {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 16px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.43px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
}

@media (min-width: 1500px) {
  /* .mainCam {
    width: 64vw;
  } */
  .remotecameraBlk2 .equalGrid {
    width: 26.3vw;
    height: 40vh;
  }
  .camandmap .camandmapGrid {
    width: 40.5vw;
    height: 65vh;
  }
}

.layoutButtonSec {
  position: absolute;
  width: 140px;
  height: 40px;
  background-color: transparent;
  bottom: 1.85vh;
  right: 10.5vw;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.layoutButton,
.layoutButton:hover,
.layoutButton:active {
  position: absolute;
  background-color: transparent;
  bottom: 0;
  right: 0;
  z-index: 2;
  outline: none;
  border: none;
  cursor: pointer;
}
.layout1 {
  left: 0px;
  background-image: url(../../../assets/images/layoutOneNor.svg);
  width: 27px;
  height: 19px;
  background-size: 60%;
  background-repeat: no-repeat;
}
.layout2 {
  left: 50px;
  background-image: url(../../../assets/images/layoutTwoNor.svg);
  width: 30px;
  height: 20px;
  background-size: 60%;
  background-repeat: no-repeat;
}
.layout3 {
  left: 100px;
  background-image: url(../../../assets/images/layouThreeNor.svg);
  width: 28px;
  height: 20px;
  background-size: 60%;
  background-repeat: no-repeat;
}
.layout1:hover,
.layout1.lsel {
  left: 0px;
  background-image: url(../../../assets/images/layoutOneSel.svg);
  width: 27px;
  height: 19px;
  background-size: 60%;
  background-repeat: no-repeat;
}
.layout2:hover,
.layout2.lsel {
  left: 50px;
  background-image: url(../../../assets/images/layoutTwoSel.svg);
  width: 30px;
  height: 20px;
  background-size: 60%;
  background-repeat: no-repeat;
}
.layout3:hover,
.layout3.lsel {
  left: 100px;
  background-image: url(../../../assets/images/layouThreeSel.svg);
  width: 28px;
  height: 20px;
  background-size: 60%;
  background-repeat: no-repeat;
}
.hideBlock,
.hideBlock .equalGrid,
.hideBlock .subCam,
/* .hideBlock .camandmap, */
.hideBlock .camandmap .camandmapGrid,
.hideBlock .subCams2 {
  width: 0;
  height: 0;
  visibility: hidden;
}

@media (min-width: 1441px) and (max-width: 1649px) {
  .mainCam {
    width: 61.75vw !important;
  }
}
@media (min-width: 1650px) and (max-width: 1800px) {
  .mainCam {
    width: 64vw !important;
  }
}
@media (min-width: 1801px) and (max-width: 1920px) {
  .mainCam {
    width: 65.25vw !important;
  }
}
@media (min-width: 2201px) and (max-width: 2880px) {
  .mainCam {
    width: 71.5vw !important;
  }
}

.cameraContainer {
  width: calc(82vw - 0px);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cameraWrapper {
  width: 100%;
  height: 80vh;
}
.cameraControls {
  width: 100%;
  height: 13.75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cameraBackground {
  background-color: #fff;
}

.fr3 {
  grid-template-columns: 1fr 3fr 1fr !important;
}
.videoFeed .mainCont {
  height: 80vh;
}

.camerasContainer .containerMain {
  display: grid;
  grid-auto-rows: minmax(calc(69vh / 5), auto);
  grid-gap: 15px;
  height: calc(100vh - 0px);
  grid-template-columns: 1fr 3fr 1fr !important;
  padding: 20px;
}

/* layout one styles ============ */

.videoFeed .layoutOne {
  display: grid;
  grid-auto-rows: minmax(calc(69vh / 5), auto);
  grid-gap: 15px;
  height: calc(100vh - 0px);
}

.videoFeed .layoutOne {
  height: calc(100vh - 20vh);
  padding: 0px;
}
.layoutOne .ol-viewport {
  border-radius: 10px;
}

/* layout two styles ============ */

.layoutTwo {
  display: grid;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
}
.layoutTwo .child {
  /* width: 20%; */
  height: 20vh;
}
.layoutTwo .lay2Cam {
  /* width: calc(49.2% - 0px);
  flex-wrap: wrap; */
  height: calc(80vh - 21.25vh);
}
.layoutTwo .lay2map {
  /* width: calc(49.2% - 0px);
  flex-wrap: wrap; */
  height: calc(80vh - 21.25vh);
}

.lay2map h6 {
  font-size: 22px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  z-index: 1;
  margin-top: 15px;
  margin-left: 20px;
}

/* layout three styles ============ */

.videoFeed .layoutThree {
  display: grid;
  grid-auto-rows: minmax(calc(69vh / 5), auto);
  grid-gap: 15px;
  /* height: calc(100vh - 0px); */
}

.frAuto {
  grid-template-columns: 1fr 1fr 1fr !important;
}
.videoFeed .layoutThree .mainCont {
  grid-column: inherit !important;
  grid-row: inherit !important;
  height: 39vh !important;
}
.videoFeed .layoutThree .child {
  height: 39vh !important;
}
.cameraControls img {
  max-width: 100%;
  width: 100%;
}
.videoFeed .layoutThree .child h6 {
  font-size: 16px;
}

.lay2Cam {
  grid-column: 1 / 4;
  grid-row: 2 / 4;
}
.lay2map {
  grid-column-start: 4;
  grid-column-end: 8;
}

.layoutTwo .w100px {
  width: 100px;
  visibility: hidden;
}

.layoutTwo {
  grid-template-columns: 100px 1fr 1fr 1fr 1fr 100px;
}

.layoutOne .w100px,
.layoutThree .w100px {
  display: none;
}
.videoFeed .layoutOne .child .mapRecenter,
.videoFeed .layoutOne .child .zoom_in_out {
  display: none !important;
}
.confirm-end-operation .ant-modal-confirm-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.videoFeed .camerasContainer {
  width: 100%;
  height: 80vh;
  background-color: #ffffff;
}

.videoFeed .mainCont {
  grid-column: 1/3;
  grid-row: 1/6;
  /* background-color: #f00; */
}

.videoFeed .remotcameraBlk {
  gap: 0px !important;
}
