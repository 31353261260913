.impSaveTxt{
    /* font-size: 14px;
    font-family: Montserrat-SemiBold;
    letter-spacing: 1.25px;
    color: #CECECF;
    text-align: right; */
    font-size: 14px;
    font-family: Montserrat-SemiBold;
    border: none;
    outline: none;
    background: none;
    color: #CECECF;
    cursor: pointer;
    right: 38px;
    position: absolute;
    bottom: 20px;
    text-transform: uppercase;
}

.addImpButton{
  height: 60px;
  width: 60px;
  float: right;
  cursor: pointer;
  position: absolute;
  right: 25px;
  bottom: 25px;
    
}

.impManufacture{
    font-size: 18px;
    font-family: Montserrat-SemiBold;
    letter-spacing: 0.15px;
}

/* .impEditBtn button {
    font-size: 14px;
    font-family: Montserrat-SemiBold;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    color: #366376;
    right: 38px;
    position: absolute;
    bottom: 20px;
  } */

  .impAutonomyBtn button {
    /* font-size: 14px;
    font-family: Montserrat-SemiBold; */
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    color: #366376;
    /* position: absolute; */
    /* bottom: 20px; */
    /* bottom: 1.6vh; */
    border: 1px solid #366376;
    /* padding: 10px 16px; */
    /* padding: 1.1vh 0.83vw; */
    border-radius: 9px;
    /* letter-spacing: 1px;
    line-height: 18px; */
  }
  .userIcon{
      position: relative;
  }
  .mr0{
      margin-right: 0;
  }
  .formStyles .ant-input,
  .formStyles .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .formStyles .ant-input:placeholder-shown{
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: 'Montserrat-Regular';
    letter-spacing: 0.15px;
    line-height: 19px;
    color: #5c5c5c;
  }
  .formStyles .ant-input::placeholder,
  .formStyles .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    /* font-size: 16px;
    font-family: 'Montserrat-Medium';
    letter-spacing: 0.15px;
    line-height: 19px; */
    color: #B6B6B7;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-size: 12px;
  }
  .userIcon .ant-upload{
    right: 20px;
    bottom: 30px;
    height: auto!important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    width: auto!important;
  }
  /* .formStyles .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 47px !important;
  } */

  .EmpUserIcon{
    position: relative;
}

.EmpUserIcon .ant-upload{
  right: 20px;
  bottom: 54px;
  height: auto!important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  width: auto!important;
}

.posabs {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  color: #959595;
  z-index: 9;
}

.ipw input {
  padding-right: 40px;
}
.mfAdd {
  background: #ededed;
  color: #000;
  position: relative;
  top: 4px;
  padding: 8px;
  cursor: pointer;
  height: 42px;
  margin-top: -5px;
  word-break: break-word;
}
.ant-divider {
  margin: 0px !important;
}

.dM {
  font-family: 'Montserrat-Medium';
}
.dS {
  font-family: 'Montserrat-SemiBold';
}
.autocanelBtn {
  display: none;
}
.autonomyModal input,
.autonomyModal input:hover,
.autonomyModal input:focus {
  height: 44px;
  border: 1px solid #366376!important;
  border-radius: 10px;
  box-shadow: none!important;
  font-size: 16px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.15px;
  line-height: 19px;
  color: #707070;
}
.autonomyModal .ant-select-selector{
  height: 44px;
  border: 1px solid #366376!important;
  border-radius: 10px;
  box-shadow: none!important;
  font-size: 16px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.15px;
  line-height: 19px;
  color: #707070;
}
.okBtn.autonomySaveBtn{
  box-shadow: none;
  border: 0;
  color: #366376!important;
  background-color: transparent!important;
  padding: 0!important;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
  text-transform: uppercase;
}
.okBtn.disableSave{
  color: #b9c4c9!important;
  cursor: default;
}
/* .autonomymodal {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
} */
/* .autonomywidth {
  width: 50% !important;
} */
.autonomySettingsModal .ant-modal-content {
  border: 2px solid #707070;
  border-radius: 20px!important;
  box-shadow: 0 3px 6px #00000029!important;
}
.autonomySettingsModal .ant-modal-close{
  right: 16px!important;
  top: 16px!important;
}
.autonomySettingsModal .anticon-close{
  color: #366376;
  font-size: 22px;
}
.autonomySettingsModal .ant-modal-body{
  padding-left: 64px!important;
  padding-right: 73px!important;
}
.autonomySettingsModal .ant-modal-footer{
  border-top: 0!important;
  padding-bottom: 28px!important;
  padding-right: 37px!important;
}
.implementsWidget .ant-avatar{
  width: 145px!important;
  height: 145px!important;
}
.impSetpopup .posabs{
  top:8px !important;
}
.autonomy_modal_sec .generictoggle{
  /* height: 5vh !important; */
  border-radius: 10px;
  padding: 0 11px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #366376;
}
.autonomy_modal_sec .ovfAuto{
  overflow-y: auto !important;
  height: 350px !important;
  padding-right: 2px;
  gap: 30px;
}
.autonomy_modal_sec .ant-input{
  /* height: 5vh !important; */
  border-radius: 10px;
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.autonomy_modal_sec .ant-input:hover{
  border-radius: 10px;
  border: 1px solid #d9d9d9 !important;
}
.genric_toggle_sec{
  /* height: 47px !important; */
  border-radius: 10px;
  padding: 0 11px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border: 1px solid #366376;
}



.autonomySettingPopup .ant-modal-body {
  height: calc(100vh - 20vh);
}
.autonomySettingPopup .ant-modal-content {
  width: 700px;
}
.posrel {
  position: relative;
}

.autoHeads.ovfAuto {
    overflow-y: auto;
    height: calc(100vh - 42vh);
    padding-right: 24px;
}

.mt24.generictoggle {
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
    margin-right: 5px;
}
.mtb_0{
  margin-top: 15px !important;
  margin-bottom: 5px !important;
}

/* Chrome, Safari, Edge, Opera */
.fieldRange input::-webkit-outer-spin-button,
.fieldRange input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.fieldRange input[type=number] {
  -moz-appearance: textfield;
}

.implmentInput {
  padding-top: 13px !important;
}

.manufactureTxt .ant-select-selection-search-input{
  margin-top: 16px !important;
}

.modelSelect .selectImplementContainer .ant-select-selection-item {
  padding-top: 10px !important;
  font-size: 16px;
  font-family: "Montserrat-Regular" !important;
  color: #000000D9 !important;
}
