.trimbleAppContainer{
    width: 94%;
    margin: 24px auto;
}

.digiAppContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.trimbleAppContainer .ant-card-bordered{
    border-radius: 12px;
    overflow: hidden;
}

 .trimbleLogIn{
    padding: 28px;
    border: 1px solid #ccc;
    width: 412px;
    text-align: center;
    background: #fff;
    border-radius: 10px;
 }

 .importBtn, .importBtn:hover, .importBtn:focus {
    height: 40px;
    border: none;
    border-radius: 3px;
    background-color: #1e3a46de;
    border-color: #1e3a46de;
    font-size: 14px;
    padding: 4px 15px;
    font-family: 'Montserrat-Bold';
    width: 112px;
    letter-spacing: 0.6px;
 }

 .importTitle{
    font-family: 'Montserrat-Medium';
    font-size: 18px;
 }

 .appStoreTxt {
    font-family: 'Montserrat-SemiBold';
    font-size: 24px;
    margin-bottom: 28px;
 }

 .trimbleLogTxt{
     display: flex;
 }

 .logoStyle{
     border-radius: 12px;
     margin-top: 12px;
     padding: 10px;
     box-shadow: 0px 0px 6px #00000054;
 }

 .trimbleIdentity{
    font-family: 'Montserrat-SemiBold';
    font-size: 16px;
 }

 .trimbleInc{
    font-family: 'Montserrat-SemiBold';
    font-size: 13px;
 }

 .trimbleTxtCon{
     text-align: left;
     margin-top: 15px;
     margin-left: 15px;
 }

 .trimbleAppContainer button{
    border: 1px solid #ccc;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    background-color: transparent;
 }

 .trimbleAppContainer button:hover{
    background: #efefef;
    border: 1px solid #adaaaa;
 }

 .ant-layout{
    background-color: transparent;
 }

 .syncBtn{
    width: 108px;
    background: #338e94;
    border: none;
    border-radius: 20px;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 0.8px;
 }

 .syncBtn:hover, .syncBtn:active, .syncBtn:focus{
    background: #2a989f;
    border: none;
 }

 .bgColor{
    background:#123a46;
    margin-left: 10px;
 }

 .bgColor:focus, .bgColor:active, .bgColor:hover{
   background:#123a46db;
 }

 .trimbleOkBtn{
   width: 120px;
   box-shadow: 0px 0px 6px #00000054;
   border-radius: 10px;
   color: #224e52;
   margin-top: 20px;
   margin-bottom: 16px;
 }

 .syncModalCon .ant-modal-content {
   min-width: 580px;
   border: 0;
   font-family: Montserrat-SemiBold;
   border-radius: 14px;
 }

 .syncModalCon .ant-modal-body {
   text-align: center;
 }

 .syncModalCon{
   width: 700px !important;
 }

 .syncModalCon .ant-table table{
   width: 678px !important;
 }

 .syncModalCon .infinite-scroll-component{
   max-height: calc(100vh - 57.5vh)!important;
 }