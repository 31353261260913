.libraryContainer .ant-tabs-nav {
  /* margin-top: -30px; */
  z-index: 100 !important;
  position: unset;
  margin-top: 0;
  height: 45px;
}

.analyticsTab .elTabs .libraryContainer .ant-tabs-content {
  height: calc(100vh - 182px) !important;
  padding: 2px 0;
  margin-top: 0 !important;
  overflow: hidden;
}

.libraryContainer .ant-tabs-nav-wrap {
  padding-bottom: 0;
  padding-left: 0;
}

/* .libraryContainer .ant-tabs-content-holder {
    padding-bottom: 20px;
} */

.libraryBlk {
  margin-top: 2.99vh !important;
}
@media (min-width: 1921px) {
  .libraryBlk {
    margin-top: 27px !important;
  }
}
