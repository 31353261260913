/* body {
  background: #dddddd;
} */

.loginRight .layout {
  height: 100%;
  background-color: transparent;
}
.loginContainer {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/images/login_background.png);
  background-size: cover;
}
.main {
  max-width: 1200px;
  max-height: 700px;
  margin: auto;
}
.loginLeft {
  font-family: Montserrat-Bold;
  color: #fff;
  height: 534px;
  margin-right: 52px;
  width: 50%;
  align-items: flex-start;
  padding-top: 40px;
  height: auto;
}
.loginLeft p {
  text-align: left;
  font-size: 52px;
  letter-spacing: 1.52px;
  line-height: 130%;
}
.loginRight {
  padding: 86px;
  position: relative;
  width: 55%;
  padding: 0px;
  align-items: flex-start;
  height: auto;
}
/* .atomText {
  color: #df953e;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 40px;
  font-family: Montserrat-SemiBold;
  text-align: center;
  margin-bottom: 90px !important;
} */
/* .LoginText {
  font-size: 30px;
  font-family: Montserrat-SemiBold;
  color: #212121;
  margin-bottom: 100px !important;
} */
.labelTxt {
  font-size: 16px;
  font-family: Montserrat-Medium;
}

.loginRight .ant-space-item Input {
  border-radius: 8px;
}
.loginRight .ant-space-item span {
  /* border-radius: 8px; */
  /* color: #ccc; */
}
.btnTxt {
  background-color: #ef8403;
  border: 1px solid #ef8403;
  border-radius: 20px;
  font-size: 22px;
  height: 55px;
  min-width: 136px;
  text-transform: uppercase;
  font-family: Montserrat-SemiBold;
  margin-top: 4px;
  box-shadow: 0px 0px 6px #00000029;
  letter-spacing: 1.74px;
}
.btnTxt:hover {
  background-color: #cc6e0cc7;
}
.LoginImg {
  margin-bottom: 30px;
}

.loginWrapper {
  -webkit-backdrop-filter: blur(10px) brightness(1.3);
  backdrop-filter: blur(10px) brightness(1.3);
  background-color: rgba(255, 255, 255, 0.17);
  box-shadow: 3px 3px 10 rgba(0, 0, 0, 1);
  text-align: center;
  padding: 3.5% 5%;
  position: relative;
  overflow: hidden;
  width: 1024px;
  max-width: 100%;
  min-height: 650px;
  border-radius: 53px;
  color: #fff;
}
@media screen and (min-width: 1441px) and (max-width: 1680px) {
  .loginWrapper {
    width: 950px;
  }
}
.rememberTxt .ant-checkbox-inner {
  background-color: transparent !important;
  background: transparent !important;
  border: 1px solid #fff !important;
  margin-right: 5px !important;
}

.rememberTxt .ant-checkbox-input {
  margin-right: 5px !important;
}
.rememberTxt .ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 1px solid #fff !important;
}

@media screen and (min-width: 320px) and (max-width: 1440px) {
  .pwSuccess {
    font-family: Montserrat-SemiBold;
    font-size: 22px !important;
  }

  .resetCont {
    font-family: Montserrat-SemiBold;
    font-size: 12px !important;
  }
  .resetContChar {
    font-family: Montserrat-Medium;
    font-size: 14px !important;
  }

  .resetWrap {
    width: 1000px !important;
    max-width: 100%;
    max-height: 560px !important;
    border-radius: 32px !important;
  }
  .resetWrap .loginLeft {
    padding: 70px !important;
  }
  .resetWrap .loginRight {
    padding: 80px 60px 100px 100px !important;
  }

  .resetWrap .loginLeft p {
    font-size: 24px !important;
    font-family: Montserrat-SemiBold;
  }
  .mtb30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .mb40 {
    margin-bottom: 40px !important;
  }
  .mb30 {
    margin-bottom: 30px !important;
  }
  .mb50 {
    margin-bottom: 50px !important;
  }
  .mt20 {
    margin-top: 20px !important;
  }
  .w48 {
    width: 48% !important;
  }
  .w52 {
    width: 52% !important;
  }
  .mb60 {
    margin-bottom: 60px !important;
  }
  .mb70 {
    margin-bottom: 70px !important;
  }
  .mtb0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .loginWrapper {
    -webkit-backdrop-filter: blur(10px) brightness(1.3);
    backdrop-filter: blur(10px) brightness(1.3);
    background-color: rgba(255, 255, 255, 0.17);
    box-shadow: 3px 3px 10 rgba(0, 0, 0, 1);
    text-align: center;
    padding: 32px 50px;
    position: relative;
    overflow: hidden;
    width: 700px;
    max-width: 100%;
    min-height: 460px;
    border-radius: 32px;
    color: #fff;
  }
  .resetSuccessWrap {
    width: 700px !important;
    max-width: 100%;
    min-height: 460px !important;
    padding: 32px 50px !important;
    border-radius: 32px !important;
  }
  .paddingTxt {
    padding: 15px 18% !important;
    text-align: left;
  }

  .loginFRow {
    /* margin-bottom: 30px !important; */
    margin-bottom: 20px !important;
    text-align: initial;
  }
  .monarchImg img {
    height: 50px !important;
    /* margin-bottom: 60px !important; */
    margin-bottom: 30px !important;
  }
  .loginLeft p {
    text-align: left;
    font-size: 24px;
    letter-spacing: 1.52px;
    line-height: 135%;
  }
  .loginContWrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .loginLeft {
    font-family: Montserrat-Bold;
    color: #fff;
    margin-right: 22px;
    width: 50%;
    align-items: flex-start;
    padding-top: 40px;
    height: auto;
  }
  .loginRight {
    position: relative;
    width: 55%;
    padding: 0px;
    align-items: flex-start;
    height: auto;
  }
  .labelTxt {
    font-size: 14px;
    font-family: Montserrat-Medium;
  }

  .loginRight .ant-input {
    border: 1px solid #dfe1e6 !important;
    background-color: #00000033;
    border-radius: 0px !important;
    height: 40px !important;
    font-family: 'Montserrat-SemiBold';
    font-size: 16px !important;
    color: #fff;
    padding: 5px 10px !important;
    box-sizing: border-box;
  }

  .loginRight .ant-input-affix-wrapper.ant-input-password {
    background-color: #00000033 !important;
    border-radius: 0px !important;
    height: 40px !important;
    font-family: 'Montserrat-Medium';
    font-size: 16px !important;
    color: #fff;
    padding: 5px 10px !important;
    box-sizing: border-box;
  }
  .loginRight .ant-input-affix-wrapper.ant-input-password input {
    border: none !important;
    background-color: transparent !important;
    padding: 0 !important;
    height: 30px !important;
    color: #fff;
  }
  .loginRight .ant-space {
    gap: inherit !important;
  }

  .loginRight .ant-input-affix-wrapper input input::placeholder {
    font-size: 14px;
    font-family: Montserrat-Medium;
  }
  .btnTxt {
    background-color: #ef8403;
    border: 1px solid #ef8403;
    border-radius: 12px;
    font-size: 16px;
    height: 40px;
    min-width: auto !important;
    text-transform: uppercase;
    font-family: Montserrat-SemiBold;
    margin-top: 10px;
    box-shadow: 0px 0px 6px #00000029;
    letter-spacing: 1.74px;
    padding: 4px 20px;
  }
  .btnTxt:hover {
    background-color: #cc6e0cc7;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .loginLeft {
    display: none;
  }

  .loginRight {
    width: 100%;
    min-width: inherit;
    padding: 80px 50px;
  }
  .atomText {
    font-size: 30px;
  }
  .monarchImg img {
    height: 50px;
  }
}

.loginRight .ant-col {
  display: block;
  width: 100%;
  height: 100%;
}

.loginRight .container {
  width: 100%;
  height: 100%;
}

.loginRight .ant-space.ant-space-vertical {
  width: 100%;
}

.loginRight .ant-input {
  border: 1px solid #dfe1e6 !important;
  background-color: #00000033;
  border-radius: 0px !important;
  height: 60px;
  font-family: 'Montserrat-SemiBold';
  font-size: 16px !important;
  color: #fff;
  padding: 5px 10px !important;
  box-sizing: border-box;
}

.loginRight .ant-input-affix-wrapper.ant-input-password {
  background-color: #00000033 !important;
  border-radius: 0px !important;
  height: 60px;
  font-family: 'Montserrat-Medium';
  font-size: 16px !important;
  color: #fff;
  padding: 5px 10px !important;
  box-sizing: border-box;
  align-items: center;
}
.loginRight .ant-input-affix-wrapper.ant-input-password input {
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
  height: 38px;
  color: #fff;
}
.loginRight .ant-space {
  gap: inherit !important;
}

.loginRight .ant-input-affix-wrapper input input::placeholder {
  font-size: 14px;
  font-family: Montserrat-Medium;
}

/* .loginRight .ant-space-item {
  margin-bottom: 8px;
} */

.loginRight .ant-space-item .LoginText {
  margin-bottom: 20px;
}
.loginRight .ant-space-item .atomText {
  margin-bottom: 50px;
}

.loginRight button:active,
.loginRight button:hover,
.loginRight button:focus {
  background: #f78c00;
  border: 1px solid #ef8403;
}
.loginRight .ant-input-affix-wrapper {
  padding: 0px !important;
}

.loginRight .ant-input-affix-wrapper > input.ant-input {
  padding: 4px 11px;
}
.loginRight .ant-input-suffix {
  padding-right: 11px;
}

.loginHead {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 65px;
  border-bottom: 1px solid #252629;
  position: relative;
  top: -64px;
  background: #fff;
}

.listitems {
  list-style-type: none;
  background: #2f333b !important;
  margin-top: 14px;
}
.listitems li {
  border-left: 3px solid #2f333b;
  margin-bottom: 2px !important;
  margin-top: 0px !important;
  line-height: 48px !important;
  color: #8a8d97;
}

.listitems li:hover,
.listitems .ant-menu-item-selected {
  background: #2f333b !important;
}

.listitems li a,
.listitems li span {
  font-size: 16px;
  font-family: Montserrat-Medium;
  color: rgba(255, 255, 255, 0.65);
}
.listitems li img {
  margin-right: 15px !important;
}
.listitems li img {
  position: relative;
  top: -2px;
}

.listitems .ant-tooltip-open a,
.ant-tooltip a {
  color: #fff !important;
}

.listitems .ant-menu-item-icon,
.listitems .anticon {
  font-size: 18px !important;
}

.listitems li:hover span {
  color: #fff;
}

.listitems li li:hover {
  border-left: none !important;
  background-color: #3a3a3a !important;
}

.listitems li li {
  border-left: none !important;
}
.listitems li li.ant-menu-item-selected {
  background-color: #3a3a3a !important;
}

.mapIcons {
  position: absolute;
  right: 15px;
  bottom: 65px;
  z-index: 999;
}
.zoom_in_out {
  background-image: url(../../assets/images/z_in_out.svg);
  width: 57px;
  height: 95px;
}

.c_loc {
  background-image: url(../../assets/images/c_loc.svg);
  width: 42px;
  height: 42px;
  bottom: -5px;
  position: relative;
  cursor: pointer;
}
.compass {
  background-image: url(../../assets/images/compass.svg);
  width: 57px;
  height: 57px;
  bottom: 5px;
  position: relative;
  cursor: pointer;
  right: 2px;
}
.mapRecenter {
  cursor: pointer;
  /* text-align: center;
  /* background-color: #f7f7f7; */
  /* position: relative;
  margin: 0px 20px 0px 7px; */
  /* border-radius: 50%; */
}

.update_polygon_screen {
  background-image: url(../../assets/images/f_screen.svg);
  width: 58px;
  height: 58px;
  position: relative;
  bottom: -16px;
  cursor: pointer;
}

.f_screen {
  background-image: url(../../assets/images/f_screenG.svg);
  width: 57px;
  height: 57px;
  position: relative;
  bottom: -10px;
  cursor: pointer;
  background-size: 55px 55px;
  background-repeat: no-repeat;
  right: -7px;
  cursor: pointer;
}

.f_screenCol {
  background-image: url(../../assets/images/f_screenGcol.svg);
  width: 57px;
  height: 57px;
  position: relative;
  bottom: -10px;
  cursor: pointer;
  background-size: 55px 55px;
  background-repeat: no-repeat;
  right: -7px;
  cursor: pointer;
}

.f_out {
  background-image: url(../../assets/images/f_outG.svg);
  width: 57px;
  height: 57px;
  position: relative;
  /* bottom: -16px; */
}
.n_pin {
  background-image: url(../../assets/images/n_pin.svg);
  width: 42px;
  height: 42px;
  position: absolute;
  top: 80px;
  right: 7px;
  z-index: 99;
}

.n_graticule {
  background-image: url(../../assets/images/grid.png);
  width: 40px;
  height: 40px;
  position: absolute;
  top: 80px;
  right: 15px;
  z-index: 99;
}
.livemapSidebar {
  position: absolute;
  z-index: 999;
  height: calc(100vh - 64px);
  border-top: 1px solid #ebecf0;
  right: 0px;
}

.demo-loadmore-list ul {
  margin-top: 20px;
}

.demo-loadmore-list ul li span.ant-avatar {
  border-radius: 0 !important;
  width: inherit !important;
  height: inherit !important;
}

.demo-loadmore-list ul .ant-list-item-meta-avatar {
  margin-left: 0px !important;
  margin-right: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
}

.demo-loadmore-list ul li img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.demo-loadmore-list ul li {
  border: none !important;
  margin-bottom: 5px;
  padding: 5px 30px;
}
.demo-loadmore-list ul li:hover {
  border: none !important;
  margin-bottom: 5px;
  background: #e5e5e5;
  padding: 5px 30px;
  color: #212121;
}

.demo-loadmore-list ul li .ant-list-item-meta {
  display: flex;
  align-items: center;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.site-layout .site-layout-background {
  background: #fff;
}

.tIconOpen {
  background-image: url(../../assets/images/menuRight.svg);
  width: 46px;
  height: 66px;
  position: absolute;
  cursor: pointer;
  right: 0px;
  top: -78px;
}
.tIconClose {
  background-image: url(../../assets/images/menuLeft.svg);
  width: 46px;
  height: 66px;
  position: absolute;
  cursor: pointer;
  right: 0px;
  top: -78px;
}
.leftTIconClose {
  background-image: url(../../assets/images/Back_btn.svg);
  width: 46px;
  height: 66px;
  position: absolute;
  cursor: pointer;
  left: 4px;
  top: 9px;
  background-repeat: no-repeat;
}

.livemapSidebar .ant-card-body {
  padding: 15px !important;
  overflow-y: auto;
  height: calc(100vh - 140px);
  padding-top: 50px;
}

.livemapSidebar .ant-input-affix-wrapper {
  margin-bottom: 10px !important;
}
.demo-loadmore-list .ant-list-item-meta-title {
  font-family: Montserrat-Medium;
  font-size: 14px;
  color: #212121;
}

.livemapSidebar .ant-card-head {
  padding: 10px 15px !important;
}
.livemapSidebar .ant-card-head-title {
  padding: 0 !important;
  font-family: Montserrat-SemiBold;
  font-size: 16px;
}

.livemapSidebar .ant-card-body .ant-checkbox-inner {
  margin-left: 0px !important;
}

.livemapSidebar .ant-card-body .ant-checkbox + span {
  padding-left: 10px !important;
  white-space: nowrap;
  position: relative;
  top: 5px;
}

.loginFRow {
  /* margin-bottom: 42px; */
  margin-bottom: 32px;
  text-align: initial;
}

.mb30 {
  margin-bottom: 30px;
}
.mb50 {
  margin-bottom: 50px;
}
.mb25 {
  margin-bottom: 25px;
}

.layer-holder {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: 180px;
}

.file-upload {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: 220px;
}

.mark-holder:hover,
.layer-holder:hover {
  cursor: pointer;
}

.mark-holder .grid-image,
.layer-holder .grid-image {
  width: 42px;
  height: 42px;
}

.mark-holder {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  border-radius: 2px;
  position: absolute;
  bottom: 295px;
  right: 9px;
}
.graticule-holder {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 142px;
  right: 0px;
}

.graticule-holder:hover {
  cursor: pointer;
}

.graticule-holder .grid-image {
  width: 42px;
  height: 42px;
}
.draw-vine {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  border-radius: 2px;
  position: absolute;
  bottom: 270px;
  right: 9px;
}

.draw-vine-row {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  border-radius: 2px;
  position: absolute;
  bottom: 600px;
  right: 9px;
}

.listitems li:hover,
.listitems .ant-menu-item-selected {
  border-left: 3px solid #f17e03;
}

.versionTxt {
  position: absolute;
  right: -42px;
  font-family: Montserrat-Medium;
  bottom: -52px;
  font-size: 10px;
  color: #808184;
}

.loading {
  position: absolute;
  transform: translate(50%, 50%);
  width: 100vw;
  z-index: 1;
  height: 100vh;
}

.loginWrapper input:-webkit-autofill,
.resetWrap input:-webkit-autofill,
.loginWrapper input:-webkit-autofill:hover,
.resetWrap input:-webkit-autofill:hover,
.loginWrapper input:-webkit-autofill:focus,
.resetWrap input:-webkit-autofill:focus,
.loginWrapper input:-webkit-autofill:active,
.resetWrap input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
}

.forgotTxt {
  font-family: Montserrat-Medium;
  font-size: 14px;
  letter-spacing: 0.15px;
  margin-bottom: 4px;
  cursor: pointer;
  text-align: right;
}

.rememberTxt {
  font-family: Montserrat-Medium;
  font-size: 14px;
  letter-spacing: 0.15px;
  margin-bottom: 10px;
}

.rememberTxt.ant-checkbox {
  border: 1px solid red !important;
}

.loginContWrap {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.monarchImg img {
  height: 70px;
  margin-bottom: 60px;
}

/* Reset password */

.resetWrap {
  border-radius: 53px;
  overflow: hidden;
  width: 1024px;
  max-width: 100%;
  max-height: 779px;
  color: #fff;
}
.resetWrap .loginLeft {
  padding: 6.5% 5%;
  font-family: Montserrat-Bold;
  color: #fff;
  background-color: #366376;
  margin-right: 0;
  align-items: flex-start;
  height: auto;
  text-align: center;
}

.resetWrap .loginRight {
  -webkit-backdrop-filter: blur(10px) brightness(1.3);
  backdrop-filter: blur(10px) brightness(1.3);
  background-color: rgba(255, 255, 255, 0.17);
  padding: 8%;
  text-align: center;
}

.resetWrap .loginLeft p {
  font-size: 34px;
  font-family: Montserrat-SemiBold;
  margin-top: 38px;
}

.resetCont {
  font-family: Montserrat-SemiBold;
  font-size: 14px;
  letter-spacing: 0.15px;
  text-align: left;
}

.resetContChar {
  font-family: Montserrat-Medium;
  font-size: 16px;
  text-align: left;
  margin-top: 28px;
}

.resetContChar img {
  margin-right: 10px;
}

.resetSuccessWrap {
  background-color: #366376;
  box-shadow: 3px 3px 10 rgba(0, 0, 0, 1);
  text-align: center;
  padding: 3.5% 5%;
  position: relative;
  overflow: hidden;
  width: 1024px;
  max-width: 100%;
  min-height: 650px;
  border-radius: 53px;
  color: #fff;
}

.mtb32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

/* Forgot password */

.forgotPwContent {
  font-family: Montserrat-Regular;
  font-size: 16px;
  letter-spacing: 0.56px;
}

.paddingTxt {
  padding: 8% 24% 0;
  text-align: left;
}

.paddingTxt .loginRight {
  width: 100%;
}

.mt72 {
  margin-top: 72px;
}

.mb72 {
  margin-bottom: 72px;
}
.errMsgDiv {
  height: 20px;
  margin-bottom: 20px;
}

.ant-input.validationClass,
.loginRight .ant-input-affix-wrapper.ant-input-password.validationClass {
  background-color: #d33c3930 !important;
  border-color: #d33c39 !important;
  border: 1px solid #d33c39 !important;
}
.lMapCont .mapRecenter {
  bottom: -5px;
}

.termscanelBtn .anticon svg {
  display: none;
}

.termspopup .btnSave {
  height: 32px;
  background-color: #e16d01 !important;
  border-color: #c96202 !important;
  font-size: 14px;
  color: #fff;
  border-radius: 3px;
  text-transform: uppercase;
}

.termspopup .btnCancel {
  width: 100px;
  height: 32px;
  font-size: 14px;
  background-color: #e16d01 !important;
  border-color: #c96202 !important;
  color: #fff !important;
  border-radius: 5px;
  border-radius: 2px;
}

.termspopup .ant-modal-footer {
  text-align: center !important;
}
.termspopup .ant-modal-title {
  text-align: center;
  color: #000;
  font-size: 22px !important;
}
.termspopup .ant-modal-header {
  /* background-color: #366376; */
  background-color: #fff;
  border-radius: 0 !important;
}

.termspopup .ant-modal-content {
  border-radius: 0 !important;
}
/* .termspopup .ant-modal-body {
  min-height: 309px;
  overflow-y: auto;
  padding: 35px 80px !important;
} */

p.loginSubtitle {
  font-size: 24px;
  font-family: Montserrat-Bold;
  letter-spacing: 1.52px;
  line-height: 135%;
  margin-bottom: 10px;
  text-align: center;
}

.versionCon {
  position: absolute;
  bottom: 0;
  left: 30px;
}
.versionCon h1 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #d3d3d3;
  float: left;
  font-family: 'Montserrat-Semibold';
}
.versionCon h3 {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.56px;
  color: #d3d3d3;
  float: left;
  padding-top: 0px;
  padding-left: 15px;
  font-family: 'Montserrat-Semibold';
}
@media (min-width: 320px) and (max-width: 767px) {
  .mobileLoginWrapper {
    margin: 15px;
    padding: 30px !important;
    min-height: 430px !important;
  }
  .mobileLoginWrapper h3 {
    background: #ec9322;
    border-radius: 10px;
    box-shadow: 0 0 6px #00000029;
    color: #fff;
    font-size: 22px;
    font-family: 'Montserrat-Semibold';
    letter-spacing: 0.8px;
    padding: 8px 15px;
  }
  img.compLogo {
    width: 100% !important;
    height: auto !important;
    margin-bottom: 30px !important;
  }
  .appIcons {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
  }
  .appIcons img {
    width: 155px;
  }
  .mobileLoginWrapper .loginContWrap {
    margin-top: 45px;
  }
}
@media (min-width: 320px) and (max-width: 359px) {
  .appIcons {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5px;
  }
  .appIcons img {
    width: 100% !important;
  }
}

.term-header {
  display: flex;
  width: 70%;
  margin: auto;
  align-items: center;
}

.term-head-img {
  flex: 1;
}
.term-img-caption {
  width: 80%;
  font-size: 12px;
  margin: auto;
}
.AD_TermsPopupPDF .term-header img {
  margin-right: 25px;
}
.term-head-title {
  /* flex: 3;
  text-align: left; */
  color: #000000;
  font-size: 24px;
  font-family: 'Montserrat-Bold';
  letter-spacing: 0.18px;
}
.AD_TermsPopupPDF {
  width: 1043px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0px !important;
}
.AD_TermsPopupPDF .term-header {
  width: 100% !important;
  justify-content: center;
}
.AD_TermsPopupPDF .ant-modal-header {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
  border-bottom: 1px solid #e7e7e7 !important;
}
.AD_TermsPopupPDF .ant-modal-footer {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e7e7e7 !important;
}

.AD_TermsPopupPDF .ant-modal-content {
  width: 1100px;
  padding: 0px 50px;
}
.AD_term_content {
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  color: #000;
  letter-spacing: 0.25px;
}
.AD_TermsPopupPDF.termspopup .ant-modal-content {
  border-radius: 10px !important;
  max-height: 650px;
}

.tcBtns,
.tcBtns:focus,
.tcBtns:hover {
  outline: none;
  width: 150px;
  height: 45px;
  border-radius: 5px;
  display: block;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.tcDecline,
.tcDecline:focus,
.tcDecline:hover {
  background: #fff;
  border: 2px solid #366376;
  color: #366376;
}

.tcAccept,
.tcAccept:focus,
.tcAccept:hover {
  background: #366376;
  border: 2px solid #366376;
  color: #fff;
}
.AD_TermsPopupPDF .ant-modal-footer .tcDecline {
  margin-right: 45px;
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
  .AD_TermsPopupPDF .ant-modal-body {
    margin: 35px 0 !important;
  }
}

.AD_TermsPopupPDF .ant-modal-body {
  overflow-y: auto;
  max-height: 310px;
  margin: 15px 0;
  padding: 0 !important;
  min-height: 309px;
}

.AD_TermsPopupTEXT {
  width: 812px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0px !important;
}
.AD_TermsPopupTEXT .term-header {
  width: 100% !important;
  justify-content: center;
}
.AD_TermsPopupTEXT .ant-modal-header {
  padding: 45px 0 !important;
  border-bottom: 1px solid #e7e7e7 !important;
  position: relative;
}
.AD_TermsPopupTEXT .ant-modal-footer {
  padding-top: 35px !important;
  padding-bottom: 65px !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: none !important;
}

.AD_TermsPopupTEXT .ant-modal-content {
  width: 1100px;
  padding: 0px 50px;
}
.AD_TermsPopupTEXT .ant-modal-body {
  overflow-y: auto;
  max-height: 144px;
  margin: 50px 50px 18px 50px;
  padding: 0 !important;
  /* min-height: 144px; */
}
.AD_term_content .ql-container.ql-snow {
  border: none !important;
}
.AD_term_content .ql-editor,
.AD_term_content {
  font-family: 'Montserrat-Regular';
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  color: #000;
  letter-spacing: 0.25px;
  /* border: none; */
  padding: 0px !important;
}
.AD_TermsPopupTEXT.termspopup .ant-modal-content {
  border-radius: 10px !important;
  max-height: 650px;
}
.AD_TermsPopupTEXT .term-header2 {
  justify-content: center;
  display: flex;
  align-items: center;
}
.AD_TermsPopupTEXT .term-head-title2 {
  width: 100% !important;
  justify-content: center;
  display: flex;
  align-items: center;
  /* font-size: 24px;
    font-family: 'Montserrat-Bold';
    letter-spacing: 0.18px; */
}
.AD_TermsPopupTEXT .term-header2 img {
  position: absolute;
  left: 0px;
}

.tcBtns2,
.tcBtns2:focus,
.tcBtns2:hover {
  outline: none;
  width: 196px;
  height: 45px;
  border-radius: 23px;
  display: block;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat-SemiBold';
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.95px;
  text-transform: uppercase;
}
.tcDecline2,
.tcDecline2:focus,
.tcDecline2:hover {
  background: #fff;
  border: 1px solid #366376;
  color: #366376;
}

.tcAccept2,
.tcAccept2:focus,
.tcAccept2:hover {
  background: #366376;
  border: 1px solid #366376;
  color: #fff;
}

.wspanAiBtn {
  background: #66AFB7 !important;
  border: 1px solid #66AFB7 !important;;
}


.wai_forgot_wrapper {
  background: rgb(53 99 117 / 80%);
  box-shadow: 3px 3px 10px #000000;
  opacity: 1;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 53px;
  overflow: hidden;
  width: 1024px;
  max-width: 100%;
  max-height: 779px;
  color: #fff;
}

.wai_forgot_wrapper .loginLeft {
  padding: 6.5% 5%;
  font-family: Montserrat-Bold;
  color: #fff;
  background-color: #366376;
  margin-right: 0;
  align-items: flex-start;
  height: auto;
  text-align: center;
}

.wai_forgot_wrapper .loginRight {
  padding: 8%;
  text-align: center;
}
.wai_forgot_wrapper .loginLeft p {
  font-size: 34px;
  font-family: Montserrat-SemiBold;
  margin-top: 38px;
}