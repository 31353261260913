/* Typography Styles */
.headline1 {
  font-size: 24px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.25px;
  line-height: 29px;
}

.headline2 {
  font-size: 20px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0;
  line-height: 24px;
}

.headline2_B {
  font-size: 20px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0;
  line-height: 24px;
}

.headline3 {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.15px;
  line-height: 19px;
}

.headline3_R {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.1px;
  line-height: 22px;
}

.headline4 {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.1px;
  line-height: 18px;
}

.headline5 {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.4px;
  line-height: 15px;
}

.overline1 {
  font-size: 16px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.1px;
  line-height: 18px;
}

.overline2 {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.125px;
  line-height: 18px;
}

.overline3 {
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.075px;
  line-height: 13px;
}

.pinBody {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.25px;
  line-height: 22px;
}

.body1 {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.5px;
  line-height: 22px;
}

.body2 {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 18px;
}

.body3 {
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.4px;
  line-height: 15px;
}

.body4 {
  font-size: 10px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.4px;
  line-height: 13px;
}

.Button {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
}

.Button2 {
  font-size: 12px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.95px;
  line-height: 15px;
}

.tractorState {
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 13px;
}

.fReg {
  font-family: 'Montserrat-Regular' !important;
}

.fMed {
  font-family: 'Montserrat-Medium' !important;
}

.fBld {
  font-family: 'Montserrat-Bold' !important;
}

.fSbd {
  font-family: 'Montserrat-SemiBold' !important;
}

/* Typography Styles End */

/* Sidemenu styles goes here  *********************************/

.login_logo_box img {
  height: auto;
  max-width: 100%;
}

.iconBoxProfile .ant-avatar {
  width: 48px;
  height: 48px;
}

.main-menu:hover .nav-text {
  position: relative;
  left: -0.73vw;
}

.iconBox img {
  max-width: 60%;
}

.login_logo_box {
  margin-left: 1.1vw !important;
  margin-right: 1.3vw !important;
  margin-top: 32px;
  margin-bottom: 60px;
  min-height: 30px;
}

.wingSpanAI .login_logo_box {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

/* .wingSpanAI.main-menu:hover .company_logo {
  background-size: 100% !important;
} */

.main-menu:hover .login_logo_box .sLogo {
  display: none;
  min-width: 45px;
  max-width: 100%;
  min-height: 28px;
}

.main-menu:hover .login_logo_box .lLogo {
  display: block;
  min-width: 171px;
  max-width: 100%;
  min-height: 28px;
}

.main-menu .login_logo_box .lLogo {
  display: none;
}

.login_user_sec {
  position: absolute;
  bottom: 30px;
}

.sMenuUL {
  padding-top: 0px !important;
}

.login_user_sec ul li {
  margin-bottom: 0;
}

.iconBox {
  margin-left: 27px;
  margin-right: 27px;
  width: 46px;
  height: 46px;
}

.iconBoxProfile {
  margin-left: 27px;
  margin-right: 27px;
}

.iconBoxProfile .ant-avatar-circle {
  border: 1px solid #67afb7;
}

.side_menu_sec {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 210px);
  /* height: calc(100vh - 245px); */
  overflow-y: auto;
  overflow-x: hidden;
}

.wingSpanAI .side_menu_sec {
  height: calc(100vh - 245px) !important;
}

.login_user_dtl .nav-text {
  color: #fff !important;
}

.main-menu:hover .logoutLink:hover .nav-text.overline2 {
  color: #fff;
}

.company_logo {
  background-image: url(../../assets/images/login_logo.svg);
  /* background-image: url(../../assets/images/WingspanAI_white_logo.svg); */
  width: 45px;
  /* width: 60px; */
  height: 28px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
  margin: auto;
}

.wingSpanAI .company_logo {
  background-image: url(../../assets/images/WingspanAI_white_logo.svg);
  width: 60px !important;
}

.main-menu:hover .company_logo {
  background-image: url(../../assets/images/login_logo_large.svg);
  /* background-image: url(../../assets/images/WingspanAI_white_exp.svg); */
  width: 171px;
  height: 28px;
  /* width: 99px;
  height: 45px; */
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
  margin: auto;
}

.wingSpanAI.main-menu:hover .company_logo {
  background-image: url(../../assets/images/WingspanAI_white_exp.svg);
  width: 99px !important;
  height: 45px !important;
}

.liveops_icon {
  background-image: url(../../assets/images/liveops_n.svg);
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .liveops_icon {
  background-image: url(../../assets/images/liveops_w.svg);
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.farmprofile_icon {
  background-image: url(../../assets/images/farmprofile_icon_n.svg);
  width: 23px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .farmprofile_icon {
  background-image: url(../../assets/images/farmprofile_icon_w.svg);
  width: 23px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.works_schedule_icon {
  background-image: url(../../assets/images/works_schedule_icon_n.svg);
  width: 21px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .works_schedule_icon {
  background-image: url(../../assets/images/works_schedule_icon_w.svg);
  width: 21px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.maintenance_icon {
  background-image: url(../../assets/images/maintenance_icon_n.svg);
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .maintenance_icon {
  background-image: url(../../assets/images/maintenance_icon_w.svg);
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.analytics_icon {
  background-image: url(../../assets/images/analytics_icon_n.svg);
  width: 17px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .analytics_icon {
  background-image: url(../../assets/images/analytics_icon_w.svg);
  width: 17px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.fleetusage_icon {
  background-image: url(../../assets/images/fleetusage_icon_n.svg);
  width: 22px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.mItemSel .fleetusage_icon {
  background-image: url(../../assets/images/fleetusage_icon_w.svg);
  width: 22px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.logoutLink .logout_icon {
  background-image: url(../../assets/images/logout_icon_n.svg);
  width: 19px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.logoutLink:hover .logout_icon {
  background-image: url(../../assets/images/logout_icon_w.svg);
  width: 19px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

/* Sidemenu styles goes end here  *********************************/

/* tabs and table container css goes here *******************************/

.elTabs > .ant-tabs-nav {
  height: 123px;
  /* margin-bottom: 28px; */
}

.elTabs .ant-tabs-nav-wrap {
  padding-bottom: 36px;
}

.elTabs .ant-tabs-tab-btn,
.elTabs .ant-tabs-tab:hover,
.attContainer .ant-tabs-tab-btn {
  color: #959595;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 22px;
  text-transform: uppercase;
}

.elTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000 !important;
}

.elTabs .ant-tabs-ink-bar,
.attContainer .ant-tabs-ink-bar {
  border-bottom: 3px solid #67afb7 !important;
  background: #67afb7 !important;
}

.attContainer .elTabs .ant-tabs-content-holder,
.attContainer .elTabs .ant-tabs-content,
.profileCont .elTabs .ant-tabs-content-holder {
  height: calc(100vh - 123px);
}

.farm_profile_container {
  width: calc(100% - (12.7vw + 10vw)) !important;
  margin-left: 12.7vw !important;
  margin-right: 10vw !important;
}

/* .elTabs .ant-layout {
    height: calc(100vh - 123px);
} */

.container_full_1 {
  width: 100%;
  padding-left: 12.7vw;
  padding-right: 10vw;
}

.container_full_1 {
  width: 100%;
  padding-left: 12.7vw;
  padding-right: 10vw;
}

.tblContainer {
  width: calc(100% - (4.69vw + 30px)) !important;
  margin-left: 4.69vw !important;
  margin-right: 30px !important;
}

.container_full_2 {
  width: calc(100% - (7.85vw + 7.85vw)) !important;
  margin-left: 7.85vw !important;
  margin-right: 7.85vw !important;
}

.mt12 {
  margin-top: 12px !important;
}

.dbWidget .ant-card-body .ant-table-tbody > tr > td,
.customTbl tbody > tr > td,
.userPf table tr > td {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.5px;
  line-height: 22px;
}

.dbWidget .ant-card-body .ant-table-thead > tr > th,
.customTbl thead > tr > th,
.userPf table tr > th {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.15px;
  line-height: 19px;
}

.tblContainer .m_tb {
  margin-top: 8px;
  margin-bottom: 36px;
}

/* dropdowns css ****************************/

.searchField .ant-input-search,
.searchSec .ant-input-search {
  max-width: 351px;
  height: 50px;
}

.viewportContainer .searchSec .ant-input-group-addon button,
.searchSec .ant-input-group-addon button {
  width: auto;
  height: 50px;
  padding-left: 16px;
}

.searchSec .ant-input-search input {
  background: none;
  border: none;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.22px;
  line-height: 18px;
  min-height: 50px;
  max-height: 50px;
  padding-left: 51px;
  width: 100%;
}

.searchSec .ant-input-search input::placeholder {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.22px;
  line-height: 18px;
}

.searchFilters .tab-label,
.searchFilters .tab-label:hover {
  height: 50px;
  padding-left: 24px;
  padding-right: 24px;
}

.searchFilters .flbTopFixed {
  color: #b6b6b7;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.09px;
  line-height: 13px;
}

.searchFilters .flbSelected {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 18px;
}

.searchFilters .selGray {
  color: #b6b6b7;
  font-style: italic;
}

.smallFilter .flblWrapper {
  min-width: 167px;
  width: 167px;
  height: 50px;
  margin-left: 19px;
}

.medFilter .flblWrapper {
  min-width: 224px;
  width: 224px;
  height: 50px;
  margin-left: 24px;
}

.singleDatePicker {
  width: 200px;
  height: 50px;
}

.searchFilters .tractorField .flblWrapper {
  width: 240px;
  height: 50px;
  margin-right: 0;
}

.searchFilters .customField {
  margin-left: 24px;
}

.searchFilters .customField .ant-select-selector {
  width: 200px;
  height: 50px;
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 18px;
  margin-right: 0;
}

.searchFilters .dateFilter {
  height: 50px;
  margin-left: 24px;
  margin-right: 0;
}

.searchFilters .dateFilter .ant-picker-input > input {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 18px;
}

.searchFilters .dW250_date {
  width: 14vw;
}

.attContainer .elTabs .tblContainer {
  height: calc(100vh - 123px);
  display: inline-block;
}

/* .viewportContainer .farmTabsTbl .infinite-scroll-component,
.farmTabsTbl .infinite-scroll-component,
.snapshotContent .infinite-scroll-component {
    max-height: calc(100vh - 350px);
}

.viewportContainer div#empTbl {
    min-height: calc(100vh - 350px);
} */

.tblDft.lbTbls.h42vh {
  height: calc(100vh - 350px);
  max-height: calc(100vh - 350px);
}

.tblDft.lbTbls.h42vh .infinite-scroll-component {
  max-height: calc(100vh - 360px);
}

.lbTbls div#empTbl {
  min-height: calc(100vh - 360px);
}

/* .tblDft.farmTabsTbl.posRel div#empTbl,
  .tblDft.farmTabsTbl.hoverable div#empTbl {
    min-height: calc(100vh - 365px);
  } */

.dsTbl .mr11 {
  margin-right: 3px !important;
}

.viewportContainer .tblDft {
  height: calc(100vh - 290px);
  max-height: calc(100vh - 290px);
}

.viewportContainer .tblDft .infinite-scroll-component {
  max-height: calc(100vh - 315px);
}

.viewportContainer .tblDft div#empTbl {
  min-height: calc(100vh - 315px);
}

.tblDft.farmTabsTbl.dsTbl {
  height: calc(100vh - 510px);
  max-height: calc(100vh - 510px);
}

.tblDft.farmTabsTbl.dsTbl .infinite-scroll-component {
  max-height: calc(100vh - 535px);
}

.tblDft.farmTabsTbl.dsTbl div#empTbl {
  min-height: calc(100vh - 535px);
}

.tblDft.farmTabsTbl {
  height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
}

.tblDft.farmTabsTbl .infinite-scroll-component {
  max-height: calc(100vh - 325px);
}

.tblDft.farmTabsTbl div#empTbl {
  min-height: calc(100vh - 325px);
}

.lbCont .tblDft.lbTbls.farmTabsTbl {
  height: calc(100vh - 330px);
  max-height: calc(100vh - 330px);
}

.lbCont .tblDft.lbTbls.farmTabsTbl .infinite-scroll-component {
  max-height: calc(100vh - 340px);
}

.lbCont .tblDft.lbTbls.farmTabsTbl div#empTbl {
  min-height: calc(100vh - 340px);
}

.tblDft.farmTabsTbl.reportsTbl {
  height: calc(100vh - 290px);
  max-height: calc(100vh - 290px);
}

.tblDft.farmTabsTbl.reportsTbl .infinite-scroll-component {
  max-height: calc(100vh - 315px);
}

.tblDft.farmTabsTbl.reportsTbl div#empTbl {
  min-height: calc(100vh - 315px);
}

.libraryContainer.lbCont.tblContainer {
  height: calc(100vh - 132px);
}

.analyticsTab .elTabs .libraryContainer.lbCont.tblContainer .ant-tabs-content {
  height: calc(100vh - 193px) !important;
}

/* LIve ops styles goes here **********************************/

.fleetlistBlk {
  position: absolute;
  left: 24px;
  top: 50px;
  z-index: 9;
  width: 358px;
}

.searchMapBlk {
  position: relative;
  margin-bottom: 18px;
}

.operationsTitle .ant-collapse-header {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  color: #6a6a6a !important;
  padding: 16px 16px 15px 16px;
  transition: all 0.3s, visibility 0s;
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 1.25px;
  line-height: 18px;
}

.parentIcon .anticon {
  right: 10px;
}

.autodriveButton {
  /* margin-left: 100px; */
  border: 1px solid #959595;
  border-radius: 18px;
  color: #5c5c5c;
  background-color: transparent;
  display: flex;
  margin: auto;
  align-items: center;
  min-width: 155px;
  font-size: 10px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.33px;
}

.autodriveButton:hover,
.autodriveButton:focus,
.autodriveButton:active {
  color: #fff !important;
  background: #ec9322 !important;
  border: 1px solid #ec9322 !important;
}

.autodriveButton .routeIcon,
.autodriveButton .routeIcon2 {
  margin-right: 7px !important;
}

.routeIcon .autodriveButton:hover {
  color: #fff;
  background: #ec9322;
  border: 1px solid #ec9322 !important;
}

.fleetTabs .ant-tabs-nav {
  padding: 0 16px;
  margin-top: 14px;
  margin-bottom: 22px;
}

.fleetTabs .ant-tabs-ink-bar {
  border: none !important;
}

.parentAccordion .fleetOverallBlk {
  padding-left: 15px;
  padding-right: 14px;
  padding-bottom: 6px !important;
  padding-top: 0 !important;
  /* height: 392px; */
}

.parentAccordion .fleetBlk {
  width: 100%;
  background: #ffffff;
  border: 1px solid #cececf;
  border-radius: 10px !important;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 12px 10px 5px 9px;
  cursor: pointer;
  min-height: 92px;
  max-height: 97px;
  box-sizing: border-box;
  position: relative;
  /* width: 326px; */
}

.fleetOverallBlk .fleetBlk:hover {
  border: 1px solid #366376;
}
.fleetOverallBlk .fleetBlk-selected,
.fleetTractorsInrBlk .fleetBlk-selected {
  border: 2px solid #366376 !important;
}

.fleetBlk .tractorDtlSec.mb18 {
  margin-bottom: 16px;
}

.fleetBlk .tractorDtlSec.mbottom.mtop {
  margin-top: 16px;
  margin-bottom: 8px;
}

.fleetBlk .tractorDtlSec.mbottom {
  margin-bottom: 16px;
}

.t_name {
  color: #000;
  width: 185px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.125px;
  line-height: 18px;
}

.t_driver_name {
  color: #333333;
  display: flex;
  align-items: center;
  min-height: 13px;
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.075px;
  line-height: 13px;
}

.ttractor_state {
  border-radius: 12px;
  min-height: 20px;
  color: #fff;
  text-transform: uppercase;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 94px;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 13px;
}

.tImplementSec {
  width: 111px;
  height: 20px;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  color: #000;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.075px;
  line-height: 13px;
}

.tImplementSec img {
  margin-right: 6px;
}

.tSpeedtSec {
  display: flex;
  align-items: center;
}

.tBatterySec {
  display: flex;
  align-items: center;
}

.tSignalSec {
  display: flex;
  align-items: center;
}

.fleetBlk .t_signals.t_error {
  margin: 0;
  padding: 0;
}

.fleetBlk .t_signals.t_error .mr4 {
  margin: 0;
}

.battery-blk {
  display: flex;
}

.speedTxt {
  font-family: Montserrat-Medium;
  font-size: 15px;
  color: #4d4d4d;
  margin-left: 3px;
  width: auto;
  display: inline-block;
  text-align: right;
}

.tspeed {
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  letter-spacing: 0.4px;
  margin-left: 3px;
  margin-right: 3px;
  line-height: 10px;
}

.tsMPH {
  font-family: 'Montserrat-Medium';
  font-size: 8px;
  letter-spacing: 0.48px;
  padding-top: 4px;
  line-height: 10px;
}

.t_battery {
  font-size: 10px;
  font-family: Montserrat-Medium;
  color: #1e1e1e;
  letter-spacing: 0.6px;
  line-height: 13px;
  display: flex;
  align-items: center;
}

.bPer {
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.4px;
  line-height: 15px;
  margin-left: 3px;
}

.charging_remain {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #959595;
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 8px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.95px;
}

.hImpNameSel {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80px;
  color: #000000;
}

.hImpNameDbl {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80px;
  color: #b6b6b7;
}

.mapLayerIcon {
  background-image: url(../../assets/images/mapLayersIcon.png);
  position: absolute;
  bottom: 15px;
  left: 25px;
  z-index: 5;
  width: 81px;
  height: 81px;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
}

.mapLayerIcon:hover {
  background-image: url(../../assets/images/mapLayersIcon_hv.png);
  position: absolute;
  bottom: 15px;
  left: 25px;
  z-index: 5;
  width: 81px;
  height: 81px;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
}

.bottomMapLayers {
  position: absolute;
  bottom: 23px;
  left: 25px;
  width: 0px;
  height: 0;
  /* min-width: 360px; */
  background: #eaeaea;
  height: 0;
  z-index: 99;
  box-shadow: -1px 0px 6px rgb(0 0 0 / 29%);
  transition: 1s;
  border-radius: 10px;
}

.bottomMapLayers .dataLayerSec {
  overflow-y: auto;
  height: calc(100vh - 230px);
}

.mpOpen {
  visibility: visible;
  animation: resize 0.2s;
  width: 358px;
  height: calc(100vh - 138px);
}

.mpClose {
  animation: resizeClose 0.2s;
  visibility: hidden;
}

.fleetTabs .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  color: #5c5c5c;
  border: 1px solid transparent;
  padding: 10px 8px;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.4px;
  line-height: 15px;
}

.fleetTabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0px !important;
}

.fleetlistBlk .fListSearchMain .ant-input,
.fListSearchMain .ant-input {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 18px;
  color: #fff;
}

.fleetlistBlk .searchMapBlk.fListSearchMain input.fListSearch::placeholder {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 18px;
  color: #cececf;
}

.fleetlistBlk .fListSearchMain .ant-input,
.indicatorimges .t_signals {
  display: flex;
  align-items: center;
}

/* map layers styles **********************/
.dataHead {
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  margin-left: 10px;
  letter-spacing: 1.25px;
  line-height: 18px;
}

@keyframes resize {
  from {
    width: 0;
    height: 0;
  }

  to {
    width: 360px;
    height: calc(100vh - 136px);
  }
}

@keyframes resizeClose {
  from {
    width: 360px;
    height: calc(100vh - 136px);
  }

  to {
    width: 0;
    height: 0;
    bottom: 30px;
  }
}

/* Right side widget details section styles goes here **********************/

.width50 {
  width: 50% !important;
}

.width100 {
  width: 100% !important;
}

.rowGapBtm1 {
  margin-bottom: 46px;
}

.rowGapBtm2 {
  margin-bottom: 38px;
}

.rowGapBtm3 {
  margin-bottom: 32px;
}

.widgetCloseBtn {
  cursor: pointer;
  padding: 38px 38px 31px 38px;
}

.rightSideWidegt {
  right: -486px;
  width: 486px;
}

.rightWidgetView {
  padding: 0px 40px 20px 40px;
}

.txtLftGap {
  margin-left: 22px !important;
}

.fwdBtnsSec .TktFwdBtn {
  padding: 14px 29px;
}

.fwdBtnsSec .TktRslBtn {
  padding: 14px 56px;
}

.widgetBtns {
  height: auto !important;
  bottom: 30px !important;
}

.tcktInfo {
  margin-bottom: 43px;
}

.profileEdit .ant-tag-checkable,
.tagsBlk .ant-tag-checkable,
.tag-chip-selected {
  padding: 8px 16px !important;
}

.rightWidgetView .tagsBlk .tags {
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  letter-spacing: 0.4px;
  line-height: 15px;
  padding: 8px 16px !important;
}

.rightWidgetView .tagsBlk .tags:last-child {
  margin-bottom: 0 !important;
}

.saveTxtBtn {
  bottom: 0 !important;
  height: auto !important;
}

.saveTxtBtn button {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
  bottom: 50px !important;
  right: 38px !important;
  padding: 0 !important;
}

.impEditBtn button {
  background: none;
  border: none;
  color: #366376;
  cursor: pointer;
  outline: none;
  position: inherit !important;
  bottom: 50px !important;
  right: 38px !important;
}

.impAutonomyBtn button {
  position: inherit !important;
  bottom: 40px !important;
  left: 38px !important;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
  padding: 12px 16px;
}

.rightWidgetView .createTcktFlag img.ml_0 {
  margin-left: 0 !important;
}

.rightWidgetView .createTcktFlag img {
  padding: 12px 14px;
  margin-left: 24px;
}

.rightWidgetView .ant-select-selector,
.rightWidgetView .ant-input {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 18px;
  min-height: 50px;
  width: 100% !important;
}

.rightWidgetView .ant-select-selector .ant-select-selection-item {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 18px;
  min-height: 50px;
}

.rightWidgetView textarea {
  color: #000000 !important;
  font-size: 16px !important;
  font-family: 'Montserrat-Regular' !important;
  letter-spacing: 0.5px !important;
  line-height: 22px !important;
}

.rightWidgetView textarea::placeholder {
  color: #959595 !important;
  font-size: 14px;
  font-family: 'Montserrat-Regular' !important;
  letter-spacing: 0.22px;
  line-height: 18px;
}

.rightWidgetView .ant-select-selection-placeholder {
  font-style: italic;
  font-size: 14px;
  font-family: 'Montserrat-Regular' !important;
  letter-spacing: 0.22px;
  line-height: 18px;
}

.rightWidgetView .fleetTbl th,
.rightWidgetView .fleetTbl td {
  padding: 0 0 32px 0 !important;
}

.rightWidgetView .ant-select {
  width: 100% !important;
}

.rightWidgetView .proPic .ant-avatar {
  width: 179px;
  height: 179px;
}

.rightWidgetView .proPic img {
  width: 100% !important;
  height: 100% !important;
}

.functionalityTag .ant-tag {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
  padding: 10px 12px !important;
}

.rightWidgetView .uploadImg .ant-upload-picture-card-wrapper {
  display: inline !important;
}

.rightWidgetView .uploadImg .ant-upload {
  position: unset !important;
  margin-top: unset !important;
  margin-left: unset !important;
}

.rightWidgetView .ant-upload .editImgs {
  position: absolute;
  right: 15px;
  bottom: 20px;
  top: unset !important;
  left: unset !important;
  cursor: pointer;
}

.autonomyBtns {
  background: transparent;
  position: absolute;
  bottom: 35px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rowGapBtm30 {
  margin-bottom: 30px;
}

.rowGapBtm4 {
  margin-bottom: 24px !important;
}

.rowGapBtm5 {
  margin-bottom: 20px !important;
}

.rowGapBtm13 {
  margin-bottom: 13px !important;
}

.rowGapBtm6 {
  margin-bottom: 10px !important;
}

.genric_toggle_sec {
  font-size: 14px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.22px;
  line-height: 18px;
  height: 50px;
}

.tractorPic img {
  width: 200px;
  height: 200px;
  margin: auto;
}

.tractorList {
  display: grid;
  grid-template-columns: 44% auto;
  gap: 25px 5px;
  padding-left: 20px;
  word-break: break-all;
  word-break: break-word;
}

.editFleetView {
  height: calc(100vh - 90px) !important;
}

.rowGapTop1 {
  margin-top: 46px;
}

.addEmpBlk.rightWidgetView .editImgs {
  right: 20px;
  bottom: 15px;
}

.txtCenter {
  text-align: center !important;
}

.rightWidgetView input::placeholder {
  color: #b6b6b7;
  font-size: 14px;
  font-family: 'Montserrat-Regular' !important;
  font-style: italic;
  letter-spacing: 0.22px;
  line-height: 18px;
}

/* Autodrive tractor details styles goes here *****************************/

/* remotedrive settings & details */
.rautoDriveRightSec {
  position: fixed;
  right: 0;
  top: 0px;
  width: 482px;
  height: 100vh;
  z-index: 999;
  box-shadow: -1px 0px 6px rgba(0, 0, 0, 0.29);
  /* transition: 1s; */
  background: #fff;
  padding: 35px;
}

.rautoDriveRightWrapper {
  height: 100%;
}

.grayContainer {
  background: #f00;
  position: absolute;
  z-index: 2;
  width: 100%;
}

.grayBlock {
  padding: 0 !important;
  position: fixed;
  background: #e5e5e5;
  height: 100vh;
  z-index: 0;
}

.autoDriveTractorHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.whiteWrapper {
  width: 100%;
  position: absolute;
  background: #fff;
  height: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
}

.adtMainContainer {
  height: calc(100vh - 64px);
}

.adtTopSection {
  display: block;
  height: calc(100vh - 265px);
  margin-bottom: 16px;
}

.adtTopSection.tdtl_nopin {
  height: calc(100vh - 90px) !important;
}

.adtTopSection.tdtl_nopin .adTabsContentSec {
  max-height: calc(100vh - 220px) !important;
  overflow-x: hidden;
}

.adtTopBox {
  background: #fff;
  border-radius: 20px;
  margin: 0 15px;
  box-shadow: -3px 3px 6px transparent;
  border: 2px solid transparent;
  position: relative;
  z-index: 2;
}

.adtClose {
  background-image: url(../../assets/images/mpCloseIcon.svg);
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.adTabsHeadSecBottom {
  padding: 14px 42px 18px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adTabsHeadSec {
  padding: 30px 25px 34px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adTabsContentSec {
  max-height: calc(100vh - 390px);
  padding: 0px 25px 0 25px;
  overflow-y: auto;
  margin-bottom: 36px;
}

.adtHeadSec {
  display: flex;
  align-items: center;
  padding: 25px 29px 0 25px;
  margin-bottom: 16px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
}

.whiteLayer {
  display: none;
}

.adtHeadTextIcon {
  margin-right: 12px;
}

.adtHeadText {
  color: #366376;
}

.adTabsContainer {
  background: #fff;
  border-radius: 20px;
  margin: 0 15px;
  box-shadow: -3px 3px 6px transparent;
  border: 2px solid transparent;
  position: relative;
}

.adRoundBox {
  box-shadow: -3px 3px 6px #00000029;
  border: 2px solid #cececf;
  transition: 2s;
}

.adTabsContainer .ant-collapse {
  border-radius: 20px;
}

.adtabsHeadText {
  font-size: 20px;
  font-family: Montserrat-Medium;
  color: #000;
  max-width: 14.5vw;
  min-width: 245px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.adtStatus {
  border-radius: 12px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 82px;
  font-size: 8px;
  font-family: Montserrat-SemiBold;
  background: #ec9328;
  color: #fff;
}

.tractorPin {
  background-image: url(../../assets/images/tractorPin_normal.svg);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: 29px;
  top: 18px;
  cursor: pointer;
}

.tractorPin:hover {
  background-image: url(../../assets/images/tractorPin_hover.svg);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: 29px;
  top: 18px;
  cursor: pointer;
}

.tractorPin:active {
  background-image: url(../../assets/images/tractorPin_selected.svg);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: 29px;
  top: 18px;
  cursor: pointer;
}

.tractorPinSelected {
  background-image: url(../../assets/images/tractorPin_selected.svg);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: 29px;
  top: 18px;
  cursor: pointer;
}

.adtBottomSection {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 185px;
  overflow-y: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
}

.adtBottomBox {
  background: #fff;
  border-radius: 20px 20px 0 0;
  margin: 0 15px;
  box-shadow: -3px 3px 6px #00000029;
  border: 2px solid #cececf;
  position: relative;
  transition: 2s;
  height: 60px;
  width: calc(100% - 30px);
}

.adtBottomBox:nth-child(1) {
  position: absolute;
  bottom: 0;
  z-index: 100;
}

.adtBottomBox:nth-child(2) {
  position: relative;
  z-index: 99;
  margin-bottom: 50px;
}

.adtBottomBox:nth-child(n + 3) {
  position: relative;
  z-index: auto;
  margin-bottom: -10px;
}

.adtabsHeadTextBottom {
  font-size: 12px;
  font-family: Montserrat-Medium;
  color: #000;
}

.adtCloseBottom {
  background-image: url(../../assets/images/mpCloseIcon.svg);
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: 18px;
  top: 17px;
  cursor: pointer;
}

.adtStatusBottom {
  border-radius: 12px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 82px;
  font-size: 8px;
  font-family: Montserrat-SemiBold;
  background: #ec9328;
  color: #fff;
  margin-right: 10px;
}

.t_lock {
  width: 10px;
  margin-top: -4px;
  margin-right: 10px;
}

.autoDriverName {
  display: flex;
  align-items: center;
}

.autoDriverName img {
  margin-right: 5px;
}

.aDname {
  font-size: 12px;
  font-family: Montserrat-Medium;
  color: #333333;
}

.autodriveStatusIcons .t_signals {
  padding: 0px 8px;
  border: 1px solid;
  border-color: #e7e7e7;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
}

.autodriveStatusIcons .t_signals .t_signals_img {
  flex: 1;
}

.autodriveStatusIcons .t_signals.t_error {
  border-color: #d43d38;
}

.fleetBlk .t_signals {
  padding: 0px 8px;
  border-radius: 12px;
  display: flex;
  cursor: pointer;
  /* min-width: 75px; */
  justify-content: flex-end;
  align-items: center;
}

.t_signals img:first-child {
  margin-right: 5px;
}

.autodrive-cancel {
  cursor: pointer;
  font-size: 10px;
  letter-spacing: 0.79px;
  color: #366376;
  font-family: Montserrat-SemiBold;
}

.t-connect-loader {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  width: 64px;
}

.t-connect-loader > img {
  width: 100%;
}

.adr-title {
  padding: 32px;
  text-align: center;
}

.auto-drive-routine-container {
  width: 90%;
  margin: auto;
}

.w100per {
  width: 100% !important;
}

.adr-content-block-border {
  border: 2px solid #d33c39 !important;
}

.adr-content-block {
  border: 2px solid #ec9322;
  border-radius: 16px;
  background-color: #fff;
  padding: 0;
}

.implement-verify-error {
  color: #d33c39;
  font-size: 10px;
  cursor: pointer;
}

.auto-drive-routine-cancel {
  /* text-align: center; */
  /* font-size: 12px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 0.95px; */
  color: #959595;
  text-transform: uppercase;
  opacity: 1;
  position: absolute;
  bottom: 55px;
  cursor: pointer;
}

.adr-content-block > h1 {
  font-weight: 600;
}

.livePic .ant-avatar.defaulticon {
  width: 296px !important;
  height: 237px !important;
  background: #fff;
  border: 1px solid #707070;
  border-radius: 2px;
}

.chooseTxt {
  font-size: 16px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.25px;
  color: #7b7b7b;
  text-transform: uppercase;
  width: 99%;
  text-align: right;
  margin-top: 12px;
  cursor: pointer;
  padding-right: 20px;
}

.autoDriveCancel {
  font-size: 12px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 0.95px;
  color: #959595;
  text-transform: uppercase;
  margin-bottom: 20px;
  cursor: pointer;
}

.verifyTxt button {
  font-family: Montserrat-Bold;
  font-size: 15px;
  width: 166px;
  height: 37px;
  background-color: #738023 !important;
  border-radius: 25px !important;
  position: absolute;
  color: #fff;
  bottom: 20px;
  /* right: 116px; */
  letter-spacing: 1.61px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
}

.autoDHead {
  margin: 65px 0 55px 0;
  padding: 0;
}

.autoDHeadText {
  /* font-family: Montserrat-Medium;
  font-size: 26px;
  letter-spacing: 0.19px; */
}

.adr_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.adrArrow {
  background-image: url(../../assets/images/adr_uparrow.svg);
  background-size: 100%;
  width: 5px;
  height: 9px;
  background-repeat: no-repeat;
}

.p30 {
  padding: 30px 30px 22px 30px;
}

.plr30 {
  padding-left: 30px;
  padding-right: 20px;
}

.adrConnection {
  min-width: 50px;
}

.adrImplement {
  width: calc(100% - 100px);
  flex-direction: column;
  display: flex;
}

/* .adr_implementTxt {
  font-family: Montserrat-Medium;
  font-size: 14px;
  letter-spacing: 0.13px;
} */
.bdrB {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
}

.implement-verify-error {
  height: 15px;
}

/* .implement-txt ~ .implement-verify-error {
  visibility: hidden;
}
.implement-txt:hover ~ .implement-verify-error {
  visibility: visible !important;
} */

.adr_plus_nor {
  background-image: url(../../assets/images/adr_plus_nor.svg);
  background-size: 100%;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  margin-right: 13px;
  display: inline-block;
  cursor: pointer;
}

.adr_plus_nor:hover {
  background-image: url(../../assets/images/adr_plus_sel.svg);
  background-size: 100%;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  margin-right: 13px;
  display: inline-block;
  cursor: pointer;
}

.adr_sel_route_txt {
  /* font-family: Montserrat-Regular;
  font-size: 14px;
  letter-spacing: 0.44px; */
  cursor: pointer;
}

.adr_selectRow {
  display: flex;
  align-items: center;
}

/* .adrt_name {
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 0.13px;
} */
.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mt15 {
  margin-top: 15px;
}

.pd0 {
  padding: 0px !important;
}

.proRow.livePic .ant-avatar.impImgW {
  width: calc(100% - 20px) !important;
}

.livePic {
  align-items: flex-start !important;
}

.impImgW img {
  border: 1px solid #707070;
}

.p_30 {
  padding-left: 30px;
  padding-right: 30px;
}

.commandStepsFlex {
  position: relative;
  /* margin-bottom: 50px; */
}

.routeSelect {
  display: flex;
  align-items: center;
  width: 100%;
}

.fleetlistBlk .routeSelect .ant-input,
.fleetlistBlk .routeSelect .ant-input:hover {
  padding-left: 10px !important;
  background-color: none !important;
  background: none !important;
  border: 1px solid #b6b6b7 !important;
  height: 35px;
  border-radius: 10px;
  box-shadow: none;
}

.commandStepsFlexRoute .anticon.anticon-close {
  position: absolute;
  right: 40px;
  top: 12px;
  font-size: 9px;
}

.adrStartBtn Button {
  background: none;
  border: none;
  outline: none;
  font-family: Montserrat-Bold;
  font-size: 10px;
  color: #ec9322;
  letter-spacing: 0.89px;
  margin-bottom: 30px;
  text-transform: uppercase;
  padding: 0;
  height: auto;
}

.adrStartBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.adr_implementTxt ~ .adrEdit {
  position: absolute;
  /* visibility: hidden; */
  top: 20px;
  left: 26px;
  font-size: 8px;
  font-family: Montserrat-Medium;
  letter-spacing: 0.08px;
  color: #366376;
}

/* .adr_implementTxt:hover ~ .adrEdit {
  visibility: visible !important;
} */

.adrHeaderRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.adrHeaderTxt {
  font-size: 18px;
  font-family: Montserrat-Regular;
  letter-spacing: 0.56px;
  color: #fff;
}

.adrHeaderCancel {
  font-size: 12px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 0.56px;
  color: #70b4bb;
  text-transform: uppercase;
}

.dblock {
  display: block !important;
}

.cpointer {
  cursor: pointer;
}

.adr-content-block .commandStepsFlex {
  position: relative;
  margin-bottom: 50px;
}

.disableRoutine {
  opacity: 0.3;
  cursor: default;
}

.adrResetBtn {
  display: block;
  width: 110px;
  text-transform: uppercase;
}

.adrOpTime {
  width: 120px;
  text-align: center;
  /* font-size: 26px;
  font-family: Montserrat-Medium;
  letter-spacing: -1px; */
  margin-right: 5px;
  color: #000000 !important;
}

.adroutineTimer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adroutineTimerBtns {
  display: flex;
  margin-top: -20px;
}

.adroutineTimerBtns img {
  cursor: pointer;
}

.adroutineTimerDtls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.adrTime_rBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 16px;
}

.flblWrapper .flbUL li.item {
  text-align: left;
  text-transform: capitalize;
}

.adrDisconnectBtn,
.adrDisconnectBtn:focus {
  background: #fff;
  border: 1px solid #cececf;
  border-radius: 30px;
  /* min-width: 150px; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  outline: none;
  color: #5c5c5c;
  text-transform: uppercase;
  box-shadow: -3px 3px 6px #00000029;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.adrBtnIcon {
  margin-right: 8px;
}

.feedbackDtlSec {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.feedbackCompInfo {
  font-size: 20px;
  font-family: Montserrat-SemiBold;
  color: #000;
  letter-spacing: 0.15px;
  margin-bottom: 25px;
  text-align: center;
}

.rateExperience {
  font-size: 12px;
  font-family: Montserrat-Regular;
  color: #000;
  letter-spacing: 0.22px;
  margin-bottom: 15px;
  text-align: center;
}

.afterRatingBtns {
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 10px;
}

.rateBtn {
  border-radius: 6px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  border: none;
  letter-spacing: 1.07px;
  font-size: 12px;
  font-family: Montserrat-SemiBold;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.ratingStartBtn {
  background: #ec9322;
}

.ratingDiscBtn {
  background: #343b5e;
}

.ratingStars {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.ratingStars .anticon-star svg {
  width: 35px;
  height: 35px;
}

.rating {
  unicode-bidi: bidi-override;
  text-align: center;
}

.rating > span {
  display: inline-block;
  position: relative;
  font-size: 40px;
  color: #707070;
  font-weight: 300;
  cursor: pointer;
}

/* .rating > span:hover, */
.rating > span.starsel {
  color: transparent;
}

/* .rating > span:hover:before, */
.rating > span.starsel:before {
  content: '\2605';
  position: absolute;
  left: 0;
  color: #ec9322;
}

.ratingPopup {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ratingPopup .ant-modal-content {
  min-width: 850px !important;
  border-radius: 28px;
}

.ratingPopup .ant-modal-close-x {
  display: none;
}

.ratingPopup .ant-modal-body {
  padding: 40px 60px 20px 60px;
}

.adrEndTitle {
  font-size: 24px;
  font-family: Montserrat-Medium;
  margin-bottom: 25px;
  padding-left: 25px;
  color: #707070;
}

.adrEndQuestion {
  font-size: 14px;
  font-family: Montserrat-Regular;
  margin-bottom: 20px;
  padding-left: 25px;
  color: #707070;
  letter-spacing: 0.22px;
}

.rateTextarea {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #707070;
  height: 165px;
  margin-bottom: 30px;
  padding: 20px;
}

.pdl25 {
  padding-left: 25px;
  justify-content: flex-start;
}

.mb5 {
  margin-bottom: 5px;
}

.mb30 {
  margin-bottom: 30px;
}

.feedSubmitBtn {
  border: none;
  background: #366376;
  border-radius: 5px;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  letter-spacing: 1.25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 150px;
  color: #fff;
  text-transform: uppercase;
}

button:disabled.feedSubmitBtn {
  opacity: 0.4;
}

.feedBtnSec {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.autoDrivetime {
  text-align: center;
  min-width: 75px;
  height: 25px;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.autoDrivetime img {
  margin-right: 6px;
}

.hourIcon {
  width: 12px;
  height: 12px;
}

.autodriveStatusIcons .t_signals .t_signals_img:first-child img {
  margin-right: 4px;
}

.tractorSpeedometer .trip-meter {
  color: #474747;
  margin-top: 8px;
}

button.ant-btn.camera_box:last-child {
  margin-right: 3px;
}

button.ant-btn.camera_box:first-child {
  margin-left: 3px;
}

/* .pad0.mbcams {
  height: 233px !important;
} */

.autoDriveCamViews iframe#cammms {
  height: 197px;
}

.t_speed_cont .speed {
  letter-spacing: -1.5px;
}

.rightSideWidegt .proRoleValue {
  margin: 0 0 0 22px;
}

.editRoles2 {
  color: #366376;
  cursor: pointer;
  text-transform: uppercase;
  position: absolute;
  bottom: 50px;
  right: 38px;
}

.rowGapBtm15 {
  margin-bottom: 15px !important;
}

.addEmpBlk.rightWidgetView .proPic .ant-avatar {
  width: 146px;
  height: 146px;
}

.rightWidgetView .teamTxt .ant-select-selector .ant-select-selection-item {
  min-height: 27px !important;
  height: 27px !important;
}

.rowGapTop4 {
  margin-top: 24px !important;
}

.rightWidgetView .proPic .ant-avatar .ant-avatar-string {
  width: 179px;
  height: 179px;
}

/* remote drive operation timer box style goes here ******************************/

.remoteOperationsPopup {
  background-color: #e7e7e7;
  border: 3px solid #e0963f;
  border-radius: 17px;
  position: fixed;
  bottom: 0;
  z-index: 999;
  padding: 10px 20px 10px 30px;
  position: absolute;
  bottom: 110px;
  width: 352px;
  min-height: 106px;
  z-index: 98;
  left: 121px;
}
/* .remoteOperationsPopup{
  /* z-index: 9999; 
  left: 121px !important;
} */

.remoteOperationsPopup h5 {
  font-size: 10px;
  color: #e0963f;
  margin: 0;
  text-align: center;
  font-family: 'Montserrat-SemiBold';
}

.remoteOperationsPopup .play_time_dur {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0;
}

.remoteOperationsPopup .play_time_dur img {
  cursor: pointer;
}

.remoteOperationsPopup .operationsDuration {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 10px;
  gap: 20px;
  width: 260px;
}

.remoteOperationsPopup p {
  background-color: #e0963f;
  border-radius: 7px;
  color: #fff;
  font-size: 10px;
  font-family: 'Montserrat-SemiBold';
  line-height: 13px;
  padding: 5px 16px;
  text-align: center;
  margin: 0;
  height: 27px;
  width: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.remoteOperationsPopup h6 {
  color: #5c5c5c;
  font-size: 8px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.08px;
  line-height: 10px;
  padding-left: 12px;
}

.fleetTabs .ant-tabs-content-holder {
  max-height: calc(100vh - 385px);
}

.fleetTabs .childAccordion2 {
  max-height: calc(100vh - 385px);
}

.dmenHandle .fleetTabs .ant-tabs-content-holder {
  max-height: calc(100vh - 510px);
}

.dmenHandle .childAccordion2 {
  max-height: calc(100vh - 510px);
}

.impSetpopup .autonomy_modal_sec .posabs {
  top: 50% !important;
}

.elTabs > .ant-tabs-nav {
  margin: 0px;
}

/* profile page css ************/

.mb46 {
  margin-bottom: 46px;
}

.padt60 {
  padding-top: 60px;
}

.profileDetails {
  display: grid;
  grid-template-columns: 32% calc(68% - 20px);
  gap: 20px;
  margin-top: 66px;
  /* height: calc(100vh - 205px); */
}

.profMap {
  border-radius: 10px;
  position: relative;
  min-height: 335px;
  max-height: 610px;
  height: calc(100vh - 470px);
  width: 100%;
}

.profMap img {
  border-radius: 15px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.mb54 {
  margin-bottom: 54px;
}

.profContainer.ovfY_auto {
  /* overflow-y: auto; */
  height: calc(100vh - 180px);
}

.profContainer.ovfY_auto .profileDetails {
  height: calc(100vh - 200px);
}

.filterGap40 {
  height: calc(128px - 70px);
  display: flex;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 35px;
}

.rowGap40 {
  margin-top: 40px;
}

.rowGap55 {
  margin-top: 55px;
}

.addEmpBlk .addEmployeeSelect .ant-select-selector {
  min-height: 65px !important;
}

.posrel {
  position: relative;
}

.posabs {
  position: absolute;
}

.disinBlock {
  display: inline-block !important;
}

.editProBackBtn {
  display: inline-block !important;
  position: absolute;
  left: -70px;
  top: 0;
}

.editProfContHight {
  height: calc(100vh - 123px);
}

.editProfContScroll {
  height: calc(100vh - 260px);
  overflow-y: auto;
}

.btnpos {
  position: absolute;
  bottom: 30px;
  right: 36%;
  z-index: 99;
}

.rowGapTop9 {
  margin-top: 90px;
}

.filterGap2 {
  height: calc(174px - 96px);
  display: flex;
  align-items: center;
  margin-top: 53px;
  margin-bottom: 43px;
}

.filterGap3 {
  height: calc(148px - 76px);
  display: flex;
  align-items: center;
  margin-top: 38px;
  margin-bottom: 38px;
}

.teamlist {
  height: calc(100vh - 310px);
  display: inline-grid;
  width: 100%;
  align-content: flex-start;
}

.addCommentBtn.ticketComments {
  margin-left: 14px !important;
}

.rowGapTop36 {
  margin-top: 36px !important;
}

.subPermissionTitle .permissionBackBtn {
  display: inline-block !important;
  position: absolute;
  left: -70px;
  top: 0;
}

.teamsBlk .teamTitle {
  margin-left: 76px;
}

.lh56 {
  line-height: 56px !important;
}

.perTeamsBack {
  position: absolute;
  left: 0;
  top: 3px;
}

.perRoleBack {
  position: absolute;
  left: -76px;
  top: 0px;
}

.rowGap50 {
  margin-top: 50px;
}

.seeDtl {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  height: calc(100vh - 200px);
  padding-bottom: 0 !important;
}

/* .employee-main-permissions {
  max-height: calc(100vh - 535px);
} */

.libraryContainer .ant-tabs-nav {
  margin-top: 10px !important;
}

/* analytics styles *******************/

.tractorOperDtls {
  width: 448px;
}

.sparyMapContainer {
  width: calc(100% - 448px);
}

.sparyMapContainer .tblContainer {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.sparyMapContainer .lMapCont {
  height: calc(100vh - 150px) !important;
  margin-bottom: 0 !important;
}

/* User profile styles ***********************************/

.employee-details {
  display: flex;
  /* margin-bottom: 30px; */
  padding-bottom: 36px;
  /* justify-content: center; */
}

.teamarrow {
  background-image: url(../../assets/images/Back_btn.svg);
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
}

.employee-profile {
  background: #f7f7f7;
  border: 1px solid #cececf;
  border-radius: 15px;
  width: 486px;
  padding: 56px;
}

.profile-details {
  text-align: center;
  margin-bottom: 48px;
}

.profile-details .ant-avatar {
  /* margin-bottom: 24px; */
  /* width: 179px;
  height: 179px; */
  width: 9.3vw;
  min-height: 9.3vw;
  min-width: 130px;
  height: 130px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 auto 24px auto;
  background: #67afb7 !important;
}

.profile-details .ant-avatar-string {
  color: #fff;
  font-size: 3.125vw;
  font-family: 'Montserrat-SemiBold';
  top: 50%;
  transform: translate(-50%, -50%) !important;
}

.profile-details h1 {
  color: #000000;
  margin-bottom: 3px;
}

.profile-details span {
  color: #000000;
}

.profile-contacts {
  margin-bottom: 48px;
}

.profile-contacts h1 {
  margin-bottom: 12px;
  color: #000000;
}

.profile-contacts h3 {
  margin-bottom: 12px;
  padding-left: 30px;
  color: #000000;
  word-break: break-all;
}

.operator-code {
  display: flex;
  margin-bottom: 15px;
}

.operator-code h1 {
  color: #000000;
  margin-right: 45px;
}

.operator-code h4 {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.56px;
  color: #000000;
  font-family: 'Montserrat-Regular';
  margin-right: 40px;
}

.employee-details-main .operator-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.employee-details-main .operator-code h1 {
  margin-right: 0 !important;
}

.employee-details-main .operator-code .proRoleValue {
  text-align: right !important;
}

.employee-permissions {
  margin-left: 2.45vw;
  width: calc(100% - 650px);
  display: flex;
  flex-direction: column;
}

.permissions-title {
  /* margin-bottom: 24px; */
}

.employee-teams {
  border: 1px solid #cececf;
  border-radius: 15px;
  padding: 30px 0px;
  margin-bottom: 32px;
  min-height: 180px;
  max-height: 200px;
  box-sizing: border-box;
  overflow-y: auto;
}

.employee-main-permissions {
  border: 1px solid #cececf;
  border-radius: 15px;
  padding: 32px 0px 16px 0;
  height: 100%;
}

.permission-sub {
  overflow-y: auto;
  min-height: 140px;
  height: calc(100vh - 570px);
  padding-left: 32px;
  padding-right: 32px;
  position: sticky;
  top: 0;
}

.employee-main-permissions h1 {
  color: #000000;
  padding-bottom: 12px;
  margin-bottom: 22px;
  border-bottom: 1px solid #cececf;
  margin-left: 32px;
  margin-right: 32px;
}

.operation-title {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

/* my profile styles goes here *********************************/

.userPf.my-profile-container {
  width: calc(100% - (8.43vw + 30px)) !important;
  margin-left: 8.43vw !important;
  margin-right: 30px !important;
}

.dbWidget.profileCont.wBg {
  border: none !important;
}

.userPf .profile-head {
  padding-top: 20px;
}

.userPf .employee-details {
  height: calc(100vh - 230px);
  padding-bottom: 0px;
}

.userPf .employee-permissions {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.userPf .employee-main-permissions {
  flex: 1;
}

.my-profile .profileContacts:last-child {
  margin-bottom: 20px;
}

.userPf .profileDtlWrapper {
  overflow-y: auto;
  min-height: 120px;
  max-height: calc(100vh - 500px);
}

.rowGapNf40 {
  margin-top: 40px;
}

.notification-filter .ant-radio-button-wrapper:first-child {
  border-radius: 10px 0 0 10px;
}

.notification-filter .ant-radio-button-wrapper:last-child {
  border-radius: 0px 10px 10px 0px;
}

.notification-filter
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #1e3a46;
}

.notification-filter
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #1e3a46;
}

.notification-filter
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #1e3a46;
  background: #fff;
  border-color: #1e3a46;
}

.notification-filter .ant-radio-button-wrapper:hover {
  color: #1e3a46;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #1e3a46;
}

.notification-filter label {
  font-size: 14px;
}

/* popups styles goes hear **********************************/

.teamEmpContainer .btnteamCancel {
  width: 136px !important;
  height: 32px !important;
}
.teamEmpContainer .btnteamSave {
  width: 136px !important;
  height: 32px !important;
  margin-left: 0 !important;
}
.teamEmpContainer .ant-modal-footer {
  padding: 0 16px 18px 16px !important;
}
.teamEmpContainer .ant-input::placeholder,
.teamEmpContainer .ant-select-selection-placeholder,
.teammodal .ant-input::placeholder,
.teammodal .ant-select-selection-placeholder {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.5px;
  line-height: 22px;
}
.team-text-area .ant-input,
.teammodal .ant-input,
.team-text-area .ant-input::placeholder {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.5px;
  line-height: 22px;
  padding: 10px 8px !important;
}
.team-text-area-2 .ant-input,
.teammodal .ant-input,
.team-text-area-2 .ant-input::placeholder {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.5px;
  line-height: 22px;
}
.teamEmpContainer .createtitle,
.teammodal .createtitle {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.mBtm48 {
  margin-bottom: 48px !important;
}
.mBtm36 {
  margin-bottom: 36px !important;
}

.ant-modal.teammodal,
.ant-modal.versionHistPopup {
  padding-bottom: 0px !important;
  top: 0 !important;
  min-width: 768px !important;
}
.ant-modal.versionHistPopup {
  display: flex;
  align-items: center;
  justify-content: center;
}
.versionHistPopup .ant-modal-content {
  width: 768px;
}

/* atonomy settings model styles goes here *******************/

.autoHeads {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
.autonomy_modal_sec .ant-input {
  border: 1px solid #366376 !important;
  height: 50px;
}
.autonomy_modal_sec .ant-select-selector {
  border: 1px solid #366376 !important;
  height: 50px;
}
.autonomy_modal_sec .generictoggle {
  height: 50px;
}
.autonomy_modal_sec .ant-switch-checked {
  background-color: #366376 !important;
}
.okBtn.autonomySaveBtn {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 1.25px;
  line-height: 18px;
}
.autonomySettingModal .ant-modal-body {
  padding-right: 0 !important;
}
.maintenanceLockModal .createtitle {
  flex-direction: column !important;
}

.rowGapBtm20 {
  margin-bottom: 20px !important;
}

table tr th,
table tr td {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.wwp {
  word-break: break-word;
}

.mapImg {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tcktsBlk .ticketsSearchBar .anticon-search {
  padding-left: 5px;
  margin-top: -2px;
}
.ticketsSearchBar .anticon-search svg {
  width: 16px;
  height: 16px;
}
.searchSec.ticketsSearchBar .ant-input-search input {
  padding-left: 16px;
}

/* fleet usage popup like (Jump&Go, Shadow,Copycat, Guardrails ) changes */

.tblStyles .ant-table-thead > tr > th {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.15px;
  line-height: 19px;
}
.tblStyles .ant-table-tbody > tr > td {
  font-size: 16px;
  font-family: 'Montserrat-Regular';
  letter-spacing: 0.5px;
  line-height: 22px;
}
.taskSearchField .ant-select-selector {
  border: 1px solid #e7e7e7 !important;
  border-radius: 10px !important;
  background: #f7f7f7 !important;
  height: 40px !important;
}
.popupStyles .ant-modal-title {
  font-size: 20px;
  font-family: 'Montserrat-SemiBold' !important;
  letter-spacing: 0;
  line-height: 24px;
}
.popupStyles .formRow .ant-form-item-label > label {
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.125px;
  line-height: 18px;
  margin-bottom: 8px;
}
.popupStyles .ant-modal-footer button {
  min-width: 100px !important;
  width: auto !important;
  height: 34px !important;
}
.popupStyles button.btnUpload {
  width: -webkit-fill-available !important;
}
.popupStyles .cfqRadioBtn .ant-radio-wrapper {
  font-size: 16px;
  font-family: 'Montserrat-SemiBold';
  letter-spacing: 0.15px;
  line-height: 19px;
}
.popupStyles .formRow {
  gap: 15px;
}
.popupStyles .formRow .commInput,
.popupStyles .formRow .ant-select {
  display: flex !important;
  min-width: 245px !important;
  height: 50px;
}
.popupStyles .formRow .formCol {
  margin-right: 0 !important;
}
.popupStyles .ant-form-item-control .ant-input {
  height: 50px;
}
.jumpandgoModal {
  display: flex !important;
  align-items: center;
  padding-bottom: 0 !important;
}
.jumpandgoModal .mainContainer {
  border: 0 !important;
  margin-bottom: 0 !important;
}
.jumpandgoModal .ant-card.jumpandgoCard {
  border: 0 !important;
}
.jumpandgoModal .ant-tabs-content-holder {
  height: 60vh;
  overflow-y: auto;
}
.jumpandgoModal .ant-tabs-tabpane {
  padding-right: 10px;
}
.jumpandgoModal .ant-card-body {
  padding: 0 !important;
  border: 0 !important;
  padding-right: 20px !important;
}
.jumpandgoModal .ant-card.jumpandgoCard .ant-card-body {
  padding: 0 !important;
}
.jumpandgoModal .ant-tabs-nav {
  height: auto !important;
}
.jumpandgoModal .ant-tabs-nav-wrap {
  padding-bottom: 15px;
}
.jumpandgoModal .ant-card.jumpangoDetails .ant-card-body {
  padding: 20px !important;
}
.jumpandgoModal .elTabs > .ant-tabs-nav {
  border-bottom: 0 !important;
}
.guardrailsModal .edit-loader {
  margin-left: 0 !important;
  margin-top: 0 !important;
  transform: translate(-50%, -50%);
}

.profile-details .profile-avatar {
  position: relative;
  display: inline-block;
}
.profile-details .profileEditImg {
  left: unset !important;
  right: 1vw;
  bottom: 56px;
}

h4.errorTcktTitle {
  margin-bottom: 5px !important;
}

.orgTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile_view {
  display: none;
}
.web_view {
  display: block;
}
.driveRoutineFlex .t_signals {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.main-menu:hover .login_user_sec {
  bottom: 14px;
}
.main-menu .login_user_sec .monarch_tech_img {
  display: none;
}
.main-menu:hover .login_user_sec .monarch_tech_img {
  display: block;
  text-align: center;
  margin: auto;
  margin-top: 24px;
}
.main-menu:hover .login_user_sec .monarch_tech_img img {
  width: 88px;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  width: 40px !important;
  height: 40px !important;
}

.rightWidgetView
  .selectImplementContainer
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: auto !important;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  width: 40px !important;
  height: 40px !important;
}

/* implements, add employee, tickets related css ============*/

.autonomy_modal_sec
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 50px;
}
.addEmpBlk .addEmployeeSelect.multiSelectField .ant-select-selector {
  min-height: 50px;
  height: auto;
  max-height: 60px;
}
.addEmpBlk
  .addEmployeeSelect.multiSelectField
  .ant-select-selector
  .ant-select-selection-item {
  min-height: 24px !important;
  height: 24px !important;
}
.multiSelectField .ant-select-selection-overflow-item:last-child {
  display: none;
}
.userProfileBlk .employee-teams {
  min-height: 160px;
  overflow-y: hidden;
}
.rightWidgetView
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
}
.profileView.implementsWidget .ant-select-selector .ant-select-selection-item {
  min-height: 100% !important;
  padding-top: 10px !important;
}
.profileHeight {
  overflow-y: auto;
  min-height: 120px;
  max-height: calc(100vh - 350px);
}
.userPf .profileHeight .profileDtlWrapper {
  max-height: inherit !important;
}
.ticketWidgetView .ant-select-selector .ant-select-selection-item {
  min-height: 100% !important;
}

.autoDempty {
  margin-top: 16px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb12 {
  margin-bottom: 12px;
}

.selClr {
  color: #366376;
  letter-spacing: 0.95px;
}
.mb36 {
  margin-bottom: 36px;
}

.implementImage {
  width: 100%;
  max-height: 237px;
  min-height: 200px;
  border-radius: 0px;
  position: relative;
}
.implementImage img {
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 0px !important;
}

.adrImpDtl .proRow {
  margin-right: 10px;
}
